import { ApiEnvs, DigitalLinkEnvs } from '@peloton/external-links/models';
import type { EnvironmentFlags } from './models';

export const toDigitalLinkEnv = (
  env: EnvironmentFlags,
  apiEnv: ApiEnvs,
): typeof DigitalLinkEnvs[keyof typeof DigitalLinkEnvs] =>
  (env.isProd && DigitalLinkEnvs.Prod) ||
  (env.isProdTest && DigitalLinkEnvs.ProdTest) ||
  (env.isStaging && DigitalLinkEnvs.StgQa) ||
  (env.isStageN && DigitalLinkEnvs.StageN) ||
  digitalEnvFromApiEnv(apiEnv);

const digitalEnvFromApiEnv = (apiEnv: ApiEnvs) =>
  apiEnv === ApiEnvs.Prod ? DigitalLinkEnvs.UatProd : DigitalLinkEnvs.UatQa;

export default toDigitalLinkEnv;
