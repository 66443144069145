// TODO: import directly https://github.com/apollographql/apollo-client/issues/4843
import type { PresetConfig } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import type { FragmentMatcherInterface } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import type { Operation } from 'apollo-link';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { GRAPHQL_ENDPOINT } from '@peloton/app-config';
import { getAPIAuthToken } from '@peloton/auth/config';
import type { ExtLinkEnv } from '@peloton/external-links';
import { ApiEnvs, getApiCluster } from '@peloton/external-links';
import { toMatchingTld } from '@peloton/internationalize';
import { toClientNameAndVersion } from './toClientNameAndVersion';

type Props = Omit<PresetConfig, 'fragmentMatcher'> & {
  env: ExtLinkEnv;
  name: string;
  authorizationHeaderEnabled?: boolean;
  fragmentMatcher?: FragmentMatcherInterface;
  customLink?: CustomLink;
};

type CustomLink = {
  link: ApolloLink;
  test: (op: Operation) => boolean;
};

const toClient = ({
  env,
  name,
  authorizationHeaderEnabled,
  fragmentMatcher,
  customLink,
  ...props
}: Props) =>
  new ApolloClient({
    link: toLink(toUri(env), authorizationHeaderEnabled, customLink),
    cache: new InMemoryCache({
      fragmentMatcher,
    }),
    ...toClientNameAndVersion(name),
    ...props,
  });

export default toClient;

export const toApolloLinkHeaders = (authorizationHeaderEnabled: boolean): object => {
  if (!authorizationHeaderEnabled) return {};
  const token = getAPIAuthToken();
  return token ? { 'X-Secrets-Authorization': `${token}` } : {};
};

const createClientLink = (
  uri: string,
  authorizationHeaderEnabled?: boolean,
  customLink?: CustomLink,
): ApolloLink => {
  const clientLink = createHttpLink({
    uri,
    credentials: 'include',
    headers: toApolloLinkHeaders(!!authorizationHeaderEnabled),
  });

  return customLink
    ? ApolloLink.split(customLink.test, customLink.link, clientLink)
    : clientLink;
};

export const toLink = (
  uri: string,
  authorizationHeaderEnabled?: boolean,
  customLink?: CustomLink,
) =>
  ApolloLink.split(
    ({ getContext }) => getContext().useApq,
    ApolloLink.from([
      // This one has to come first or else it doesnt actually work
      // Also the `as any` here is because
      // the ApolloLink types are different in apollo-link-http and the apq library
      createPersistedQueryLink({ useGETForHashedQueries: true }) as any,
      createClientLink(uri, authorizationHeaderEnabled, customLink),
    ]),
    ApolloLink.from([createClientLink(uri, authorizationHeaderEnabled, customLink)]),
  );

// GraphQL Gateways only exist on these three clusters
const GATEWAY_CLUSTERS = ['stage', 'test', 'prod'];
export const toUri = (env: ExtLinkEnv) => {
  if (GRAPHQL_ENDPOINT) {
    // use webpack proxy to connect to localhost
    return '/graphql';
  } else {
    const cluster = getApiCluster(env.api);
    const tld = toMatchingTld(env.hostname);
    if (!GATEWAY_CLUSTERS.includes(cluster)) {
      return `https://graph.stage.k8s.onepeloton${tld}/graphql`;
    }
    const namespace = getApiNamespace(env.api);
    return `https://graph${namespace}.${cluster}.k8s.onepeloton${tld}/graphql`;
  }
};

const getApiNamespace = (env: ApiEnvs) => {
  if (
    env === ApiEnvs.Prod ||
    env === ApiEnvs.Qa1 ||
    env === ApiEnvs.Qa2 ||
    env === ApiEnvs.Local
  ) {
    return '';
  } else {
    // ApiEnvs must be a k8s API string

    // when using `api-api-core-` prefix, there could be numbers
    if (env.startsWith('api-api-core')) {
      const [namespace] = env.split('.');
      return namespace.replace('api-api-core', '');
    }
    if (getApiCluster(env) === 'dev') {
      // graph only needs the namespace included when on dev
      const [, namespace] = env.split('.');
      return '-' + namespace;
    } else {
      return '';
    }
  }
};
