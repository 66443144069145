/**
 * Given an `input` string and an optional `count`, return a string with the
 * last 1 or `count` breaking spaces replaced by non-breaking spaces.
 *
 * @param {number} [count=1] - The number of spaces to replace, from the end of `input`.
 * @param {string} input - The string to be unorphaned.
 */
import { nbsp } from './nonbreaking';

export const unorphan = (count: number = 1, input: string = ''): string => {
  const pieces = input.split(/\s+/g);
  const firstPiece = pieces.shift() as string;

  return pieces.reduce(
    (result, piece, index) =>
      index >= pieces.length - count ? `${result}${nbsp}${piece}` : `${result} ${piece}`,
    firstPiece,
  );
};

export const unorphanLast = (input?: string) => unorphan(1, input);

export const unorphanAll = (input: string) => input.replace(/\s/g, nbsp);
