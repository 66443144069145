import type { EnvironmentFlags } from '@peloton/env/models';
import { AccountLinkEnvs } from '@peloton/external-links/models';

const toAccountLinkEnv = ({
  isProd,
  isProdTest,
  isLocal,
  isUatProd,
  isStageN,
}: EnvironmentFlags): typeof AccountLinkEnvs[keyof typeof AccountLinkEnvs] =>
  (isProd && AccountLinkEnvs.Prod) ||
  (isProdTest && AccountLinkEnvs.ProdTest) ||
  (isLocal && AccountLinkEnvs.Local) ||
  (isUatProd && AccountLinkEnvs.UatProd) ||
  (isStageN && AccountLinkEnvs.StageN) ||
  AccountLinkEnvs.UatQa;

export default toAccountLinkEnv;
