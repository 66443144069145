const mapToView = (content: any) => ({
  comparisonHero: content['membershipPage']['membershipPage.comparisonHero'],
  comparisonHeroImage: content['membershipPage']['membershipPage.comparisonHeroBg'],
  membershipAppTableCategories: extractValues(
    content['membershipPage']['membershipPage.membershipAppTable'][
      'membershipPage.membershipAppTable.categories'
    ] || {},
  ),
  membershipAppTableProducts: extractValues(
    content['membershipPage']['membershipPage.membershipAppTable'][
      'membershipPage.membershipAppTable.products'
    ] || {},
  ),
  membershipEquipmentTableCategories: extractValues(
    content['membershipPage']['membershipPage.membershipEquipmentTable'][
      'membershipPage.membershipEquipmentTable.categories'
    ] || {},
  ),
  membershipEquipmentTableProducts: extractValues(
    content['membershipPage']['membershipPage.membershipEquipmentTable'][
      'membershipPage.membershipEquipmentTable.products'
    ] || {},
  ),
  monthlyBillingCadence:
    content['membershipPage']['membershipMonthlyBillingCadence']['value'],
  yearlyBillingCadence:
    content['membershipPage']['membershipYearlyBillingCadence']['value'],
  filterToggle: content['membershipPage']['planComparisonTableFilterToggle']?.['value'],
  fromPrefix: content['membershipPage']['fromPrefix']?.['value'],
});

export default mapToView;

const extractValues = (data: object) =>
  Object.values(data).map((item: any) => item.value);
