import { Locale } from '@peloton/internationalize';

type State = Locale;

const defaultState = Locale.Default;

const reducer = (state: State = defaultState) => state;

export type ReducerState = {
  locale: State;
};

export default reducer;
