import { brand, grey, slate } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import { css } from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import { superRed, white } from '@ecomm/colors';

const link = css`
  cursor: pointer;
`;

export const l1 = css`
  ${link}

  ${hoverTransition({
    property: 'color, fill',
    from: superRed,
    to: brand.darkest,
  })}
`;

export const l2 = css`
  ${link}

  ${hoverTransition({
    property: 'color, fill',
    from: brand.darkest,
    to: superRed,
  })}
`;

export const l3 = css`
  ${link}

  ${hoverTransition({
    property: 'color, fill',
    from: grey[70],
    to: brand.darkest,
  })}
`;

export const l4 = css`
  ${link}

  ${hoverTransition({
    property: 'color, fill',
    from: white,
    to: transparentize(0.4, white),
  })}
`;

export const l5 = css`
  ${link}
  color: ${brand.darkest};
  padding-bottom: 1px;
  border-bottom: solid 1px ${grey[70]};

  ${hoverTransition({
    property: 'color, fill, border-color',
    to: superRed,
  })};
`;

export const l6 = css`
  ${link}
  color: ${slate[50]};
  padding-bottom: 1px;
  border-bottom: solid 1px ${slate[50]};

  ${hoverTransition({
    property: 'color, fill, border-color',
    to: brand.darkest,
  })};
`;
