import { prop } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { toLocale } from '@peloton/internationalize';
import type { Validator } from '../models';
import { toAddressValidation, toCreditCardValidation, toUserValidation } from '../models';
import type {
  BlurAddressFieldAction,
  UpdateAddressFieldAction,
  UpdateAddressErrorAction,
} from '../redux/address';
import { ActionTypes as Address, updateAddressError } from '../redux/address';
import type {
  BlurPaymentFieldAction,
  UpdatePaymentErrorAction,
  UpdatePaymentFieldAction,
} from '../redux/payment';
import { ActionTypes as Payment, updatePaymentError } from '../redux/payment';
import type {
  BlurUserFieldAction,
  UpdateUserErrorAction,
  UpdateUserFieldAction,
} from '../redux/user';
import { ActionType as User, updateUserError } from '../redux/user';

export const validateSaga = function* (
  action: Action,
  validator: Validator,
  updateError: UpdateError,
): SagaIterator {
  if (!validator) {
    return;
  }

  const { value } = action.payload;
  try {
    const valid = yield call([validator, 'validate'], value);
    if (valid) {
      yield put(updateError(''));
    }
  } catch (error) {
    yield put(updateError(error.message));
  }
};

export const toValidatorSaga = function* (action: Action): SagaIterator {
  switch (action.type) {
    case Payment.PaymentFieldBlurred:
    case Payment.PaymentFieldUpdated: {
      const { name, paymentMethod } = action.payload;
      const validations = yield call(toCreditCardValidation);
      const updateError = yield call(updatePaymentError as any, paymentMethod, name);
      const validator = yield call(prop as any, name, validations);
      return yield call(validateSaga, action, validator, updateError);
    }

    case Address.AddressFieldBlurred:
    case Address.AddressFieldUpdated: {
      const { addressType, name } = action.payload;
      // TODO: Replace call to toLocale with a selector
      const validations = yield call(toAddressValidation, toLocale());
      const updateError = yield call(updateAddressError as any, addressType, name);
      const validator = yield call(prop as any, name, validations);
      return yield call(validateSaga, action, validator, updateError);
    }

    case User.UserFieldBlurred:
    case User.UserFieldUpdated: {
      const { name } = action.payload;
      const validations = yield call(toUserValidation);
      const updateError = yield call(updateUserError as any, name);
      const validator = yield call(prop as any, name, validations);
      return yield call(validateSaga, action, validator, updateError);
    }

    default:
      return;
  }
};

export const debounce = function* (action: Updates) {
  yield delay(200);
  yield call(toValidatorSaga, action);
};

const watcherSaga = function* () {
  // Must be takeEvery as on registration (or autocomplete), multiple actions
  // are fired simultaneously. Potentially investigate an explicit REGISTER action
  yield takeEvery(
    [Address.AddressFieldUpdated, Payment.PaymentFieldUpdated, User.UserFieldUpdated],
    debounce,
  );
  yield takeLatest(
    [Address.AddressFieldBlurred, Payment.PaymentFieldBlurred, User.UserFieldBlurred],
    toValidatorSaga,
  );
};

export default watcherSaga;

type Action = Blurs | Updates;

type Updates =
  | UpdateAddressFieldAction
  | UpdatePaymentFieldAction
  | UpdateUserFieldAction;

type Blurs = BlurAddressFieldAction | BlurPaymentFieldAction | BlurUserFieldAction;

type ErrorAction =
  | UpdateAddressErrorAction
  | UpdatePaymentErrorAction
  | UpdateUserErrorAction;

type UpdateError = (errorMsg: string) => ErrorAction;
