const mapToView = (content: any) => ({
  gifteeLabel: content['gifting']['giftee.label'],
  gifteeInput: content['gifting']['giftee.input'],
  gifteeDescription: content['gifting']['giftee.description'],
  gifterLabel: content['gifting']['gifter.label'],
  gifterInput: content['gifting']['gifter.input'],
  giftMessage: content['gifting']['gift.message'],
  giftInput: content['gifting']['gift.input'],
  giftMessageCharactersRemaining: content['gifting']['gift.charactersRemaining'],
  giftMessageInvalidInput: content['gifting']['gift.invalidInput'],
  giftReviewOrderTitle: content['gifting']['gift.reviewOrder.title'],
  giftReviewOrderFrom: content['gifting']['gift.reviewOrder.from'],
  giftReviewOrderMyEmail: content['gifting']['gift.reviewOrder.myEmail'],
  giftReviewOrderGiftMessage: content['gifting']['gift.reviewOrder.giftMessage'],
  addAllAccessMembershipTitle: content['gifting']['gift.addAllAccessMembership.title'],
  addAllAccessMembershipDescription:
    content['gifting']['gift.addAllAccessMembership.description'],
  addAllAccessMembershipDisclaimer:
    content['gifting']['gift.addAllAccessMembership.disclaimer'],
  addAllAccessMembershipSelectDuration:
    content['gifting']['gift.addAllAccessMembership.selectDuration'],
  addAllAccessMembershipOption: content['gifting']['gift.addAllAccessMembership.option'],
  addAllAccessMembershipCta: content['gifting']['gift.addAllAccessMembership.cta'],
  missingField: content['gifting']['errors.missingfield'],
  shipGiftTo: content['gifting']['gift.reviewOrder.shipGiftTo'],
});

export default mapToView;
