export const isAbsoluteUrl = (src: string): boolean => /^http(s)?:/.test(src);

const isProtocolRelativeUrl = (src: string): boolean => /^\/\//.test(src);

export const isRelativePath = (src: string): boolean =>
  !isAbsoluteUrl(src) && !isProtocolRelativeUrl(src);

export const isDataUri = (src: string): boolean => /^data:/.test(src);

export const isBlobUri = (src: string): boolean => /^blob:/.test(src);

const toUrlWithProtocol = (src: string): string =>
  isProtocolRelativeUrl(src)
    ? `${typeof window === 'undefined' ? 'https:' : window.location.protocol}${src}`
    : src;

const toAbsoluteUrl = (src: string): string => {
  if (isAbsoluteUrl(src)) {
    return src;
  } else if (isProtocolRelativeUrl(src)) {
    return toUrlWithProtocol(src);
  } else {
    return `${window.location.origin}${src}`;
  }
};

export default toAbsoluteUrl;
