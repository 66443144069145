import { pathOr, pickAll } from 'ramda';
import { MissingKeyError } from '@peloton/copy';
import type { ReportError } from '@peloton/error-reporting';
import type { CompanyPageText, KeyValue } from '../models.generated';

const MISSING_KEY_SAMPLE_RATE = 0.1;

const genericTransformKeyValueData = (
  response: object,
  model: string,
  contentType: string,
  id?: KeyValue | CompanyPageText,
  reportError?: ReportError,
): string => {
  const { value } = pickAll<object, { value?: string }>(
    ['value'],
    pathOr({}, ['data', model], response),
  );

  if (value) {
    return value;
  } else {
    if (Math.random() < MISSING_KEY_SAMPLE_RATE) {
      reportError?.(new MissingKeyError(contentType, id));
    }
    return `${contentType}: ${id}`;
  }
};

export default genericTransformKeyValueData;
