import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import { toStoreSlug } from '@peloton/stores';
import { PlanType } from '../models';
import toSubscriptionPlans from './mappers';

export const toSubscriptionPlansUrl = (planType: PlanType, postalCode?: string) =>
  postalCode
    ? `/ecomm/store/${toStoreSlug()}/${planType}_subscriptions/plans?postal_code=${postalCode}`
    : `/ecomm/store/${toStoreSlug()}/${planType}_subscriptions/plans`;

export const fetchDigitalSubs = (api: Client, postalCode?: string) =>
  api
    .get(toSubscriptionPlansUrl(PlanType.Digital, postalCode))
    .then(pipeDataTwice(toSubscriptionPlans));
