import React from 'react';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';

type Props = {
  content?: string;
  values?: Object;
  children?: any;
};

const FormatText: React.FC<Props> = ({ content = '', values, children }) => {
  const toFormattedText = useGetTextFormatter();

  return typeof children === 'function' ? (
    children(toFormattedText(content, values))
  ) : (
    <>{toFormattedText(content, values)}</>
  );
};

export default FormatText;
