export enum ActionTypes {
  SaveUserPassword = 'ecomm/quick-checkout/SAVE_USER_PASSWORD',
}

const defaultState = null;

const reducer = (state: string | null = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SaveUserPassword: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  userPassword: string;
};

export const saveUserPassword = (password: string | null) =>
  <const>{
    type: ActionTypes.SaveUserPassword,
    payload: password,
  };

export type SaveUserPasswordAction = ReturnType<typeof saveUserPassword>;

type Action = SaveUserPasswordAction;
