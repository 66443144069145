import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import de from 'react-intl/locale-data/de';
// eslint-disable-next-line no-restricted-imports
import fr from 'react-intl/locale-data/fr';
import type { Country, Currency, Language, Locale } from './models';
import { toCountry, toCurrency, toFormats, toLanguage, toLocale } from './models';

type Props = {
  country?: Country;
  language?: Language;
  currency?: Currency;
  locale?: Locale;
};

const Internationalize: React.FC<React.PropsWithChildren<Props & IntlProvider.Props>> = ({
  country = toCountry(),
  language = toLanguage(country),
  currency = toCurrency(country),
  locale = toLocale(language, country),
  messages,
  formats,
  ...props
}) => {
  // For non-en locales react-intl throws errors and breaks
  // if the locale isnt explicitly added
  addLocaleData(de);
  addLocaleData(fr);

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      formats={{
        ...toFormats(currency),
        ...formats,
      }}
      textComponent={React.Fragment}
      {...props}
    />
  );
};

export default Internationalize;
