import type { ID } from '@ecomm/models';
import type { Payment } from './Payment';

export enum CheckoutType {
  Digital = 'digital',
  Quick = 'quick',
}

export type DigitalCheckout = Pick<
  AddressInfo,
  'shippingCountry' | 'shippingPostalCode'
> &
  Policies &
  Purchase &
  User &
  OnlyIncludes;

export type ValidatePromoCode = {
  code: string;
  sku: string;
};

export type ReferrerPayload = {
  code: string;
};

export type QuickCheckout = AddressInfo & Policies & Purchase & User & OnlyIncludes;

type OnlyIncludes = { onlyIncludes?: string[] };

type AddressInfo = {
  shippingAddresseeFirstName: string;
  shippingAddresseeLastName: string;
  shippingCity: string;
  shippingCountry: string;
  shippingPhoneNumber: string;
  shippingPostalCode: string;
  shippingState: string;
  shippingStreetAddress1: string;
  shippingStreetAddress2: string;
};

type Policies = {
  allowMarketing: boolean;
  hasAcceptedPolicy: boolean;
};

type User = {
  email: string;
  password?: string;
};

type Purchase = {
  payments: Payment[];
  productOptionId: ID;
};

export type SuccessRedirectOverride = (
  orderId: string | number,
  promotion?: string,
  data?: { userId?: string },
) => void;
