import { schema } from 'normalizr';
import type { ApiProductOption } from '../types';
import trait from './trait';
import { toProductLine } from './utils';

const processOption = ({
  description,
  displayName: name,
  id,
  imageUrls: images,
  isAvailable = true,
  prepaidMonths,
  priceInCents: price,
  productLine,
  sku,
  slug,
  traitSet: traits,
}: ApiProductOption) => ({
  description,
  id,
  images,
  isAvailable,
  name,
  prepaidMonths: prepaidMonths ? prepaidMonths : undefined,
  price,
  productLine: toProductLine(productLine),
  sku,
  slug,
  traits,
});

const option = new schema.Entity(
  'options',
  { traits: [trait] },
  { processStrategy: processOption },
);

export default option;
