import { createMatchSelector } from 'connected-react-router';
import { map, keys, pathOr } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { eventChannel } from 'redux-saga';
import { all, call, put, select, takeEvery, take } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import {
  getSignedInUserEmail,
  getUserFullName,
  getUser,
  isSignedIn,
} from '@peloton/auth';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import type { StartAction, InitAction } from './redux';
import { ActionType, chatLoaded } from './redux';
import { isChatLoaded } from './selectors';
import toScriptSrc from './toScriptSrc';
import type { UserData } from './utils';
import { startInteraction, getDrift, identifyWithSegmentId } from './utils';
// Ramda's curried assoc doesn't play well with redux-sagas' call.
export const assoc = (key: string, value: any, obj: {}) => (obj[key] = value);

export const initializeChat = function* ({
  payload: { driftKey, locale },
}: InitAction): SagaIterator {
  const isLoaded = yield select(isChatLoaded);

  if (isLoaded) {
    return;
  }

  const script = yield call([document, 'createElement'], 'script');

  yield all([
    call(assoc, 'textContent', toScriptSrc(driftKey, locale), script),
    call(assoc, 'async', true, script),
  ]);

  yield call([document.body, 'appendChild'], script);

  yield call(identifyWithSegmentId);

  yield put(chatLoaded());
};

// key is the drift event we want to listen to
// value is what to call the event for segment
const driftEventsToSegment = {
  startConversation: 'Conversation Started',
  message: 'Message Received',
  emailCapture: 'Email Captured',
  phoneCapture: 'Phone Captured',
  'conversation:selected': 'Conversation Selected',
  'message:sent': 'Message Sent',
  'scheduling:requestMeeting': 'Meeting Request Sent',
  'scheduling:meetingBooked': 'Meeting Booked',
  'conversation:playbookFired': 'Playbook Fired',
  'conversation:playbookClicked': 'Playbook Clicked',
  'conversation:playbookDismissed': 'Playbook Dismissed',
  'conversation:buttonClicked': 'Button Clicked',
  'conversation:firstInteraction': 'First Interaction',
};

const createDriftEventsChannel = () => {
  const events = keys(driftEventsToSegment);

  return eventChannel(emit => {
    const drift = getDrift();

    const subscribe = (event: string) => {
      drift.on(event, (data: any) => {
        emit({
          event,
          ...data,
        });
      });
    };

    map(subscribe, events);

    // eventChannels require an unsubscribe function to be returned
    const unsubscribe = drift.off;

    return () => map(unsubscribe, events);
  });
};

const getProductInterest = function* (): SagaIterator {
  const isBike = yield select(createMatchSelector('/bike'));
  const isTread = yield select(createMatchSelector('/tread'));
  const isDigital = yield select(createMatchSelector('/digital'));

  return isBike ? 'Bike' : isTread ? 'Tread' : isDigital ? 'Digital' : '';
};

export const trackEvents = function* (): SagaIterator {
  const driftEvents = yield call(createDriftEventsChannel);

  while (true) {
    const payload = yield take(driftEvents);
    const segmentEvent = driftEventsToSegment[payload.event];
    const productInterest = yield call(getProductInterest);

    if (segmentEvent) {
      yield call(track, {
        // Namespace with Drift because the events are so generic
        event: `Drift ${segmentEvent}`,
        properties: {
          url: pathOr('', ['location', 'pathname'], window),
          productInterest,
          ...payload,
        },
      });
    }
  }
};

export const identifyOnChatOpen = function* (): SagaIterator {
  const driftOpenChatChannel = yield call(createDriftOpenChatChannel);

  yield take(driftOpenChatChannel);

  const userEmail = yield select(getSignedInUserEmail);
  const fullName = yield select(getUserFullName);
  const user = yield select(getUser);

  if (isSignedIn(user)) {
    yield call(identifyWithSegmentId, <UserData>{
      email: userEmail,
      name: fullName,
      phone: user.phoneNumber,
    });
  }
};

const createDriftOpenChatChannel = () => {
  return eventChannel(emit => {
    const drift = getDrift();

    drift.on('chatOpen', () => {
      emit(true);
    });

    return () => drift.off('chatOpen');
  });
};

export const startInteractionSaga = function* ({
  payload: { id },
}: StartAction): SagaIterator {
  try {
    yield call(startInteraction, id);
  } catch (e) {
    const error = new DomainError(`Error starting interaction #${id} with drift`, e);
    yield put(reportError({ error }));
  }
};

const watcherSaga = function* () {
  yield takeEvery(ActionType.Init, initializeChat);
  yield takeEvery(ActionType.Loaded, trackEvents);
  yield takeEvery(ActionType.Loaded, identifyOnChatOpen);
  yield takeEvery(ActionType.Start, startInteractionSaga);
};

export default watcherSaga;
