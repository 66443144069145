export const treads = '/treads';
export const treadPlus = '/tread-plus';
export const treadPlusReviews = '/tread-plus/reviews';
export const treadPlusReviewsSubmit = '/tread-plus/reviews/submit';
export const treadsSpecs = '/treads/specs';
export const treadsClasses = '/treads/classes';
export const treadSignUp = '/tread/sign-up';
export const treadPlusFinancing = '/tread-plus/financing';
export const bikesSignUp = '/bikes/sign-up';
export const treadPlusSignUp = '/tread-plus/sign-up';
