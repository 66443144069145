import type { AxiosTransformer } from 'axios';
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { identity, ifElse } from 'ramda';

const isFormData = (data: any): data is FormData => {
  return data && data.toString && data.toString() === '[object FormData]';
};

export const toDefaultRequestTransforms = (
  decamelizeIfFn: (str: Object) => Object = decamelizeIf,
): AxiosTransformer[] =>
  ([] as AxiosTransformer[]).concat(
    decamelizeIfFn,
    axios.defaults.transformRequest || [],
  );

export const decamelizeKeysWithNumbers = (str: Object) =>
  decamelizeKeys(str, { split: /(?=[A-Z0-9])/ });

export const decamelizeIf = ifElse(isFormData, identity, decamelizeKeysWithNumbers);

export const toDefaultResponseTransforms = (): AxiosTransformer[] =>
  ([] as AxiosTransformer[]).concat(axios.defaults.transformResponse || [], camelizeKeys);
