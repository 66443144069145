import React from 'react';
import { Helmet } from 'react-helmet';

const Prerender404Tags: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Helmet>
    <meta name="prerender-status-code" content="404" />
  </Helmet>
);

export default Prerender404Tags;
