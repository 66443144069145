import { spacing, white, black } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { bold } from '@peloton/typography';
import { useMicroCopy } from '@content/client/microCopy';

export const TARGET_IDS = {
  main: 'main',
};

type SkipLinkProps = { href: `#${string}` };

const SkipLink: React.FC<React.PropsWithChildren<SkipLinkProps>> = ({
  href,
  children,
  ...remainingProps
}) => (
  <a {...remainingProps} href={href}>
    {children}
  </a>
);

export const SkipLinkToMain: React.FC<React.PropsWithChildren<unknown>> = () => {
  const skipLinkLabel = useMicroCopy('skipToContentLabel') as string;
  return <StyledSkipLink href={`#${TARGET_IDS.main}`}>{skipLinkLabel}</StyledSkipLink>;
};

export const Main: React.FC<
  React.PropsWithChildren<Exclude<React.ComponentPropsWithoutRef<'main'>, 'id'>>
> = props => <main {...props} id={TARGET_IDS.main} tabIndex={-1} />;

const StyledSkipLink = styled(SkipLink)`
  ${bold}
  padding: ${spacing[8]} ${spacing[16]};
  position: absolute;
  top: 0;
  left: 0;
  color: ${black};
  background: ${white};
  transform: translateX(-105%);
  transform: translateX(calc(- 100% - 1px));
  transition: transform 0.15s ease-out;
  z-index: 9999;
  @media (prefers-reduced-motion) {
    transition: none;
  }
  &:focus {
    transform: translateX(0);
  }
`;
