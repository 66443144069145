import type { ReactNode } from 'react';
import React, { createContext, useState, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import type { InViewHookResponse } from 'react-intersection-observer';

type RefValues = {
  height: number;
};

export enum GlobalRefElement {
  BANNER = 'banner',
  HEADER = 'header',
  SUBHEADER = 'subHeader', // SubHeaderNav (old) or Headband (new)
}

type State = {
  banner?: RefValues;
  footerInViewProps?: InViewHookResponse;
  header?: RefValues;
  subHeader?: RefValues;
  subHeaderElement?: HTMLElement | null;
  setElement: (
    elementType: GlobalRefElement,
    dimensions?: RefValues,
    element?: HTMLElement | null,
  ) => void;
};

export const GlobalReferenceContext = createContext<State>({
  setElement: () => {},
});

const NO_REF_VALUE: RefValues = {
  height: 0,
};

type Props = {
  children: ReactNode;
};

const GlobalReferenceProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const [bannerDimensions, setBannerDimensions] = useState<RefValues | undefined>();
  const [headerDimensions, setHeaderDimensions] = useState<RefValues | undefined>();
  const [subHeaderDimensions, setSubHeaderDimensions] = useState<RefValues | undefined>();

  // footer considered in-view when it takes up 50% of viewport
  const footerInViewProps = useInView({ rootMargin: '0px 0px -50% 0px' });

  const [subHeaderElement, setSubHeaderElement] = useState<
    HTMLElement | null | undefined
  >();

  const setElement = (
    elementType: GlobalRefElement,
    dimensions?: DOMRect,
    element?: HTMLElement,
  ) => {
    switch (elementType) {
      case GlobalRefElement.BANNER:
        setBannerDimensions(dimensions || NO_REF_VALUE);
        return;
      case GlobalRefElement.HEADER:
        setHeaderDimensions(dimensions || NO_REF_VALUE);
        return;
      case GlobalRefElement.SUBHEADER:
        setSubHeaderDimensions(dimensions || NO_REF_VALUE);
        setSubHeaderElement(element);
        return;
    }
  };

  const value = useMemo(() => {
    return {
      banner: bannerDimensions,
      footerInViewProps,
      header: headerDimensions,
      subHeader: subHeaderDimensions,
      subHeaderElement,
      setElement,
    };
  }, [
    bannerDimensions,
    footerInViewProps,
    headerDimensions,
    subHeaderDimensions,
    subHeaderElement,
  ]);

  return (
    <GlobalReferenceContext.Provider value={value}>
      {children}
    </GlobalReferenceContext.Provider>
  );
};

export default GlobalReferenceProvider;
