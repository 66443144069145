import mapToView, { mapVariationToView } from './mapToView';

const APP_MEMBERSHIP_ENTRY_ID = 'jiSJLbFwikgNFwU0CxH2U';
const APP_MEMBERSHIP_VARIATION_ENTRY_ID = '6vIVLxWtRRPYogTpPD1UtB';

export const appMembershipVariation = {
  entryId: APP_MEMBERSHIP_VARIATION_ENTRY_ID,
  contentType: 'appMembershipPage.variation',
  mapToView: mapVariationToView,
};

export default {
  entryId: APP_MEMBERSHIP_ENTRY_ID,
  contentType: 'appMembershipPage',
  mapToView,
};
