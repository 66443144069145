import React from 'react';
import type { IntlProps } from '@peloton/internationalize/wrappers/FormattedMessage';
import type { KeyValue } from '@ecomm/copy';
import { FormatText, useKeyValueData } from '@ecomm/copy';

const FormattedMessageFallback: React.FC<IntlProps> = ({
  id,
  values,
  children,
  defaultMessage,
}) => {
  let copy = useKeyValueData(id as KeyValue);
  if (!id) {
    return null;
  }

  if (id.startsWith('/static/media/')) {
    copy = id;
  }

  if (defaultMessage && copy.startsWith('Key Value: ')) {
    copy = defaultMessage;
  }

  return <FormatText content={copy} values={values} children={children} />;
};

export default FormattedMessageFallback;
