// https://pelotoncycle.atlassian.net/wiki/spaces/EO/pages/998408208/WWW+app+Feature+toggles

export enum ToggleStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Dark = 'dark',
  DateTrigger = 'dateTrigger',
  DarkDateTrigger = 'darkDateTrigger',
  Local = 'local',
  Optimizely = 'optimizely',
}
