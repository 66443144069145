import type { RouterRootState, RouterState } from 'connected-react-router';
import { createMatchSelector } from 'connected-react-router';
import {
  any,
  append,
  contains,
  eqProps,
  findIndex,
  flatten,
  lensPath,
  map,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  reduce,
  reject,
  set,
  view,
} from 'ramda';
import { createSelector } from 'reselect';
import { localeUrlRegexMatcher } from '@peloton/internationalize/models/path';
import { isTreadWelcomeBox, ProductLine } from '@ecomm/shop/models';
import { sortProducts } from '@ecomm/shop/sortProducts';
import type { Warranties } from '@ecomm/warranty/models';
import type { ReducerState as WarrantyState } from '@ecomm/warranty/redux';
import { getWarranties } from '@ecomm/warranty/redux';
import type { Cart, Item, SingleItem } from '../../models';
import {
  hasOrIsRow,
  hasOrIsItemByProductLine,
  getItemsByTypeInCart,
  getDevicePackagesWithAccs,
  hasOrIsAccessory,
  hasOrIsBike,
  hasOrIsBikePlus,
  hasOrIsGuide,
  hasOrIsTreadPlus,
  toCartTrackingProperties,
  toItemTrackingProperties,
  hasOrIsTread,
  hasOrIsRefurbishedBike,
  hasOrIsRefurbishedBikePlus,
  hasOrIsUMichBikePlus,
  isColoredBikePlus,
  getRemainingTotal,
} from '../../models';
import type { State } from '../cart';
import type { ReducerState as CartState } from '../combinedReducers';

// Panel
export const selectPanel = (state: CartState) => state.cartSummary.panel;
export const selectPanelOpen = (state: CartState) => selectPanel(state).isOpen;

// Cart
export const selectCart = (state: CartState) => state.cartSummary.cart;
export const selectCartLoading = (state: CartState) => selectCart(state).isLoading;
export const selectCartPayments = (state: CartState) => selectCart(state).cart?.payments;

export const getCartId = createSelector(selectCart, pathOr('', ['cart', 'id']));

export const getCartEmail = createSelector(selectCart, pathOr('', ['cart', 'email']));

export const getCartRemainingTotal = createSelector(selectCart, ({ cart }) =>
  getRemainingTotal(cart?.total, cart?.payments),
);

export const getHasItemJustBeenAddedToCart = (state: CartState) =>
  state.cartSummary.cart.itemHasJustBeenAddedToCart;

export const getShippingMethod = createSelector(
  selectCart,
  pathOr('', ['cart', 'shipmentQuote', 'method']),
);

export const getWarrantiesInCartForProductLine = (productLine: ProductLine) =>
  createSelector<CartState & WarrantyState, State, Warranties, Item[]>(
    selectCart,
    getWarranties,
    (cart, warranties: Warranties) =>
      pathOr(
        [],
        ['cart', 'items'],
      )(cart).filter((item: Item) =>
        contains(item.slug, map(prop('slug'))(warranties[productLine])),
      ),
  );

export const hasBike = (state: CartState) => {
  const items: Item[] = pathOr([], ['cart', 'items'], selectCart(state));
  const itemsInBundles = flatten(map(propOr([], 'items'), items));
  return any(propEq('productLine', ProductLine.Bike), itemsInBundles);
};

export const groupItemsReducer = (acc: SingleItem[], curr: SingleItem) => {
  const i = findIndex(eqProps('name', curr))(acc);
  if (i === -1) {
    return append(curr, acc);
  } else {
    const quantityLens = lensPath([i, 'quantity']);
    const quantity = view<{ quantity: number }[], number>(quantityLens)(acc);
    return set(quantityLens, quantity + 1)(acc);
  }
};

// The any below is due to an error in the ramda typings
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/25581
// TODO: fix when issue is resolved
// only exporting the following methods for testing
export const toItems = pipe(
  propOr([], 'items'),
  reject<any>(isTreadWelcomeBox),
  reduce(groupItemsReducer, []),
  sortProducts,
);

const toCartItemProps = (item: Item) => ({
  id: item.id,
  name: item.name,
  slug: item.slug,
  description: item.description,
  price: item.price,
  type: item.type,
  quantity: item.quantity,
  items: toItems(item),
  images: item.images,
  productLine: item.productLine,
});

export const getCartItems = createSelector(selectCart, (cart: State) =>
  pathOr([], ['cart', 'items'], cart).map(toCartItemProps),
);

export const makeSelectFormattedCartItemNames = createSelector(
  [getCartItems],
  cartItems => {
    const productNameCollection = cartItems.map((item: { name: string }) => item.name);

    return productNameCollection.join(', ', productNameCollection);
  },
);

export const getDevicesInCartForProductLine = (productLine: ProductLine) =>
  createSelector(
    selectCart,
    (cart: State) =>
      pathOr([], ['cart', 'items'], cart).filter((item: Item) =>
        hasOrIsItemByProductLine(productLine)(item),
      ).length,
  );

export const getSubtotal = createSelector(
  [selectCart],
  cartState => cartState.cart?.subtotal || 0,
);

export const getIsGift = createSelector(
  [selectCart],
  cartState => cartState.cart?.isGift || false,
);

export const hasTreadPlusInCartStore = createSelector(selectCart, (cart: State) =>
  pathOr([], ['cart', 'items'], cart).some(
    hasOrIsItemByProductLine(ProductLine.TreadPlus),
  ),
);

export const hasTreadInCartStore = createSelector(selectCart, (cart: State) =>
  pathOr([], ['cart', 'items'], cart).some(hasOrIsItemByProductLine(ProductLine.Tread)),
);

export const hasRowInCartStore = createSelector(selectCart, (cart: State) =>
  pathOr([], ['cart', 'items'], cart).some(hasOrIsItemByProductLine(ProductLine.Row)),
);

export const hasDeviceOfProductLine = (state: CartState, productLine: ProductLine) => {
  const items: Item[] = pathOr([], ['cart', 'items'], selectCart(state));
  const itemsInBundles = flatten(map(propOr([], 'items'), items));
  return any(propEq('productLine', productLine), itemsInBundles);
};

export const hasUMichBikePlusInCartStore = createSelector(selectCart, (cart: State) =>
  pathOr([], ['cart', 'items'], cart).some(hasOrIsUMichBikePlus),
);

export const hasColoredBikePlusInCartStore = createSelector(selectCart, (cart: State) =>
  pathOr([], ['cart', 'items'], cart).some(isColoredBikePlus),
);

export const hasSubscriptions = (state: CartState) => {
  const items: Item[] = pathOr([], ['cart', 'items'], selectCart(state));
  return any(propEq('type', 'subscription'), items);
};

export const getHasTradeIn = (state: CartState) =>
  Boolean(selectCart(state).cart?.tradeIn);

// Tracking
export const getCheckoutTrackProps = createSelector(selectCart, cartState => {
  const cart: Cart = propOr({}, 'cart')(cartState);
  const bikes = getItemsByTypeInCart(hasOrIsBike)(cart);
  const treadPluses = getItemsByTypeInCart(hasOrIsTreadPlus)(cart);

  return {
    propertyType: 'Web',
    hasBike: bikes.length > 0,
    hasTread: treadPluses.length > 0,
    hasDigitalSub: false,
  };
});

export const getCartViewedProps = createSelector(
  selectCart,
  pipe(pathOr([], ['cart', 'items']), toItemTrackingProperties),
);

export const getCheckoutStartedProps = createSelector(
  selectCart,
  pipe(pathOr({}, ['cart']), toCartTrackingProperties),
);

export const isDigitalCheckout = (router: RouterState): boolean =>
  Boolean(
    createMatchSelector('/digital/checkout')({ router }) ||
      createMatchSelector(`/(${localeUrlRegexMatcher})/digital/checkout`)({ router }) ||
      createMatchSelector('/digital/promotions/:slug')({ router }) ||
      createMatchSelector(`/(${localeUrlRegexMatcher})/digital/promotions/:slug`)({
        router,
      }) ||
      createMatchSelector('/digital/offers')({ router }) ||
      createMatchSelector(`/(${localeUrlRegexMatcher})/digital/offers`)({ router }),
  );

export const getPromotionId = createSelector(
  (state: RouterRootState) => state.router,
  router => {
    const match = createMatchSelector('/digital/(promotions|partnerships)/:slug')({
      router,
    });
    const matchI18n = createMatchSelector(
      `/(${localeUrlRegexMatcher})/digital/(promotions|partnerships)/:slug`,
    )({
      router,
    });
    return pathOr(undefined, ['params', 'slug'], match || matchI18n);
  },
);

export const getCheckoutCategory = createSelector(
  selectCart,
  // TODO: use `getRouter()` instead of this inline selector once this issue is fixed:
  // https://github.com/supasate/connected-react-router/issues/427
  (state: RouterRootState) => state.router,
  (cartState, router) => {
    const cart: Cart = propOr({}, 'cart')(cartState);
    const hasBikeInCart = getItemsByTypeInCart(hasOrIsBike)(cart).length > 0;
    const hasRefurbishedBikeInCart =
      getItemsByTypeInCart(hasOrIsRefurbishedBike)(cart).length > 0;
    const hasBikePlusInCart = getItemsByTypeInCart(hasOrIsBikePlus)(cart).length > 0;
    const hasRefurbishedBikePlusInCart =
      getItemsByTypeInCart(hasOrIsRefurbishedBikePlus)(cart).length > 0;
    const hasTreadInCart = getItemsByTypeInCart(hasOrIsTread)(cart).length > 0;
    const hasTreadPlusInCart = getItemsByTypeInCart(hasOrIsTreadPlus)(cart).length > 0;
    const hasGuideInCart = getItemsByTypeInCart(hasOrIsGuide)(cart).length > 0;
    const hasRowInCart = getItemsByTypeInCart(hasOrIsRow)(cart).length > 0;
    const hasAccessoryInCart = getItemsByTypeInCart(hasOrIsAccessory)(cart).length > 0;
    const hasDevicePackagesWithAccessories = getDevicePackagesWithAccs(cart).length > 0;
    // If this is a digital-only checkout page
    if (isDigitalCheckout(router)) {
      // Override all other categories and return just digital
      return 'digital';
    }

    const categories = [
      (hasBikeInCart || hasRefurbishedBikeInCart) && 'bike',
      (hasBikePlusInCart || hasRefurbishedBikePlusInCart) && 'bike-plus',
      hasGuideInCart && 'guide',
      hasTreadInCart && 'tread',
      hasTreadPlusInCart && 'tread-plus',
      hasRowInCart && 'row',
      (hasAccessoryInCart || hasDevicePackagesWithAccessories) && 'accessory',
    ];
    return categories.filter(Boolean).join(',');
  },
);

// Coupon
export const promoCodeError = (state: CartState) =>
  state.cartSummary.coupon.promoException;

export const promoCodeLoading = (state: CartState) =>
  state.cartSummary.coupon.isPromoLoading;

export const isLastCallSuccess = (state: CartState) =>
  state.cartSummary.coupon.isLastCallSuccess;

// Ui
export const getUIState = (state: CartState) => state.cartSummary.ui;
