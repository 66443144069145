import type { CtaWithUrlSetData } from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useIsFaasHoldoutExperimentActive } from '@ecomm/feature-toggle/useFaasHoldoutActive';
import useHasFinancingPartner from '@ecomm/financing/hooks/useHasFinancingPartner';
import { useProductStates } from '@ecomm/product-states/Context';
import { Product } from '@ecomm/product-states/models/product';
import { usePrograms } from '@ecomm/programs/Context';
import type { FinancingAvailabilityState } from '@ecomm/shop/hooks/useBundleFinancing';
import useBundleFinancing from '@ecomm/shop/hooks/useBundleFinancing';
import { BundleType } from '@ecomm/shop/models';
import type { PossibleShowroomState } from '@ecomm/showrooms/model';

export const useGetShowroomStatus = (): PossibleShowroomState => {
  const { showroom } = usePrograms();

  return showroom;
};

export const getBikesFinancing = (financingState: FinancingAvailabilityState | null) => {
  if (!financingState) return undefined;

  return (
    financingState[BundleType.BikePlus] ||
    financingState[BundleType.Bike] ||
    financingState[BundleType.RefurbishedBike] ||
    financingState[BundleType.RefurbishedBikePlus] ||
    false
  );
};

export const ctaKeyToBundleWithBikes = (ctaKey: string | undefined) => {
  switch (true) {
    case ctaKey?.includes('bikes'):
      return 'bikes';
    case ctaKey?.includes(BundleType.Row):
      return BundleType.Row;
    case ctaKey?.includes(BundleType.Tread):
      return BundleType.Tread;
    case ctaKey?.includes('rainforestCafe'):
      return BundleType.RainforestCafe;
    default:
      return '';
  }
};

export const ctaKeyToFinancingAvailability = (
  ctaKey: string | undefined,
  financingState: FinancingAvailabilityState | null,
) => {
  const bundleKey = ctaKeyToBundleWithBikes(ctaKey);

  return bundleKey === 'bikes'
    ? getBikesFinancing(financingState)
    : financingState?.[bundleKey];
};

const getSubdomainToggleId = (
  url: string,
  subdomainAndToggleMap: Record<string, boolean>,
): boolean | undefined => {
  const subdomainRegExps = Object.keys(subdomainAndToggleMap);
  const subdomainKey = subdomainRegExps.find(regExp => RegExp(regExp).test(url));
  return subdomainKey ? subdomainAndToggleMap[subdomainKey] : true;
};

const useFilteredSecondaryLinks = (
  secondaryLinks?: CtaWithUrlSetData,
): Required<CtaWithUrlSetData['ctas']> | undefined => {
  const {
    isProductAvailableForMarketingPages,
    isProductAvailableForPurchaseOrSoldOut,
  } = useProductStates();

  const isAppActive = isProductAvailableForMarketingPages(Product.DigitalApp);
  const isToggleActive = useIsToggleActive();
  const isBikeClassesActive = isToggleActive('bikeClasses');

  const {
    tradeIn: isTradeInActive,
    valueCalculator: isValueCalculatorActive,
    homeTrial: isHomeTrialActive,
  } = usePrograms();

  const showrooms = useGetShowroomStatus();
  const isShowroomsActive = showrooms !== 'DISABLED';
  const isTreadClassesActive = isToggleActive('treadClasses');
  const isTreadReviewsActive = isToggleActive('treadReviews');
  const isTreadPlusSignUpNavLinkActive = isToggleActive('treadPlusSignupNavLink');
  const isGuideReviewsActive = isToggleActive('displayGuideReviews');
  const isStudioInNav = isToggleActive('studioInNav');
  const hasFinancingPartner = useHasFinancingPartner();
  const isRefurbActive =
    isProductAvailableForPurchaseOrSoldOut(Product.RefurbBikePlus) ||
    isProductAvailableForPurchaseOrSoldOut(Product.RefurbBike);

  const isRowLink = secondaryLinks?.key?.includes('row');

  const { financingState } = useBundleFinancing();

  const isFinancingActive = ctaKeyToFinancingAvailability(
    secondaryLinks?.key,
    financingState,
  );
  const isBookingActive = isToggleActive('caesarShowroom') || !isRowLink;

  const shouldShowBikeReviews = isToggleActive('bikeReviews');

  const showRentalsInNav = !useIsFaasHoldoutExperimentActive();

  const atLeastOneCFUAvailable =
    isProductAvailableForMarketingPages(Product.Tread) ||
    isProductAvailableForMarketingPages(Product.TreadPlus) ||
    isProductAvailableForMarketingPages(Product.Bike) ||
    isProductAvailableForMarketingPages(Product.BikePlus) ||
    isProductAvailableForMarketingPages(Product.Row) ||
    isProductAvailableForMarketingPages(Product.RainforestCafe);

  const urlAndToggleMap: Record<string, boolean> = {
    '/app': isAppActive,
    '/bikes/classes': isBikeClassesActive,
    '/bikes/financing': false,
    '/bike/reviews': shouldShowBikeReviews,
    '/bikes/trade-in': isTradeInActive,
    '/calculator': isValueCalculatorActive && hasFinancingPartner,
    '/financing': isFinancingActive,
    '/home-trial': isHomeTrialActive,
    '/showrooms': isShowroomsActive,
    '/tread/classes': isTreadClassesActive,
    '/tread/reviews': isTreadReviewsActive,
    '/tread-plus/financing': false,
    '/tread-plus/sign-up': isTreadPlusSignUpNavLinkActive,
    '/guide/reviews': isGuideReviewsActive,
    '/refurbished': isRefurbActive,
  };

  const subdomainAndToggleMap: Record<string, boolean> = {
    'studio.onepeloton': isStudioInNav,
    'booking.onepeloton': isBookingActive,
  };

  const areBothBikesAvailable =
    isProductAvailableForMarketingPages(Product.Bike) &&
    isProductAvailableForMarketingPages(Product.BikePlus);

  const productStateOverrides: Record<string, boolean> = {
    '/app': isProductAvailableForMarketingPages(Product.DigitalApp),
    '/bikes': areBothBikesAvailable,
    '/bikes/compare': areBothBikesAvailable,
    '/membership': atLeastOneCFUAvailable,
    // TODO
    // '/bike/reviews': areBothBikesAvailable,
    // '/bike/financing': areBothBikesAvailable,
    // '/home-trial': areBothBikesAvailable,
  };

  /*
	Not adding this url to the urlAndToggleMap because the url is different for
	CRA and Next www apps. For CRA, we're using the absolute url and for Next,
	it's relative. It seemed to risky to change the relevant Contentful entry
	because it is being used for other components
	*/
  const isRentalUrl = (url: string) => {
    return url.includes('/bike/rentals');
  };

  if (secondaryLinks) {
    const { ctas } = secondaryLinks;

    const filteredCtas = ctas.filter(({ url }) => {
      const isSubdomain = url.includes('onepeloton');
      if (isRentalUrl(url)) {
        return showRentalsInNav;
      }
      if (isSubdomain) {
        return getSubdomainToggleId(url, subdomainAndToggleMap);
      }
      const filterObject = {
        ...urlAndToggleMap,
        ...productStateOverrides,
      };

      const hasFilter = Object.keys(filterObject).includes(url);

      return (hasFilter && filterObject[url]) || !hasFilter;
    });

    return filteredCtas[0] ? filteredCtas : undefined;
  }
  return undefined;
};

export default useFilteredSecondaryLinks;
