const mapToView = (content: any) => ({
  linkColumns: Object.values(content['footer']['footer.linkColumns'] || {}),
  leadGen: content['footer']['footer.leadGen'],
  contactLinks: content['footer']['footer.contactLinks'],
  socialLinks: content['footer']['footer.socialLinks'],
  appStoreLinks: content['footer']['footer.appStoreLinks'],
  disclaimer: content['footer']['footer.financingDisclosureRefresh'],
  legalLinks: content['footer']['footer.legalLinks'],
  copyright: content['footer']['footer.copyrightText'],
  legalNavigationLabel: content['footer']['footer.legalNavigationLabel'],
  secondaryNavigationLabel: content['footer']['footer.secondaryNavigationLabel'],
  localePicker: toLocalePickerData(content['footer']['footer.localePicker']),
});

export const toLocalePickerData = (data: { [k: string]: any }) => {
  return data
    ? Object.values(data).reduce((acc, curr) => {
        const isNativeCountryNamesAggregate = curr['countries.us'];
        switch (curr.__typename) {
          case 'keyValue':
            return { ...acc, acceptedCards: curr.value };
          case 'image':
            return { ...acc, tuvSudImage: curr };
          case 'promotionalText':
            return { ...acc, modalHeaderText: curr.text };
          default:
            if (isNativeCountryNamesAggregate) {
              return {
                ...acc,
                nativeCountryNames: toNativeCountryNames(curr),
              };
            }
            return { ...acc, [curr.key]: curr };
        }
      }, {})
    : {};
};

const toNativeCountryNames = (data: object) => {
  const countryNames = Object.values(data).reduce((acc, curr) => {
    const key = curr.key.split('countries.').pop();
    return { ...acc, [key]: curr.value };
  }, {});

  return countryNames;
};

export default mapToView;
