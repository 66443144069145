import { createGlobalStyle } from 'styled-components';
import { media } from '@peloton/styles';

export const driftWidgetId = 'drift-frame-controller';
export const driftFrameSelector = '.drift-frame-chat';
export const driftSelector = '#drift-frame-chat, #drift-frame-controller';

// TODO: Would be good to get transition straight from flyout, but we need the `!important` here
// Make sure the z-index is lower than that of the peloton flyout
const ChatStyles = createGlobalStyle`
  ${driftSelector} {
    z-index: 90004 !important;
  }

  ${driftFrameSelector} {
    position: fixed !important;

    ${media.desktop`
      bottom: 55px !important; 
    `}  
  }
  
  ${driftSelector} {
    bottom: 0px !important;
    right: 0px !important;
    transition: 300ms ease !important;

    ${media.tablet`
      bottom: 10px !important;
      right: 20px !important;
      transition: 400ms ease !important;
    `}

    ${media.desktop`
      bottom: 0 !important;
      right: 0 !important;
      margin: 0;
    `}
  }

  @media print {
    ${driftSelector} {
      display: none !important;
    }
  }
`;

export default ChatStyles;
