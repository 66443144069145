import { mapObjIndexed } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { getContext, call, all } from 'redux-saga/effects';

export const getScreenPropsSaga = function* (): SagaIterator {
  try {
    const screenProps = yield getContext(ANALYTICS_CONTEXT);

    if (screenProps) {
      return yield all(
        mapObjIndexed((getter: () => string) => call(getter), screenProps),
      );
    }

    return {};
  } catch {
    // TODO: Handle error?
  }
};

export const ANALYTICS_CONTEXT = 'analyticsContext';

export type ScreenPropsGetters = {
  formFactor(): string;
  platformType(): string;
  screenSize(): string;
};

export type ScreenProps = {
  formFactor: string;
  platformType: string;
  screenSize: string;
};
