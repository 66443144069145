import type { SagaIterator } from 'redux-saga';
import { call, select, takeEvery } from 'redux-saga/effects';
import { fetcherSaga, PlanType } from '@peloton/subscription-plans';
import { isLoaded } from '@ecomm/models';
import { getPlansUIState } from '@onewellness/redux';
import { ActionTypes, failPlansFetch, succeedPlansFetch } from '../redux/plans';

export const getPlansSaga = function* (): SagaIterator {
  const status = yield select(getPlansUIState);
  const loaded = yield call(isLoaded, status);

  if (!loaded) {
    yield call(fetcherSaga, PlanType.Digital, undefined, {
      onSuccess: succeedPlansFetch,
      onError: failPlansFetch,
    });
  }
};

export const requestPlansSaga = function* () {
  yield takeEvery(ActionTypes.PlansFetchRequested, getPlansSaga);
};

export default requestPlansSaga;
