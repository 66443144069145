import { extractNavigationFields } from '../../lib/extractAggregateFields';
import mapToView from './mapToView';

// https://app.contentful.com/spaces/6jnflt57iyzx/environments/master/entries/7HIi2PeCwFsyW1gDnqtdop
const NAV_ENTRY_ID = '7HIi2PeCwFsyW1gDnqtdop';

export default {
  entryId: NAV_ENTRY_ID,
  contentType: 'navigation',
  mapToView,
  customExtractor: extractNavigationFields,
  excludeFromNextGlobalData: true,
};
