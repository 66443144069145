import {
  CONTENTFUL_WWW_PROD_TOKEN,
  CONTENTFUL_WWW_PREVIEW_TOKEN,
} from '@peloton/app-config';
import { createClientHelpers, createClients } from './client';
const wwwPreviewToken =
  CONTENTFUL_WWW_PREVIEW_TOKEN ?? process.env.CONTENTFUL_WWW_PREVIEW_TOKEN;
const wwwProdToken = CONTENTFUL_WWW_PROD_TOKEN ?? process.env.CONTENTFUL_WWW_PROD_TOKEN;

const clientParams = {
  space: '6jnflt57iyzx',
  environment: 'master',
  accessToken: wwwProdToken as string,
  previewAccessToken: wwwPreviewToken,
};

const { client, previewClient } = createClients(clientParams);

const structuredContentClientParams = {
  ...clientParams,
  environment: 'structured-content',
};

const {
  client: structuredContentClient,
  previewClient: structuredContentPreviewClient,
} = createClients(structuredContentClientParams);

const { getClient } = createClientHelpers(client, previewClient);
const { getClient: getStructuredContentClient } = createClientHelpers(
  structuredContentClient,
  structuredContentPreviewClient,
);

export {
  getClient,
  getStructuredContentClient,
  client,
  previewClient,
  structuredContentClient,
  structuredContentPreviewClient,
};

export default client;
