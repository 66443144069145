export enum Partner {
  LG = 'lg_tv',
  Sky = 'sky_tv',
  Peloton = 'peloton',
}

export type Attribution = {
  code?: string;
  type: Partner;
};
