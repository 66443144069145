import React from 'react';
import { connect } from 'react-redux';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { getAppName } from '@peloton/env';
import type {
  ExtLink,
  ExtLinkEnv,
  PeloLink,
  PeloLinkType,
} from '@peloton/external-links';
import { isExtLink, toHref, getExtLinkEnv } from '@peloton/external-links';
import { checkAndRemoveLocalePrefix } from '@peloton/internationalize/models/path';

type ReactAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
type ReactButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type StateProps = { appName: PeloLinkType; extLinkEnv: ExtLinkEnv };

type AnchorProps = Pick<ReactAnchorProps, Exclude<keyof ReactAnchorProps, 'href'>> & {
  to: string;
};

type ButtonProps = Pick<ReactButtonProps, Exclude<keyof ReactButtonProps, 'onClick'>> &
  Required<Pick<ReactButtonProps, 'onClick'>>;

type ExtLinkProps = Pick<ReactAnchorProps, Exclude<keyof ReactAnchorProps, 'href'>> & {
  to: ExtLink;
};

// Subset of ExtLink without social links
type PeloLinkProps = Pick<ReactAnchorProps, Exclude<keyof ReactAnchorProps, 'href'>> & {
  to: PeloLink;
};

type HTMLProps = {
  'data-test-id'?: string | number;
  'data-experiment-id'?: string | number;
  'data-test-class'?: string;
  'aria-label'?: string;
  'data-element-id'?: string;
};
export type Props = (AnchorProps | ButtonProps | ExtLinkProps | NavLinkProps) & HTMLProps;

type MergedProps = StateProps & Props;

// We use a NavLink instead of a Link so that we can set active states if we want.
// The alternative would be passing a prop as to whether to render as a NavLink or a Link
// which would require a more fine-grained refactor of how we use the ecomm Link.

// Pull out dispatch from connect in withExtLinkEnv
const Link = React.forwardRef<any, MergedProps>(({ appName, ...props }, ref) => {
  if (areSelfLinkProps(appName, props)) {
    const { to, extLinkEnv, ...rest } = props;
    return <NavLink innerRef={ref} to={to.path} {...rest} />;
  } else if (areExtLinkProps(props)) {
    const { to, extLinkEnv, children, ...anchorProps } = props;
    return (
      <a ref={ref} href={toHref(to, extLinkEnv)} children={children} {...anchorProps} />
    );
  } else if (areAnchorProps(props)) {
    const { to, extLinkEnv, children, ...anchorProps } = props;
    return <a ref={ref} href={to} {...anchorProps} children={children} />;
  } else if (areButtonProps(props)) {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#onclick_events
    // Summary: Setting `href="#"` when you only have an onClick handler can cause
    //          unexpected behavior and conveys incorrect semantics for screen readers.
    const { extLinkEnv, ...buttonProps } = props;
    return <button ref={ref} {...buttonProps} />;
  }

  //If we get here, we have a NavLink which will use basepath
  const toWithoutLocale = checkAndRemoveLocalePrefix(props.to as string);

  const { extLinkEnv: env, ...otherProps } = props;
  return <NavLink innerRef={ref} {...otherProps} to={toWithoutLocale} />;
});

Link.displayName = 'Link';

const areAnchorProps = (props: Props): props is AnchorProps =>
  'to' in props &&
  typeof props.to === 'string' &&
  (/^(https?:)?\/\//.test(props.to) ||
    /^mailto:/.test(props.to) ||
    /^tel:/.test(props.to));

const areButtonProps = (props: Props): props is ButtonProps =>
  (!('to' in props) || typeof props.to === 'undefined') && Boolean(props.onClick);

const areExtLinkProps = (props: Props): props is ExtLinkProps =>
  'to' in props &&
  typeof props.to === 'object' &&
  'type' in props.to &&
  isExtLink(props.to, (props as MergedProps).extLinkEnv);

const areSelfLinkProps = (appName: PeloLinkType, props: Props): props is PeloLinkProps =>
  areExtLinkProps(props) && props.to.type === appName;

type State = Parameters<typeof getExtLinkEnv>[0] & Parameters<typeof getAppName>[0];
const mapStateToProps = (state: State) => ({
  appName: getAppName(state),
  extLinkEnv: getExtLinkEnv(state),
});

const ConnectedLink = (connect(mapStateToProps, {}, null, { forwardRef: true })(
  Link,
) as unknown) as React.ComponentClass<Props>;

export default ConnectedLink;

export type LinkProps = React.ComponentProps<typeof ConnectedLink>;
