import type { Money, Token } from '@ecomm/models';
import { PaymentMethod } from '@ecomm/models';

export type Payment = {
  token: Token;
  label: PaymentMethod.CreditCard;
  amountInCents: Money;
};

export const toPayment = (token: Token, amountInCents: Money = 0): Payment => ({
  amountInCents,
  label: PaymentMethod.CreditCard,
  token,
});
