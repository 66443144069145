import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { ID, UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  TrackOrderRequested = 'onewellness/pg-corporate-benefits-checkout/ui/TRACK_ORDER_REQUESTED',
  TrackOrderSucceeded = 'onewellness/pg-corporate-benefits-checkout/ui/TRACK_ORDER_SUCCEEDED',
  TrackOrderFailed = 'onewellness/pg-corporate-benefits-checkout/ui/TRACK_ORDER_FAILED',
}

export type State = UIState;

export const defaultState: State = {
  ...toUIState(Status.Init),
};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.TrackOrderRequested:
      return {
        ...toUIState(Status.Loading),
        orderId: action.payload.orderId,
        completion: action.payload.completion,
      };
    case ActionTypes.TrackOrderSucceeded:
      return {
        ...state,
        ...toUIState(Status.Loaded),
      };

    case ActionTypes.TrackOrderFailed: {
      const { exception } = action.payload;
      return {
        ...state,
        ...toUIState(Status.Failed, exception),
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  trackOrder: State;
};

export const requestTrackOrder = (
  orderId: ID,
  completion?: () => void,
): TrackOrderRequestAction => ({
  type: ActionTypes.TrackOrderRequested,
  payload: {
    orderId,
    completion,
  },
});

export const trackOrderSucceeded = (): TrackOrderSuccessAction => ({
  type: ActionTypes.TrackOrderSucceeded,
});

export const trackOrderFailed = (
  errorId: string = 'corporateBenefitsCheckout.errors.trackOrder',
): TrackOrderFailedAction => ({
  type: ActionTypes.TrackOrderFailed,
  payload: { exception: toException(errorId) },
});

export type TrackOrderRequestAction = {
  type: ActionTypes.TrackOrderRequested;
  payload: {
    orderId: ID;
    completion?: () => void;
  };
};
type TrackOrderSuccessAction = { type: ActionTypes.TrackOrderSucceeded };
type TrackOrderFailedAction = {
  type: ActionTypes.TrackOrderFailed;
  payload: { exception: Exception };
};

type Actions = TrackOrderRequestAction | TrackOrderSuccessAction | TrackOrderFailedAction;
