import { toStudioLink, toSocialLink } from '@peloton/external-links';

export const oldStudioHome = toSocialLink('https://studio.onepeloton.com');

export const studioAccount = (subpage: string) => toStudioLink(`/account/${subpage}`);
export const toStudioAccount = (regionSlug: string) =>
  toStudioLink(`/${regionSlug}/account`);
export const studioAccountClasses = studioAccount('classes');
export const studioAccountCredits = studioAccount('credits');
export const studioAccountVerificationToken = (token: string) =>
  studioAccount(`verify-email/${token}`);
export const studioHome = toStudioLink('/');
export const studioLocation = (locationLink: string) => toSocialLink(locationLink);
export const studioOrderCheckout = (orderId: string) =>
  toStudioLink(`/order/${orderId}/checkout`);
export const studioOrderConfirmation = (orderId: string) =>
  toStudioLink(`/order/${orderId}/confirmation`);
export const studioReserveClass = (regionSlug: string, classId: string) =>
  toStudioLink(`/${regionSlug}/schedule/${classId}/reserve`);
export const studioSchedule = toStudioLink('/schedule');
export const toStudioSchedule = (regionSlug: string) =>
  toStudioLink(`/${regionSlug}/schedule`);
export const studioShop = toStudioLink('/shop');
export const studioSupport = toStudioLink('/support');
export const studioSignupCallbackPath = '/signup/callback';
export const studioInstagram = toSocialLink('https://www.instagram.com/pelotonstudios');
