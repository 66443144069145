export enum ActionTypes {
  OverridePurchaseAnnualSub = 'ecomm/quick-checkout/OVERRIDE_PURCHASE_ANNUAL_SUB',
}

const defaultState = false;

const reducer = (state: boolean = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.OverridePurchaseAnnualSub: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  overridePurchaseAnnual: boolean;
};

export const overridePurchaseAnnualSubscriptionSet = (purchaseAnnualSub: boolean) =>
  <const>{
    type: ActionTypes.OverridePurchaseAnnualSub,
    payload: purchaseAnnualSub,
  };

export type EligibilitySetAction = ReturnType<
  typeof overridePurchaseAnnualSubscriptionSet
>;

type Action = EligibilitySetAction;
