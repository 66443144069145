import { isBrowser } from '@peloton/browser';
import { toException } from '@ecomm/exceptions/Exception';

export enum Errors {
  CartIsNotReady = 'cart.exceptions.isNotReady',
  SelfReferral = 'checkout.errors.order.referrerIsReferree',
}

const ERROR_SEARCH = '?error=';

export const getInitialExeption = (windowLocationSearch?: string) => {
  if (!isBrowser && !Boolean(windowLocationSearch)) {
    return;
  }
  const search = windowLocationSearch ?? window.location.search;
  const hasError = search.includes(ERROR_SEARCH);
  if (hasError) {
    const encodedError = search.replace(ERROR_SEARCH, '');
    const exception = localize(encodedError);
    return toException(exception);
  }
  return;
};

const localize = (error: string) => {
  if (error === `Cart+isn%27t+ready+for+checkout`) {
    return Errors.CartIsNotReady;
  }
  if (error === `Self+referral+error`) {
    return Errors.SelfReferral;
  }
  return;
};
