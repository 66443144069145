import type { SagaIterator } from 'redux-saga';
import { spawn, call, select, takeEvery } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import * as Analytics from '@peloton/analytics';
import type { User } from '@peloton/auth';
import { getUser, isSignedIn, UserReducerActionType } from '@peloton/auth';
import { toCountry } from '@peloton/internationalize/models';
import { getLocation } from '@peloton/redux';
import {
  getCheckoutCategory,
  getCheckoutTrackProps,
  makeSelectFormattedCartItemNames,
  selectCart,
} from '@ecomm/cart/redux';
import { getUserEmail, getVerification } from '@ecomm/checkout/redux';
import { RoutePaths as CheckoutRoutes } from '@ecomm/pg-checkout/config';
import { trackMarketingEmailSubmission as _trackMarketingEmailSubmission } from '@ecomm/quick-checkout/analytics';
import { ActionTypes as UserSubscriptionsActionTypes } from '@ecomm/user-subscriptions';
import {
  getExistingDeviceSub,
  getExistingDigitalSub,
} from '../user-subscriptions/selectors';
import type {
  PageViewPayload,
  TrackMarketingEmailSubmissionAction,
  TrackMarketingOptInOutAction,
  TrackPageViewAction,
} from './redux';
import { ActionType } from './redux';
import { getOptInOutFieldsFromLocation } from './selectors';
import { trackUserSubscriptionInfo } from './trackUserSubscriptionInfo';

type Traits = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  hashedEmail: string;
  username: string;
  createdAt: number;
  gender?: string;
};

export const identify = ({ id, ...traits }: Traits, analytics = Analytics) => {
  analytics.identify({
    userId: id,
    properties: analytics.toSegmentReadableKeys(traits),
  });
};

export const analyticsIdentifySaga = function* () {
  const user: User = yield select(getUser);

  if (isSignedIn(user)) {
    const traits = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      hashedEmail: user.hashedEmail,
      username: user.username,
      createdAt: user.createdAt,
      gender: user.gender,
    };

    yield call(identify, traits);
  }
};

const careerDetailsRegexp = new RegExp('/company/careers/([A-z0-9]+)');

// TODO: Find a way to move these pages names into the packages where
//       these pages are defined or to a common space where they can be more
//       easily reviewed.
const toPageName = ({ name, pathname }: PageViewPayload) => {
  if (name) {
    return { name };
  }

  if (pathname === CheckoutRoutes.CheckoutPage) {
    return { name: 'Checkout' };
  }

  if (pathname === '/checkout/review') {
    return { name: 'Checkout Review' };
  }

  if (pathname.match(new RegExp('/checkout/success'))) {
    return { name: 'Checkout Success' };
  }

  if (pathname.match(new RegExp('/orderhistory/[A-z0-9]+'))) {
    return { name: 'Order History Details' };
  }

  if (
    pathname.match(new RegExp('/shipping-reservation/[A-z0-9]+')) ||
    pathname.match(new RegExp('/delivery/[A-z0-9]+'))
  ) {
    return { name: 'Shipping Reservation' };
  }

  if (pathname.match(careerDetailsRegexp)) {
    return { name: 'Careers Details' };
  }

  return { name: pathname };
};

const calculateTrackingPropsSaga = function* (pathname: string): SagaIterator {
  if (pathname === CheckoutRoutes.CheckoutPage) {
    const cart = yield select(selectCart);

    if (cart.cart) {
      const props = yield select(getCheckoutTrackProps);
      return props;
    }
  }

  const careersMatch = pathname.match(careerDetailsRegexp);
  if (careersMatch && careersMatch.length > 1) {
    return { id: careersMatch[1] };
  }

  return {};
};

export const trackPageViewSaga = function* (action: TrackPageViewAction): SagaIterator {
  const pathname = action.payload.pathname;
  const urlParams = action.payload.urlParams || {};

  const extraProps = yield call(calculateTrackingPropsSaga, pathname);

  const pageViewPayload = {
    properties: { path: pathname, ...urlParams, ...extraProps },
    ...toPageName(action.payload),
  };

  yield call(Analytics.page, pageViewPayload);

  (window as any).drift?.page();
};

enum OptInEvents {
  CheckedMarketingConsentBox = 'Checked marketing consent box',
}

export const trackMarketingEmailSubmission = function* (
  action: TrackMarketingEmailSubmissionAction,
): SagaIterator {
  const email = yield select(getUserEmail);
  _trackMarketingEmailSubmission(email);
};

export const trackMarketingOptInOutClick = function* (
  action: TrackMarketingOptInOutAction,
): SagaIterator {
  const country = yield call(toCountry);
  const { pathname } = yield select(getLocation);
  const { email, fieldChecked } = yield select(getOptInOutFieldsFromLocation);
  const productCategory = yield select(getCheckoutCategory);
  const productNames = yield select(makeSelectFormattedCartItemNames);
  let promotion = '';
  let verificationId = '';
  if (productCategory === 'digital') {
    const verification = yield select(getVerification);
    if (!promotion && verification && verification.segment) {
      promotion = verification.segment;
      verificationId = verification.id;
    }
  }

  yield spawn(() => {
    const properties = {
      country,
      email,
      fieldChecked,
      page: pathname,
      productCategory,
      productNames,
      promotion,
      skus: action.payload.skus,
      verificationId,
    };

    track({ event: OptInEvents.CheckedMarketingConsentBox, properties });
  });
};

export const trackUserSubscriptionSaga = function* (): SagaIterator {
  const user: User = yield select(getUser);

  if (isSignedIn(user)) {
    const existingDeviceSub = yield select(getExistingDeviceSub);
    const existingDigitalSub = yield select(getExistingDigitalSub);

    if (existingDeviceSub) {
      yield call(trackUserSubscriptionInfo, {
        subscriptionType: existingDeviceSub.kind,
        subscriptionId: existingDeviceSub.id,
      });
    }

    if (existingDigitalSub) {
      yield call(trackUserSubscriptionInfo, {
        subscriptionType: existingDigitalSub.kind,
        subscriptionId: existingDigitalSub.id,
      });
    }
  }
};

export const analyticsSaga = function* () {
  yield takeEvery(UserReducerActionType.REQUEST_SUCCESS, analyticsIdentifySaga);
  yield takeEvery(UserSubscriptionsActionTypes.Success, trackUserSubscriptionSaga);
  yield takeEvery(ActionType.TrackPageView, trackPageViewSaga);
  yield takeEvery(ActionType.TrackMarketingOptInOutClick, trackMarketingOptInOutClick);
  yield takeEvery(
    ActionType.TrackMarketingEmailSubmission,
    trackMarketingEmailSubmission,
  );
};
