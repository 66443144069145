import { Page, toPageFromPath } from '@onewellness/routes';

export enum OnboardingStep {
  lookup,
  checkYourInbox,
  complete,
}

export const toOnboardingStep = (path: string): OnboardingStep | undefined => {
  const page = toPageFromPath(path);

  switch (page) {
    case Page.CheckEligibility:
      return OnboardingStep.lookup;
    case Page.VerifyEligibility:
      return OnboardingStep.checkYourInbox;
    case Page.BenefitPricing:
    case Page.Checkout:
    case Page.GetStarted:
      return OnboardingStep.complete;
    default:
      return undefined;
  }
};
