import type { personalizedDataResponse } from '@peloton/social-prospects';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  ReferrerRequest = 'ecomm/checkout/referrer/REFERRER_REQUEST',
  ReferrerRequestSuccess = 'ecomm/checkout/referrer/REFERRER_SUCCESS',
  ReferrerRequestFailure = 'ecomm/checkout/referrer/REFERRER_FAILURE',
}

type State = {
  value: personalizedDataResponse | null;
  error?: string;
  ui: UIState;
};

export const defaultState = {
  value: null,
  ui: toUIState(Status.Init),
};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.ReferrerRequest: {
      return {
        ...state,
        ui: toUIState(Status.Loading),
      };
    }

    case ActionTypes.ReferrerRequestSuccess: {
      return {
        ui: toUIState(Status.Loaded),
        value: action.payload.data,
      };
    }

    case ActionTypes.ReferrerRequestFailure: {
      return {
        value: defaultState.value,
        ui: toUIState(Status.Init),
        error: action.payload.errorMsg,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  referrer: State;
};

export const referrerRequest = (code: string) =>
  <const>{
    type: ActionTypes.ReferrerRequest,
    payload: { code },
  };
export const referrerRequestSuccess = (data: personalizedDataResponse) =>
  <const>{
    type: ActionTypes.ReferrerRequestSuccess,
    payload: { data },
  };

export const referrerRequestFailure = (errorMsg: string) =>
  <const>{
    type: ActionTypes.ReferrerRequestFailure,
    payload: { errorMsg },
  };

export type ReferrerRequestAction = ReturnType<typeof referrerRequest>;

export type ReferrerRequestSuccessAction = ReturnType<typeof referrerRequestSuccess>;

export type ReferrerRequestFailureAction = ReturnType<typeof referrerRequestFailure>;

type Action =
  | ReferrerRequestAction
  | ReferrerRequestSuccessAction
  | ReferrerRequestFailureAction;
