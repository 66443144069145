import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { fetchOrder } from '@ecomm/order/api';
import { trackOrderSaga } from '@ecomm/order/sagas';
import type { TrackOrderRequestAction } from '../redux';
import { TrackOrderActionTypes, trackOrderSucceeded, trackOrderFailed } from '../redux';

export const trackCorporateWellnessOrderSaga = function* ({
  payload,
}: TrackOrderRequestAction): SagaIterator {
  try {
    const client = yield getContext(CLIENT_CONTEXT);
    const { rawOrder } = yield call(fetchOrder, client, payload.orderId);

    yield all([
      call(trackOrderSaga, rawOrder, payload.orderId),
      put(trackOrderSucceeded()),
    ]);

    if (payload.completion) {
      yield call(payload.completion);
    }
  } catch (error) {
    yield put(trackOrderFailed());
  }
};

const watcher = function* (): SagaIterator {
  yield takeEvery(
    TrackOrderActionTypes.TrackOrderRequested,
    trackCorporateWellnessOrderSaga,
  );
};

export default watcher;
