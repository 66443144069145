import * as Optimizely from './optimizely';

type GlobalPromiseHooks = {
  optimizelyLoaderPromise: Promise<any>;
};

const promiseHooks = (): GlobalPromiseHooks => (window as any).__promiseHooks;

const install = (projectId: string, handler: any = () => undefined) => {
  // we must in-line require scriptjs, since it has a dependency on the browser window
  const scriptjs = require('scriptjs');
  return scriptjs(Optimizely.getScript({ projectId }), handler);
};

// TODO! Consider replace install with this
const installPromisified = (projectId: string) =>
  promiseHooks()?.optimizelyLoaderPromise ||
  new Promise(resolve => install(projectId, resolve));

export const getExperiments = () => Optimizely.getExperiments();

const hasVariationFor = (
  experiment: string,
  variation: string,
  experiments: { [K: string]: string } = {},
  noControl?: boolean,
) => {
  const variationForExperiment = experiments[experiment];
  return (
    variationForExperiment === variation ||
    (variationForExperiment === undefined && !noControl && variationIsControl(variation))
  );
};

const isVariation = (
  experiment: string,
  variation: string,
  experiments: { [K: string]: string } = {},
) => {
  const variationForExperiment = experiments[experiment];
  if (typeof variationForExperiment === 'undefined') {
    return undefined;
  }
  return variationForExperiment === variation;
};

export const variationIsControl = (variation: string) =>
  variation === 'off' || variation === 'Original';

export { isVariation, install, installPromisified, hasVariationFor };
