import { spacing, grey } from '@pelotoncycle/design-system';
import { createGlobalStyle, css } from 'styled-components';

// Hide One Trust Shield that loads at bottom of page
export const oneTrustReset = css`
  #ot-sdk-btn-floating {
    display: none;
  }
  #onetrust-consent-sdk div#onetrust-banner-sdk {
    display: flex;
    flex-direction: column-reverse;
    gap: ${spacing[8]};
    background: transparent;
    box-shadow: none;
    outline: none;
  }
  #onetrust-banner-sdk button#onetrust-accept-btn-handler,
  #onetrust-banner-sdk button#onetrust-reject-all-handler,
  #onetrust-banner-sdk button#onetrust-pc-btn-handler {
    width: 100%;
  }

  div#onetrust-banner-sdk div.ot-sdk-container {
    background: ${grey[30]};
  }
`;

export const OneTrustStyles = createGlobalStyle`
  ${oneTrustReset}
`;
