import { alert } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { b5, reg } from '@ecomm/typography';
import Banner from './Banner';

const ErrorBanner = styled(Banner)`
  ${b5}
  ${reg}
  background-color: ${alert.warning};
  border-radius: 3px;
  padding: 10px 25px;
  ${media.tablet`
    ${reg}
    padding: 10px 15px;
  `}

  a {
    border-bottom: 1px solid currentColor;
  }
`;

export default ErrorBanner;
