import { pipe, prop, once } from 'ramda';
import { TLD } from '@peloton/app-config';
import type { PosStore } from './PosStore';
import { toPosStore } from './PosStore';
import type { WebStore } from './WebStore';
import { toWebStore } from './WebStore';

export type Store = PosStore | WebStore;

export const _toStore = (hostname: string = TLD ?? window.location.hostname) =>
  /pos.onepeloton.com$/.test(hostname) ? toPosStore() : toWebStore(hostname);

const toStore = once(_toStore);

// Need to annotate because pipe does not support optional first param in initial fn.
const toStoreSlug: (h?: string) => string = pipe<string, Store, string>(
  toStore,
  prop('slug'),
);

export { toStore, toStoreSlug };
