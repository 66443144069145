import { anyPass, complement, propEq } from 'ramda';

export enum Status {
  ActiveAutoRenewOff = 'active_auto_renew_off',
  ActiveCancelScheduled = 'active_scheduled_for_cancellation',
  ActiveGracePeriod = 'active_in_grace_period',
  ActiveNormal = 'active_normal',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Inactive = 'inactive',
  OverdueDelinquent = 'overdue_delinquent',
  OverdueNoPayment = 'overdue_no_payment',
  Paused = 'paused',
  Unused = 'unused',
}

export const isActive = (sub: SubLike): boolean => hasActiveStatus(sub);

export const isInactive = (sub: SubLike): boolean => hasInactiveStatus(sub);

const hasStatus = propEq('status');

const hasActiveStatus = anyPass([
  hasStatus(Status.ActiveAutoRenewOff),
  hasStatus(Status.ActiveCancelScheduled),
  hasStatus(Status.ActiveGracePeriod),
  hasStatus(Status.ActiveNormal),
  hasStatus(Status.OverdueDelinquent),
  hasStatus(Status.OverdueNoPayment),
  hasStatus(Status.Paused),
  hasStatus(Status.Unused),
]);

const hasInactiveStatus = complement(hasActiveStatus);

type SubLike = {
  status: Status;
};
