import type { AxiosError, AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { pipe } from 'ramda';
import type { ClientError } from './ClientError';
import { fromAxiosError } from './ClientError';

export type Client = AxiosInstance;

export const CLIENT_CONTEXT = 'apiClient';

export const GRAPHQL_CLIENT_CONTEXT = 'graphqlClient';

export const toData = <T>(response: AxiosResponse<T>) => response.data;

export const pipeData = <T, U>(mapper: (a: T) => U) => (response: AxiosResponse<T>) =>
  mapper(toData(response));

// Useful for when actual API response has a data property containing the primary content.
export const pipeDataTwice = pipe(pipeData, pipeData);

export const toError = <T>(error: AxiosError): ClientError => fromAxiosError<T>(error);

export const pipeError = <T, U>(errorHandler: (err: ClientError) => U) => (
  error: AxiosError,
) => errorHandler(toError<T>(error));

// When passed as config to requests, prevents 401 handling.
export const toSkipErrorHandlingConfig = (
  mixin: AxiosRequestConfig = {},
  skipErrorHandlers?: Record<number, boolean>,
) => {
  const mergedSkipErrorHandlers = skipErrorHandlers ?? { skipAll: true };

  return {
    meta: {
      skipErrorHandlers: mergedSkipErrorHandlers,
    },
    ...mixin,
  };
};

// TODO: Update with values accepted for the `Peloton-Platform` header.
export type Platform =
  | 'web'
  | 'iOS_app'
  | 'fire_tv'
  | 'android'
  | 'android_tv'
  | 'fire_tablet'
  | 'studio_bike'; // TODO: change to bigclock platform when available

export type ClientRequestConfig = AxiosRequestConfig;
