import { connect } from 'react-redux';
import type { ExtLinkEnv } from './models';
import type { ExtLinkEnvState } from './redux';
import { getExtLinkEnv } from './redux';

export type ExtLinkEnvProps = {
  extLinkEnv: ExtLinkEnv;
};

const mapStateToProps = (state: ExtLinkEnvState): ExtLinkEnvProps => ({
  extLinkEnv: getExtLinkEnv(state),
});

export const withExtLinkEnv = connect(mapStateToProps, {});
