/* eslint react/display-name: 0 */
import { compose, descend, filter, keys, sort } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { compose as recompose } from 'recompose';
import { deepUpdateOnKeys } from '@peloton/hocs';
import type { PropsOf } from '@peloton/react';
import type { Breakpoint, ResponsiveSelectorState } from './reducer';
import {
  getIsBreakpointAt,
  getIsBreakpointGreaterThan,
  getIsBreakpointLessThan,
} from './reducer';

const greaterThanMapStateToProps = (
  state: ResponsiveSelectorState,
  props: ResponsiveProps,
) => ({
  isVisible: getIsBreakpointGreaterThan(state, props.breakpoint),
});

const greaterThanOrEqualMapStateToProps = (
  state: ResponsiveSelectorState,
  props: MultiResponsiveProps,
): SwitchStateProps => ({
  matches: compose<
    Partial<Record<Breakpoint, React.ReactElement<any>>>,
    Breakpoint[],
    Breakpoint[],
    Breakpoint[]
  >(
    sort(descend(breakpoint => state.browser.breakpoints[breakpoint])),
    filter<Breakpoint>(
      breakpoint =>
        getIsBreakpointGreaterThan(state, breakpoint) ||
        getIsBreakpointAt(state, breakpoint),
    ),
    keys as (a: any) => Breakpoint[], // Keys' type is bad.
  )(props.breakpoints),
});

const lessThanMapStateToProps = (
  state: ResponsiveSelectorState,
  props: ResponsiveProps,
) => ({
  isVisible: getIsBreakpointLessThan(state, props.breakpoint),
});

const eitherMapStateToProps = (
  state: ResponsiveSelectorState,
  props: ResponsiveProps,
) => ({
  firstIsVisible: getIsBreakpointLessThan(state, props.breakpoint),
});

const MaybeComponent: React.FC<React.PropsWithChildren<MaybeProps>> = React.memo(props =>
  props.isVisible ? (props.children as React.ReactElement<any>) : null,
);

const EitherComponent: React.FC<React.PropsWithChildren<EitherProps>> = React.memo(
  props => {
    const children = React.Children.toArray(props.children);

    return props.firstIsVisible
      ? (React.Children.only(children[0]) as React.ReactElement<any>)
      : (React.Children.only(children[1]) as React.ReactElement<any>);
  },
);

type EitherToggleProps = EitherProps & {
  children(belowBreakpoint: boolean): React.ReactNode | null;
};

const EitherToggle: React.FC<EitherToggleProps> = React.memo(
  ({ children, firstIsVisible }) => {
    // Ensure the component returns a ReactElement or null
    const content = children(firstIsVisible);
    if (React.isValidElement(content) || content === null) {
      return content;
    }
    // Optionally, return null or wrap in a fragment if content is not a valid element
    return null; // Or return <>{content}</> if you want to render it as text or other non-element values
  },
) as React.FC<EitherToggleProps>;

const SwitchComponent: React.FC<
  React.PropsWithChildren<SwitchStateProps & MultiResponsiveProps>
> = React.memo(
  props =>
    props.breakpoints[props.matches.filter(match => props.breakpoints[match])[0]] || null,
);

const GreaterThan = connect<MaybeProps, {}, ResponsiveProps>(greaterThanMapStateToProps)(
  MaybeComponent,
);
const LessThan = connect<MaybeProps, {}, ResponsiveProps>(lessThanMapStateToProps)(
  MaybeComponent,
);
const BreakpointEither = connect<EitherProps, {}, ResponsiveProps>(eitherMapStateToProps)(
  EitherComponent,
);
const BreakpointToggle = connect<EitherProps, {}, Omit<ResponsiveProps, 'children'>>(
  eitherMapStateToProps,
)(EitherToggle);

const BreakpointSwitchConnectEnhancer = connect<
  SwitchStateProps,
  {},
  MultiResponsiveProps
>(greaterThanOrEqualMapStateToProps);

const BreakpointSwitch = BreakpointSwitchConnectEnhancer(SwitchComponent);

const OptimizedBreakpointSwitch = recompose<
  PropsOf<typeof SwitchComponent>,
  MultiResponsiveProps
>(
  BreakpointSwitchConnectEnhancer,
  deepUpdateOnKeys<SwitchStateProps & MultiResponsiveProps>(['breakpoints', 'matches']),
)(SwitchComponent);

export {
  BreakpointToggle,
  GreaterThan,
  LessThan,
  BreakpointEither,
  BreakpointSwitch,
  OptimizedBreakpointSwitch,
};

type ResponsiveProps = {
  breakpoint: Breakpoint;
  children?: React.ReactNode | React.ReactNode[];
};
export type SwitchConfig = Partial<Record<Breakpoint, React.ReactElement<any>>>;
type MultiResponsiveProps = { breakpoints: SwitchConfig };
type MaybeProps = { isVisible: boolean };
type EitherProps = { firstIsVisible: boolean };
type SwitchStateProps = { matches: Breakpoint[] }; // Ordered by priority (larger breakpoints first)
