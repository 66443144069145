import { createSelector } from 'reselect';
import type { UserSelectorState } from '@peloton/auth/redux';
import { getSignedInUserEmail } from '@peloton/auth/redux';
import type { Omit } from '@peloton/types';
import type { Address, AddressType } from '@ecomm/checkout/models';
import type { ReducerState as CheckoutState } from '@ecomm/checkout/redux';
import {
  getCheckoutAddress,
  getMarketingPreference,
  getUserEmail,
  getPromoField,
} from '@ecomm/checkout/redux';
import type { BypassState, DigitalCheckout, QuickCheckout, Severity } from '../models';
import type { ReducerState } from './rootReducer';

export type RootState = ReducerState & CheckoutState & UserSelectorState;

export const getQuickCheckoutState = (state: ReducerState) =>
  state.quickCheckout.ui.status;

export const getUserPassword = (state: RootState): string =>
  state.quickCheckout.userPassword;

export const getQuickCheckoutData = createSelector<
  RootState,
  { addressType: AddressType },
  Address,
  string | undefined,
  string,
  boolean,
  Omit<QuickCheckout, 'payments' | 'productOptionId'>
>(
  getCheckoutAddress,
  getSignedInUserEmail,
  getUserEmail,
  getMarketingPreference,
  (address, signedInEmail, checkoutEmail, allowMarketing) => ({
    shippingAddresseeFirstName: address.firstName,
    shippingAddresseeLastName: address.lastName,
    shippingCity: address.city,
    shippingCountry: address.country.toUpperCase(),
    shippingPhoneNumber: address.phone,
    shippingPostalCode: address.postalCode,
    shippingState: address.state,
    shippingStreetAddress1: address.line1,
    shippingStreetAddress2: address.line2 || '',
    email: signedInEmail || checkoutEmail,
    hasAcceptedPolicy: true,
    allowMarketing,
  }),
);

export const getDigitalCheckoutData = createSelector<
  RootState,
  { addressType: AddressType },
  Address,
  string | undefined,
  string,
  boolean,
  string,
  string,
  Omit<DigitalCheckout, 'payments' | 'productOptionId'>
>(
  getCheckoutAddress,
  getSignedInUserEmail,
  getUserEmail,
  getMarketingPreference,
  getPromoField,
  getUserPassword,
  (address, signedInEmail, checkoutEmail, allowMarketing, accessCode, userPassword) => ({
    shippingCountry: address.country.toUpperCase(),
    shippingPostalCode: address.postalCode,
    email: signedInEmail || checkoutEmail,
    hasAcceptedPolicy: true,
    allowMarketing,
    accessCode,
    password: userPassword,
  }),
);

export const getDigitalErrors = (
  state: ReducerState,
  props: { severity: Severity },
): string => state.quickCheckout.digital[props.severity];

export const getPostalCode = createSelector<
  RootState,
  { addressType: AddressType },
  Address,
  string
>([getCheckoutAddress], address => address.postalCode);

export const getBypass = (state: ReducerState): BypassState => state.quickCheckout.bypass;

export const getOverridePurchaseAnnual = (state: RootState): boolean =>
  state.quickCheckout.overridePurchaseAnnual;

export const getRegisterUserState = (state: ReducerState) =>
  state.quickCheckout.registerUser.status;
