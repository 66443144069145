import { routerMiddleware } from 'connected-react-router';
import { pipe } from 'ramda';
import * as Redux from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';
import history from './history';

export const toMiddlewareOpts = (
  additionalMiddleware: Redux.Middleware[],
): MiddlewareOpts => {
  const middleware = [routerMiddleware(history as any), thunk, ...additionalMiddleware];
  const enhancers = [responsiveStoreEnhancer];

  return {
    compose:
      typeof window === 'undefined'
        ? Redux.compose
        : (<any>window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || Redux.compose,
    enhancers,
    middleware,
  };
};

export type MiddlewareOpts = {
  compose: typeof Redux.compose;
  enhancers: Redux.StoreEnhancer<any>[];
  middleware: Redux.Middleware[];
};

const applyMiddleware = ({
  compose,
  enhancers,
  middleware,
}: MiddlewareOpts): Redux.StoreEnhancer<any> =>
  compose(...enhancers, Redux.applyMiddleware(...middleware));

const toMiddleware = pipe(toMiddlewareOpts, applyMiddleware);

export const withAdditionalMiddleware = (appMiddleware: Redux.Middleware[]) => (
  storeMiddleware: Redux.Middleware[],
) => toMiddleware([...storeMiddleware, ...appMiddleware]);

export default toMiddleware;
