import { combineReducers } from 'redux';
import type { ReducerState as ApplyBenefitReducerState } from '../applyBenefit';
import applyBenefit from '../applyBenefit';
import type { ReducerState as LookupReducerState } from '../lookup';
import lookup from '../lookup';
import type { ReducerState as PartnerReducerState } from '../partnerLookup';
import partner from '../partnerLookup';
import type { ReducerState as PlansReducerState } from '../plans';
import plans from '../plans';
import type { ReducerState as RegisterReducerState } from '../register';
import register from '../register';
import type { ReducerState as TrackOrderReducerState } from '../trackOrder';
import trackOrder from '../trackOrder';
import type { ReducerState as UIReducerState } from './ui';
import ui from './ui';

export type State = ApplyBenefitReducerState &
  LookupReducerState &
  PartnerReducerState &
  PlansReducerState &
  RegisterReducerState &
  TrackOrderReducerState &
  UIReducerState;

export default combineReducers({
  ui,
  lookup,
  partner,
  plans,
  register,
  applyBenefit,
  trackOrder,
});

export type ReducerState = {
  benefits: State;
};
