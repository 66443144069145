import { asyncComponent } from '@peloton/code-splitting';
import BaseModal from './BaseModal';
import ModalGlobalStyles from './ModalGlobalStyles';
import type { ModalProps as TModalProps } from './ModalView';
import ModalView, { ModalWidth } from './ModalView';
import { reducer, modalOpened, modalClosed, Actions } from './redux';
import { isOpen } from './selectors';

const Modal = asyncComponent(
  () => import(/* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './Modal'),
);

const TrackingVideoModal = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './TrackingVideoModal'
    ),
);

const TwoColumnModal = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './TwoColumnModal'
    ),
);

const VideoModal = asyncComponent(
  () =>
    import(/* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './VideoModal'),
);

const VideoModalWithContent = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './VideoModalWithContent'
    ),
);

const ViewportModal = asyncComponent(
  () =>
    import(/* webpackChunkName: "ModalChunk", webpackPrefetch: true */ './ViewportModal'),
);

export {
  Actions,
  BaseModal,
  Modal,
  ModalView,
  ModalGlobalStyles,
  ModalWidth,
  TrackingVideoModal,
  TwoColumnModal,
  VideoModal,
  VideoModalWithContent,
  ViewportModal,
  isOpen,
  modalClosed,
  modalOpened,
  reducer,
};

export type ModalProps = TModalProps;
