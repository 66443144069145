import { parse } from 'querystring';
import type { Time } from '@peloton/time';
import { isActiveNow, toDateTrigger } from '@ecomm/date-trigger';
import type { ToggleDefinition } from './baseToggles';
import { ToggleStatus } from './ToggleStatus';

export type Toggle = {
  active: boolean;
  original: ToggleStatus;
  start?: string;
  end?: string;
};

export type Toggles<T extends string> = {
  [K in T]: Toggle;
};

export type ToggleDefinitions<T extends string> = Record<T, ToggleDefinition>;

export const isToggleEnabled = ({
  toggle,
  isDarkEnv,
  isLocalEnv,
  now,
}: {
  toggle: ToggleDefinitions<string>[keyof ToggleDefinitions<string>];
  isDarkEnv: boolean;
  isLocalEnv: boolean;
  now?: Time;
}) => {
  switch (toggle.status) {
    case ToggleStatus.Enabled:
      return true;
    case ToggleStatus.Dark:
      return isDarkEnv;
    case ToggleStatus.DateTrigger:
      return isActiveNow(
        toDateTrigger({
          start: toggle.start,
          end: toggle.end,
        }),
        now,
      );
    case ToggleStatus.DarkDateTrigger:
      return (
        isDarkEnv &&
        isActiveNow(
          toDateTrigger({
            start: toggle.start,
            end: toggle.end,
          }),
          now,
        )
      );
    case ToggleStatus.Local:
      return isLocalEnv;
    default:
      return false;
  }
};

type ParsedUrlQuery = {
  [key: string]: string | string[] | undefined;
};

// For Next.JS applications that already have parsed query string
export const getNextQueryToggles = (allowToggles: boolean, query: ParsedUrlQuery) =>
  allowToggles ? handleQuery(query) : {};

export const getQueryToggles = (
  isDark: boolean,
  isLocal: boolean,
  search: string,
): Toggles<string> => {
  if (!isDark && !isLocal) {
    return {};
  }
  return handleQuery(parse(search.replace('?', '')));
};

const handleQuery = (queryObject: ParsedUrlQuery) => {
  let { toggleOn = [], toggleOff = [] } = queryObject;
  toggleOn = Array.isArray(toggleOn) ? toggleOn : [toggleOn];
  toggleOff = Array.isArray(toggleOff) ? toggleOff : [toggleOff];

  return {
    ...toQueryToggle(true)(toggleOn),
    ...toQueryToggle(false)(toggleOff),
  };
};

const toQueryToggle = (active: boolean) => (toggles: string[]) =>
  toggles.reduce<Toggles<string>>(
    (acc, toggleName) => ({
      ...acc,
      [toggleName]: {
        active,
        original: active ? ToggleStatus.Enabled : ToggleStatus.Disabled,
      },
    }),
    {},
  );

export const computeToggles = (
  toggleDefinitions: ToggleDefinitions<string>,
  isDark: boolean,
  isLocal: boolean,
  now?: Time,
): Toggles<string> => {
  const computedToggles = Object.keys(toggleDefinitions).reduce((acc, name) => {
    const feature = toggleDefinitions[name];
    acc[name] = {
      active: isToggleEnabled({
        isDarkEnv: isDark,
        isLocalEnv: isLocal,
        toggle: feature,
        now,
      }),
      original: feature.status,
      ...(feature.status === ToggleStatus.DateTrigger ||
      feature.status === ToggleStatus.DarkDateTrigger
        ? {
            start: feature.start,
            end: feature.end,
          }
        : {}),
    };
    return acc;
  }, {}) as Toggles<string>;
  return computedToggles;
};
