import { useMemo } from 'react';

type ValueTuple = [string, string];

const parseCookies = () => {
  const cookieValues = document.cookie.split(';').map(entry => {
    const separatorIndex = entry.indexOf('=');
    const name = entry.slice(0, separatorIndex);
    const value = entry.slice(separatorIndex + 1);
    return [name, value].map(v => decodeURIComponent(v.trim())) as ValueTuple;
  });
  return new Map(cookieValues);
};

const useCookies = () => {
  const value = useMemo(
    () => ({
      getCookie: (cookie: string) => parseCookies().get(cookie),
    }),
    [],
  );

  return value;
};

export default useCookies;
