import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { AdditionalTrackingProps } from '@ecomm/pg-checkout/analytics/trackCheckoutSteps';
export enum ActionType {
  Load = 'ecomm/checkout/PAGE_LOADING',
  Ready = 'ecomm/checkout/PAGE_READY',
  Fail = 'ecomm/checkout/PAGE_FAILED',
}

export const initialState = toUIState(Status.Loading);

const reducer = (state: UIState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.Load:
      return toUIState(Status.Loading);

    case ActionType.Ready:
      return toUIState(Status.Loaded);

    case ActionType.Fail:
      return toUIState(Status.Failed, action.payload.exception);

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  ui: UIState;
};

export const loadCheckout = (): CheckoutLoadingAction => ({
  type: ActionType.Load,
});

export const checkoutLoadingFailed = (
  errorId: string = 'shop.errors.checkoutLoading',
): CheckoutFailedAction => ({
  type: ActionType.Fail,
  payload: {
    exception: toException(errorId),
  },
});

export const checkoutReady = (
  payload: AdditionalTrackingProps = {},
): CheckoutReadyAction => ({
  type: ActionType.Ready,
  payload,
});

type CheckoutLoadingAction = {
  type: ActionType.Load;
};

type CheckoutFailedAction = {
  type: ActionType.Fail;
  payload: {
    exception: Exception;
  };
};

export type CheckoutReadyAction = {
  type: ActionType.Ready;
  payload: AdditionalTrackingProps;
};

type Action = CheckoutLoadingAction | CheckoutReadyAction | CheckoutFailedAction;
