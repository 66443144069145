import type { Reducer } from 'redux';
import type { CheckoutBillingInfo } from '../models';

export type State = CheckoutBillingInfo;

export enum ActionType {
  UpdateBillingInfo = 'pelo/updateShipping/UPDATE_BILLING_INFO',
}

export const reducer: Reducer<State | {}> = (state = {}, action: Action) => {
  switch (action.type) {
    case ActionType.UpdateBillingInfo:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  billing: State;
};

type Action = UpdateBillingInfo;

export type UpdateBillingInfo = {
  type: ActionType.UpdateBillingInfo;
  payload: Partial<State>;
};

export const updateBillingInfo = (billingInfo: Partial<State>): UpdateBillingInfo => ({
  type: ActionType.UpdateBillingInfo,
  payload: billingInfo,
});
