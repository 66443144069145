import uniqBy from 'lodash/uniqBy';
import type { Reducer } from 'redux';
import type { TypedAction } from '@peloton/redux';

const giftCardsReducer: Reducer<AddedGiftCardData[]> = (
  state = defaultState,
  action: Action,
) => {
  switch (action.type) {
    case Type.addGiftCard:
      return addUniqueGiftCard(state, action.payload);
    case Type.removeGiftCard:
      return removeGiftCardByTokenFromArray(state, action.payload);
    default:
      return state;
  }
};

const defaultState: AddedGiftCardData[] = [];

export type AddedGiftCardData = {
  paymentMethodToken: string;
  lastFour: string;
  balance: number;
  currency: string;
};

// Actions

type Action =
  | TypedAction<ReturnType<typeof addGiftCard>, Type.addGiftCard>
  | TypedAction<ReturnType<typeof removeGiftCardByToken>, Type.removeGiftCard>;

enum Type {
  addGiftCard = 'membership/checkout/ADD_GIFT_CARD',
  removeGiftCard = 'membership/checkout/REMOVE_GIFT_CARD',
}

// Action creators

const addGiftCard = (giftCard: AddedGiftCardData) => ({
  type: Type.addGiftCard,
  payload: giftCard,
});

const removeGiftCardByToken = (paymentMethodToken: string) => ({
  type: Type.removeGiftCard,
  payload: paymentMethodToken,
});

type State = { quickCheckout: { giftCards: AddedGiftCardData[] } };

// Selectors

const getAppliedGiftCardsList = (state: State) => state.quickCheckout.giftCards;

export { giftCardsReducer, addGiftCard, getAppliedGiftCardsList, removeGiftCardByToken };

const addUniqueGiftCard = (
  appliedGiftCardsList: AddedGiftCardData[],
  giftCard: AddedGiftCardData,
): AddedGiftCardData[] => {
  return uniqBy([...appliedGiftCardsList, giftCard], 'paymentMethodToken');
};

const removeGiftCardByTokenFromArray = (
  appliedGiftCardsList: AddedGiftCardData[],
  token: string,
) => {
  return appliedGiftCardsList.filter(giftCard => giftCard.paymentMethodToken !== token);
};
