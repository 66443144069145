import {
  Country,
  Language,
  Locale,
  SupportedTLD,
  toCountry,
  toCountryFromString,
  toDisplayName,
  toLocale,
  toLocaleFromCountry,
  toLocaleFromString,
} from '../models';
import type {
  AddressToValidateForEcommCheckout,
  PelotonPlatform,
  RentalLocale,
  SupportContact,
  RentalPrices,
} from './models';
import {
  ADDRESS_KEYS_FOR_ECOMM_CHECKOUT_VALIDATION,
  AT_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT,
  AU_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT,
  AU_SUPPORT_CONTACT,
  AustraliaSegmentKey,
  CA_BIKE_PLUS_RENTAL_PRICE,
  CA_BIKE_RENTAL_PRICE,
  CA_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT,
  CanadaSegmentKey,
  DE_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP,
  DE_BIKE_PLUS_RENTAL_PRICE,
  DE_BIKE_RENTAL_PRICE,
  GermanySegmentKey,
  LOCALE_ALT_TEXT_MAP,
  LOCALE_CURRENCY_SYMBOL_MAP,
  LOCALE_DISPLAY_NAME_MAP_FOR_STUDIO_CHECKOUT_CITY_STATE_POSTAL_INPUTS,
  STRIPE_ACCOUNT_KEYS_FOR_ECOMM,
  SupportedLocaleForUniversalLogin,
  UK_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP,
  UK_BIKE_PLUS_RENTAL_PRICE,
  UK_BIKE_RENTAL_PRICE,
  UK_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT,
  UK_SUPPORT_CONTACT,
  UKSegmentKey,
  US_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP,
  US_BIKE_PLUS_RENTAL_PRICE,
  US_BIKE_RENTAL_PRICE,
  US_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT,
  US_SUPPORT_CONTACT,
  USSegmentKey,
} from './models';
import { AUStates, Provinces, States } from './States';

/** @description This helps fallback in areas where builds would fail due to missing translations */
export const isTldTranslationIncomplete = (TLD = '') => TLD === '.at';

/** @description This helps fallback in areas where builds would fail due to missing translations */
export const isLocaleTranslationIncomplete = (locale?: Locale) =>
  locale === Locale.GermanAustria;

// locale pattern can be .locale from TLD or lang-Locale from route
export const transformLocaleForEcommClasses = (locale: string): Locale => {
  // if locale is from route
  if (locale.includes('-')) {
    // for english we're building with US locale always
    if (locale.includes('en')) return Locale.Default;

    // otherwise we assume locale is correct and return locale from route
    return toLocaleFromString(locale);
  }

  // Next approach could provide more locales, that currently are not supported
  // return toLocaleFromCountry(toCountry(locale));

  const localeMap = {
    '.de': Locale.GermanGermany,
    '.com': Locale.Default,
    '.co.uk': Locale.Default,
    '.ca': Locale.Default,
    '.com.au': Locale.Default,
    '.at': Locale.GermanGermany,
  };

  return localeMap[locale] || Locale.Default;
};

export const getTldForUniversalLogin = (locale: SupportedLocaleForUniversalLogin) => {
  switch (locale) {
    case SupportedLocaleForUniversalLogin.GermanGermany:
      return SupportedTLD.De;
    case SupportedLocaleForUniversalLogin.GermanAustria:
      return SupportedTLD.At;
    case SupportedLocaleForUniversalLogin.EnglishUnitedKingdom:
      return SupportedTLD.CoUk;
    case SupportedLocaleForUniversalLogin.EnglishCanada:
      return SupportedTLD.Ca;
    case SupportedLocaleForUniversalLogin.EnglishAustralia:
      return SupportedTLD.Au;
    case SupportedLocaleForUniversalLogin.EnglishUnitedStates:
    default:
      return SupportedTLD.Com;
  }
};

export const isPlatformStripeAccount = (stripeAccountKey: string) =>
  stripeAccountKey === STRIPE_ACCOUNT_KEYS_FOR_ECOMM[Locale.EnglishUnitedStates];

/** @description Clumsily-named so we can reason about how this is used from centralized config */
export const theUserIsInGermany = (locale: Locale | string | Country) =>
  locale === Locale.GermanGermany ||
  locale === toDisplayName(Locale.GermanGermany) ||
  locale === Country.Germany;

/** @description Clumsily-named so we can reason about how this is used from centralized config */
export const theUserIsInAustria = (locale: Locale | string | undefined) =>
  locale === Locale.GermanAustria || locale === '.at';

/** @description Clumsily-named so we can reason about how this is used from centralized config */
export const theUserIsInTheUnitedStates = (locale: Locale | Country) =>
  locale === Locale.EnglishUnitedStates || locale === Country.UnitedStates;

/** @description Clumsily-named so we can reason about how this is used from centralized config */
export const theUserIsInTheUnitedKingdom = (locale: Locale | Country | string) =>
  locale === Locale.EnglishUnitedKingdom || locale === Country.UnitedKingdom;

/** @description Clumsily-named so we can reason about how this is used from centralized config */
export const theUserIsInCanada = (locale: Locale | Country | string) =>
  locale === Locale.EnglishCanada || locale === Country.Canada;

/** @description Clumsily-named so we can reason about how this is used from centralized config */
export const theUserIsInAustralia = (locale: Locale | Country) =>
  locale === Locale.EnglishAustralia || locale === Country.Australia;

/** @description Clumsily-named so we can reason about how this is used from centralized config */
export const theUserIsInMexico = (locale: Locale) => locale === Locale.SpanishMexico;

export const getPelotonAddressUsedInAccountPgMyMembership = (locale: Locale) => {
  if (locale === Locale.EnglishUnitedKingdom) {
    return UK_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP;
  } else if (locale === Locale.GermanGermany) {
    return DE_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP;
  } else {
    return US_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP;
  }
};

export const getPelotonAddress = (locale: Locale) => {
  if (locale === Locale.EnglishUnitedKingdom) {
    return UK_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP;
  } else if (locale === Locale.GermanGermany) {
    return DE_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP;
  } else {
    return US_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP;
  }
};

export const getStateOptionsForAccountPgServicePlan = (
  locale: Locale | undefined = toLocale(),
) => {
  switch (locale) {
    case Locale.EnglishCanada:
      return Provinces;
    case Locale.EnglishUnitedStates:
      return States;
    case Locale.EnglishAustralia:
      return AUStates;
    default:
      return [];
  }
};

/** @description Yuck. 🤷‍♂️ */
export const isAddrFullForEcommCheckoutFormValidations = (
  address: AddressToValidateForEcommCheckout,
  locale: Locale,
): boolean => {
  let emptyField = 0;
  Object.entries(address).map((value: String[]) => {
    if (
      (value[1] === null || value[1] === '') &&
      value[0] !== ADDRESS_KEYS_FOR_ECOMM_CHECKOUT_VALIDATION.line2
    ) {
      emptyField++;
    }
  });

  const withState = [
    Locale.EnglishUnitedStates,
    Locale.EnglishCanada,
    Locale.EnglishAustralia,
  ];
  if (withState.indexOf(locale) === -1) {
    return emptyField < 2;
  }
  return emptyField === 0;
};

export const getPostalCodeRegexForLocale = (locale?: Locale) => {
  switch (locale) {
    case Locale.EnglishCanada:
      return CA_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT;

    case Locale.EnglishUnitedKingdom:
      return UK_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT;

    case Locale.EnglishAustralia:
      return AU_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT;

    // TODO figure out why this wasn't on before MEMS-4787
    // case Locale.GermanGermany:
    //   return DE_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT;

    case Locale.GermanAustria:
      return AT_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT;

    case Locale.EnglishUnitedStates:
    default:
      return US_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT;
  }
};

export const getLocaleFlagImageAltText = (locale: Locale): string =>
  LOCALE_ALT_TEXT_MAP[locale];

export const getCurrencySymbolForLocale = (locale: Locale) =>
  LOCALE_CURRENCY_SYMBOL_MAP[locale];

export const getColumnCountsForEcommCommerceToolsCheckoutAddressForm = (
  locale: Locale,
) => {
  const displayGridLocale =
    locale === Locale.EnglishUnitedStates || locale === Locale.EnglishAustralia;
  const displayGridUKLocale = locale === Locale.EnglishUnitedKingdom;

  const displayGridLocaleColumnWise = displayGridLocale ? 3 : 1;

  return {
    mobile: displayGridLocale || displayGridUKLocale ? 2 : 1,
    tablet: displayGridUKLocale ? 2 : displayGridLocaleColumnWise,
    desktop: displayGridUKLocale ? 2 : displayGridLocaleColumnWise,
  };
};

export const toNeedsConsentForMarketingInAnalytics = (locale: Locale) =>
  !theUserIsInTheUnitedStates(locale);

export const getBikeRentalPrice = (locale: RentalLocale) => {
  const prices: Record<RentalLocale, RentalPrices> = {
    [Locale.EnglishCanada]: CA_BIKE_RENTAL_PRICE,
    [Locale.EnglishUnitedStates]: US_BIKE_RENTAL_PRICE,
    [Locale.GermanGermany]: DE_BIKE_RENTAL_PRICE,
    [Locale.EnglishUnitedKingdom]: UK_BIKE_RENTAL_PRICE,
  };

  return prices[locale];
};

export const getBikePlusRentalPrice = (locale: RentalLocale) => {
  const prices: Record<RentalLocale, RentalPrices> = {
    [Locale.EnglishCanada]: CA_BIKE_PLUS_RENTAL_PRICE,
    [Locale.EnglishUnitedStates]: US_BIKE_PLUS_RENTAL_PRICE,
    [Locale.GermanGermany]: DE_BIKE_PLUS_RENTAL_PRICE,
    [Locale.EnglishUnitedKingdom]: UK_BIKE_PLUS_RENTAL_PRICE,
  };

  return prices[locale];
};

export const toIncreasedMembershipPriceForGuidePricing = (locale: Locale) => {
  if (locale === Locale.EnglishCanada) {
    return 5500;
  }

  if (locale === Locale.EnglishAustralia) {
    return 5900;
  }

  if (locale === Locale.EnglishUnitedStates) {
    return 4400;
  }
  return 3900;
};

export const localeToMembershipPriceForGuidePricing = (
  locale: Locale,
  isIncreasedAllAccessMembershipPriceEnabled: Boolean = false,
) => {
  if (isIncreasedAllAccessMembershipPriceEnabled) {
    return toIncreasedMembershipPriceForGuidePricing(locale);
  }
  if (locale === Locale.EnglishCanada) {
    return 4900;
  }

  if (locale === Locale.EnglishAustralia) {
    return 5900;
  }

  return 3900;
};

export const getPelotonSupportContact = (locale = toLocale()): SupportContact => {
  if (locale === Locale.EnglishAustralia) {
    return AU_SUPPORT_CONTACT;
  } else if (locale === Locale.EnglishUnitedKingdom) {
    return UK_SUPPORT_CONTACT;
  } else {
    return US_SUPPORT_CONTACT;
  }
};

export const toLocaleFromDisplayNameForStudioCityStatePostalInputs = (
  countryDisplayName: string,
): Locale =>
  LOCALE_DISPLAY_NAME_MAP_FOR_STUDIO_CHECKOUT_CITY_STATE_POSTAL_INPUTS[
    countryDisplayName
  ];

export const getDefaultLocaleForWebTv = (pelotonPlatform: PelotonPlatform) => {
  switch (pelotonPlatform) {
    case 'sky_tv':
      return Locale.EnglishUnitedKingdom;
    default:
      return Locale.EnglishUnitedStates;
  }
};

export const getLocaleFromLanguageForWebTv = (language: Language) => {
  if (language === Language.German) {
    return Locale.GermanGermany;
  }
  if (language === Language.Spanish) {
    return 'es-ES';
  }
  return Locale.EnglishUnitedStates;
};

export const toPostalCodeForPgBenefitsCreateDigitalSub = (country: Country) => {
  switch (country) {
    case Country.Canada:
      return 'M3M 1G7';
    case Country.UnitedKingdom:
      return 'BN17 5AB';
    case Country.Australia:
      return '3678';
    default:
      return '60647';
  }
};

export const toAffirmCountryForEcommFinancing = (country: string) => {
  switch (country) {
    case Country.Australia:
      return 'AUS';
    case Country.Canada:
      return 'CA';
    default:
      return 'USA';
  }
};

export const toSegmentKeyEnum = (country = toCountry()) => {
  switch (country) {
    case Country.UnitedStates:
      return USSegmentKey;
    case Country.UnitedKingdom:
      return UKSegmentKey;
    case Country.Canada:
      return CanadaSegmentKey;
    case Country.Germany:
      return GermanySegmentKey;
    case Country.Australia:
      return AustraliaSegmentKey;
    default:
      return USSegmentKey;
  }
};

export const getCopyrightIdForUniversalLoginFooter = (
  locale: SupportedLocaleForUniversalLogin,
) => {
  switch (locale) {
    case SupportedLocaleForUniversalLogin.GermanGermany:
      return 'site.copyrightDE';
    case SupportedLocaleForUniversalLogin.EnglishUnitedKingdom:
      return 'site.copyrightGB';
    case SupportedLocaleForUniversalLogin.EnglishUnitedStates:
    default:
      return 'site.copyright';
  }
};

// Function takes in an object of env vars named with the country at the end
// These keys would be imported from app-config
// e.g.
// {
//    ONE_TRUST_KEY_US,
//    ONE_TRUST_KEY_DE,
//    SEGMENT_KEY_US,
//    SEGMENT_KEY_DE,
// }
// The function will return an object with the base name of the key
// and the value for the country specific key that matches the locale
// e.g.
// {
//  ONE_TRUST_KEY,
//  SEGMENT_KEY
// }
export const mapInternationalKeysByLocale = (
  locale: Locale,
  envObj: Record<string, string | undefined>,
) => {
  const envMap = {};
  Object.entries(envObj).forEach(([key, value]) => {
    const keyCopy = key;
    const splitKey = keyCopy.split('_');
    const country = toCountryFromString(splitKey.pop() ?? '', true);
    const keyName = splitKey.join('_');

    if (country) {
      const matchingLocale = toLocaleFromCountry(country);
      if (matchingLocale === locale) {
        envMap[keyName] = value;
      }
    } else {
      envMap[key] = value;
    }
  });
  return envMap;
};

export const getNeedsTermsAcceptedForCtCart = (locale: Locale) => {
  switch (locale) {
    case Locale.EnglishUnitedStates:
      return false;
    case Locale.EnglishCanada:
    case Locale.FrenchCanada:
    case Locale.GermanGermany:
    case Locale.GermanAustria:
    case Locale.EnglishUnitedKingdom:
    case Locale.EnglishAustralia:
    default:
      return true;
  }
};
