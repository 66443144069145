import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, pipeError, throwError, toData } from '@peloton/api';
import type { Credentials } from '@peloton/auth';
import { toApiCredentials } from '@peloton/auth/api';
import type { ErrorCode, Registration } from './models';
import { toErrorCode, toRegistrationWithCountry } from './models';

const toRegistrationUrl = () => '/api/user';

export const register = (api: Client, data: Registration) =>
  api
    .post(toRegistrationUrl(), toRegistrationWithCountry(data), {
      ...api.defaults,
      headers: {
        ...api.defaults.headers.common,
        'X-Referer': window.location.href,
      },
    })
    .then(pipeData(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));

export const loginOauth = (api: Client, credentials: Credentials) =>
  api
    .post('/auth/login_oauth', toApiCredentials(credentials))
    .then(toData)
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
