import { pick } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import type { Credentials } from '@peloton/auth';
import { fetchAuthUser, logIn } from '@peloton/auth';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import { LOCAL_STORAGE_CUSTOM_EVENT_TYPE } from '@peloton/hooks/shared/useLocalStorage';
import { loginOauth, register } from '@ecomm/registration/api';
import {
  failCheckout,
  RegisterUserActionTypes,
  registerUserFail,
  registerUserSuccess,
} from '../redux';
import type { registerUserStart } from '../redux';

export const registerUserSaga = function* (
  action: ReturnType<typeof registerUserStart>,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const toCredentials = pick(['email', 'password']);
  const { onRegistrationSuccess, isFreeCheckoutOauthEnabled, ...data } = action.payload;

  try {
    const response = yield call(register, client, data);
    const credentials: Credentials = toCredentials(data);
    if (isFreeCheckoutOauthEnabled) {
      const { accessToken } = yield call(loginOauth, client, credentials);
      if (accessToken) {
        yield call(
          [localStorage, 'setItem'],
          CHECKOUT_ACCESS_TOKEN_STORAGE_KEY,
          JSON.stringify(accessToken),
        );

        yield call(
          dispatchEvent,
          new StorageEvent(LOCAL_STORAGE_CUSTOM_EVENT_TYPE, {
            key: CHECKOUT_ACCESS_TOKEN_STORAGE_KEY,
          }),
        );

        yield call(fetchAuthUser, client);
      }
    } else {
      yield put(logIn(credentials));
    }
    yield call(onRegistrationSuccess, response?.id);
    yield put(registerUserSuccess());
  } catch (error) {
    yield put(registerUserFail(error.message));
    yield put(failCheckout('errors.incomplete'));
  }
};

const watcherSaga = function* () {
  yield takeLatest(RegisterUserActionTypes.RegisterUserStart, registerUserSaga);
};

export default watcherSaga;
