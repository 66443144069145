type Configurable<TConfig> = {
  config: Readonly<TConfig>;
  updateConfig: (config: Partial<TConfig>) => void;
};

export const createConfigurable = <TConfig extends object>(
  initialConfig: TConfig,
): Configurable<TConfig> => {
  const config = { ...initialConfig };
  const updateConfig = (newConfig: Partial<TConfig>) => Object.assign(config, newConfig);

  return {
    config,
    updateConfig,
  };
};
