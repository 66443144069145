import React from 'react';

export default function asyncComponent<ImportComponentProps>(
  importComponent: ImportFunction<ImportComponentProps>,
) {
  class AsyncComponent extends React.Component<
    ImportComponentProps,
    State<ImportComponentProps>
  > {
    constructor(props: ImportComponentProps) {
      super(props);

      this.state = {};
    }

    async componentDidMount() {
      try {
        const { default: component } = await importComponent();

        this.setState({
          component,
        });
      } catch (e) {
        console.error('Error loading AsyncComponent:', e);
      }
    }

    render() {
      const Component = this.state.component;

      return Component ? <Component {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}

type ImportFunction<ImportComponentProps> = () => Promise<{
  default: ImportComponentType<ImportComponentProps>;
}>;
type ImportComponentType<ImportComponentProps> = React.ComponentType<
  React.PropsWithChildren<ImportComponentProps>
>;
type State<ImportComponentProps> = {
  component?: ImportComponentType<ImportComponentProps>;
};
