import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { Actions as ApplyBenefitActions } from '../applyBenefit';
import { ActionTypes as ApplyBenefitActionTypes } from '../applyBenefit';
import type { Actions as RegisterActions } from '../register';
import { ActionTypes as RegisterActionTypes } from '../register';

export const ActionTypes = { ...ApplyBenefitActionTypes, ...RegisterActionTypes };

export type State = UIState;

export const defaultState = toUIState(Status.Init);

const reducer = (state: UIState = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.RegistrationRequested:
    case ActionTypes.ApplyBenefit:
      return {
        ...toUIState(Status.Loading),
      };
    case ActionTypes.ApplyBenefitSucceeded:
      return {
        ...state,
        ...toUIState(Status.Loaded),
      };
    case ActionTypes.ApplyBenefitFailed:
    case ActionTypes.RegistrationFailed: {
      const { exception } = action.payload;
      return {
        ...state,
        ...toUIState(Status.Failed, exception),
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  ui: State;
};

type Actions = ApplyBenefitActions | RegisterActions;
