export type PartnerStorageItem = {
  amount: string;
  partner: string;
  partnerParamsPresent: boolean;
  product: string;
};

export type StorageItem = {
  referralCode: string;
  referrerUserId: string | null;
};

export const getStorageItem = (
  itemKey: string,
): StorageItem | PartnerStorageItem | string | null => {
  if (typeof window === 'undefined') {
    return null;
  }
  const storageItemString = localStorage.getItem(itemKey);
  if (storageItemString) {
    return JSON.parse(storageItemString);
  }
  return null;
};
