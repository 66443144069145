import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeDataTwice } from '@peloton/api';
import type { ID } from '@ecomm/models';
import { toOrder } from './mappers';
import type { ApiBilling, ApiOrder } from './types';

export const toOrderUrl = (identifier: ID) => `/ecomm/order/${identifier}`;

export const toOrderCardsUrl = (identifier: ID) => `/ecomm/order/${identifier}/cards`;

const ordersUrl = '/ecomm/orders';

export const fetchOrders = (api: Client) =>
  api.get(ordersUrl).then(pipeDataTwice(toOrder));

export const fetchOrder = (api: Client, id: ID) =>
  Promise.all([api.get(toOrderUrl(id)), api.get(toOrderCardsUrl(id))]).then(
    ([orderResp, billingResp]) => {
      const order = pipeDataTwice(identity)(orderResp) as ApiOrder;
      const billing = pipeDataTwice(identity)(billingResp) as ApiBilling[];

      return {
        order: toOrder({ ...order, billing }),
        rawOrder: order,
      };
    },
  );
