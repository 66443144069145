import React from 'react';
import styled, { css } from 'styled-components';
import type { ToggleButtonTheme } from '@peloton/buttons';
import { black, white } from '@peloton/colors';
import type { IconProps } from './types';

type PauseProps = IconProps &
  ToggleButtonTheme & {
    label: string;
  };

type StyleProps = { theme: 'light' | 'dark' };

export const resolveFillColor = (theme: string): string =>
  theme === 'light' ? white : black;

const TogglePause: React.FC<React.PropsWithChildren<PauseProps>> = ({
  theme = 'dark',
  label,
  ...iconProps
}) => (
  <StyledSvg
    aria-label={label}
    fill={resolveFillColor(theme)}
    role="img"
    theme={theme}
    viewBox="0 0 34 34"
    xmlns="http://www.w3.org/2000/svg"
    {...iconProps}
  >
    <g fillRule="evenodd">
      <path d="M17 .25C7.75.25.25 7.75.25 17S7.75 33.75 17 33.75 33.75 26.25 33.75 17 26.25.25 17 .25zm0 1.5c8.422 0 15.25 6.828 15.25 15.25S25.422 32.25 17 32.25 1.75 25.422 1.75 17 8.578 1.75 17 1.75z" />
      <g transform="translate(12 11.667)">
        <rect width="2.667" height="12" rx="1" />
        <rect width="2.667" height="12" x="6.333" rx="1" />
      </g>
    </g>
  </StyledSvg>
);

export default TogglePause;

const dropShadow = css`
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.15));
`;

export const StyledSvg = (styled.svg`
  ${({ theme }) => (theme === 'light' ? dropShadow : '')}
  width: 50px;
` as any) as React.ComponentType<React.PropsWithChildren<StyleProps>>;
