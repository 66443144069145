import { useContext, useRef } from 'react';
import { GlobalReferenceContext, GlobalRefElement } from '../GlobalReferenceProvider';
import useIsomorphicEffect from './useIsomorphicEffect';
import useResizeRefWatch from './useResizeRefWatch';

const useHeaderReference = () => {
  const { setElement } = useContext(GlobalReferenceContext);
  const headerRef = useRef<HTMLDivElement | null>(null);
  useResizeRefWatch(headerRef, ref =>
    setElement(GlobalRefElement.HEADER, ref?.getBoundingClientRect()),
  );

  useIsomorphicEffect(() => {
    setElement(GlobalRefElement.HEADER, headerRef.current?.getBoundingClientRect());
  }, []);

  return headerRef;
};

export default useHeaderReference;
