import type { DomainError } from 'packages/@peloton/domain-error';
import { APIErrorCode, toAPIErrorCode } from '@onewellness/api';
import type { Program } from './program';
import { programHasAamBenefit } from './program';

export type Entitlement = {
  token: string;
  program: Program;
  isClaimed: boolean;
};

export type EntitlementByTokenResponse = { entitlementByToken?: Entitlement };

export const entitlementProgramHasAamBenefit = (entitlement: Entitlement) =>
  programHasAamBenefit(entitlement.program);

export const hasTokenError = (response: EntitlementByTokenResponse): boolean => {
  return response.entitlementByToken?.isClaimed ?? true;
};

export const hasClaimBenefitsError = (error: DomainError): boolean => {
  const errorCode = toAPIErrorCode(error.originalError);

  const benefitsErrors = [
    APIErrorCode.InvalidBenefitHubToken,
    APIErrorCode.ExpiredBenefitHubToken,
    APIErrorCode.ClaimedBenefitHubToken,
  ];

  return benefitsErrors.includes(errorCode);
};

export const hasAllAccessSubscriptionError = (error: DomainError): boolean => {
  const errorCode = toAPIErrorCode(error.originalError);
  return errorCode === APIErrorCode.EnrollmentNotCovered;
};

export const hasApplyBenefitsError = (error: DomainError): boolean => {
  const errorCode = toAPIErrorCode(error.originalError);

  const benefitsErrors = [
    APIErrorCode.ActiveSharedSubscription,
    APIErrorCode.ActiveMultipleSubscriptions,
    APIErrorCode.ActiveConcessionarySubscription,
  ];

  return benefitsErrors.includes(errorCode);
};

export const toErrorCode = (response: EntitlementByTokenResponse): APIErrorCode => {
  if (response.entitlementByToken) {
    if (response.entitlementByToken.isClaimed) {
      return APIErrorCode.ClaimedBenefitHubToken;
    }
  }

  return APIErrorCode.ExpiredBenefitHubToken;
};
