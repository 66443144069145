import type { ReducerState as TReducerState } from './rootReducer';
import reducer from './rootReducer';
import {
  getAppName,
  getFlags as getEnvFlags,
  getIsLocalEnv,
  getIsProdEnv,
  getIsStagingEnv,
  getIsTestEnv,
  getLocale,
  getLanguage,
} from './selectors';

export default reducer;

export {
  getAppName,
  getEnvFlags,
  getIsLocalEnv,
  getIsProdEnv,
  getIsStagingEnv,
  getIsTestEnv,
  getLocale,
  getLanguage,
};

export type ReducerState = TReducerState;
