import moment, { isMoment } from 'moment-timezone';
import type {
  Moment,
  MomentInputObject,
  unitOfTime,
  Duration as MomentDuration,
} from 'moment-timezone';
import { range } from 'ramda';
// eslint-disable-next-line no-restricted-imports
import type { Language } from '@peloton/internationalize';

export type Time = Moment;
export type Duration = MomentDuration;
export type TimeUnit = unitOfTime.Base; // NOTE: This does not cover all the specialized time units for moment
type TimeOpts = MomentInputObject;

/**
 * Creates an an object with an empty array of date keys. I.e. { 1539057600: [], 1539144000: [], ... }
 * @param days is how many days ahead you want to create the empty dates object for.
 * For example, if you want to do 14 days total (2 weeks), you should execute `emptyDatesObject(13)`
 * We are using 13 instead of 14 because 0 starts on today
 */
export const emptyDatesObject = (days: number) => {
  const listOfDates = range(0, days + 1).map(v =>
    toNowTime().startOf('day').add(v, 'day').unix().toString(),
  );
  return listOfDates.reduce((acc, val) => ({ ...acc, [val]: [] }), {});
};

export const toNowTime = () => moment();

// tz.guess produces a string such as [America/Montreal] based on JS internationization api
export const toNowTimezoneString = () => `${moment().format()}[${moment.tz.guess()}]`;

export const toGuessedUTCTimezone = () => moment().tz(moment.tz.guess()).format('z');

export const toTime = (
  timestamp: number | string | Date | Time,
  isMilliseconds: boolean = false,
) => {
  if (isMoment(timestamp)) {
    return timestamp;
  }
  if (typeof timestamp === 'number') {
    return isMilliseconds ? moment(timestamp) : moment.unix(timestamp);
  }
  if (typeof timestamp === 'string') {
    if (new Date(timestamp).toString() === 'Invalid Date') {
      return moment.invalid();
    }
    //This is to bypass the warnings caused by https://momentjs.com/guides/#/warnings/js-date/
    //by specifying the formats we are sending into moment
    const usedFormats = [
      'YYYY-MM-DD',
      'MMMM DD, YYYY',
      'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
      'YYYY-MM-DD LTS',
      'YYYY-MM-DD HH:mm',
      'YYYY-MM-DDTHH:mm:ss.SSSZ',
      'YYYY-MM-DDTHH:mm:ssZ',
      'MMMM DD, YYYY HH:mm:ss [GMT]Z',
    ];
    return moment(timestamp, usedFormats);
  }
  return moment(timestamp);
};

export const toUtcTime = (timestamp: number | string | Date | Time) =>
  moment.utc(toTime(timestamp));

export const toTimeWithZone = moment.parseZone;

export const toMaybeTime = (timestamp?: number, isMilliseconds: boolean = false) =>
  timestamp ? toTime(timestamp, isMilliseconds) : toInvalidTime();

export const toTimeWith = (opts: TimeOpts) => moment(opts);

export const toTimeFromDate = (date: Date) => toTime(Math.round(date.getTime() / 1000));

export const toUtcTimeFromDate = (date: Date) => {
  const dateAsMoment = moment(date);
  const utcDate = dateAsMoment.add(dateAsMoment.utcOffset(), 'minutes').toDate(); // moment currently doesn't have a straightforward way to get the utc time, so we have to offset it manually
  return toTimeFromDate(utcDate);
};

export const toTimeFromString = (dateString: string, format = moment.ISO_8601) =>
  moment(dateString, format);

export const toInvalidTime = () => moment.invalid();

export const isTime = (maybeTime: any): maybeTime is Time => moment.isMoment(maybeTime);

export const isTimeValid = (maybe: any): maybe is Time =>
  isTime(maybe) && maybe.isValid();

export const toDiffDuration = (startTime: Time, endTime: Time): Duration =>
  moment.duration(endTime.diff(startTime));

export const setDisplayLanguage = (displayLanguage: Language) =>
  moment.locale(displayLanguage);

export const guessTimeZone = () => moment.tz.guess();

export const isValidDateFormatValue = (
  dateFormatValueToTest: string,
  dateFormat: string,
) => {
  return moment(dateFormatValueToTest, dateFormat, true).isValid();
};

export const dateObjectWithDateFormat = (dateValue: string, dateFormat: string) => {
  return moment(dateValue, dateFormat, true);
};
