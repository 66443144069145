import { LinkEnvs } from '@peloton/external-links/models';
import type { EnvironmentFlags } from './models';

const toLinkEnv = ({
  isProd,
  isProdTest,
  isStaging,
  isLocal,
  isUatProd,
  isStageN,
}: EnvironmentFlags): typeof LinkEnvs[keyof typeof LinkEnvs] =>
  (isProd && LinkEnvs.Prod) ||
  (isProdTest && LinkEnvs.ProdTest) ||
  (isStaging && LinkEnvs.Qa1) ||
  (isLocal && LinkEnvs.Local) ||
  (isUatProd && LinkEnvs.UatProd) ||
  (isStageN && LinkEnvs.StageN) ||
  LinkEnvs.UatQa;

export default toLinkEnv;
