import type { Time } from '@peloton/time';
import { toNowTime, toTimeFromString } from '@peloton/time';

export type DateTrigger = {
  name?: string;
  start: Time;
  end: Time;
};

export const toDateTrigger = ({
  start,
  end,
  ...opts
}: {
  name?: string;
  start: string;
  end: string;
}): DateTrigger => ({
  ...(opts.name ? { name: opts.name } : {}),
  start: toTimeFromString(start),
  end: toTimeFromString(end),
});

// '[)' is moment's way of saying inclusive of start time, exclusive of end time
export const isActiveAt = (now: Time, dateTrigger: DateTrigger): boolean =>
  now.isBetween(dateTrigger.start, dateTrigger.end, undefined, '[)');

export const isActiveNow = (dateTrigger: DateTrigger, nowTime = toNowTime()): boolean =>
  isActiveAt(nowTime, dateTrigger);
