import { keyframes, css } from 'styled-components';

const fadeInKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeInAnimation = css`
  animation: ${fadeInKeyframes} 1s ease;
`;
