import { camelize } from 'humps';
import { curry } from 'ramda';
import type { Reducer } from 'redux';
import { getLocation } from '@peloton/redux/selectors';
import type { Experiments } from '../models';
import { isVariation, hasVariationFor } from '../SplitTesting';

export type State = {
  optimizelyProjectId?: string;
  experiments: Experiments;
};

export enum Actions {
  UPDATE_EXPERIMENTS = 'pelo/split-testing/UPDATE_EXPERIMENTS',
  LOAD_EXPERIMENTS = 'pelo/split-testing/LOAD_EXPERIMENTS',
  SET_USER_ATTRIBUTE = 'pelo/split-testing/SET_USER_ATTRIBUTE',
}

const defaultState: State = {
  experiments: {},
};

export const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.UPDATE_EXPERIMENTS: {
      const { experiments } = action;
      return {
        ...state,
        experiments,
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type UpdateExperiments = {
  type: Actions.UPDATE_EXPERIMENTS;
  experiments: { [K: string]: string };
};

export const updateExperiments = (experiments: { [K: string]: string }) => ({
  type: Actions.UPDATE_EXPERIMENTS,
  experiments,
});

type LoadExperiments = {
  type: Actions.LOAD_EXPERIMENTS;
};

export const loadExperiments = () => ({
  type: Actions.LOAD_EXPERIMENTS,
});

export type SetUserAttribute = {
  type: Actions.SET_USER_ATTRIBUTE;
  payload: {
    attribute: string;
    value: string;
  };
};

export const setUserAttribute = (attribute: string, value: any): SetUserAttribute => ({
  type: Actions.SET_USER_ATTRIBUTE,
  payload: {
    attribute,
    value,
  },
});

type Action = UpdateExperiments | LoadExperiments | SetUserAttribute;

const getSplitTestingState = (state: GlobalState): State =>
  state.splitTesting || { experiments: {} };

export const getOptimizelyProjectId = (state: GlobalState) =>
  getSplitTestingState(state).optimizelyProjectId;

export const getExperiments = (state: GlobalState) =>
  getSplitTestingState(state).experiments;

export const getExperiment = (state: GlobalState, experiment: string) =>
  getSplitTestingState(state).experiments[experiment];

export const hasExperimentAndVariation = curry(
  (experiment: string, variation: string, state: GlobalState) =>
    hasVariationFor(experiment, variation, getExperiments(state)),
);

// The original function, hasExperimentAndVariation, will return true for the case
// with the test turned off, and you check for 'Original' or 'off' as the variation.
// Use this function to return false for those variations while the test off.
export const hasExperimentAndVariationWithoutControl = curry(
  (experiment: string, variation: string, state: GlobalState) =>
    hasVariationFor(experiment, variation, getExperiments(state), true),
);

export const hasVariation = curry(
  (experiment: string, variation: string, state: GlobalState) =>
    isVariation(experiment, variation, getExperiments(state)),
);

export type GlobalState = {
  splitTesting: State;
};

export type ExperimentAndVariation = {
  experiment: string;
  variation: string;
};

export const getOptimizelyExperimentAndVariation = (
  state: GlobalState,
  campaignName: string,
): ExperimentAndVariation | null => {
  const experiments = state.splitTesting?.experiments;

  for (const experiment in experiments) {
    if (experiment.includes(campaignName)) {
      const variation = experiments[experiment];

      if (variation !== 'Original') {
        return { experiment, variation };
      }
    }
  }

  return null;
};

export const getOptimizelyExperimentsAndVariations = (
  state: any,
  campaignName: string,
): ExperimentAndVariation[] => {
  const experiments = state.splitTesting?.experiments;
  const matchingExperiments: ExperimentAndVariation[] = [];

  for (const experiment in experiments) {
    if (experiment.includes(campaignName)) {
      const variation = experiments[experiment];

      if (variation !== 'Original') {
        matchingExperiments.push({ experiment, variation });
      }
    }
  }

  return matchingExperiments;
};

const camelCasedPath = (pathName: string) => {
  const snakeCasedPath = pathName.substring(1).replace(/\/$/, '').replace(/[/,-]/g, '_');

  return camelize(snakeCasedPath);
};

export const generatePersonalizationContentfulKeyPrefix = (state: any) => {
  const { pathname } = getLocation(state);
  const routeName = pathname === '/' ? 'home' : camelCasedPath(pathname);
  const campaignName = `${routeName}PagePersonalization`;

  const optimizelyExperimentAndVariation = getOptimizelyExperimentAndVariation(
    state,
    campaignName,
  );

  if (optimizelyExperimentAndVariation) {
    const { experiment, variation } = optimizelyExperimentAndVariation;
    return `${campaignName}.${experiment}.${variation}.`;
  }

  return '';
};
