import {
  DRIFT_KEY,
  ONEWELLNESS_UAT_BENEFIT_AUTH_TOKEN,
  ONEWELLNESS_UAT_CF_ACCESS_CLIENT_ID,
  ONEWELLNESS_UAT_CF_ACCESS_CLIENT_SECRET,
  ONEWELLNESS_PROD_BENEFIT_AUTH_TOKEN,
  OPTIMIZELY_KEY,
  RECAPTCHA_ENTERPRISE_KEY,
  STRIPE_API_KEY,
} from '@peloton/app-config';
import type { EnvironmentFlags } from '@peloton/env';
import { Locale, STRIPE_ACCOUNT_KEYS_FOR_ECOMM } from '@peloton/internationalize';
import type { SegmentKey } from './segment';
import { toSegmentKeyEnum } from './segment';

const oneWellness = {
  staging: {
    id: ONEWELLNESS_UAT_CF_ACCESS_CLIENT_ID,
    secret: ONEWELLNESS_UAT_CF_ACCESS_CLIENT_SECRET,
    token: ONEWELLNESS_UAT_BENEFIT_AUTH_TOKEN,
  },
  prod: {
    id: null,
    secret: null,
    token: ONEWELLNESS_PROD_BENEFIT_AUTH_TOKEN,
  },
};

// !!! Do not define keys this way, we have a ticket to address this technical debt soon...
enum AppsKeyId {
  Prod = 'AKIAZPMR2Z6C67C4C345',
}
enum AppsAccessKey {
  Prod = 'DHbS0Iu9LWNfkgegVf0DwyyfmZdlY7jMMhFKPrxL',
}

export const isPlatformStripeAccount = (stripeAccountKey: string) =>
  stripeAccountKey === STRIPE_ACCOUNT_KEYS_FOR_ECOMM[Locale.EnglishUnitedStates];

type Keys = {
  oneWellness: typeof oneWellness;
  drift: string;
  optimizely: string;
  recaptchaApi: string;
  stripeApi: string;
  segment: SegmentKey;
  stripeAccount: string | undefined;
  appsKeyId: AppsKeyId;
  appsAccessKey: AppsAccessKey;
};

type Props = EnvironmentFlags & {
  locale: Locale;
};

/** @deprecated import from '@peloton/app-config' wherever possible */
const getKeys = ({ locale, isProd, isStaging }: Props): Keys => {
  const segmentKeyEnum = toSegmentKeyEnum();

  return {
    oneWellness,
    drift: DRIFT_KEY!,
    optimizely: OPTIMIZELY_KEY!,
    recaptchaApi: RECAPTCHA_ENTERPRISE_KEY!,
    stripeApi: STRIPE_API_KEY!,
    appsAccessKey: AppsAccessKey.Prod,
    appsKeyId: AppsKeyId.Prod,

    // TODO: Refactor to not depend on { isProd, isStaging }
    // Segment currently varies by TLD x ENV
    segment: isProd
      ? segmentKeyEnum.Prod
      : isStaging
      ? segmentKeyEnum.Staging
      : segmentKeyEnum.Dev,

    // TODO: Refactor to not depend on { locale }
    stripeAccount: STRIPE_ACCOUNT_KEYS_FOR_ECOMM[locale],
  };
};

export default getKeys;
