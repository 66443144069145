import { schema } from 'normalizr';
import type { ApiPaymentSet as ApiPayment } from '@ecomm/cart/api/types';
import type { CreditCard } from '@ecomm/models';
import type { Payment } from '../../models';
import { PaymentKind } from '../../models';

const processPayment = (
  { billingPartner, billingPartnerId: id, paidInCents: amount, label }: ApiPayment,
  { card }: { card: CreditCard },
): Payment => {
  const paymentDetails = {
    amount,
    billingPartner,
    id,
  };
  switch (billingPartner) {
    case null:
    case undefined:
    case 'stripe':
      return {
        ...paymentDetails,
        card: card || ({} as CreditCard),
        kind: PaymentKind.CreditCard,
        label,
      };

    case 'deposit':
      return {
        ...paymentDetails,
        kind: PaymentKind.Deposit,
        label,
      };

    default:
      // This should implicitly cover affirm and synchrony
      return {
        ...paymentDetails,
        kind: PaymentKind.Financing,
        label,
      };
  }
};

const payment = new schema.Entity(
  'payments',
  {},
  { processStrategy: processPayment, idAttribute: 'billingPartnerId' },
);

export default payment;
