import { brand, slate } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { hr3, b3, reg } from '@ecomm/typography';

export const Content: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <ContentContainer>
    <InnerContent>{children}</InnerContent>
  </ContentContainer>
);

const ContentContainer = styled.div`
  text-align: center;
  padding: 20px;
  background-color: ${brand.light};
`;

const InnerContent = styled.div`
  max-width: 500px;
  min-height: 200px;
  margin: 120px auto 120px auto;
  flex: 1 0 auto;
  ${media.tablet`
    max-width: 680px;
  `}
`;

export const Title = styled.h1`
  ${hr3}
  margin: 0 auto;
`;

export const Subtitle = styled.p`
  ${b3}
  ${reg}
  margin-top: 20px;
  color: ${slate[50]};
  ${media.tablet`
    margin-top: 30px;
  `}
`;
