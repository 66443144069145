import { any, contains, pluck, propEq, reduce } from 'ramda';
import { track } from '@peloton/analytics';
import { toCountry } from '@peloton/internationalize';
import type { ApiCoupon } from '@ecomm/cart/api/types';
import type { OrderReceipt } from '@ecomm/checkout/models';
import { PaymentMethod, toDollars } from '@ecomm/models';
import type { ApiOrderItem } from '@ecomm/order/api/types';
import { ProductLine } from '@ecomm/shop/models';

const trackOrder = (apiOrderReceipt: OrderReceipt['data']) => {
  const trackingProperties = toTrackingProperties(apiOrderReceipt);
  track({
    event: 'Completed Transaction',
    properties: trackingProperties,
  });
  if (trackingProperties.hasBike) {
    track({
      event: 'Purchased Bike',
      properties: trackingProperties,
    });
  }
  if (trackingProperties.hasBikePlus) {
    track({
      event: 'Purchased Bike Plus',
      properties: trackingProperties,
    });
  }
  if (trackingProperties.hasTread) {
    track({
      event: 'Purchased Tread',
      properties: trackingProperties,
    });
  }
  if (hasTreadPreorder(apiOrderReceipt.itemSet)) {
    track({
      event: 'Placed Tread Deposit',
      properties: {
        ...trackingProperties,
        email: apiOrderReceipt.email,
      },
    });
  }

  const subProductOptionIds = subscriptionProductOptionIds(apiOrderReceipt.itemSet);
  subProductOptionIds.forEach(id =>
    track({
      event: 'Purchased Subscription',
      properties: {
        ...trackingProperties,
        productOptionId: id,
      },
    }),
  );
};

export default trackOrder;

const merchandiseTotal = (itemSet: ApiOrderItem[]) =>
  reduce<ApiOrderItem, number>((acc, item) => acc + item.subtotalInCents, 0, itemSet);

const toItems = (itemSet: ApiOrderItem[]) =>
  itemSet.map(({ productOption, quantity }) => ({
    id: productOption.id,
    sku: productOption.sku,
    name: productOption.displayName,
    price: toDollars(productOption.priceInCents),
    quantity,
  }));

const bikeCountForItem = (item: ApiOrderItem) =>
  item.productOption.productLine === ProductLine.Bike ? item.quantity : 0;

const bikePlusCountForItem = (item: ApiOrderItem) =>
  item.productOption.productLine === ProductLine.StoneOcean ||
  item.productOption.productLine === ProductLine.BikePlus
    ? item.quantity
    : 0; // TODO: completely replace StoneOcean with BikePlus when possible

const treadCountForItem = (item: ApiOrderItem) =>
  item.productOption.productLine === ProductLine.Tread ? item.quantity : 0;

const digitalSubCountForItem = (item: ApiOrderItem) =>
  item.productOption.productLine === ProductLine.DigitalSubscription ? item.quantity : 0;

const bikeCount = (itemSet: ApiOrderItem[]) =>
  reduce<ApiOrderItem, number>((acc, item) => acc + bikeCountForItem(item), 0, itemSet);

const bikePlusCount = (itemSet: ApiOrderItem[]) =>
  reduce<ApiOrderItem, number>(
    (acc, item) => acc + bikePlusCountForItem(item),
    0,
    itemSet,
  );

const treadCount = (itemSet: ApiOrderItem[]) =>
  reduce<ApiOrderItem, number>((acc, item) => acc + treadCountForItem(item), 0, itemSet);

const digitalSubCount = (itemSet: ApiOrderItem[]) =>
  reduce<ApiOrderItem, number>(
    (acc, item) => acc + digitalSubCountForItem(item),
    0,
    itemSet,
  );

const hasTreadPreorder = (itemSet: ApiOrderItem[]) =>
  any(item => item.productOption.slug === 'tread-reservation-deposit', itemSet);

const subscriptionProductOptionIds = (itemSet: ApiOrderItem[]) =>
  reduce<ApiOrderItem, string[]>(
    (acc, item) => {
      if (item.productOption.isSubscription) {
        acc.push(item.productOption.id);
      }
      return acc;
    },
    [],
    itemSet,
  );

const toCoupons = (couponSet: ApiCoupon[]) => pluck('code')(couponSet);

const toPromotion = (billingPartner: string): string | undefined => {
  return billingPartner === 'benefit_hub' ? 'Corporate Wellness' : undefined;
};

const orderItemsHaveValue = (value: string) => any<ApiOrderItem>(propEq(value, true));

export const toTrackingProperties = ({
  id,
  currency,
  subtotalInCents,
  shippingInCents,
  taxPaidInCents,
  totalWithTaxPaidInCents,
  couponSet,
  paymentSet,
  itemSet,
  referrerId,
  userId,
}: OrderReceipt['data']) => {
  const billingPartner = paymentSet
    ? pluck('billingPartner')(paymentSet).join(', ')
    : 'stripe';

  return {
    propertyType: 'Web',
    orderId: id,
    merchandiseTotal: toDollars(merchandiseTotal(itemSet)),
    shipping: toDollars(shippingInCents),
    tax: toDollars(taxPaidInCents),
    discount: toDollars(merchandiseTotal(itemSet) - subtotalInCents),
    salesTotal: toDollars(subtotalInCents),
    revenue: toDollars(subtotalInCents + shippingInCents),
    total: toDollars(totalWithTaxPaidInCents),
    coupon: toCoupons(couponSet),
    currency,
    products: toItems(itemSet),
    promotion: toPromotion(billingPartner),
    referrerId,
    recipientId: userId,
    orderBikeQuantity: bikeCount(itemSet),
    orderTreadQuantity: treadCount(itemSet),
    hasBike: bikeCount(itemSet) > 0,
    hasBikePlus: bikePlusCount(itemSet) > 0,
    hasDigitalSub: digitalSubCount(itemSet) > 0,
    hasTread: treadCount(itemSet) > 0,
    applePay: paymentSet
      ? contains(PaymentMethod.ApplePay, pluck('label')(paymentSet))
      : false,
    googlePay: paymentSet
      ? contains(PaymentMethod.GooglePay, pluck('label')(paymentSet))
      : false,
    billingPartner,
    hasTradeIn: orderItemsHaveValue('hasTradeIn')(itemSet),
    country: toCountry(),
  };
};
