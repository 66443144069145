import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';

const PageSpinner: React.FC<React.PropsWithChildren<unknown>> = ({
  children = <Spinner area={30} />,
  ...props
}) => <SpinnerContainer children={children} {...props} />;

export default PageSpinner;

export const SpinnerContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  margin: 120px 0;
  width: 100%;
`;
