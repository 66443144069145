import { pathOr, pickAll, zipObj } from 'ramda';
import type { Image } from '../models.generated';
import { useImageQuery } from '../queries/contentTypes/image.generated';
import type { ImageData } from './utils';
import { imageGuard, IMAGE_BREAKPOINT_NAMES } from './utils';

export type ImageType = {
  mobile: ImageData;
  tablet: ImageData;
  desktop: ImageData;
  desktopLarge: ImageData;
  caption?: string;
};

export type ImageObject = {
  image: ImageType;
};

export const transformImageData = (response: object) => {
  const {
    image = {
      mobile: { url: '', description: '' },
      tablet: { url: '', description: '' },
      desktop: { url: '', description: '' },
      desktopLarge: { url: '', description: '' },
    },
  } = pickAll<object, ImageObject>(['image'], pathOr({}, ['data'], response));

  const { caption } = pathOr('', ['data', 'image'], response);

  const imageDetails = Object.values(image)
    .filter(value => typeof value === 'object')
    .map((breakpointImage: ImageData) =>
      breakpointImage
        ? { url: breakpointImage.url, description: breakpointImage.description }
        : breakpointImage,
    );

  const safeImage = zipObj(IMAGE_BREAKPOINT_NAMES, imageGuard(imageDetails)) as ImageType;
  IMAGE_BREAKPOINT_NAMES.forEach(breakpointName => {
    safeImage[breakpointName].description = safeImage[breakpointName].description || '';
  });

  return {
    image: {
      ...safeImage,
      caption,
    },
  };
};

const useImageData = (id: Image): ImageObject => {
  const res = useImageQuery({ variables: { id } });

  return transformImageData(res);
};

export default useImageData;
