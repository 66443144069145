import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

export class ContentSizedDiv extends React.Component<Props> {
  private resizeObserver: ResizeObserver;
  private parentDiv: HTMLDivElement;
  private contentDiv: HTMLDivElement;

  constructor(props: Props) {
    super(props);

    this.resizeObserver = new ResizeObserver(this.onResize);
  }

  public componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  public render() {
    const parentStyle = Object.assign({}, this.props.style, { overflow: 'hidden' });
    return (
      <div {...this.props} style={parentStyle} ref={this.setParentDiv}>
        <div ref={this.setContentDiv}>{this.props.children}</div>
      </div>
    );
  }

  private setParentDiv = (element: HTMLDivElement) => {
    this.parentDiv = element;
    this.onResize();
  };

  private setContentDiv = (element: HTMLDivElement) => {
    const oldElement = this.contentDiv;
    this.contentDiv = element;
    this.registerWithResizeObserver(element, oldElement);
  };

  private registerWithResizeObserver(
    newElement: HTMLDivElement,
    oldElement: HTMLDivElement,
  ) {
    if (oldElement) {
      this.resizeObserver.unobserve(oldElement);
    }

    if (newElement) {
      this.resizeObserver.observe(newElement);
      this.onResize();
    }
  }

  private onResize = () => {
    if (this.parentDiv && this.contentDiv) {
      const contentHeight = window.getComputedStyle(this.contentDiv).height;
      if (contentHeight !== this.parentDiv.style.height) {
        this.parentDiv.style.height = contentHeight;
      }
    }
  };
}
