import type { Reducer } from 'redux';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { ContactInfo, UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { BlurAddressFieldAction } from './address';
import { ActionTypes as AddressActions } from './address';

export enum Actions {
  UpdateShippingRequest = 'ecomm/updateShipping/UPDATE_SHIPPING_REQUEST',
  UpdateSuccess = 'ecomm/updateShipping/UPDATE_SUCCESS',
  UpdateFailure = 'ecomm/updateShipping/UPDATE_FAILURE',
  UpdateInvalid = 'ecomm/updateShipping/UPDATE_INVALID',
}

type Action =
  | UpdateSuccess
  | UpdateFailure
  | UpdateInvalid
  | UpdateShippingRequest
  | BlurAddressFieldAction;

type State = {
  ui: UIState;
};

export const defaultState: State = {
  ui: toUIState(Status.Init),
};

const shipping: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.UpdateShippingRequest:
    case AddressActions.AddressFieldBlurred:
      return {
        ...state,
        ui: toUIState(Status.Loading),
      };
    case Actions.UpdateSuccess:
    case Actions.UpdateInvalid:
      return {
        ...state,
        ui: toUIState(Status.Loaded),
      };
    case Actions.UpdateFailure:
      return {
        ...state,
        ui: toUIState(Status.Failed, action.payload),
      };
    default:
      return state;
  }
};

export default shipping;

export type ReducerState = {
  shipping: State;
};

export type UpdateSuccess = {
  type: Actions.UpdateSuccess;
};

export type UpdateFailure = {
  type: Actions.UpdateFailure;
  payload: Exception;
};

export type UpdateInvalid = {
  type: Actions.UpdateInvalid;
};

export type UpdateShippingRequest = {
  type: Actions.UpdateShippingRequest;
  payload: ContactInfo;
};

export const updateShippingSuccess = () => ({
  type: Actions.UpdateSuccess,
});

export const updateShippingInvalid = () => ({
  type: Actions.UpdateInvalid,
});

export const updateShippingFailure = (error: Error): UpdateFailure => ({
  type: Actions.UpdateFailure,
  payload: toException(`checkout.errors.shipping.${error.message}`),
});

export const updateShipping = (shippingInfo: ContactInfo): UpdateShippingRequest => ({
  type: Actions.UpdateShippingRequest,
  payload: shippingInfo,
});
