import type { DetailedHTMLProps, ImgHTMLAttributes, FC } from 'react';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';

type ImgStyleAttributes = NonNullable<JSX.IntrinsicElements['img']['style']>;

export type LazyImageProps = {
  layout?: 'fill';
  objectFit?: ImgStyleAttributes['objectFit'];
  objectPosition?: ImgStyleAttributes['objectPosition'];
};

type ImgProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

type Props = ImgProps & LazyImageProps;

const TRANSPARENT_IMG =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const LazyImage: FC<React.PropsWithChildren<Props>> = props => {
  const defaultImgAttrs =
    props.loading === 'lazy'
      ? {
          ...props,
          src: TRANSPARENT_IMG,
          srcSet: undefined,
          sizes: undefined,
        }
      : props;

  const [imgAttrs, setImgAttrs] = useState<ImgProps>(defaultImgAttrs);

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  useEffect(() => {
    if (props.loading === 'lazy' && inView) {
      setImgAttrs(props);
    }
  }, [inView, props]);

  return (
    // eslint-disable-next-line styled-components-a11y/alt-text
    <Img {...imgAttrs} ref={props.loading === 'lazy' ? ref : null} data-src={props.src} />
  );
};

const layoutFillStyles = css<Props>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: ${props => props.objectFit || 'cover'};
  top: 0;
  left: 0;
  ${props =>
    props.objectPosition
      ? css`
          object-position: ${props.objectPosition};
        `
      : undefined};
`;

const imgSizeStyles = css<Props>`
  width: ${props => props.width};
  ${props => (props.height ? imgHeightStyles : undefined)}
`;

const imgHeightStyles = css<Props>`
  height: ${props => props.height};
`;

const Img = styled.img<Props>`
  ${props =>
    props.loading === 'lazy'
      ? props.layout === 'fill'
        ? layoutFillStyles
        : imgSizeStyles
      : undefined}
`;

export default LazyImage;
