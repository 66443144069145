import { toAbsoluteUrl, toDigitalLink, toSupportLink } from '@peloton/external-links';

export const digitalHome = toDigitalLink('/');
export const classes = toDigitalLink('/classes');
export const login = toDigitalLink('/login');
export const profile = toDigitalLink('/profile/overview');
export const preferences = toDigitalLink('/preferences');
export const completeProfile = toDigitalLink('/complete');
export const subscriptionPreferences = toDigitalLink('/preferences/subscriptions');
export const chaseExistingSubArticle = toSupportLink(
  '/articles/360048867251#h_01ENBQH457Z3BA6VJ2SRVZ7M9J',
);
export const uhcExistingSubArticle = toSupportLink('/articles/4404984024980 ');
export const uhcPromotionLink = toAbsoluteUrl('https://www.myuhc.com/peloton');
export const teams = toDigitalLink('/community/teams/');
