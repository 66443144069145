import type { ComponentType, PropsWithChildren } from 'react';
import React from 'react';
import { LOCALE_TOGGLE } from '@peloton/internationalize/models/toggle';

export function withFeatureToggle<P = {}>(
  Component: ComponentType<P>,
  I18nComponent: ComponentType<P>,
) {
  const WithFeatureToggle = (props: PropsWithChildren<P>, ref: React.RefObject<any>) => {
    return LOCALE_TOGGLE ? (
      <I18nComponent {...props} ref={ref} />
    ) : (
      <Component {...props} ref={ref} />
    );
  };
  return React.forwardRef(WithFeatureToggle);
}
