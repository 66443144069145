import { pathOr } from 'ramda';
import type { LoginFormAction } from '@ecomm/login-form';
import { LOGIN_FORM_SUBMITTED, isLoginFormAction } from '@ecomm/login-form';

export enum ActionType {
  Open = 'ecomm/loginModal/OPENED',
  Close = 'ecomm/loginModal/CLOSED',
}

export const defaultState = {
  prefilledEmail: '',
  isOpen: false,
};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Open: {
      const toEmail = pathOr('', ['payload', 'email']);

      return {
        ...state,
        isOpen: true,
        prefilledEmail: toEmail(action),
      };
    }
    case ActionType.Close:
      return {
        ...state,
        isOpen: false,
        prefilledEmail: '',
      };

    case LOGIN_FORM_SUBMITTED:
      return isLoginFormAction(action as LoginFormAction)
        ? {
            ...state,
            isOpen: false,
            prefilledEmail: '',
          }
        : state;

    default:
      return state;
  }
};

export default reducer;

export const openLoginModal = (email: string = ''): OpenAction => ({
  type: ActionType.Open,
  payload: { email },
});

export const closeLoginModal = (): CloseAction => ({
  type: ActionType.Close,
});

export const selectIsOpen = (state: State): boolean => state.isOpen;

export const selectPrefilledEmail = (state: State): string => state.prefilledEmail;

export type State = {
  isOpen: boolean;
  prefilledEmail: string;
};

type OpenAction = {
  type: ActionType.Open;
  payload: { email: string };
};

type CloseAction = {
  type: ActionType.Close;
};

type Action = OpenAction | CloseAction | LoginFormAction;
