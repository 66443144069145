import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { isEscapeKey } from '@peloton/keyboard';

export enum Panels {
  Toggles = 'toggles',
  ProductStates = 'productStates',
  Promos = 'promos',
  DateTrigger = 'dateTrigger',
}

const keyboardCodeMap = {
  KeyM: Panels.Promos,
  KeyT: Panels.Toggles,
  KeyD: Panels.DateTrigger,
};

const keyboardKeyMap = {
  µ: Panels.Promos,
  '†': Panels.Toggles,
  '∂': Panels.DateTrigger,
};

type PanelManagerState = {
  activePanel?: Panels;
  isPanelOpen: boolean;
  setActivePanel: (panel?: Panels) => void;
  closePanel: VoidFunction;
};

export const PanelManagerContext = createContext<PanelManagerState>({
  isPanelOpen: false,
  setActivePanel: () => {},
  closePanel: () => {},
});

// TODO: Add logic for other panels
const PanelManagerProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [activePanel, setActivePanel] = useState<Panels | undefined>();
  const closePanel = () => setActivePanel(undefined);

  const escapeKeyListener = useCallback(
    (ev: KeyboardEvent) => {
      if (isEscapeKey(ev) && activePanel) {
        setActivePanel(undefined);
      }
    },
    [activePanel],
  );

  const panelKeyListener = useCallback(
    (ev: KeyboardEvent) => {
      const panel = keyDownCheck(ev);
      if (panel) {
        // Allow toggling on and off with hotkey
        const newPanelState = activePanel === panel ? undefined : panel;
        setActivePanel(newPanelState);
      }
    },
    [activePanel],
  );

  useEffect(() => {
    if (document) {
      document.addEventListener('keydown', escapeKeyListener);
      document.addEventListener('keydown', panelKeyListener);
    }

    return () => {
      document.removeEventListener('keydown', escapeKeyListener);
      document.removeEventListener('keydown', panelKeyListener);
    };
  }, [escapeKeyListener, panelKeyListener]);

  return (
    <PanelManagerContext.Provider
      value={{
        activePanel,
        isPanelOpen: activePanel !== undefined,
        setActivePanel,
        closePanel,
      }}
    >
      {children}
    </PanelManagerContext.Provider>
  );
};

export default PanelManagerProvider;

export const usePanelManagerContext = () => {
  return useContext(PanelManagerContext);
};

const keyDownCheck = (ev: KeyboardEvent): Panels | undefined => {
  if (ev && ev.altKey) {
    if (keyboardCodeMap[ev.code]) return keyboardCodeMap[ev.code];
    // IE Coverage
    if (keyboardKeyMap[ev.key]) return keyboardKeyMap[ev.key];
  }
  return undefined;
};
