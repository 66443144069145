import type { Digital } from '@ecomm/checkout/models';

export enum ActionTypes {
  UpdateSkipTrialPath = 'ecomm/checkout/digital/SET_SKIP_TRIAL_PATH',
}

const getDefaultState = () => ({ skipFreeTrialPath: '' });

const reducer = (state: Digital = getDefaultState(), action: Action) => {
  switch (action.type) {
    case ActionTypes.UpdateSkipTrialPath: {
      const { skipFreeTrialPath } = action.payload;

      return {
        ...state,
        skipFreeTrialPath,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  digital: Digital;
};

export const updateSkipFreeTrialPath = (skipFreeTrialPath: string) =>
  <const>{
    type: ActionTypes.UpdateSkipTrialPath,
    payload: { skipFreeTrialPath },
  };

export type SetSkipFreeTrialPathAction = ReturnType<typeof updateSkipFreeTrialPath>;
type Action = SetSkipFreeTrialPathAction;
