/** This is duplicated in consolidatedAppConfigs. Not ideal, but used in too many places to
 *  want to update all consumers at this time.
 */
export enum BundleType {
  Accessories = 'accessories',
  Bike = 'bike',
  BikePlus = 'bike-plus',
  Tread = 'tread',
  TreadPlus = 'tread-plus',
  RainforestCafe = 'guide',
  Row = 'row',
  RefurbishedBike = 'refurbished-bike',
  RefurbishedBikePlus = 'refurbished-bike-plus',
}

export type EquipmentBundleType =
  | BundleType.Bike
  | BundleType.BikePlus
  | BundleType.Tread
  | BundleType.TreadPlus
  | BundleType.RainforestCafe
  | BundleType.Row
  | BundleType.RefurbishedBike
  | BundleType.RefurbishedBikePlus;
