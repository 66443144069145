import type { A } from '@peloton/auth';

export enum ActionType {
  TrackPageView = 'ecomm/analytics/TRACK_PAGE_VIEW',
  TrackMarketingEmailSubmission = 'ecomm/analytics/TRACK_MARKETING_EMAIL_SUBMISSION',
  TrackMarketingOptInOutClick = 'ecomm/analytics/TRACK_MARKETING_OPT_IN_OUT_CLICK',
}

export type PageViewPayload = {
  /**
   * What to send as the `name` property on the page event.
   * If not set, defaults to the value of `pathname`.
   */
  name?: string;
  pathname: string;
  urlParams?: Record<string, string>;
};

export type MarketingClickPayload = {
  skus?: string;
};

export type TrackPageViewAction = A<ActionType.TrackPageView, PageViewPayload>;
export type TrackMarketingEmailSubmissionAction = {
  type: ActionType.TrackMarketingEmailSubmission;
};
export type TrackMarketingOptInOutAction = A<
  ActionType.TrackMarketingOptInOutClick,
  MarketingClickPayload
>;

export const trackPageView = ({
  name,
  pathname,
  urlParams,
}: PageViewPayload): TrackPageViewAction => ({
  type: ActionType.TrackPageView,
  payload: {
    name,
    pathname,
    urlParams,
  },
});

export const trackMarketingEmailSubmission = (): TrackMarketingEmailSubmissionAction => ({
  type: ActionType.TrackMarketingEmailSubmission,
});

export const trackMarketingOptInOutClick = (skus?: string) => ({
  type: ActionType.TrackMarketingOptInOutClick,
  payload: { skus },
});
