import { curry } from 'ramda';
import type { ID } from '@ecomm/models';
import type { MultiPageProps } from '@ecomm/multipage';
import type { ReducerState as QuickCheckoutReducer } from '@ecomm/quick-checkout/redux/rootReducer';

export enum ActionTypes {
  PreviousFromCheckoutRequested = 'ecomm/quick-checkout/PREVIOUS_FROM_CHECKOUT_REQUESTED',
  PreviousFromCheckoutDenied = 'ecomm/quick-checkout/PREVIOUS_FROM_CHECKOUT_DENIED',
  PreviousFromCheckoutAllowed = 'ecomm/quick-checkout/PREVIOUS_FROM_CHECKOUT_ALLOWED',
  NextFromEmailCaptureRequested = 'ecomm/quick-checkout/NEXT_FROM_EMAIL_CAPTURE_REQUESTED',
  NextFromEmailCaptureDenied = 'ecomm/quick-checkout/NEXT_FROM_EMAIL_CAPTURE_DENIED',
  NextFromEmailCaptureAllowed = 'ecomm/quick-checkout/NEXT_FROM_EMAIL_CAPTURE_ALLOWED',
}

export type PreviousFromCheckoutRequestedAction = {
  type: ActionTypes.PreviousFromCheckoutRequested;
  payload: MultiPageProps & {
    product: ID;
  };
};

export type PreviousFromCheckoutDeniedAction = {
  type: ActionTypes.PreviousFromCheckoutDenied;
};

export type PreviousFromCheckoutAllowedAction = {
  type: ActionTypes.PreviousFromCheckoutAllowed;
};

export type NextFromEmailCaptureRequestedAction = {
  type: ActionTypes.NextFromEmailCaptureRequested;
  payload: MultiPageProps & {
    product: ID;
    skus?: string;
  };
};

export type NextFromEmailCaptureDeniedAction = {
  type: ActionTypes.NextFromEmailCaptureDenied;
};

export type NextFromEmailCaptureAllowedAction = {
  type: ActionTypes.NextFromEmailCaptureAllowed;
  payload: {
    skus?: string;
  };
};

type Actions =
  | NextFromEmailCaptureRequestedAction
  | NextFromEmailCaptureAllowedAction
  | NextFromEmailCaptureDeniedAction
  | PreviousFromCheckoutRequestedAction
  | PreviousFromCheckoutAllowedAction
  | PreviousFromCheckoutDeniedAction;

export type State = {
  requested: boolean;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
};

export const defaultState = {
  requested: false,
  goToNextStep: () => {},
  goToPreviousStep: () => {},
};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.NextFromEmailCaptureRequested:
      return { requested: true, goToNextStep: action.payload.goToNextStep };

    case ActionTypes.NextFromEmailCaptureAllowed:
      return defaultState;

    case ActionTypes.PreviousFromCheckoutAllowed:
      return defaultState;

    default:
      return state;
  }
};

export default reducer;

export type MultiStepState = {
  multiStep: State;
};

export const requestNextFromEmailCapture = curry(
  (
    goToNextStep: () => void,
    product: ID,
    skus?: string,
  ): NextFromEmailCaptureRequestedAction => ({
    type: ActionTypes.NextFromEmailCaptureRequested,
    payload: { product, goToNextStep, skus },
  }),
);

export const denyNextFromEmailCapture = (): NextFromEmailCaptureDeniedAction => ({
  type: ActionTypes.NextFromEmailCaptureDenied,
});

export const allowNextFromEmailCapture = (
  skus?: string,
): NextFromEmailCaptureAllowedAction => ({
  type: ActionTypes.NextFromEmailCaptureAllowed,
  payload: { skus },
});

export const requestPreviousFromCheckoutStep = curry(
  (goToPreviousStep: () => void, product: ID): PreviousFromCheckoutRequestedAction => ({
    type: ActionTypes.PreviousFromCheckoutRequested,
    payload: { product, goToPreviousStep },
  }),
);

export const DenyPreviousFromCheckoutStep = (): PreviousFromCheckoutDeniedAction => ({
  type: ActionTypes.PreviousFromCheckoutDenied,
});

export const AllowPreviousFromCheckoutStep = (): PreviousFromCheckoutAllowedAction => ({
  type: ActionTypes.PreviousFromCheckoutAllowed,
});

export const getHasAlreadyRequested = (state: QuickCheckoutReducer): boolean =>
  state.quickCheckout.multiStep.requested;

export const getGoToNextStep = (state: QuickCheckoutReducer): (() => void) =>
  state.quickCheckout.multiStep.goToNextStep;
