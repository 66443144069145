export const ID_ME_USER_DATA_REQUEST = `/ecomm/id_me/get_user_data`;
export const CP_TRIAL_PATH = '/digital/offers/cp-free-trial';
export const STUDENT_TRIAL_PATH = '/digital/offers/students-free-trial';
export const ID_ME_STATE = 'id_me_state';

export enum IdMeCohort {
  Student = 'student',
  Teacher = 'teacher',
  FirstResponder = 'responder',
  Military = 'military',
  Medical = 'medical',
  Nurse = 'nurse',
}

export type IdMeUserAttribute = {
  handle: string;
  name: string;
  value: string;
};

export type IdMeStatus = {
  group: string;
  subgroups: string[];
  verified: boolean;
};
