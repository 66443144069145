import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import type { Locale } from '@peloton/internationalize';
import { fetchWWWBuilderContentAggregate } from '@content/client/lib/fetchBuilderContentAggregate';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import useContentAggregate from '../aggregates/useContentAggregate';
import nav from './nav';

// When preview copy is enabled, we don't pass a custom fetcher so that we can use the default fetcher,
// which goes straight to contentful
// When preview copy is *not* enabled, we pass a custom fetcher that fetches from the builder function
const useCustomFetcher = (locale: Locale) => {
  const previewCopy = useIsToggleActive()('previewCopy');
  const preview = previewCopy || ALLOW_PREVIEW_COPY;
  if (preview) {
    return undefined;
  }

  return () => {
    return fetchWWWBuilderContentAggregate(nav.entryId, locale);
  };
};

const useNav = (locale: Locale): any => {
  const customFetcher = useCustomFetcher(locale);
  return useContentAggregate(nav.entryId, false, locale, customFetcher);
};

export default useNav;
