/**
 * Given an `input` string, return a string with the
 * all breaking dashes replaced by non-breaking dashes/hyphens.
 *
 * @param {string} input - The string to be hardened.
 */
import { nbh } from './nonbreaking';

export const hardenDashes = (input: string = ''): string => {
  const pieces = input.split('-');
  return pieces.join(nbh);
};
