import { schema } from 'normalizr';
import type { ApiDimension } from '../types';
import trait from './trait';

const processDimension = ({ id, name, slug, traitSet: traits }: ApiDimension) => ({
  id,
  name,
  slug,
  traits,
});

const dimension = new schema.Entity(
  'dimensions',
  { traits: [trait] },
  { processStrategy: processDimension },
);

export default dimension;
