import type { SubscriptionPlan } from '@peloton/subscription-plans';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { Partner } from '@onewellness/models';
import type { Audience } from '@onewellness/routes';

export enum ActionTypes {
  LookupRequested = 'onewellness/lookup/ELIGIBILITY_LOOKUP_REQUESTED',
  LookupSucceeded = 'onewellness/lookup/ELIGIBILITY_LOOKUP_SUCCEEDED',
  LookupFailed = 'onewellness/lookup/ELIGIBILITY_LOOKUP_FAILED',
}

export type State = UIState & {
  email: string;
  lookupCount: number;
  scrollY: number;
  partner?: Partner;
  plan?: SubscriptionPlan;
};

export const defaultState: State = {
  email: '',
  ...toUIState(Status.Init),
  lookupCount: 0,
  scrollY: 0,
  partner: undefined,
  plan: undefined,
};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.LookupRequested: {
      const { email, partner, token } = action.payload;

      return {
        ...toUIState(Status.Loading),
        lookupCount: state.lookupCount + 1,
        scrollY: window.scrollY,
        email,
        partner,
        token,
      };
    }
    case ActionTypes.LookupSucceeded:
      return {
        ...state,
        ...toUIState(Status.Loaded),
        scrollY: window.scrollY,
      };

    case ActionTypes.LookupFailed: {
      const { exception } = action.payload;
      return {
        ...state,
        ...toUIState(Status.Failed, exception),
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  lookup: State;
};

export const requestLookup = (
  payload: LookupRequestActionPayload,
): LookupRequestAction => ({
  type: ActionTypes.LookupRequested,
  payload,
});

export const lookupSucceeded = (): LookupSuccessAction => ({
  type: ActionTypes.LookupSucceeded,
});

export const lookupFailed = (
  errorId: string = 'pgCorporateBenefitsOnboarding.errors.lookup',
): LookupFailedAction => ({
  type: ActionTypes.LookupFailed,
  payload: { exception: toException(errorId) },
});

export type LookupRequestActionPayload = {
  audience: Audience;
  email: string;
  hasAccount: boolean;
  partner?: Partner;
  token?: string;
};

export type LookupRequestAction = {
  type: ActionTypes.LookupRequested;
  payload: LookupRequestActionPayload;
};
type LookupSuccessAction = { type: ActionTypes.LookupSucceeded };
type LookupFailedAction = {
  type: ActionTypes.LookupFailed;
  payload: { exception: Exception };
};

type Actions = LookupRequestAction | LookupSuccessAction | LookupFailedAction;
