import { schema } from 'normalizr';
import type { ApiProduct } from '../types';
import category from './category';
import dimension from './dimension';
import option from './option';
import { toProductLine } from './utils';

const processProduct = ({
  categorySet: categories,
  description,
  dimensionSet: dimensions,
  displayName: name,
  id,
  imageUrls,
  optionSet: options,
  priceInCents: price,
  productLine,
  slug,
}: ApiProduct) => ({
  categories,
  description,
  dimensions,
  id,
  imageUrls,
  name,
  options,
  price,
  productLine: toProductLine(productLine),
  slug,
});

const product = new schema.Entity(
  'products',
  {
    options: [option],
    categories: [category],
    dimensions: [dimension],
  },
  { processStrategy: processProduct },
);

export default product;
