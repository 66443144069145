import { useMemo } from 'react';
import { theUserIsInAustria } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { useHasVariation } from '@peloton/split-testing/hooks';
import useShowPromoDealsNav from '@content/client/hooks/useShowPromoDealsNav';
import useNav from '@content/client/www/nav/useNav';
import type { NavigationData } from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type { AlternateKeys, ProductNavMeta } from '@ecomm/header/models';
import { Menu } from '@ecomm/header/models';
import { useProductStates } from '@ecomm/product-states/Context';
import { Product } from '@ecomm/product-states/models/product';
import { usePrograms } from '@ecomm/programs/Context';
import { useKeyFromDigitalContext } from '../digitalContext';

type ApparelToggle = {
  showApparel: boolean;
};

export const ClassesRoute = {
  name: Menu.Classes,
  cmsId: 'nav.disciplineClasses' as const,
  rootUrls: [RegExp(/classes/)],
  subnavName: 'classes',
};

export const BikeRoute = {
  name: Menu.Bike,
  cmsId: 'nav.bike' as const,
  rootUrls: [
    RegExp(/^\/bikes(?!\/classes)(\/[a-zA-Z-]*)?$/m),
    RegExp(/^\/bike(?!\/classes)(\/[a-zA-Z-]*)?$/m),
    RegExp(/\/bike-plus/),
  ],
};

export const BikesRoute = {
  name: Menu.Bikes,
  cmsId: 'nav.bikes' as const,
  rootUrls: [
    RegExp(/^\/bikes(?!\/classes)(\/[a-zA-Z-]*)?$/m),
    RegExp(/\/bike(?!\/classes)(\/[a-zA-Z-]*)?$/m),
    RegExp(/\/bike-plus/),
    RegExp(/\/exercise-bikes/),
  ],
  subnavName: 'bikes',
};
export const TreadsRoute = {
  name: Menu.Treads,
  cmsId: 'nav.treads' as const,
  rootUrls: [
    RegExp(/^\/treads(?!\/classes)(\/[a-zA-Z-]*)?$/m),
    RegExp(/\/tread(?!\/classes)(\/[a-zA-Z-]*)?$/m),
    RegExp(/\/tread-plus/),
    RegExp(/\/treadmills/),
  ],
  subnavName: 'treads',
};

export const TreadRoute = {
  name: Menu.Tread,
  cmsId: 'nav.tread.productStates' as const,
  rootUrls: [RegExp(/^\/tread(?!\/classes)(\/[a-zA-Z-]*)?$/m), RegExp(/\/tread-plus/)],
  subnavName: 'tread',
};

export const TreadCartAvailable = {
  name: Menu.Tread,
  cmsId: 'nav.tread.prismLaunch' as const,
  rootUrls: [RegExp(/^\/tread(?!\/classes)(\/[a-zA-Z-]*)?$/m)],
  subnavName: 'tread',
};

export const AppAvailable = {
  name: Menu.App,
  cmsId: 'nav.app' as const,
  rootUrls: [RegExp(/\/app$/), RegExp(/\/app-membership$/)],
};

export const TreadCartNotAvailable = {
  name: Menu.Tread,
  cmsId: 'nav.tread' as const,
  rootUrls: [RegExp(/^\/tread(?!\/classes)(\/[a-zA-Z-]*)?$/m)],
};
export const Accessories = {
  name: Menu.Accessories,
  cmsId: 'nav.accessories' as const,
  rootUrls: [RegExp(/\/shop\/accessories/), RegExp(/\/gift-cards/)],
};

export const Apparel = {
  name: Menu.Apparel,
  cmsId: 'nav.apparel' as const,
  rootUrls: [RegExp(/apparel/)],
};

export const ApparelNew = {
  name: Menu.Apparel,
  cmsId: 'nav.apparelNew' as const,
  rootUrls: [RegExp(/apparel/)],
};

export const Strength = {
  name: Menu.Strength,
  cmsId: 'nav.strength' as const,
  rootUrls: [RegExp(/guide-announce/)],
};

export const RowRoute = {
  name: Menu.Row,
  cmsId: 'nav.row' as const,
  rootUrls: [RegExp(/\/row(?!\/classes)(\/[a-zA-Z-]*)?$/m)],
  subnavName: 'row',
};

export const About = {
  name: Menu.About,
  cmsId: 'nav.about' as const,
  rootUrls: [RegExp(/about/)],
};

export const RainforestCafe = {
  name: Menu.RainforestCafe,
  cmsId: 'nav.rainforestCafe' as const,
  rootUrls: [RegExp(/\/guide/)],
  subnavName: 'rainforestCafe',
};
export const Deals = {
  name: Menu.Deals,
  cmsId: 'nav.deals' as const,
  rootUrls: [RegExp(/deals/)],
};

export const useProductNavsFromStates = ({
  navAppKey,
  showApparel,
}: AlternateKeys & ApparelToggle): ProductNavMeta[] => {
  const {
    isProductAvailableForPurchase,
    isProductAvailableForPurchaseOrSoldOut,
    isProductAvailableForMarketingPages,
  } = useProductStates();

  const showPromoDealsPageNav = useShowPromoDealsNav();

  const isToggleActive = useIsToggleActive();
  const isAppVariation = useHasVariation('App Navigation Update', 'Variation #1');
  const isDealsPageActive = isToggleActive('dealsPage') || showPromoDealsPageNav;
  const isGuideRemovedFromMainNav = isToggleActive('removeGuideFromMainNav');

  const isBikeMarketable = isProductAvailableForMarketingPages(Product.Bike);
  const isBikePlusMarketable = isProductAvailableForMarketingPages(Product.BikePlus);
  const isTreadMarketable = isProductAvailableForMarketingPages(Product.Tread);
  const isTreadPlusMarketable = isProductAvailableForMarketingPages(Product.TreadPlus);
  const isAppMarketable = isProductAvailableForMarketingPages(Product.DigitalApp);
  const isRowMarketable = isProductAvailableForMarketingPages(Product.Row);

  const isGuideMarketable = isProductAvailableForMarketingPages(Product.RainforestCafe);
  const isGuidePresaleAvailable = isProductAvailableForPurchase(Product.RainforestCafe);
  const onlyOneBikeAvailable =
    (isBikeMarketable && !isBikePlusMarketable) ||
    (!isBikeMarketable && isBikePlusMarketable);

  if (isGuideRemovedFromMainNav) {
    Accessories.rootUrls.push(RegExp(/\/guide/));
  }

  const bothTreadsAvailable = isTreadMarketable && isTreadPlusMarketable;
  const atLeastOneTreadAvailable = isTreadMarketable || isTreadPlusMarketable;
  const atLeastOneCFUAvailable =
    isProductAvailableForPurchaseOrSoldOut(Product.Row) ||
    isProductAvailableForPurchaseOrSoldOut(Product.Tread) ||
    isProductAvailableForPurchaseOrSoldOut(Product.TreadPlus) ||
    isProductAvailableForPurchaseOrSoldOut(Product.Bike) ||
    isProductAvailableForPurchaseOrSoldOut(Product.BikePlus);

  const isGuideAnnounced = isToggleActive('leadGenBetaPage');
  const isApparelMegaMenuActive = useHasVariation('Apparel MegaMenu', 'Variation #1');

  /** @todo added for .at launch */
  const locale = useLocale();
  const isAustriaLocale = theUserIsInAustria(locale);

  /** @todo remove isAustriaTLD after .at launch finishes */
  return [
    isDealsPageActive && Deals,
    isAustriaLocale && About,
    !isAustriaLocale && ClassesRoute,
    isRowMarketable && RowRoute,
    (onlyOneBikeAvailable || isAustriaLocale) && BikeRoute,
    isBikeMarketable && isBikePlusMarketable && BikesRoute,
    bothTreadsAvailable && TreadsRoute,
    !bothTreadsAvailable && atLeastOneTreadAvailable && TreadRoute,
    isGuideAnnounced && !isGuideRemovedFromMainNav && Strength,
    !isGuideRemovedFromMainNav &&
      isGuideMarketable && {
        ...(!isGuideRemovedFromMainNav && RainforestCafe),
        ...(!isGuidePresaleAvailable && {
          cmsId: 'nav.rainforestCafe.presale',
        }),
      },
    isAppMarketable && {
      ...AppAvailable,
      ...(navAppKey ? { cmsId: navAppKey } : {}),
      ...(isAppVariation ? { cmsId: `${navAppKey}_variated` } : {}),
    },
    !isAustriaLocale && atLeastOneCFUAvailable && Accessories,
    ...(!isAustriaLocale && showApparel
      ? isApparelMegaMenuActive
        ? [ApparelNew]
        : [Apparel]
      : []),
  ].filter((p): p is ProductNavMeta => !!p);
};

export const getToggledCmsProductNavs = (
  subnavs: NavigationData['subnavs'],
  toggledNavs: ProductNavMeta[],
): ProductNavMeta[] => {
  const cmsSubnavKeys = subnavs?.map(({ key }) => key);
  const toggledCmsProductNavs = toggledNavs?.filter(({ cmsId }) =>
    cmsSubnavKeys?.includes(cmsId),
  );

  const sortedNavs = toggledCmsProductNavs?.sort((curr, next) => {
    const currId = curr['cmsId'];
    const nextId = next['cmsId'];

    if (cmsSubnavKeys?.indexOf(currId) > cmsSubnavKeys?.indexOf(nextId)) {
      return 1;
    } else {
      return -1;
    }
  });

  return sortedNavs;
};

const useToggledProductNavs = (): ProductNavMeta[] => {
  const { apparel: showApparel } = usePrograms();

  const navAppKey = useKeyFromDigitalContext('nav.app', 'preFix');

  return useProductNavsFromStates({
    navAppKey,
    showApparel,
  });
};

const useProductNavsMeta = (): ProductNavMeta[] => {
  const locale = useLocale();
  const { content, isLoading } = useNav(locale);

  const toggledNavs = useToggledProductNavs();
  const toggledCmsProductNavs = useMemo(() => {
    let subnavs = [];

    if (!isLoading) {
      subnavs = content?.subnavs;
    }

    return getToggledCmsProductNavs(subnavs, toggledNavs);
  }, [toggledNavs, content, isLoading]);

  return toggledCmsProductNavs;
};

export default useProductNavsMeta;
