import {
  Badge,
  TwoColumnLayout,
  EyebrowHeadlineBody,
  Media,
  grey,
  spacing,
} from '@pelotoncycle/design-system';
import React, { useContext } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { media } from '@peloton/styles';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useIsTreadPlusLeadGen } from '@ecomm/feature-toggle/useIsTreadPlusLeadGen';
import { HeaderNavContext } from '@ecomm/header/HeaderNavProvider';
import { Link } from '@ecomm/internationalize-ui';
import { b5 } from '@ecomm/typography';

type Props = {
  categoryId: string;
  data: Omit<ProductDetailsCardData, 'featureLists'>;
  showCtas: boolean;
  hasSecondaryLinks: boolean;
};

const ProductCard: React.FC<React.PropsWithChildren<Props>> = ({
  categoryId,
  data: {
    key,
    productName,
    description,
    image: { title, url: imgSrc },
    primaryCta: { text, url, targetBlank },
    secondaryCta,
    ribbonText,
  },
  showCtas,
  hasSecondaryLinks,
}) => {
  const { trackEvent } = useTracking();
  const showCtaWrapper = showCtas && secondaryCta;
  const { hideMenu } = useContext(HeaderNavContext);
  const showRibbon = ribbonText && ribbonText !== 'hide';
  const cachedImage = toCloudinarySrc({ src: imgSrc, width: 120 });
  const isTreadPlusLeadGen = useIsTreadPlusLeadGen(key);

  const secondaryUrl = secondaryCta?.url;

  const isGuideRemovedFromMainNav = useIsToggleActive()('removeGuideFromMainNav');
  const isShowStrengthPlus = useIsToggleActive()('showStrengthPlusInNav');
  const isNavBadgingEnabled = useIsToggleActive()('bfcm_promo');
  const showPromoBadgeText = isNavBadgingEnabled && showRibbon;

  const shouldShowAccessoriesCtas =
    !showCtas &&
    isGuideRemovedFromMainNav &&
    (key === 'nav.productCard.accessories' ||
      key === 'nav.productCard.GiftCard' ||
      key === 'nav.productCard.Guide');

  const shouldShowAppCtas =
    isShowStrengthPlus &&
    (key === 'nav.productCard.strengthPlus' || key === 'nav.productCard.app');

  return (
    <CardWrapper data-test-id={key} hasSecondaryLinks={hasSecondaryLinks}>
      <LinkWrapper
        to={url}
        href={url}
        target={targetBlank ? '_blank' : '_self'}
        onClick={() => {
          hideMenu();
        }}
      >
        {showPromoBadgeText && (
          <StyledBadge
            variant="horizontal"
            theme="accent"
            position="absolute"
            top="-24px"
          >
            {ribbonText}
          </StyledBadge>
        )}

        <StyledTwoColumnLayout
          is="div"
          tabIndex={showCtas ? -1 : 0}
          template="seventyThirty"
          reverse
        >
          <ProductInfo showCtaWrapper={Boolean(showCtaWrapper)}>
            <div className="headline-menu">
              <EyebrowHeadlineBody
                eyebrowText={productName}
                bodyText={description}
                size="large"
              />
            </div>
            <div>
              {(showCtas || shouldShowAccessoriesCtas || shouldShowAppCtas) && (
                <CtaWrapper>
                  <StyledNavLink
                    data-test-id={`${key}.primaryLink`}
                    ariaLabel={`${key}.primaryLink`}
                    to={url}
                    href={url}
                    variant="body"
                    size="small"
                    target={targetBlank ? '_blank' : '_self'}
                    onClick={() => {
                      trackEvent({
                        properties: {
                          category: categoryId,
                          parent: key,
                          linkTo: url,
                          linkName: text,
                        },
                      });
                      hideMenu();
                    }}
                  >
                    {text}
                  </StyledNavLink>
                  {secondaryCta && !isTreadPlusLeadGen && (
                    <StyledNavLink
                      data-test-id={`${key}.secondary`}
                      ariaLabel={`${key}.secondary`}
                      to={secondaryUrl}
                      href={secondaryUrl}
                      target={secondaryCta.targetBlank ? '_blank' : '_self'}
                      variant="body"
                      size="small"
                      className="links"
                      onClick={() => {
                        trackEvent({
                          properties: {
                            category: categoryId,
                            parent: key,
                            unitName: secondaryCta.key || '',
                            linkTo: secondaryUrl,
                            linkName: secondaryCta.text,
                          },
                        });
                        hideMenu();
                      }}
                    >
                      {secondaryCta.text}
                    </StyledNavLink>
                  )}
                </CtaWrapper>
              )}
            </div>
          </ProductInfo>
          <StyledImage
            media={{
              alt: title,
              desktop: cachedImage,
              tablet: cachedImage,
              mobile: cachedImage,
              type: 'image',
            }}
            objectFit="contain"
            height="auto"
            width="10rem"
          />
        </StyledTwoColumnLayout>
      </LinkWrapper>
    </CardWrapper>
  );
};

export default ProductCard;

const CardWrapper = styled.div<{ hasSecondaryLinks: boolean }>`
  position: relative;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${({ hasSecondaryLinks }) =>
      hasSecondaryLinks ? '3.5rem' : '2.5rem'};
  }
`;

const LinkWrapper = styled(Link)`
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledTwoColumnLayout = styled(TwoColumnLayout)`
  div {
    justify-content: start;
  }
`;

const StyledImage = styled(Media)`
  min-height: 7rem; /* prevents UI from jumping when images load */
  margin-right: 2.5rem;
  flex-basis: 35% !important;
`;

const ProductInfo = styled.div<{ showCtaWrapper: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ showCtaWrapper }) => (showCtaWrapper ? 'start' : 'center')};
  flex-basis: 65% !important;
  [role='heading'] {
    margin-left: -0.05em; /* [1] */
  }
  .headline-menu {
    font-size: ${spacing[16]};
    margin-bottom: -8px;
  }
  p {
    ${b5};
    color: ${grey[70]};
    margin-top: -5px;
    max-width: 12.5rem;
    text-transform: none;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNavLink = styled(Link)`
  padding-top: ${spacing[8]};
  margin-top: -${spacing[8]};

  span {
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
  }
  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover {
    color: ${grey[70]};
  }
`;

const StyledBadge = styled(Badge)`
  line-height: 1.375em !important;
  font-size: ${spacing[8]} !important;
  border-radius: 1px;
  padding: 2px 4px;

  ${media.desktopLarge`
    line-height: 1.23em !important;
    font-size: .813rem !important;
    font-weight: 600;
    border-radius: 4px;
    padding: 4px 8px;
  `}
`;
