import { curry } from 'ramda';
import type { Reducer } from 'redux';
import { createSelector } from 'reselect';
import type { Locale } from '@peloton/internationalize';
import { toMatchingTld } from '@peloton/internationalize';
import type { RoutingState } from '@peloton/redux/selectors';
import { getQueryParams } from '@peloton/redux/selectors';
import { toLocaleHostname } from '../internationalize/models';
import type { ExtLink, ExtLinkEnv } from './models';
import { toExtLinkEnv, toHref as toHrefFromLink, safelyDecode } from './models';
import toRedirectUrl from './toRedirectUrl';
import { REDIRECT_APP_QUERY_PARAM, REDIRECT_URL_QUERY_PARAM } from './urls';

export type ExtLinkEnvState = {
  extLinkEnv: ExtLinkEnv;
};

export enum LinkEnvActionTypes {
  SetHostname = 'pelo/linkEnvReducer/SET_HOST',
}

export const setHostname = (locale: Locale) => ({
  type: LinkEnvActionTypes.SetHostname,
  payload: locale,
});

export type SetHostnameAction = ReturnType<typeof setHostname>;

type LinkEnvAction = SetHostnameAction;

export const extLinkEnvReducer: Reducer<ExtLinkEnv> = (
  state = toExtLinkEnv(),
  action: LinkEnvAction,
) => {
  switch (action.type) {
    case LinkEnvActionTypes.SetHostname:
      return {
        ...state,
        hostname: toLocaleHostname(action.payload),
      };
    default:
      return state;
  }
};

export const getExtLinkEnv = (state: ExtLinkEnvState) => state.extLinkEnv;

export const selectExtLinkEnv = createSelector(
  [getExtLinkEnv],
  (extLinkEnv: ExtLinkEnv) => extLinkEnv,
);

export const toHrefFromState = curry((state: ExtLinkEnvState, link: ExtLink) =>
  toHrefFromLink(link, getExtLinkEnv(state)),
);

export const getTLD = (state: ExtLinkEnvState) =>
  toMatchingTld(getExtLinkEnv(state).hostname);

/**
 * Get the full redirect URL given the current route & external-link environment.
 */
export const getUrlForRedirectQueryParams = (state: RoutingState & ExtLinkEnvState) => {
  const queryParams = getQueryParams(state);
  const encodedRedirectPath = queryParams[REDIRECT_URL_QUERY_PARAM];
  const type = queryParams[REDIRECT_APP_QUERY_PARAM];
  const path = safelyDecode(encodedRedirectPath);
  const extLinkEnv = getExtLinkEnv(state);
  return toRedirectUrl({ extLinkEnv, path, type });
};
