export type Money = number;

/** @param amount value in cents */
export const toDollars = (amount: Money): number => amount / 100; // TODO: Rename to make less USD-centric

export const toCents = (amount: Money): number => amount * 100;

/**
 * @description Rounds up to the nearest dollar
 * @param amount value in cents
 * */
export const toDollarsCeil = (amount: Money): Money => Math.ceil(amount / 100);

export const roundToDollarWithCents = (amount: Money): Money =>
  Number(toDollars(amount).toFixed(2));
