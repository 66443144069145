export const CHECKOUT_ACCESS_TOKEN_STORAGE_KEY = 'checkoutAccessToken';
export const LOGIN_SOCIAL_ERROR_CODE = 'loginSocialErrorCode';
export const RETURN_TO_STORAGE_KEY = 'returnTo';
export const NUMBER_OF_LOGIN_ATTEMPTS = 'loginRetryAttempts';

export const socialErrorCodesDictionary = {
  [10502]: 'errorCode.10502',
  [10503]: 'errorCode.10503',
  [20001]: 'errorCode.20001',
  [20101]: 'errorCode.20101',
  [20102]: 'errorCode.20102',
  [20103]: 'errorCode.20103',
  [20104]: 'errorCode.20104',
  [20105]: 'errorCode.20105',
  [20106]: 'errorCode.20106',
  [20107]: 'errorCode.20107',
  [20109]: 'errorCode.20109',
  [20999]: 'errorCode.20999',
  [20110]: 'errorCode.20110',
  [20111]: 'errorCode.20111',
  [20601]: 'errorCode.20601',
  [20602]: 'errorCode.20602',
  [20701]: 'errorCode.20701',
  [20702]: 'errorCode.20702',
};
