import mapToView from './mapToView';

// https://app.contentful.com/spaces/6jnflt57iyzx/environments/master/entries/746wBFxKworr7T1NkIhg47
const RENTAL_PRICES_ENTRY_ID = '746wBFxKworr7T1NkIhg47';

export default {
  entryId: RENTAL_PRICES_ENTRY_ID,
  contentType: 'rental-prices',
  mapToView,
};
