import { map, pipe, propOr } from 'ramda';
import { BundleType } from '@ecomm/shop/models/BundleType';
import type { Cart } from './Cart';
import { toTotalDiscount } from './Discount';
import type { Item, FlattenedItem } from './Item';
import { hasOrIsAccessory, toSingleItems, toPrimaryImage } from './Item';

const toSegmentProperties = (item: FlattenedItem) => {
  const { name, price, productId, productLine, quantity, sku, bundleSlug } = item;
  return {
    brand: 'Peloton',
    category: productLine,
    name,
    price,
    product_id: productId,
    quantity,
    image_url: toPrimaryImage(item),
    sku,
    package_name: bundleSlug,
  };
};

export const toBundleName = (items: Item[]) =>
  items.filter(item => item.type === 'bundle').map(item => item.name);

export const toAccessoriesProps = (items: Item[]) => {
  const accessories = items.filter(hasOrIsAccessory);
  const hasAccessory = accessories.length > 0;

  return {
    accessories,
    hasAccessory,
  };
};

export const hasGuideBundle = (bundlesInCart: string[]) => {
  return {
    hasGuide: bundlesInCart
      .map(bundle => bundle.toLowerCase())
      .includes(BundleType.RainforestCafe),
  };
};

export const toItemTrackingProperties = pipe(toSingleItems, map(toSegmentProperties));

export const toCartTrackingProperties = ({
  currency,
  discounts = [],
  id,
  items = [],
  shipmentQuote,
  subtotal,
  tax,
  total,
  ...rest
}: Cart) => ({
  currency,
  orderId: id,
  value: total,
  revenue: subtotal,
  shipping: propOr(0, 'amount', shipmentQuote),
  tax,
  discount: toTotalDiscount(discounts),
  products: toItemTrackingProperties(items),
  hasTradeIn: Boolean(rest.tradeIn),
  bundleName: toBundleName(items),
  ...toAccessoriesProps(items),
  ...hasGuideBundle(toBundleName(items)),
});
