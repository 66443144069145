import type { SagaIterator } from 'redux-saga';
import { takeEvery, call, getContext } from 'redux-saga/effects';
import type { ReportError } from './ErrorReporter';
import type { ReportErrorAction } from './redux';
import { ActionType } from './redux';

export const CLIENT_CONTEXT = 'errorHandler';

const reportError = function* (errorReporter: ReportError, action: ReportErrorAction) {
  const { error, options } = action.payload;
  yield call(errorReporter, error, options);
};

const errorReportingSaga = function* (): SagaIterator {
  const errorReporter = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(ActionType.ReportError, reportError, errorReporter);
};

export default errorReportingSaga;
