import { replace } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { toCountry } from '@peloton/internationalize/models';
import { getLocation } from '@peloton/redux';
import { getUserTrackingProperties } from '@ecomm/auth';
import { CLIENT_CONTEXT } from '@onewellness/api/client';
import { ErrorCode } from '@onewellness/api/utils';
import { benefitActivateAPI } from '../api';
import { toErrorCode } from '../models/eligiblePersonActivate';
import type { LookupRequestAction } from '../redux';
import { LookupActionTypes, lookupFailed, lookupSucceeded } from '../redux';
import { Audience, Page, toPathWithAudience } from '../routes';
import { trackLookupFailed, trackLookupSuccess } from '../segment';

export const lookupByEmailSaga = function* ({
  payload: { audience, email, hasAccount, partner, token },
}: LookupRequestAction): SagaIterator {
  const userTrackingProperties = yield select(getUserTrackingProperties);

  try {
    const client = yield getContext(CLIENT_CONTEXT);
    const locale = toCountry();
    const response = yield call(
      benefitActivateAPI,
      client,
      email,
      hasAccount,
      locale,
      token,
    );
    const { search: queryParams } = yield select(getLocation);

    if (response.eligiblePersonActivate.requestOk) {
      yield all([
        put(lookupSucceeded()),
        call(trackLookupSuccess, {
          audience,
          userTrackingProperties,
          email,
          partner,
        }),
        put(
          replace(
            `${toPathWithAudience(Page.VerifyEligibility, audience)}${queryParams}`,
          ),
        ),
      ]);
    } else {
      const error = toErrorCode(response);
      let errorId = `errors.${error}`;

      if (audience === Audience.YMCA && error === ErrorCode.InvalidEmail) {
        errorId = `ymca.errors.${error}`;
      }

      yield put(lookupFailed(errorId));
    }
  } catch (error) {
    const report = new DomainError(error.message, {
      ...error,
      name: 'EligibilityLookup',
    });
    const errorId = `errors.${error.message}`;
    yield all([
      put(lookupFailed(errorId)),
      put(reportError({ error: report })),
      call(trackLookupFailed, {
        audience,
        userTrackingProperties,
        exception: error,
        partner,
      }),
    ]);
  }
};

const watcherSaga = function* () {
  yield takeEvery(LookupActionTypes.LookupRequested, lookupByEmailSaga);
};

export default watcherSaga;
