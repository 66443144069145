import { isBrowser } from '@peloton/browser/identify';

const isTokenExpired = (token: string | null): boolean => {
  if (!isBrowser || !token) {
    return false;
  }

  try {
    const { exp } = JSON.parse(atob((token as string).split('.')[1]));
    if (exp < Math.floor(Date.now() / 1000)) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return true;
  }
  return false;
};

export default isTokenExpired;
