import React from 'react';
import { Route } from 'react-router';
import { useIsToggleActive } from '@ecomm/feature-toggle';
import ProductValuesLegalSection from './ProductValuesLegalSection';

type LegalSectionProps = {
  hasFinancingPartner: boolean;
  bikeEnabled: boolean;
  bikePlusEnabled: boolean;
  isTreadAvailable: boolean;
  isTreadPlusAvailable: boolean;
  isRowAvailable: boolean;
  waysToTryEnabled: boolean;
};

const ProductLegalSection: React.FC<React.PropsWithChildren<LegalSectionProps>> = ({
  hasFinancingPartner,
  bikeEnabled,
  bikePlusEnabled,
  isTreadAvailable,
  isTreadPlusAvailable,
  isRowAvailable,
  waysToTryEnabled,
  ...rest
}) => {
  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const isFreeTrialForSixtyDaysEnabled = isToggleActive('freeTrialForSixtyDays');
  const isCPayEnabled = isToggleActive('cpay_enabled');

  return (
    <>
      {hasFinancingPartner && bikeEnabled && (
        <>
          <Route
            path="/:path(bike/reviews)"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="bikeReviewsFinancingLegalCopy"
                testId="bikePageLegalCopy"
              />
            )}
          />
        </>
      )}
      {hasFinancingPartner && (bikeEnabled || bikePlusEnabled) && (
        <>
          <Route
            path="/:path(bikes/trade-in)"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="bikesTradeInFinancingLegalCopy"
                testId="bikePageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(bikes/compare)"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey={
                  isCPayEnabled
                    ? 'bikesCompareFinancingLegalCopy'
                    : 'bikesCompareFinancingLegalCopy.CPayOff'
                }
                testId="bikePageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(shop/guide/*)"
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="shopGuideLegalCopy"
                testId="guidePageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(shop/bike/*)"
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey={
                  isProjectPhoenixEnabled
                    ? 'shopBikeFinancingLegalCopy'
                    : 'shopBikeFinancingLegalCopy.shippingIncluded'
                }
                testId="bikePageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(shop/bike-plus/*)"
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="shopBikePlusFinancingLegalCopy"
                testId="bikePageLegalCopy"
              />
            )}
          />
        </>
      )}
      {hasFinancingPartner && bikePlusEnabled && (
        <>
          <Route
            path="/:path(bike-plus/reviews)"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="bikePlusReviewsFinancingLegalCopy"
                testId="bikePlusPageLegalCopy"
              />
            )}
          />
        </>
      )}
      {hasFinancingPartner && (bikeEnabled || bikePlusEnabled) && (
        <>
          <Route
            path="/:path(home-trial)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey="homeTrialLegalFooter"
                testId="bikePageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(bikes)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey="bikesFinancingLegalCopy"
                testId="bikePageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(bike-plus)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey="bikePlusFinancingLegalCopy"
                testId="bikePageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(bike)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey={
                  isProjectPhoenixEnabled
                    ? 'bikeFinancingLegalCopy'
                    : 'bikeFinancingLegalCopy.shippingIncluded'
                }
                testId="bikePageLegalCopy"
              />
            )}
          />
        </>
      )}
      {hasFinancingPartner && isTreadAvailable && (
        <>
          <Route
            path="/:path(tread)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey={
                  isProjectPhoenixEnabled
                    ? 'prismFinancingLegalCopy'
                    : 'prismFinancingLegalCopy.shippingIncluded'
                }
                testId="treadPageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(tread/reviews)"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="treadReviewsFinancingLegalCopy"
                testId="treadPageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(tread/specs)"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="prismSpecsFinancingLegalCopy"
                testId="treadPageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(shop/tread/*)"
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey={
                  isProjectPhoenixEnabled
                    ? 'shopTreadFinancingLegalCopy'
                    : 'shopTreadFinancingLegalCopy.shippingIncluded'
                }
                testId="treadPageLegalCopy"
              />
            )}
          />
        </>
      )}
      {hasFinancingPartner && isRowAvailable && (
        <>
          <Route
            path="/:path(shop/row/*)"
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="shopRowFinancingLegalCopy"
                testId="shopRowPageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(row/reviews)"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="rowReviewsFinancingLegalCopy"
                testId="rowPageLegalCopy"
              />
            )}
          />
        </>
      )}
      {hasFinancingPartner && isTreadPlusAvailable && (
        <>
          <Route
            path="/:path(treads)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey="treadsFinancingLegalCopy"
                testId="treadPageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(treads/specs)"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="treadsSpecsFinancingLegalCopy"
                testId="treadPageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(tread-plus)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey="auroraFinancingLegalCopy"
                testId="treadPageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(tread-plus/reviews)"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="auroraReviewsFinancingLegalCopy"
                testId="treadPageLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(shop/tread-plus/*)"
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="shopTreadPlusFinancingLegalCopy"
                testId="treadPageLegalCopy"
              />
            )}
          />
        </>
      )}
      {hasFinancingPartner && (
        <Route
          path="/:path(guide/*)"
          render={props => (
            // @ts-expect-error
            <ProductValuesLegalSection
              {...props}
              copyKey="rainforestCafeFinancingLegalText"
              testId="rainforestCafePageLegalCopy"
            />
          )}
        />
      )}
      {waysToTryEnabled && hasFinancingPartner && (
        <Route
          path="/:path(try)"
          exact
          render={props => (
            <ProductValuesLegalSection {...props} copyKey="pgWaysToTry.legal" />
          )}
        />
      )}
      {hasFinancingPartner && (
        <>
          <Route
            path="/"
            exact
            render={props => (
              // @ts-expect-error
              <ProductValuesLegalSection
                {...props}
                copyKey="homepageLegalFooter"
                testId="homepageLegalFooter"
              />
            )}
          />
          <Route
            path="/:path(classes)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey="classes.legal"
                testId="classes.legal"
              />
            )}
          />
          <Route
            path="/:path(app)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey="appLegalCopy"
                testId="appLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(membership)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey={
                  isFreeTrialForSixtyDaysEnabled
                    ? 'membershipLegalCopy.60DayPromo'
                    : 'membershipLegalCopy'
                }
                testId="membershipLegalCopy"
              />
            )}
          />
          <Route
            path="/:path(showrooms)"
            exact
            render={props => (
              <ProductValuesLegalSection
                {...props}
                copyKey="showroomsLegalCopy"
                testId="showroomsLegalCopy"
              />
            )}
          />
        </>
      )}
      {hasFinancingPartner && (
        <Route
          path="/:path(benefits/hardware)"
          exact
          render={props => (
            // @ts-expect-error
            <ProductValuesLegalSection
              {...props}
              copyKey="onewellness.membershipHardware.footer.legal"
              testId="benefitsHardwareLegalCopy"
            />
          )}
        />
      )}
      {hasFinancingPartner && (
        <Route
          path="/:path(hardware/promotions)"
          render={props => (
            // @ts-expect-error
            <ProductValuesLegalSection
              {...props}
              copyKey="onewellness.membershipHardware.footer.legal"
              testId="benefitsHardwareLegalCopy"
            />
          )}
        />
      )}
    </>
  );
};
export default ProductLegalSection;
