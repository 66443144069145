import { path } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import type { Effect } from 'redux-saga/effects';
import {
  all,
  select,
  call,
  getContext,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { assertClientContext, CLIENT_CONTEXT } from '@peloton/api';
import { fetchUserExists } from '@peloton/auth';
import { getShowOptOut } from '@ecomm/checkout/redux/selectors';
import { openLoginModal } from '@ecomm/login-modal';
import type { BlurUserFieldAction, EnteredEmailAction } from '../redux/user';
import {
  ActionType,
  userDoesExist,
  userDoesNotExist,
  updateUserField,
} from '../redux/user';

export const checkExistingUserSaga = function* (
  action: EnteredEmailAction | BlurUserFieldAction,
): IterableIterator<Effect> {
  const client = yield getContext(CLIENT_CONTEXT);
  assertClientContext(client);

  let email: string | undefined;
  switch (action.type) {
    case ActionType.UserFieldBlurred:
      email = path(['payload', 'value'], action);
      break;
    case ActionType.EnteredEmail:
    default:
      email = path(['payload', 'email'], action);
  }

  const exists = email ? yield call(fetchUserExists, client, email) : false;

  if (exists) {
    yield all([put(userDoesExist(action.payload.skus)), put(openLoginModal(email))]);
  } else {
    yield put(userDoesNotExist(action.payload.skus));
  }
};

export const filterSaga = function* (action: BlurUserFieldAction) {
  const name = path(['payload', 'name'], action);
  if (name === 'email') {
    yield call(checkExistingUserSaga, action);
  }
};

export const resolveDefaultMarketingState = function* (): SagaIterator {
  const hasSoftOptIn = yield select(getShowOptOut);

  if (hasSoftOptIn) {
    yield put(updateUserField('allowMarketing', true));
  }
};

const watcher = function* (): IterableIterator<Effect> {
  yield takeLatest(ActionType.UserFieldBlurred, filterSaga);
  yield takeEvery(ActionType.ResolveDefaultMarketingState, resolveDefaultMarketingState);
};

export default watcher;
