import { connect } from 'react-redux';
import type { ExtLink } from './models';
import { toHref } from './models';
import type { ExtLinkEnvState } from './redux';
import { getExtLinkEnv } from './redux';

type LinkMap = Record<string | number | symbol, ExtLink>;
type UrlMap<Keys extends string | number | symbol> = Record<Keys, string>;

export const toMapStateToLinkProps = <Links extends LinkMap>(links: Links) => (
  state: ExtLinkEnvState,
): UrlMap<keyof Links> =>
  Object.keys(links).reduce<UrlMap<keyof Links>>(
    // Use of `Object.assign` below is on purpose as of TypeScript 2.8.1
    // "spread and rest are not supported on generics at the moment"
    // https://github.com/Microsoft/TypeScript/issues/12756#issuecomment-265812676v
    (hrefs: UrlMap<keyof Links>, key: string) =>
      Object.assign({}, hrefs, {
        [key]: toHref(links[key], getExtLinkEnv(state)),
      }),
    {} as UrlMap<keyof Links>,
  );

export const withExtHrefs = <OwnProps = {}, Links extends LinkMap = {}>(links: Links) =>
  connect<UrlMap<keyof Links>, {}, OwnProps, ExtLinkEnvState>(
    toMapStateToLinkProps(links),
  );
