import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { Partner } from '@onewellness/models';

export enum ActionTypes {
  PartnerLookupRequested = 'onewellness/partner/PARTNER_LOOKUP_REQUESTED',
  PartnerLookupSucceeded = 'onewellness/partner/PARTNER_LOOKUP_SUCCEEDED',
  PartnerLookupFailed = 'onewellness/partner/PARTNER_LOOKUP_FAILED',
}

export type State = UIState & {
  hashId?: string;
  entity?: Partner;
};

export const defaultState: State = {
  ...toUIState(Status.Init),
};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.PartnerLookupRequested: {
      const hasPartnerHashId = Boolean(action.payload.hashId);

      if (hasPartnerHashId) {
        return {
          ...toUIState(Status.Loading),
          hashId: action.payload.hashId,
        };
      } else {
        return {
          ...toUIState(Status.Loaded),
        };
      }
    }
    case ActionTypes.PartnerLookupSucceeded:
      return {
        ...state,
        ...toUIState(Status.Loaded),
        entity: action.payload.partner,
      };

    case ActionTypes.PartnerLookupFailed: {
      const { exception } = action.payload;
      return {
        ...state,
        ...toUIState(Status.Failed, exception),
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  partner: State;
};

export const requestPartnerLookup = (hashId?: string): PartnerLookupRequestAction => ({
  type: ActionTypes.PartnerLookupRequested,
  payload: {
    hashId,
  },
});

export const partnerLookupSucceeded = (partner: Partner): PartnerLookupSuccessAction => ({
  type: ActionTypes.PartnerLookupSucceeded,
  payload: {
    partner,
  },
});

export const partnerLookupFailed = (
  errorId: string = 'corporateBenefitsPartner.errors.partnerLookup',
): PartnerLookupFailedAction => ({
  type: ActionTypes.PartnerLookupFailed,
  payload: { exception: toException(errorId) },
});

export type PartnerLookupRequestAction = {
  type: ActionTypes.PartnerLookupRequested;
  payload: { hashId?: string };
};
type PartnerLookupSuccessAction = {
  type: ActionTypes.PartnerLookupSucceeded;
  payload: { partner: Partner };
};
type PartnerLookupFailedAction = {
  type: ActionTypes.PartnerLookupFailed;
  payload: { exception: Exception };
};

type Actions =
  | PartnerLookupRequestAction
  | PartnerLookupSuccessAction
  | PartnerLookupFailedAction;
