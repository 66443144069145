import type { ExtLinkEnv, ExtLink, PeloLinkType } from './models';
import {
  toEcommLink,
  toDigitalLink,
  toStudioLink,
  toWWWLink,
  toHref,
  toHomecomingLink,
  toAccountLink,
} from './models';

type ToRedirectUrlArgs = {
  extLinkEnv: ExtLinkEnv;
  path: string;
  type?: string;
};

const toRedirectUrl = ({ extLinkEnv, path, type }: ToRedirectUrlArgs) => {
  const linkFactory = getLinkFactoryForType(type);
  const redirectLink = linkFactory(sanitizePath(path));
  return toHref(redirectLink, extLinkEnv);
};

export default toRedirectUrl;

const getLinkFactoryForType = (type: string | undefined): ExtLinkFactory => {
  if (type && supportedLinkFactories[type]) {
    return supportedLinkFactories[type];
  }

  if (type && process.env.NODE_ENV === 'development') {
    // eslint:disable-next-line:no-console
    console.error(`Unsupported redirect type: ${type}`);
  }

  // We didn't always have a redirectApp param,
  // so default to the digital link for legacy reasons.
  // TODO: ^^ That reasoning sounds specific to query params, so we should
  //          decide the default inside getUrlForRedirectQueryParams.
  // TODO: Once this is pulled into @peloton, have this default to the current app
  return toDigitalLink;
};

const sanitizePath = (path: string) => {
  // Ensure the path starts with a single forward-slash
  // so that we don't end up on a different domain.
  return `/${path.replace(/^\/+/, '')}`;
};

type ExtLinkFactory = (path: string) => ExtLink;

const supportedLinkFactories: Partial<Record<PeloLinkType, ExtLinkFactory>> = {
  www: toWWWLink,
  ecomm: toEcommLink,
  digital: toDigitalLink,
  studio: toStudioLink,
  homecoming: toHomecomingLink,
  account: toAccountLink,
};
