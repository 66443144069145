import React, { useCallback, useEffect, useState } from 'react';
import { useTimeoutFn, useUpdateEffect } from 'react-use';
import { useErrorReporter } from '@peloton/error-reporting';
import type { Experiments } from '@peloton/split-testing/models';
import { addLifecycleListener, getExperiments } from '@peloton/split-testing/optimizely';
import { Context, STALL_TIMEOUT, useOptimizely } from './index';

type Props = {
  optimizelyProjectId?: string;
  pathname: string;
  skipInstall?: boolean;
};

export const SplitTestingProvider: React.FC<React.PropsWithChildren<Props>> = ({
  optimizelyProjectId,
  pathname,
  skipInstall,
  children,
}) => {
  const { errorReporter } = useErrorReporter();
  const [hasStalled, setHasStalled] = useState(false);
  const setStalledAndAlert = () => {
    setHasStalled(true);
    errorReporter.reportError(
      new Error('Experiencing long bucketing wait time for Optimizely experiments'),
    );
  };

  const [, cancel] = useTimeoutFn(setStalledAndAlert, STALL_TIMEOUT);
  const [experiments, setExperiments] = useState<Experiments | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (experiments !== null) {
      // Once experiments are loaded, stop the stall timer (under 10s)
      cancel();
      // Reset stalled state in case it took over 10s to load
      setHasStalled(false);
    }
  }, [experiments, cancel]);

  const refreshExperiments = useCallback(() => {
    setExperiments(getExperiments());
  }, []);

  const onLoad = useCallback(() => {
    setIsLoaded(true);
    refreshExperiments();
    addLifecycleListener('campaignDecided', refreshExperiments);
  }, [refreshExperiments]);

  useUpdateEffect(() => {
    refreshExperiments();
  }, [pathname]);

  useOptimizely(onLoad, optimizelyProjectId, skipInstall);

  return (
    <Context.Provider
      value={{
        experiments,
        refreshExperiments,
        onLoad,
        isLoaded,
        hasStalled,
      }}
    >
      {children}
    </Context.Provider>
  );
};
