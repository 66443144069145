import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@ecomm/router';

const Routes = [
  <Route
    key="id-me-callback"
    path="/idme/callback"
    exact
    component={asyncComponent(
      () => import('./IdMeCallbackPage' /* webpackChunkName:  "IdMeCallbackPage" */),
    )}
  />,
];

export default Routes;
