export enum MarketingConsent {
  NoConsent = 'no consent',
  Explicit = 'explicit',
  Implicit = 'implicit',
}

export enum TrackingEvent {
  ClickedBookmark = 'Clicked Bookmark',
  ClickedCarouselSlide = 'Clicked Carousel Slide',
  ClickedClassesCard = 'Clicked Classes Card',
  ClickedLink = 'Clicked Link',
  ClickedReviewsCarousel = 'Clicked Reviews Carousel',
  ClickedSchedule = 'Clicked Schedule',
  ClickedStack = 'Clicked Stack',
  ClickedSubnavATC = 'Clicked Buy CTA in subnav',
  ClickedSubnavMenu = 'Clicked Subnav Menu',
  ClickedNavigation = 'Clicked Navigation',
  MarketingOptInChecked = 'Checked marketing consent box',
  SendEmailResultsSender = 'Send Email Results Sender',
  SendEmailResultsRecipient = 'Send Email Results Recipient',
  SubmittedEmailForMarketing = 'Submitted Email for Marketing',
  SubmittedEmailForWTG = 'Submitted Email for WTG',
  ClickedPrequalifyCTA = 'Clicked Prequalify CTA',
  ClickedAccordion = 'Clicked Accordion',
  ModalOpened = 'Modal Opened',
  ModalClosed = 'Modal Closed',
  EstimateOTD = 'Estimate OTD',
  ProductViewed = 'Product Viewed',
  ClickedTile = 'Clicked Tile',
  ClickedProductComparisonCardToggle = 'Clicked Product Comparison Card Toggle',
  CheckoutQrCodeScanned = 'Checkout QR Code Scanned',
  FeaturedQrCodeScanned = 'Featured QR Code Scanned',
  VideoContentStarted = 'Video Content Started',
  VideoContentPlayed = 'Video Content Played',
  VideoContentPaused = 'Video Content Paused',
  VideoContentCompleted = 'Video Content Completed',
}

export enum AdaTrackingEvent {
  ClickedOnChat = 'Clicked On Chat',
  ChatBotFired = 'Chat Bot Fired',
  ChatConversationStarted = 'Chat Conversation Started',
  ChatMessageSent = 'Chat Message Sent',
  ChatDismissed = 'Chat Dismissed',
  ScriptFailedToLoad = 'Ada Script Failed to Load',
  ChatFailedToLoad = 'Ada Chat Failed to Load',
}
