export type Exception = {
  id: string;
};

export enum ExceptionID {
  Default = 'errors.default',
}

export const toException = (id: string = ExceptionID.Default): Exception => ({
  id,
});
