import { combineReducers } from 'redux';
import type { ReducerState as AppNameState } from './appName';
import appName from './appName';
import type { ReducerState as FlagState } from './flags';
import flags from './flags';
import type { ReducerState as LocaleState } from './locale';
import locale from './locale';

export type State = AppNameState & FlagState & LocaleState;

export default combineReducers({
  appName,
  flags,
  locale,
});

export type ReducerState = {
  env: State;
};
