import { track } from '@peloton/analytics';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';

type TrackingProps = {
  subscriptionType: string;
  subscriptionId: string;
};

export const trackUserSubscriptionInfo = (trackingProps: TrackingProps) =>
  track({
    event: 'LoggedIn Visit',
    properties: { page: getCurrentRelativePathname(), ...trackingProps },
  });
