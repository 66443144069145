import type { ClientError } from '@peloton/api';
import { isHttpError } from '@peloton/api';

export enum ErrorCode {
  BenefitHubError = 'one-wellness-error',
  ClaimedBenefitHubToken = 'claimed-access-token',
  EnrollmentNotCovered = 'enrollment-not-covered',
  ExpiredBenefitHubToken = 'access-token-expired',
  InvalidBenefitHubToken = 'invalid-access-token',
  ActiveSharedSubscription = 'active-shared-subscription',
  ActiveMultipleSubscriptions = 'active-multiple-subscriptions',
  ActiveConcessionarySubscription = 'active-concessionary-subscriptions',
  ExistingDigitalSub = 'existingSub-digital',
  InvalidPostalCode = 'invalidPostalCode',
  InvalidSecurityCode = 'invalidSecurityCode',
  CreditCardExpired = 'creditCardExpired',
  ProductUnavailable = 'productUnavailable',
  PromotionExpired = 'promotionExpired',
  ReferrerIsReferree = 'referrerIsReferree',
  TransactionDeclined = 'transactionDeclined',
  TransactionNotProcessed = 'transactionNotProcessed',
  UserIneligibleForOPC = 'userIneligibleForOPC',
  InvalidProrationAmount = 'invalidProrationAmount',
  InventoryQuantityUnavailable = 'inventoryQuantityUnavailable',
  Default = 'default',
}

const apiErrorMap = {
  1300: ErrorCode.BenefitHubError,
  1301: ErrorCode.InvalidBenefitHubToken,
  1302: ErrorCode.ExpiredBenefitHubToken,
  1303: ErrorCode.ClaimedBenefitHubToken,
  1304: ErrorCode.EnrollmentNotCovered,
  1310: ErrorCode.ActiveSharedSubscription,
  1311: ErrorCode.ActiveMultipleSubscriptions,
  1312: ErrorCode.ActiveConcessionarySubscription,
  4330: ErrorCode.InvalidPostalCode,
  4450: ErrorCode.InvalidSecurityCode,
  4480: ErrorCode.CreditCardExpired,
  4490: ErrorCode.TransactionDeclined,
  4510: ErrorCode.TransactionNotProcessed,
  4700: ErrorCode.ProductUnavailable,
  4703: ErrorCode.InventoryQuantityUnavailable,
  4933: ErrorCode.PromotionExpired,
  4934: ErrorCode.ReferrerIsReferree,
  5613: ErrorCode.ExistingDigitalSub,
  6004: ErrorCode.UserIneligibleForOPC,
  6005: ErrorCode.InvalidProrationAmount,
};

export const toErrorCode = (error: ClientError): ErrorCode => {
  const apiErrorCode = isHttpError(error) ? error.responseBody.errorCode : undefined;

  return apiErrorMap[apiErrorCode] || ErrorCode.Default;
};
