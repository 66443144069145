import { schema } from 'normalizr';
import type { ApiCouponSavings } from '@ecomm/cart/api/types';

const processCouponSavings = ({
  couponCode: id,
  totalSavingsInCents: amount,
}: ApiCouponSavings) => ({
  id,
  amount,
});

const couponSavings = new schema.Entity(
  'couponSavings',
  {},
  { processStrategy: processCouponSavings, idAttribute: 'couponCode' },
);

export default couponSavings;
