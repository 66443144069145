export const KEY_REGEX = /🔑(?<parentKey>.*?)📓(?<parentType>.*?)🚏(?<field>.*?)🛑🤰?/gu;

export type EncodedKeyParts = {
  parentPath?: string;
  parentType: string;
  parentKey: string;
  field: string;
};

const toEncodedKey = ({ parentPath, parentType, parentKey, field }: EncodedKeyParts) => {
  const contentKey = parentPath === '' ? '' : `${parentPath}🤰`;

  return `${contentKey}🔑${parentKey}📓${parentType}🚏${field}🛑`;
};

export default toEncodedKey;
