import type { Locale } from '@peloton/internationalize';
import { Status } from './models';

export enum ActionType {
  Init = 'ecomm/chat/INITIALIZE_CHAT',
  Loaded = 'ecomm/chat/LOADED_CHAT',
  Start = 'ecomm/chat/START_CHAT',
}

type State = { status: Status.Init } | { status: Status.Loaded };

export const initialState: State = { status: Status.Init };

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.Loaded:
      return { status: Status.Loaded };

    default:
      return state;
  }
};

export type ReducerState = {
  chat: State;
};

export default reducer;

export const initChat = (driftKey: string, locale: Locale): InitAction => ({
  type: ActionType.Init,
  payload: { driftKey, locale },
});

export const chatLoaded = (): LoadedAction => ({
  type: ActionType.Loaded,
});

export const startChat = (id: number): StartAction => ({
  type: ActionType.Start,
  payload: { id },
});

export type InitAction = {
  type: ActionType.Init;
  payload: { driftKey: string; locale: Locale };
};

type LoadedAction = {
  type: ActionType.Loaded;
};

export type StartAction = {
  type: ActionType.Start;
  payload: {
    id: number;
  };
};

type Action = InitAction | LoadedAction | StartAction;
