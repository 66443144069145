import { useHasVariation } from '@peloton/split-testing/hooks';
import { toTimeFromDate } from '@peloton/time';

export const CONVERSATIONAL_COMMERCE_EXPERIMENT = 'Conversational Commerce ABC';
export const ORIGINAL = 'Original';
export const SINGLE_PROMPT_VARIATION = 'Variation #1';
export const ROTATING_PROMPTS_VARIATION = 'Variation #2';

const INSIDE_SALES_TIMEZONE = 'America/New_York';
const INSIDE_SALES_START_TIME = 9;
const INSIDE_SALES_END_TIME = 22;

export const useIsWithinInsideSalesWorkingHours = () => {
  const now = toTimeFromDate(new Date());
  now.tz(INSIDE_SALES_TIMEZONE);

  const insideSalesStartTime = now.clone().hour(INSIDE_SALES_START_TIME).minutes(0);
  const insideSalesEndTime = now.clone().hour(INSIDE_SALES_END_TIME).minutes(0);

  return now.isBetween(insideSalesStartTime, insideSalesEndTime, undefined, '[]');
};

export const useIsOriginalActive = () => {
  const isWithinInsideSalesWorkingHours = useIsWithinInsideSalesWorkingHours();

  const variationValue = useHasVariation(CONVERSATIONAL_COMMERCE_EXPERIMENT, ORIGINAL);
  // We depend on the return value of this hook being specifically null and not another falsy value when
  // injecting the chat scripts. If this returns a falsy value while bucketing is happening, the wrong script will be injected
  if (variationValue === null) {
    return null;
  }

  return variationValue || isWithinInsideSalesWorkingHours;
};

export const useIsSinglePromptActive = () => {
  const isWithinInsideSalesWorkingHours = useIsWithinInsideSalesWorkingHours();

  return (
    useHasVariation(CONVERSATIONAL_COMMERCE_EXPERIMENT, SINGLE_PROMPT_VARIATION) &&
    !isWithinInsideSalesWorkingHours
  );
};

export const useIsRotatingPromptsActive = () => {
  const isWithinInsideSalesWorkingHours = useIsWithinInsideSalesWorkingHours();

  return (
    useHasVariation(CONVERSATIONAL_COMMERCE_EXPERIMENT, ROTATING_PROMPTS_VARIATION) &&
    !isWithinInsideSalesWorkingHours
  );
};
