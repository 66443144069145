import { toWWWLink } from '@peloton/external-links';
import * as paths from './wwwPrivateTreadPaths';

export const treads = toWWWLink(paths.treads);
export const treadPlus = toWWWLink(paths.treadPlus);
export const treadPlusReviews = toWWWLink(paths.treadPlusReviews);
export const treadPlusReviewsSubmit = toWWWLink(paths.treadPlusReviewsSubmit);
export const treadsSpecs = toWWWLink(paths.treadsSpecs);
export const treadsClasses = toWWWLink(paths.treadsClasses);
export const treadSignUp = toWWWLink(paths.treadSignUp);
export const treadPlusFinancing = toWWWLink(paths.treadPlusFinancing);
export const bikesSignUp = toWWWLink(paths.bikesSignUp);
export const treadPlusSignUp = toWWWLink(paths.treadPlusSignUp);
