import { toWWWLink } from '@peloton/external-links';
import * as paths from './wwwPrivatePaths';

/**
 * This links package exports all private links
 * for the www app
 */
export const bikesTradeIn = toWWWLink(paths.bikesTradeIn);
export const bikesCompare = toWWWLink(paths.bikesCompare);
export const bikePlus = toWWWLink(paths.bikePlus);
export const bikes = toWWWLink(paths.bikes);
export const homeTrial = toWWWLink(paths.homeTrial);
export const bikesClasses = toWWWLink(paths.bikesClasses);
export const bikesFinancing = toWWWLink(paths.bikesFinancing);
export const tradeInTerms = toWWWLink(paths.tradeInTerms);
