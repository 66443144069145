import { red } from '@pelotoncycle/design-system';
import React from 'react';
import styled, { keyframes } from 'styled-components';

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  area?: number;
  fill?: string;
  label?: string;
  testId?: string;
};

const Spinner: React.FC<React.PropsWithChildren<Props>> = ({
  area = 20,
  fill,
  label,
  style,
  testId = 'spinner',
  ...attrs
}) => (
  <Container
    aria-label={label || 'loading'}
    role="status"
    data-test-id={testId}
    style={{ ...style, height: area, width: area }}
    {...attrs}
  >
    <SVGSpinner role="presentation" viewBox="0 0 32 32" fill={fill}>
      <path
        opacity={0.5}
        d="M16 31C7.7 31 1 24.3 1 16S7.7 1 16 1s15 6.7 15 15-6.7 15-15 15zm0-26C9.9 5 5 9.9 5 16s4.9 11 11 11 11-4.9 11-11S22.1 5 16 5z"
      />
      <path d="M26.2 11.8c1.5-.2 2.7-1 3.3-2.3C28.4 7 26.3 4.8 24 3.3c-1.3.5-2.3 1.7-2.6 3.1 2.2 1.2 3.9 3.2 4.8 5.4z" />
    </SVGSpinner>
  </Container>
);

export default Spinner;

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const spin = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;

const SVGSpinner = styled.svg<{ fill?: string }>`
  animation: ${spin} 0.7s infinite linear;
  fill: ${props => props.fill || red[50]};
`;
