import React from 'react';
import ReactMarkdown from 'react-markdown';
import type { ReactMarkdownProps } from 'react-markdown';
import type { CopyValues, CopyHook } from '@peloton/copy-generic';
import { addLocaleToMarkdownRelativeUrl } from '@peloton/internationalize/addCurrentLocaleToUrl';
import toFormattedText from './toFormattedText';

declare global {
  interface Window {
    Cypress: any;
  }
}

type FormattedMarkdownProps = Pick<
  ReactMarkdownProps,
  Exclude<keyof ReactMarkdownProps, 'source' | 'renderers'>
> & {
  renderers?: Record<string, React.ElementType>;
};

export type MarkdownProps = {
  markdown?: FormattedMarkdownProps;
};

export type Props = MarkdownProps & {
  content: string;
  values?: Object;
  dataTestId?: string;
  overwriteParagraph?: boolean;
  updateForSubpathLocale?: boolean;
};

export type MarkdownComponent<ContentType extends string> = React.FC<
  React.PropsWithChildren<
    {
      id: ContentType;
      values?: CopyValues;
    } & MarkdownProps
  >
>;

export const toMarkdownComponent = <ContentType extends string>(
  hook: CopyHook<ContentType>,
  /* eslint-disable react/display-name */
): MarkdownComponent<ContentType> => ({ id, values, ...props }) => (
  <Markdown content={hook(id, values)} {...props} />
);

type MarkdownChildrenSchema = {
  props: {
    children: string;
  };
};

export type MarkdownChildrenType = {
  children: string | MarkdownChildrenSchema[];
};

export const toMarkdownChildrenString = (children: MarkdownChildrenType) => {
  let retValue: string = '';
  if (typeof children === 'string') {
    retValue = children;
  } else if (Array.isArray(children)) {
    retValue = (children as MarkdownChildrenSchema[])[0]?.props.children;
  }

  return retValue;
};

const toReactMarkdownRenderer = (Renderer: React.ElementType) => {
  const extraProps =
    typeof window !== 'undefined' && window?.Cypress ? { 'data-md': true } : {};

  // eslint-disable-next-line react/display-name
  return ({ node, ...props }: any) => <Renderer {...props} {...extraProps} />;
};

const Markdown: React.FC<React.PropsWithChildren<Props>> = ({
  content,
  values,
  dataTestId,
  overwriteParagraph,
  markdown: { renderers = {}, ...rest } = {},
  updateForSubpathLocale = false,
}) => {
  const localeContent = updateForSubpathLocale
    ? addLocaleToMarkdownRelativeUrl(content)
    : content;
  return (
    <ReactMarkdown
      {...({
        ...rest,
        source: toFormattedText(localeContent, values).toString(),
        escapeHtml: false,
        renderers: {
          ...(overwriteParagraph
            ? {}
            : {
                paragraph: ({ node, children, ...props }) => (
                  <span data-md="true" {...props}>
                    {children}
                  </span>
                ),
              }),

          ...Object.entries(renderers)
            .map(([key, value]) => ({
              [key]: toReactMarkdownRenderer(value),
            }))
            .reduce<{ [nodeType: string]: React.ElementType }>(
              (acc, cur) => ({ ...acc, ...cur }),
              {},
            ),
        },
      } as ReactMarkdownProps)}
      date-test-id={dataTestId}
    />
  );
};

export default Markdown;
