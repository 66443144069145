export enum RoutePaths {
  CheckoutPage = '/checkout',
}

export enum ReplacementPart {
  SeatPost = 'seat_post',
  TreadBracket = 'tread_bracket',
  RearGuard = 'tread_plus_rear_guard',
}

//Cookie keys
export const CREDIT_CHECK_COOKIE_KEY = 'peloton_credit_check_passed';
