import { ApiEnvs } from '@peloton/external-links/models';
import type { EnvironmentFlags } from './models';
import parseHostname from './parseHostname';

const toApiEnv = (
  hostname: string,
  {
    isProd,
    isUatProd,
    isProdTest,
  }: Pick<EnvironmentFlags, 'isProd' | 'isUatProd' | 'isProdTest'>,
): ApiEnvs => {
  const { api } = parseHostname(hostname);
  if (api) {
    if (api === 'local') {
      return ApiEnvs.Local;
    } else if (api === 'qa1') {
      return ApiEnvs.Qa1;
    } else if (api === 'qa2') {
      return ApiEnvs.Qa2;
    } else if (api === 'api') {
      return ApiEnvs.Prod;
    }
    return toKubernetesApi(api);
  }
  if (isProd || isUatProd || isProdTest) {
    return ApiEnvs.Prod;
  }
  if (hostname.indexOf('qa2-www') === 0) {
    return ApiEnvs.Qa2;
  }

  const REACT_APP_API_ENV = process.env.REACT_APP_API_ENV;

  return (REACT_APP_API_ENV && ApiEnvs[REACT_APP_API_ENV]) || ApiEnvs.Qa1;
};

export default toApiEnv;

export const toKubernetesApi = (api: string): ApiEnvs => {
  const [namespace, cluster = 'dev'] = api.replace('k8s--', '').split('--');
  if (cluster === 'test') {
    return `api.${namespace}.${cluster}.k8s` as ApiEnvs;
  }
  return `api-${namespace}.${cluster}.k8s` as ApiEnvs;
};
