import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import { toLocaleFromHostname } from '@peloton/internationalize';
import { extractAggregateFields } from './lib/extractAggregateFields';
import { parseContent } from './lib/parseContent';
import { getClient } from './peloContentClient';
import { globalEntriesConfig } from './www/aggregates/aggregateMap';

const identity = <T = {}>(data: T) => data;

/**
 * Exclusively used to fetch content for global app components that have NOT
 * been rewritten to be compatible in both next--js
 *
 * If your component is next-compatible, use globalEntriesFetcher instead
 *
 * This fetcher should be deprecated once all pages are moved to Next.
 */
export const toFetcher = (preview: boolean = ALLOW_PREVIEW_COPY) => async (
  entryId: string,
) => {
  const client = getClient(preview);
  const locale = toLocaleFromHostname();
  const config = globalEntriesConfig[entryId];

  if (!config) {
    throw new Error('No generated file assigned to this entry ID');
  }

  const contentType = config.contentType;
  const mapToView = config.mapToView ?? identity;
  const customExtractor = config.customExtractor || extractAggregateFields;

  try {
    if (preview) {
      const data = await client.getEntry(entryId, {
        include: 10,
        locale,
      });

      return mapToView(customExtractor(data));
    }

    /*
      The generated content aggregates need to be within a subdirectory of the static directory
      so that this dynamic import does not also include all the generated PB page files as well
    */
    const content = await import(
      `./static/__content-aggregates__/${locale}/${contentType}.generated.json`
    );
    return parseContent(content);
  } catch (err) {
    throw new Error(`Locale: ${locale} - Content Type: ${contentType} - Error: ${err}`);
  }
};

const craGlobalEntriesFetcher = toFetcher();

export default craGlobalEntriesFetcher;
