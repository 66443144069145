import { ApiEnvs } from '@peloton/external-links/models';

export enum OauthEnvironment {
  Dev = 'DEVELOPMENT',
  Stage = 'STAGE',
  Test = 'TEST',
  Prod = 'PRODUCTION',
  Unknown = 'UNKNOWN',
}

export const apiEnvToOauthEnv = {
  [ApiEnvs.Prod]: OauthEnvironment.Prod,
  [ApiEnvs.Qa2]: OauthEnvironment.Test,
  [ApiEnvs.Qa1]: OauthEnvironment.Stage,
  'api.api-core.dev.k8s': OauthEnvironment.Dev,
};
