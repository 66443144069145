import { transparentize } from 'polished';

// Solids
export const black = '#000000';
export const offBlack = '#181a1d';
export const slateBlack = '#23252a';
export const white = '#ffffff';
export const offWhite = '#f5f7f9';
export const snowWhite = '#f9fafb';
export const borderGray = '#e4e6e7';
export const iconGray = '#a8acb1';
export const mediumGray = '#65666a';
export const mediumGrayOnDark = '#888b93';
export const slate = '#697180';
export const superRed = '#df1c2f';
export const superRedDark = '#d00c2a';
export const yellow = '#ffe28c';
export const darkBlue = '#406599';
export const blueBlack = '#222529';
export const green = '#08820B';
export const highlightBlue = '#5E9ED6';
export const highlightLightBlue = '#B8DDF5';
export const paleBlue = '#e4e6e7';

// Semi-transparent
export const disabledGray = transparentize(0.4, mediumGray);
export const disabledRed = transparentize(0.2, superRed);
export const dividerGray = transparentize(0.7, mediumGray);
export const transparentBlack = transparentize(0.2, offBlack);
export const dropShadowGray = transparentize(0.9, offBlack);
