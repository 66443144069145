import { track } from '@peloton/analytics';
import { toCountry } from '@peloton/internationalize';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';

export const trackMarketingEmailSubmission = (email: string) =>
  track({
    event: 'Submitted Email for Marketing',
    properties: {
      pageName: getCurrentRelativePathname(),
      propertyType: 'Web',
      campaignName: 'Add to Cart Digital',
      campaignId: '7011C000001INSkQAO',
      unitName: 'Digital Checkout Started',
      unitPlacement: 'in-line',
      productInterest: 'digital',
      productItemInterest: 'digital',
      marketingConsent: 'explicit',
      country: toCountry(),
      email,
    },
  });
