export interface ChallengePageContent {
  totalTeammates: string;
  totalTeammatePlural: string;
  joinSoonSubtitle: string;
  ctaCopyLine1: string;
  ctaCopyLine2: string;
  ctaCopyLine3: string;
  ctaButton: string;
  disclaimer: string;
  title: string;
  subtitle: string;
  challengeDuration: string;
}
const mapToView = (content: any): ChallengePageContent => {
  return {
    totalTeammates: content['ChallengePage']['totalTeammates']['value'],
    totalTeammatePlural: content['ChallengePage']['totalTeammatePlural']['value'],
    joinSoonSubtitle: content['ChallengePage']['joinSoonSubtitle']['value'],
    ctaCopyLine1: content['ChallengePage']['ctaCopyLine1']['value'],
    ctaCopyLine2: content['ChallengePage']['ctaCopyLine2']['value'],
    ctaCopyLine3: content['ChallengePage']['ctaCopyLine3']['value'],
    ctaButton: content['ChallengePage']['ctaButton']['value'],
    disclaimer: content['ChallengePage']['disclaimer']['value'],
    title: content['ChallengePage']['title']['value'],
    subtitle: content['ChallengePage']['subtitle']['value'],
    challengeDuration: content['ChallengePage']['challengeDuration']['value'],
  };
};

export const CHALLENGE_PAGE_ENTRY_ID = '4yb5P9AFxDccYxOhguL9sn';

export default {
  entryId: CHALLENGE_PAGE_ENTRY_ID,
  contentType: 'contentAggregate',
  mapToView,
};
