import { isNil } from 'ramda';
import whatInput from 'what-input';

const DATA_KEY = 'data-focus-method';

export const reportFocusMethod = (
  element: Pick<HTMLElement, 'addEventListener'>,
): void => {
  element.addEventListener(
    'focusin',
    (event: FocusEvent) => {
      if (canSetAttribute(event.target)) {
        event.target.setAttribute(DATA_KEY, whatInput.ask());
      }
    },
    true,
  );

  element.addEventListener(
    'focusout',
    (event: FocusEvent) => {
      if (canSetAttribute(event.target)) {
        event.target.removeAttribute(DATA_KEY);
      }
    },
    true,
  );
};

const canSetAttribute = (target: EventTarget | null): target is HTMLElement =>
  !isNil(target) && typeof (<HTMLElement>target).setAttribute === 'function';
