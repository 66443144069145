import { isNil } from 'ramda';
import { createSelector } from 'reselect';
import {
  getIsUserLoading,
  getUser,
  getUserError,
  getUserErrorMessage,
  isSignedIn,
} from '@peloton/auth';
import type { UserTrackingProperties as TUserTrackingProperties } from '@peloton/auth/models/user';
import { toException } from '@ecomm/exceptions';
import { Status, toUIState } from '@ecomm/models';
import type { User } from './models';

export type UserTrackingProperties = TUserTrackingProperties;

export const getUserTransactionState = createSelector(
  getUser,
  getIsUserLoading,
  getUserError,
  getUserErrorMessage,
  (user, isLoading, isError, errorMsg) => {
    switch (true) {
      case isError:
        return toUIState(Status.Failed, toException(errorMsg || 'default'));
      case !isNil(user) && !isLoading:
        return toUIState(Status.Loaded);

      case isLoading:
        return toUIState(Status.Loading);

      default:
        return toUIState(Status.Init);
    }
  },
);

export const getUserTrackingProperties = createSelector(
  [getUser],
  (user: User): UserTrackingProperties =>
    isSignedIn(user)
      ? {
          isLoggedIn: true,
          hasDeviceSubscription: user?.hasDeviceSubscription || false,
          hasDigitalSubscription: user?.hasDigitalSubscription || false,
          email: user?.email,
          hashedEmail: user?.hashedEmail,
          userId: user?.id,
        }
      : {
          isLoggedIn: false,
          hasDeviceSubscription: user?.hasDeviceSubscription || false,
          hasDigitalSubscription: user?.hasDigitalSubscription || false,
        },
);
