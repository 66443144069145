const mapToView = (data: any) => {
  const content = data['appMembershipPage'];
  const planComparisonTable = content['appMembershipPage.planComparisonTable'] || {};
  return {
    appMembershipTableCategories: extractValues(
      planComparisonTable['appMembershipPage.planComparisonTable.categories'] || {},
    ),
    appMembershipTableProducts: extractValues(
      planComparisonTable['appMembershipPage.planComparisonTable.products'] || {},
    ),
    monthlyBillingCadence: content['membershipMonthlyBillingCadence']['value'],
    yearlyBillingCadence: content['membershipYearlyBillingCadence']['value'],
  };
};

export const mapVariationToView = (data: any) => {
  const content = data['appMembershipPage.variation'];
  const planComparisonTable =
    content['appMembershipPage.planComparisonTable.Variation'] || {};
  return {
    appMembershipTableCategories: extractValues(
      planComparisonTable[
        'appMembershipPage.planComparisonTable.categories (Variation)'
      ] || {},
    ),
    appMembershipTableProducts: extractValues(
      planComparisonTable['appMembershipPage.planComparisonTable.products (Variation)'] ||
        {},
    ),
    monthlyBillingCadence: content['membershipMonthlyBillingCadence']['value'],
    yearlyBillingCadence: content['membershipYearlyBillingCadence']['value'],
  };
};

export default mapToView;

const extractValues = (data: object) =>
  Object.values(data).map((item: any) => item.value);
