import { brand, white } from '@pelotoncycle/design-system';
import styled, { css } from 'styled-components';
import type { Breakpoint } from '@peloton/responsive';
import { media } from '@peloton/styles';
import { ButtonThemes, SmallSize, TabletStandardSize } from '@ecomm/buttons';
import type { BreakpointOptions } from '@ecomm/image';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { b1, b2 } from '@ecomm/typography';

export const MOBILE_IMAGE_HEIGHT = 800;
const TABLET_IMAGE_HEIGHT = 800;
const DESKTOP_IMAGE_WIDTH = 1024;
const DESKTOP_LARGE_IMAGE_WIDTH = 1280;

export const mediaAgnosticHeroBreakpointDimensions = {
  mobile: { height: MOBILE_IMAGE_HEIGHT },
  tablet: { height: TABLET_IMAGE_HEIGHT },
  desktop: { width: DESKTOP_IMAGE_WIDTH },
  desktopLarge: { width: DESKTOP_LARGE_IMAGE_WIDTH },
};

type BreakpointDimensions = {
  [breakpoint in Breakpoint]?: {
    height?: number;
    width?: number;
  };
};

type Args = {
  breakpointOptions: BreakpointOptions;
  requiredDimensions: BreakpointDimensions;
};

export const buildMediaAgnosticHeroBreakpointDimensions = ({
  breakpointOptions,
  requiredDimensions,
}: Args): BreakpointOptions => {
  const breakpointOptionsCopy = {};
  Object.entries(breakpointOptions).forEach(([breakpointName, breakpointConfig]) => {
    const requiredDimensionsForBreakpoint = requiredDimensions[breakpointName];

    const { height, width, ...rest } = breakpointConfig;

    breakpointOptionsCopy[breakpointName] = {
      ...rest,
      ...(requiredDimensionsForBreakpoint || { height, width }),
    };
  });

  return breakpointOptionsCopy as BreakpointOptions;
};

export const ContainerStyles = css`
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 480px;

  ${media.tablet`
    height: 550px;
  `}

  ${media.desktop`
    height: 760px;
  `}
`;

const WrapperStyles = css`
  color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 2rem 1rem;

  h1,
  h2,
  h6,
  p {
    max-width: 480px;
  }

  ${media.iPhone6`
    padding: 2rem 1.5rem;
  `}

  ${media.tablet`
    padding: 2rem 3.125rem;
    h1 {
      line-height: 1.25em;
    }
  `}

  ${media.tabletXLarge`
    text-align: left;
    align-items: flex-start;
    h1, h2 {
      max-width: 680px;
    }

    h6, p {
      max-width: 520px;
    }
  `}

  ${media.desktop`
    padding: 2rem 8.75rem;
  `}
`;

export const ContentWrapper = styled.div`
  ${WrapperStyles}
`;

export const ContentWrapperWithGradient = styled.div`
  ${WrapperStyles};
  background-color: rgba(0, 0, 0, 0.5);
  mix-blend-mode: normal;

  ${media.desktop`
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, transparent 85%);
  `}

  /* a style hack that enables us to style superscripts in contentful e.g. <sup>†</sup> */
  sup {
    font-size: 0.75rem;
    vertical-align: super;
    line-height: 0;
  }
`;

type SubheadProps = {
  increaseMaxWidth?: boolean;
};

export const Subhead = styled.p<SubheadProps>`
  position: relative;
  ${b2};
  max-width: 284px;

  ${media.tablet`
    max-width: 400px;
  `}

  ${media.desktop<SubheadProps>`
    ${b1}
    max-width: ${props => `${props.increaseMaxWidth ? 530 : 510}px`};
  `}

  span:first-of-type:not([data-test-id="promo-hero-price"]) {
    &:before {
      content: '';
      display: block;
    }

    ${media.tabletXLarge`
      &:before {
        display: none;
      }
    `}
    color: ${brand.primary};
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 1rem;
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${media.tabletLarge`
    flex-direction: row;
  `}

  ${media.desktop`
    margin: 2.5rem 0 1rem;
  `}
`;

export const SupportTextWrapper = styled.div`
  a {
    text-decoration: underline;
  }
`;

export const HeroCta = (styled(Link)`
  ${props => (props.theme.button ? props.theme.button : ButtonThemes.Button1)}
  ${SmallSize}
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
  min-width: 232px;

  &:nth-child(2) {
    ${ButtonThemes.Button5};
  }

  ${media.tabletLarge`
    ${TabletStandardSize};
    margin-right: 1.5rem;
    min-width: 232px;
  `}
` as any) as React.ComponentType<React.PropsWithChildren<LinkProps>>;
