import { always } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { fetchDigitalSubs } from './api';
import type { NormalizedSubscriptions } from './models';
import { PlanType } from './models';
import { addPlans } from './redux';

const defaultHook = always({ type: '' });

export const fetcherSaga = function* (
  subscriptionType: PlanType,
  postalCode?: string,
  hooks?: Hooks,
): SagaIterator {
  const { onError = defaultHook, onSuccess = defaultHook } = hooks || {};
  try {
    const client = yield getContext(CLIENT_CONTEXT);
    if (subscriptionType === PlanType.Digital) {
      const {
        entities: { plans },
      }: NormalizedSubscriptions = yield call(fetchDigitalSubs, client, postalCode);
      yield all([put(addPlans(plans)), put(onSuccess())]);
    }
  } catch (e) {
    const error = new DomainError('Error fetching digital subs', e);
    yield all([put(reportError({ error })), put(onError())]);
  }
};

type ActionLike = {
  type: string;
};

type Hooks = {
  onError(): ActionLike;
  onSuccess(): ActionLike;
};
