import { curry, lensProp, set } from 'ramda';
import type { DigitalErrors } from '../models';
import { Severity } from '../models';

export enum ActionTypes {
  PaymentInfoEntered = 'ecomm/quick-checkout/digital/PAYMENT_INFO_ENTERED',
  CheckoutErrored = 'ecomm/quick-checkout/digital/CHECKOUT_ERRORED',
  ErrorResolved = 'ecomm/quick-checkout/digital/ERROR_RESOLVED',
}

type State = Record<Severity, DigitalErrors | ''>;

export const defaultState: State = {
  [Severity.Error]: '',
  [Severity.Warning]: '',
};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.CheckoutErrored: {
      const { error, severity } = action.payload;
      return set(lensProp(severity), error, state);
    }

    case ActionTypes.ErrorResolved: {
      const { severity } = action.payload;
      return set(lensProp(severity), '', state);
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  digital: State;
};

export const errorCheckout = curry(
  (severity: Severity, error: DigitalErrors): CheckoutErroredAction => ({
    type: ActionTypes.CheckoutErrored,
    payload: { error, severity },
  }),
);

export const resolveError = (severity: Severity): ErrorResolvedAction => ({
  type: ActionTypes.ErrorResolved,
  payload: { severity },
});

export const paymentInfoEntered = (): PaymentInfoEnteredAction => ({
  type: ActionTypes.PaymentInfoEntered,
});

type CheckoutErroredAction = {
  type: ActionTypes.CheckoutErrored;
  payload: { error: DigitalErrors; severity: Severity };
};

type ErrorResolvedAction = {
  type: ActionTypes.ErrorResolved;
  payload: { severity: Severity };
};

type PaymentInfoEnteredAction = {
  type: ActionTypes.PaymentInfoEntered;
};

type Actions = CheckoutErroredAction | ErrorResolvedAction;
