import type { ThemedCssFunction } from 'styled-components';
import { css } from 'styled-components';

export const BreakpointWidth = {
  iPhone6: 375,
  smallScreen: 380,
  tablet: 580,
  tabletLarge: 640,
  tabletXLarge: 768,
  desktop: 800,
  desktopLarge: 1024,
  desktopXLarge: 1280,
  infinity: 1920,
} as const;

export type BreakpointValues = typeof BreakpointWidth[keyof typeof BreakpointWidth];
export type BreakpointKeys = keyof typeof BreakpointWidth;

type BreakpointCssMap = Record<keyof typeof BreakpointWidth, ThemedCssFunction<any>>;

export const media: BreakpointCssMap = Object.keys(BreakpointWidth).reduce(
  (mediaQueries, label) => ({
    ...mediaQueries,
    [label]: (...args: any[]) => css`
      @media (min-width: ${BreakpointWidth[label]}px) {
        ${css.call(this, ...args)}
      }
    `,
  }),
  {} as BreakpointCssMap,
);

export const mobileLandscape = (...args: any[]) => css`
  @media only screen and (min-width: 320px) and (max-width: 824px) and (orientation: landscape) {
    ${css.call(this, ...args)}
  }
`;

export const mobilePortrait = (...args: any[]) => css`
  @media only screen and (max-width: 580px) {
    ${css.call(this, ...args)}
  }
`;
