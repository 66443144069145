import { toSocialLink, toSupportLink } from '@peloton/external-links';

export const recommendedBikeHeadphones = toSupportLink(
  '/articles/202703459-Recommended-headphones-to-use-with-the-Peloton-bike',
);
export const recommendedTreadHeadphones = toSupportLink('/articles/360016697011');
export const requests = toSupportLink('/requests/new');
export const returns = toSupportLink('/articles/201108256-Peloton-s-Return-Policy');
export const shipping = toSupportLink(
  '/articles/203078315-What-to-expect-from-the-in-home-delivery-and-set-up-of-your-Peloton-bike',
);
export const londonStudio = toSocialLink('https://studio.onepeloton.com/london-cycle');
export const support = toSupportLink('/');
export const bikeWarranty = toSupportLink('/articles/203020505');
export const extendedBikeWarranty = toSupportLink(
  '/articles/115003244106-Peloton-Bike-Extended-Warranty-Coverage',
);
export const warranties = toSupportLink('/articles/4404036415764-Peloton-Warranties');
export const treadWarranty = toSupportLink('/articles/360014278112');
export const recommendedHeadphones = toSupportLink(
  '/articles/202703459-Recommended-headphones-to-use-with-the-Peloton-bike',
);
export const ukFinancingDisclosure = toSupportLink('/articles/360018778212/');
export const ukFinancingComplaints = toSupportLink('/articles/360019088051');
export const accountSharingSupport = toSupportLink(
  '/articles/217510366-How-many-people-can-I-share-my-Peloton-Digital-Membership-with-',
);
export const homeTrialSupport = toSupportLink('/articles/360031096331/');
export const studioAccountSupport = toSupportLink('/articles/360039540031');

export const covidTreadSupport = toSupportLink(
  '/articles/360041096071-COVID-19-Tread-Delivery-Status',
);
export const covid19GeneralDeliveryStatus = toSupportLink('/articles/360040218772');
export const covid19BikeDeliveryStatus = toSupportLink('/articles/360040667052');
export const covid19TreadDeliveryStatus = toSupportLink(
  '/articles/360041096071-COVID-19-Tread-Delivery-Status',
);
export const usRecall = toSupportLink(
  '/articles/360060446032-Peloton-Recalls-Tread-And-Tread-Full-Details-Here',
);
export const caRecall = toSupportLink(
  '/articles/360060446032-Peloton-Recalls-Tread-Full-Details-Here',
);
export const ukRecall = toSupportLink(
  '/articles/360060446032-Peloton-Tread-Important-Safety-Notice-Full-Details-Here-',
);
export const howToCancelDigitalMembership = toSupportLink(
  '/articles/205411915-How-To-Cancel-A-Peloton-Digital-Membership',
);
export const auMembershipTerms = toSupportLink('/articles/203822009');
