import type { BypassState } from '../models';
export enum ActionTypes {
  BypassSetEmailCapture = 'ecomm/quick-checkout/BYPASS_SET_EMAIL_CAPTURE',
}

const defaultState = { emailCapture: false };

const reducer = (state: BypassState = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.BypassSetEmailCapture: {
      const { emailCapture } = action.payload;

      return {
        ...state,
        emailCapture,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  bypass: BypassState;
};

export const bypassSetEmailCapture = (emailCapture: boolean) =>
  <const>{
    type: ActionTypes.BypassSetEmailCapture,
    payload: { emailCapture },
  };

export const reset = () =>
  <const>{
    type: ActionTypes.BypassSetEmailCapture,
    payload: defaultState,
  };

export type BypassSetEmailCaptureAction = ReturnType<typeof bypassSetEmailCapture>;

type Action = BypassSetEmailCaptureAction;
