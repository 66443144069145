import { currentHostname } from '@peloton/browser/identify';
import type { EnvironmentFlags } from './models';
import {
  isCIEnv,
  isLocalEnv,
  isProdEnv,
  isProdTestEnv,
  isStagingEnv,
  isStageNEnv,
  isTestEnv,
  isUatEnv,
  isUatProdEnv,
} from './models';

const environmentFlags = (appName: string) => (
  hostname = currentHostname,
): EnvironmentFlags => ({
  isProd: isProdEnv(appName, hostname),
  isProdTest: isProdTestEnv(appName, hostname),
  isStaging: isStagingEnv(hostname),
  isStageN: isStageNEnv(hostname),
  isLocal: isLocalEnv(hostname),
  isUat: isUatEnv(appName, hostname),
  isUatProd: isUatProdEnv(hostname),
  isCI: isCIEnv(),
  isTest: isTestEnv(hostname),
});

export default environmentFlags;
