import {
  BIKES_NAV_KEY,
  BIKES_SECONDARY_LINK_COMPARE_KEY,
  TREADS_SECONDARY_LINK_COMPARE_KEY,
  TREADS_NAV_KEY,
} from '@acme-ui/nav/constants';
import type { CtaWithUrlSetData } from '@ecomm/copy';
import type { ProductNav } from '@ecomm/copy/models.generated';
import useFilteredSecondaryLinks from '../../hooks/useFilteredSecondaryLinks';

interface useCompareButtonParams {
  navKey: ProductNav;
  secondaryLinks: CtaWithUrlSetData;
}

interface useCompareButtonReturn {
  shouldShowCompareButton: boolean;
  compareButtonText?: string;
  compareButtonUrl?: string;
  compareButtonTarget?: boolean;
}

/*
 * This hook looks for the right secondary link data for the compare button cta
 */
export const useCompareButton = ({
  navKey,
  secondaryLinks,
}: useCompareButtonParams): useCompareButtonReturn => {
  const filteredLinks = useFilteredSecondaryLinks(secondaryLinks);
  let secondaryLinkTargetKey: string;

  if (navKey === BIKES_NAV_KEY) {
    secondaryLinkTargetKey = BIKES_SECONDARY_LINK_COMPARE_KEY;
  } else if (navKey === TREADS_NAV_KEY) {
    secondaryLinkTargetKey = TREADS_SECONDARY_LINK_COMPARE_KEY;
  } else {
    return {
      shouldShowCompareButton: false,
      compareButtonText: undefined,
      compareButtonUrl: undefined,
      compareButtonTarget: undefined,
    };
  }

  const secondaryLinkTarget = filteredLinks?.find(
    ({ key: linkKey }) => linkKey === secondaryLinkTargetKey,
  );

  return {
    shouldShowCompareButton: true,
    compareButtonText: secondaryLinkTarget?.text,
    compareButtonUrl: secondaryLinkTarget?.url,
    compareButtonTarget: secondaryLinkTarget?.targetBlank,
  };
};
