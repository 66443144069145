// eslint-disable-next-line no-restricted-imports
import { find, map, pipe, prop, propEq, values } from 'ramda';
import type { SubscriptionPlan } from './models';
import type { ReducerState } from './redux';
import { APP_ONE_MTM_SKU } from './skus';

const getSubscriptionPlans = (state: ReducerState) => state.subscriptionPlans;

export const DIGITAL_MTM_SKU = 'digital-mtm';
export const ANNUAL_SKU = '1y-ny-promo';

export const getPlanById = (
  state: ReducerState,
  props: { id: string },
): SubscriptionPlan | undefined => getSubscriptionPlans(state)[props.id];

export const getRequiresAccessCode = (
  state: ReducerState,
  props: { id: string },
): boolean => {
  const sub = getSubscriptionPlans(state)[props.id];
  return sub && sub.requireAccessCode;
};

// Takes in the option slug not the product slug
export const getPlanBySlug = (
  state: ReducerState,
  props: { slug: string },
): SubscriptionPlan | undefined =>
  find(propEq('slug', props.slug), values(getSubscriptionPlans(state)));

export const getSubscriptionSlugs = pipe<
  ReducerState,
  Record<string, SubscriptionPlan>,
  SubscriptionPlan[],
  string[]
>(getSubscriptionPlans, values, map(prop('slug')));

export const getSubscriptionBySku = (
  state: ReducerState,
  sku: string,
): SubscriptionPlan => find(propEq('sku', sku), values(getSubscriptionPlans(state)));

export const getRollOverPrice = (state: ReducerState, sku?: string): number => {
  const plan = getSubscriptionBySku(state, sku || APP_ONE_MTM_SKU);
  return plan ? plan.price : 0;
};

export const getCorporateWellnessSubscription = (
  state: ReducerState,
): SubscriptionPlan | undefined => getPlanBySlug(state, { slug: DIGITAL_MTM_SKU });

export const getAnnuallyBilledSubscription = (
  state: ReducerState,
): SubscriptionPlan | undefined => getPlanBySlug(state, { slug: ANNUAL_SKU });

export const isFreeTrialSku = (state: ReducerState, sku: string): boolean => {
  const plan = getSubscriptionBySku(state, sku);
  return plan?.price === 0 && plan?.doesRollover;
};

export const isFreeTrialBySlug = (state: ReducerState, slug: string): boolean => {
  const plan = getPlanBySlug(state, { slug });
  return Boolean(plan?.price === 0 && plan?.doesRollover);
};
