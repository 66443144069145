//provider component that edits the peloton_visited_sites cookie and adds the current domain to that array

import Cookies from 'cookies-js';
import React from 'react';

const setLogoutCookie = () => {
  const currentPathname = location.origin;
  const currentCookie = Cookies.get('peloton_visited_sites');
  if (!currentCookie || currentCookie.includes(currentPathname)) return;
  const newCookie = [currentCookie, currentPathname];
  Cookies.set('peloton_visited_sites', newCookie, { domain: '.onepeloton.com' });
};

export const testLogoutCookie = () => {
  setLogoutCookie();
};

const LogoutCookieProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  React.useEffect(() => {
    setLogoutCookie();
  }, []);

  return <>{children}</>;
};

export default LogoutCookieProvider;
