import type { OneTrustGeoData } from './models';
import { ProtectedStates } from './models';

const UNITED_STATES = 'US';

export const isLocatedInCalifornia = ({ country, state }: OneTrustGeoData) => {
  return country === UNITED_STATES && state === ProtectedStates.CALIFORNIA;
};

export const isLocatedInProtectedState = ({ country, state }: OneTrustGeoData) => {
  return (
    country === UNITED_STATES &&
    Object.values(ProtectedStates).includes(state as ProtectedStates)
  );
};

export const isLocatedOutsideTheUS = ({ country }: Omit<OneTrustGeoData, 'state'>) => {
  return country !== UNITED_STATES;
};

export const geoDataLacksCountryAndState = (geoData: any) => {
  return !(geoData.hasOwnProperty('country') && geoData.hasOwnProperty('state'));
};
