import { black, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import ChatStyles from '@peloton/drift/ChatStyles';
import { Peloton } from '@peloton/logos';
import { InlineLink } from '@ecomm/links/links';
import Container from './PageContainer';
import { Content, Title, Subtitle } from './sharedErrorComponents';

/*
 * This error page is the backup to the regular ErrorPage
 *
 * It is a cut down version of the regular error page, with
 * the intent of minimizing exposure to possibly erroring code
 * ie the provider store or internationlization.  It should be
 * used at the highest level error boundary and rarely seen.
 */
const SimpleErrorPage = () => {
  return (
    <Container>
      <ChatStyles />
      <Header>
        <StyledLogo height={35} />
      </Header>
      <Content>
        <Title>Oops, something went wrong.</Title>
        <Subtitle>
          Please try again or contact us at{' '}
          <InlineLink href="tel:18666501996">1‑866‑650‑1996</InlineLink>.
        </Subtitle>{' '}
      </Content>
    </Container>
  );
};

export default SimpleErrorPage;

const StyledLogo = styled(Peloton)`
  margin: 15px 0px 5px 0px;
`;

const Header = styled.div`
  background-color: ${white};
  box-shadow: 0 3px 5px 0 ${rgba(black, 0.1)};
  z-index: 90004;
  position: relative;
  width: 100%;
`;
