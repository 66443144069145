import { extractAggregateFields } from '../../lib/extractAggregateFields';

const mapToView = (content: any) => {
  const {
    sys: {
      createdAt,
      updatedAt,
      contentType: {
        sys: { id },
      },
    },
  } = content;

  return {
    __typename: id,
    createdAt,
    updatedAt,
    ...extractAggregateFields(content),
  };
};

export default mapToView;
