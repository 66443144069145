import { TLDS_FOR_ENV_HOSTNAME_COMPONENTS } from '../internationalize/consolidatedAppConfigs/models';

type HostnameComponents = {
  subdomain: string;
  subSubdomain: string | undefined;
  subSubSubdomain: string | undefined;
  tld: string;
};

/**
 * Identifies a valid portion of a domain, according to the rules defined here:
 * https://developer.mozilla.org/en-US/docs/Learn/Common_questions/What_is_a_domain_name
 *
 * Labels must contain "only the letters A through Z, digits 0 through 9, and
 * the `-` character". For our use, we are ignoring the additional length
 * limits and the restriction against using the `-` character in the first or
 * last positions; we will instead lean on the DNS system and/or the browser
 * to prevent pages with invalid domains from loading.
 */
const label = '[\\-a-z0-9]+';

/**
 * Identifies a valid TLD from our configured list of used TLDs.
 */
const validTLD = TLDS_FOR_ENV_HOSTNAME_COMPONENTS.join('|').replace('.', '\\.');

const hostnameRegex = `^(?:(${label})\\.){0,1}?(?:(${label})\\.)?(${label})\\.onepeloton\\.(${validTLD})$`;

/**
 * Parses a `hostname` into its parts:
 * TLD, Domain, Subdomain, and SubSubdomain.
 *
 * @param hostname A hostname string, such as from `window.location.hostname`.
 */
export const toHostnameComponents = (
  hostname: string | undefined,
): HostnameComponents | undefined => {
  const match = hostname?.match(hostnameRegex);

  if (!match) {
    return undefined;
  }

  const [, subSubSubdomain, subSubdomain, subdomain, tld] = match;

  return {
    subdomain,
    subSubdomain,
    subSubSubdomain,
    tld,
  };
};
