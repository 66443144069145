import { CLOUDINARY_ACCOUNT } from '@peloton/app-config';
import toAbsoluteUrl, {
  isRelativePath,
  isDataUri,
  isBlobUri,
} from '@peloton/external-links/toAbsoluteUrl';
import type { Options } from '@peloton/images-ui';

type Src = { src: string };

export const toCloudinaryPrefix = () =>
  `https://res.cloudinary.com/${CLOUDINARY_ACCOUNT}/image/fetch`;

// Cloudinary does not accept fractional numbers for width or height transformations
const toCloudinarySafeDimension = (num: any) => {
  const coercedNumber = Number(num);
  if (!Number.isNaN(coercedNumber)) {
    return Math.ceil(num);
  } else {
    return num;
  }
};

const defaultOptions: Pick<Options, 'format' | 'quality'> = {
  format: 'auto',
  quality: 'auto:good',
};

export const toCloudinarySrcWithNoTransforms = (
  /**
   * This function exists because some assets do not require transforms e.g PDFs
   */
  { src }: Src,
) => `${toCloudinaryPrefix()}/${toAbsoluteUrl(src)}`;

export const toCloudinarySrc = ({ src, ...options }: Src & Options) =>
  `${toCloudinaryPrefix()}/${toTransformParams(options)}/${toAbsoluteUrl(src)}`;

export const toCloudinaryOrLocalSrc = (
  // If we're in a local environment and the src string is a relative path, just return the relative path;
  // OR if we're in any environment and the src string is a data URI, just return that.
  // Otherwise, build a Cloudinary url to apply the requested transforms.
  isLocal: boolean,
  options: Options & Src,
): string =>
  (isLocal && isRelativePath(options.src)) ||
  isDataUri(options.src) ||
  isBlobUri(options.src)
    ? options.src
    : toCloudinarySrc(options);

const toTransformParams = (options: Options): string => {
  const _options = {
    ...defaultOptions,
    devicePixelRatio:
      (typeof window !== 'undefined' ? window.devicePixelRatio : undefined) ?? 1,
    ...options,
  };

  // A little trick:
  // `formatTransform` returns an empty array if the value is `undefined`.
  // `concat` doesn't add empty arrays to the final array.
  // Therefore, this gives us an array containing only the set transforms.
  const transforms = ([] as string[]).concat(
    // *** NOTE - ORDER MATTERS! ***
    // Cloudinary treats these as transforms performed in a specific order.
    // Be sure that any new transforms are put in an order that makes sense.
    // Example: cropping before setting quality may result in a higher-quality image than the reverse
    formatTransform('ar', _options.aspectRatio),
    formatTransform('c', _options.crop),
    formatTransform('co', _options.color),
    formatTransform('dpr', _options.devicePixelRatio.toFixed(1)),
    formatTransform('e', _options.effect),
    formatTransform('f', _options.format),
    formatTransform('g', _options.gravity),
    formatTransform('h', toCloudinarySafeDimension(_options.height)),
    formatTransform('q', _options.quality),
    formatTransform('w', toCloudinarySafeDimension(_options.width)),
    formatTransform('r', _options.radius),
    formatTransform('x', _options.x),
  );

  return transforms.join(',');
};

const formatTransform = (prefix: string, value?: number | string) =>
  value !== undefined ? `${prefix}_${value}` : [];
