import type { Effect } from '@redux-saga/types';
import { createConfigurable } from '@peloton/config-singleton';

type Config = {
  getAnalyticsPropsForLogIn: (
    knownAnalyticsProps: Record<string, any>,
  ) => IterableIterator<Effect | Record<string, any>>;

  getAnalyticsPropsForLogOut: (
    knownAnalyticsProps: Record<string, any>,
  ) => IterableIterator<Effect | Record<string, any>>;

  getAnalyticsPropsForCreateAccount: (
    knownAnalyticsProps: Record<string, any>,
  ) => IterableIterator<Effect | Record<string, any>>;
};

export const { config, updateConfig } = createConfigurable<Config>({
  getAnalyticsPropsForLogIn: function* (props: Record<string, any>) {
    return props;
  },
  getAnalyticsPropsForLogOut: function* (props: Record<string, any>) {
    return props;
  },
  getAnalyticsPropsForCreateAccount: function* (props: Record<string, any>) {
    return props;
  },
});

export const OKTA_TOKEN_KEYS = ['accessToken', 'idToken'];

export const getTokensFromCookie = (): { accessToken?: string; idToken?: string } => {
  if (typeof window === 'undefined') return {};
  return document.cookie.split(';').reduce((accum, curr) => {
    const [rawKey, value] = curr.split('=');
    const key = rawKey.trim();
    if (OKTA_TOKEN_KEYS.indexOf(key) > -1) {
      return { ...accum, [key]: value };
    }
    return accum;
  }, {});
};

export const getAPIAuthToken = (): string | null => {
  const { accessToken: cookieAccessToken } = getTokensFromCookie();
  return cookieAccessToken ? cookieAccessToken : null;
};

const hasAPIAuthToken = (): boolean => Boolean(getAPIAuthToken());

// we should not use Apollo Persisted Queries when we have an API auth token
// so we don't store secrets in the edge cache
export const useApq = () => !hasAPIAuthToken();
