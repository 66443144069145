import type { AxiosInstance } from 'axios';
import React from 'react';

const Context = React.createContext<AxiosInstance | undefined>(undefined);

export const ClientProvider: React.FC<
  React.PropsWithChildren<{ client?: AxiosInstance }>
> = ({ children, client }) => (
  <Context.Provider value={client}>{children}</Context.Provider>
);

export const useClient = () => {
  return React.useContext(Context) as AxiosInstance;
};
