import { css } from 'styled-components';
import { black, highlightLightBlue } from '@peloton/colors';

export const a11yOutlineStyles = css`
  border: 3px solid black;
  outline: 3px solid ${highlightLightBlue};
  outline-offset: -1px;
`;

export const a11yOutline = css`
  &[data-focus-method='keyboard']:focus {
    ${a11yOutlineStyles}
  }
`;

export const defaultSvgShadowForA11yContrast = css`
  filter: drop-shadow(0px 3px 3px ${black});
`;
