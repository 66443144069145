export enum ActionTypes {
  FreeTrialEligibilitySet = 'ecomm/checkout/FREE_TRIAL_ELIGIBILITY_SET',
}

const defaultState = true;

const reducer = (state: boolean = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.FreeTrialEligibilitySet: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  freeTrialEligibility: boolean;
};

export const freeTrialEligibilitySet = (eligible: boolean) =>
  <const>{
    type: ActionTypes.FreeTrialEligibilitySet,
    payload: eligible,
  };

export const reset = () => ({
  type: ActionTypes.FreeTrialEligibilitySet,
  payload: defaultState,
});

export type EligibilitySetAction = ReturnType<typeof freeTrialEligibilitySet>;

type Action = EligibilitySetAction;
