import { useCallback, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { createGlobalStyle } from 'styled-components';
import { toLanguageFromLocale } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize/models/locale';
import { AdaTrackingEvent } from '@ecomm/analytics/models';

type MetaFields = {
  [key: string]: string | number | boolean | null;
};

type ResetParams = {
  greeting?: string;
};

type EndConversationData = {
  chatter_id: string;
  event_data: object;
  session_id: string;
};

type ConversationData = {
  author: string;
  conversation_id: string;
  message_id: string;
};

type EventContext = {
  eventKey: string;
};

declare global {
  interface Window {
    adaEmbed?: {
      reset: (resetParams?: ResetParams) => Promise<void>;
      toggle: () => Promise<void>;
      getInfo: () => Promise<{
        isChatOpen: boolean;
      }>;
      setMetaFields: (fields: MetaFields) => Promise<void>;
      subscribeEvent(
        eventKey: string,
        callback: (data: object, context: EventContext) => void,
      ): Promise<number>;
    };
    adaSettings?: Record<string, any>;
  }
}

export const ADA_SETTINGS_SCRIPT_ID = '__ada-settings';

export const ADA_BUTTON_SELECTOR = '#ada-button-frame';
export const ADA_CHAT_SELECTOR = '#ada-chat-frame';

export const toAdaSettings = (locale: Locale) => {
  return `{
        language: ${JSON.stringify(toLanguageFromLocale(locale))},
        onAdaEmbedLoaded: window.__adaAddEventListeners,
      }`;
};

export const toAdaSettingsString = (locale: Locale) => {
  return `window.adaSettings = ${toAdaSettings(locale)}`;
};

export const toAdaScriptProps = (botHandle: string) => {
  return {
    id: '__ada',
    'data-handle': botHandle,
    src: 'https://static.ada.support/embed2.js',
  };
};

export const injectAdaScript = (locale: Locale, botHandle: string) => {
  const settings = toAdaSettings(locale);
  window.adaSettings = JSON.parse(JSON.stringify(settings));

  const chatScript = document.createElement('script');
  const scriptProps = toAdaScriptProps(botHandle);
  for (const [key, value] of Object.entries(scriptProps)) {
    chatScript.setAttribute(key, value);
  }

  document.body.appendChild(chatScript);
};

export const getAda = () => window.adaEmbed;

export const adaExists = () => Boolean(getAda());

export const setAdaMetaFields = (fields: MetaFields) => {
  return window.adaEmbed?.setMetaFields(fields);
};

export const openAdaChat = async (answerId?: string) => {
  const ada = window.adaEmbed;

  if (!ada) {
    return;
  }

  const { isChatOpen } = await ada.getInfo();

  if (answerId) {
    await ada.reset({ greeting: answerId });
  }

  if (isChatOpen) {
    return;
  }

  await ada.toggle();
};

export const HideAdaChatButton = createGlobalStyle`
  ${ADA_BUTTON_SELECTOR} {
    display: none;
  }
`;
export const useAddEventListeners = () => {
  const { trackAdaEvent } = useTrackAdaEvents();

  const addEvents = useCallback(() => {
    if (adaExists()) {
      window.adaEmbed?.subscribeEvent(
        'ada:end_conversation',
        (data: EndConversationData, _) => {
          trackAdaEvent({
            event: AdaTrackingEvent.ChatDismissed,
            properties: {
              unitName: 'Ada Chatbot End Conversation',
              chatId: `${data.chatter_id}`,
              linkName: 'End Chat',
            },
          });
        },
      );
      window.adaEmbed?.subscribeEvent(
        'ada:conversation:message',
        (data: ConversationData, _) => {
          trackAdaEvent({
            event: AdaTrackingEvent.ChatMessageSent,
            properties: {
              unitName: 'Ada Chatbot Conversation',
              chatId: `${data.conversation_id}`,
              linkName: `${data.message_id}`,
            },
          });
        },
      );
      window.adaEmbed?.subscribeEvent('ada:chat_frame_timeout', () => {
        trackAdaEvent({
          event: AdaTrackingEvent.ChatFailedToLoad,
          properties: {
            unitName: 'Ada Chatbot Failed to Load',
            linkName: '',
            chatId: '',
          },
        });
      });
    }
  }, [trackAdaEvent]);

  useEffect(() => {
    (window as any).__adaAddEventListeners = () => addEvents();
  }, [addEvents]);
};

export type AdaTrackingData = {
  event: AdaTrackingEvent;
  properties: {
    unitName: string;
    linkName: string;
    chatId: string;
  };
};

export const useTrackAdaEvents = () => {
  const { trackEvent } = useTracking();

  const trackAdaEvent = (trackingData: AdaTrackingData) => {
    trackEvent({
      event: trackingData.event,
      properties: {
        parent: 'Ada Chatbot',
        parentType: 'Ada Chatbot(Champ)',
        ...trackingData.properties,
      },
    });
  };
  return { trackAdaEvent };
};
