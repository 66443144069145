import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { trackOrder } from '@ecomm/analytics';
import type { ID } from '@ecomm/models';
import {
  hasTracked as hasPersistedTrackOrder,
  trackOrder as persistTrackOrder,
} from './persist';

export const trackOrderSaga = function* (rawOrder: any, orderId: ID): SagaIterator {
  const hasPersistedTrack = yield call(hasPersistedTrackOrder, { orderId });
  if (!hasPersistedTrack) {
    try {
      yield call(trackOrder, rawOrder);
      yield call(persistTrackOrder, { orderId });
    } finally {
      // do nothing if tracking failed
    }
  }
};
