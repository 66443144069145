const mapToView = (content: any) => ({
  new: content['apparel']['apparel.new'],
  collaboration: content['apparel']['apparel.collaboration'],
  reviews: content['apparel']['apparel.reviews'],
  size: content['apparel']['apparel.size'],
  qty: content['apparel']['apparel.qty'],
  selectSize: content['apparel']['apparel.selectSize'],
  addToCart: content['apparel']['apparel.addToCart'],
  freeShipping: content['apparel']['apparel.freeShipping'],
  freeReturnsWithin: content['apparel']['apparel.freeReturnsWithin'],
  productDetails: content['apparel']['apparel.productDetails'],
  sizeAndFit: content['apparel']['apparel.sizeAndFit'],
  materialsAndCare: content['apparel']['apparel.materialsAndCare'],
  shippingAndReturns: content['apparel']['apparel.shippingAndReturns'],
  color: content['apparel']['apparel.color'],
  quickView: content['apparel']['apparel.quickView'],
  quickViewLink: content['apparel']['apparel.quickViewLink'],
  shopTheLook: content['apparel']['apparel.shopTheLook'],
  peloton: content['apparel']['apparel.peloton'],
  onSale: content['apparel']['apparel.onSale'],
  lowInStock: content['apparel']['apparel.lowInStock'],
  soldOut: content['apparel']['apparel.soldOut'],
  qtyPlaceholder: content['apparel']['apparel.qtyPlaceholder'],
  sizePlaceholder: content['apparel']['apparel.sizePlaceholder'],
  sizeButton: content['apparel']['apparel.sizeButton'],
  finalSale: content['apparel']['apparel.finalSaleTxt'],
  genericError: content['apparel']['apparel.genericError'],
  quantityError: content['apparel']['apparel.quantityError'],
  comingSoon: content['apparel']['apparel.comingSoon'],
});

export default mapToView;
