import { createMemoryHistory, createBrowserHistory } from 'history';
import { toLocaleFromHostname } from '@peloton/internationalize';
import { getLocaleBasePath } from '@peloton/internationalize/models/path';
import { LOCALE_TOGGLE } from '@peloton/internationalize/models/toggle';
import { isReactNative } from '@peloton/navigator';

export const createHistoryWithBaseName = () =>
  createBrowserHistory({ basename: getLocaleBasePath(toLocaleFromHostname()) });

const getBrowserHistory = () =>
  LOCALE_TOGGLE ? createHistoryWithBaseName() : createBrowserHistory();

export default isReactNative() || typeof window === 'undefined'
  ? createMemoryHistory()
  : getBrowserHistory();
