import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { LocationArrow } from '@ecomm/icons';
import { b4 } from '@ecomm/typography';

type Props = {
  className?: string;
  isNavLink: boolean;
  onClick: () => void;
  text: string;
  trackingParent?: string;
  useHamburgerMenuStyles?: boolean;
};

const UseMyLocationLink: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  isNavLink,
  onClick,
  text,
  trackingParent = '',
  useHamburgerMenuStyles = false,
}) => {
  const { trackEvent } = useTracking();
  return (
    <Container className={className}>
      {isNavLink ? (
        <MenuLink
          onClick={() => {
            onClick();

            trackEvent({
              properties: {
                category: 'location',
                unitName: 'nav.geo.location',
                linkTo: '',
                linkName: text,
              },
            });
          }}
        >
          {text}
        </MenuLink>
      ) : (
        <StyledTrackingLink
          onClick={() => {
            onClick();

            trackEvent({
              properties: {
                unitName: text,
                parent: trackingParent,
              },
            });
          }}
        >
          {text}
        </StyledTrackingLink>
      )}
      <LocationArrowContainer aria-hidden="true">
        <LocationArrow
          title="Geolocation arrow"
          color={useHamburgerMenuStyles ? `${grey[30]}` : undefined}
        />
      </LocationArrowContainer>
    </Container>
  );
};

const LocationArrowContainer = styled.span`
  margin-left: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const MenuLink = styled.button`
  ${b4}
`;

const StyledTrackingLink = styled.button`
  ${b4}
`;

export default UseMyLocationLink;
