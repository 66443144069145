import mapToView from './mapToView';

const BFCM_ENTRY_ID = '6pIVvKIHKxggRW9fHsd0eC';

export default {
  entryId: BFCM_ENTRY_ID,
  contentType: 'prospect-referral-BFCM-packages',
  excludeFromNextGlobalData: true,
  mapToView,
};
