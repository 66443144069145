import { Store } from '../../stores/WebStore';
import { Country, Locale, SupportedTLD } from '../models';

export enum SupportedLocaleForUniversalLogin {
  EnglishUnitedStates = Locale.EnglishUnitedStates,
  EnglishCanada = Locale.EnglishCanada,
  EnglishAustralia = Locale.EnglishAustralia,
  EnglishUnitedKingdom = Locale.EnglishUnitedKingdom,
  GermanGermany = Locale.GermanGermany,
  GermanAustria = Locale.GermanAustria,
  SpanishMexico = Locale.SpanishMexico,
  Default = Locale.Default,
}

export const BLANK_NATIVE_COUNTRY_NAMES_FOR_ECOMM_GEOMODAL_CONTEXT = {
  us: '',
  gb: '',
  ca: '',
  au: '',
  de: '',
  at: '',
};

export const GEOMODAL_V2_PACKAGE_NAME_TO_SLUG_MAP = {
  'en-GB': {
    bikePackage: 'the-intl-bike-package',
    bikeStarter: 'bike-starter-package-uk',
    bikeSelect: 'bike-select-package-uk',
    bikeUltimate: 'bike-ultimate-package-uk',
    bikePlusStarter: 'bike-plus-starter-package-uk',
    bikePlusSelect: 'bike-plus-select-package-uk',
    bikePlusUltimate: 'bike-plus-ultimate-package-uk',
    treadBasics: 'tread-basics-package-uk',
    treadStarter: 'tread-starter-package-uk',
    treadSelect: 'tread-select-package-uk',
    treadUltimate: 'tread-ultimate-package-uk',
  },
  'de-DE': {
    bikePackage: 'the-bike-package-de',
    bikeStarter: 'bike-starter-package-de',
    bikeSelect: 'bike-select-package-de',
    bikeUltimate: 'bike-ultimate-package-de',
    bikePlusStarter: 'bike-plus-starter-package-de',
    bikePlusSelect: 'bike-plus-select-package-de',
    bikePlusUltimate: 'bike-plus-ultimate-package-de',
    treadBasics: 'tread-basics-package-de',
    treadStarter: 'tread-starter-package-de',
    treadSelect: 'tread-select-package-de',
    treadUltimate: 'tread-ultimate-package-de',
  },
  'en-AU': {
    bikePackage: 'bike-basics-package-au',
    bikeStarter: 'bike-starter-package-au',
    bikeSelect: 'bike-select-package-au',
    bikeUltimate: 'bike-ultimate-package-au',
    bikePlusStarter: 'bike-plus-starter-package-au',
    bikePlusSelect: 'bike-plus-select-package-au',
    bikePlusUltimate: 'bike-plus-ultimate-package-au',
  },
  'en-CA': {
    bikePackage: 'bike-basics-package-ca',
    bikeStarter: 'bike-starter-package-ca',
    bikeSelect: 'bike-select-package-ca',
    bikeUltimate: 'bike-ultimate-package-ca',
    bikePlusStarter: 'bike-plus-starter-package-ca',
    bikePlusSelect: 'bike-plus-select-package-ca',
    bikePlusUltimate: 'bike-plus-ultimate-package-ca',
    treadBasics: 'tread-basics-package-ca',
    treadStarter: 'tread-starter-package-ca',
    treadSelect: 'tread-select-package-ca',
    treadUltimate: 'tread-ultimate-package-ca',
  },
  'en-US': {
    bikePackage: 'bike-package',
    bikeStarter: 'bike-starter-package-us',
    bikeSelect: 'bike-select-package-us',
    bikeUltimate: 'bike-ultimate-package-us',
    bikePlusStarter: 'bike-plus-starter-package-us',
    bikePlusSelect: 'bike-plus-select-package-us',
    bikePlusUltimate: 'bike-plus-ultimate-package-us',
    treadBasics: 'tread-basics-package-us',
    treadStarter: 'tread-starter-package-us',
    treadSelect: 'tread-select-package-us',
    treadUltimate: 'tread-ultimate-package-us',
  },
};

export const ADMIN_WANTS_A_TEST_TO_FAIL_IF_COUNTRIES_ENUM_CHANGES = [
  ['UnitedStates', 'us'],
  ['UnitedKingdom', 'gb'],
  ['Canada', 'ca'],
  ['Germany', 'de'],
  ['Australia', 'au'],
  ['Austria', 'at'],
  ['Default', 'us'],
];

export const LOCALES_FOR_ECOMM_COPY_TO_FETCH = [
  'en-US',
  'en-GB',
  'en-CA',
  'de-DE',
  'en-AU',
];

export const VIRTUAL_SHOWROOM_IDS: Record<Locale, number | null> = {
  [Locale.EnglishUnitedStates]: 220000,
  [Locale.EnglishUnitedKingdom]: 219274,
  [Locale.GermanGermany]: 232230,
  [Locale.GermanAustria]: null,
  [Locale.EnglishCanada]: 232231,
  [Locale.EnglishAustralia]: null,
  [Locale.EnglishBeta]: 220000,
  [Locale.SpanishMexico]: null,
  [Locale.FrenchCanada]: null,
};

export const AVAILABLE_LOCALES_FOR_ECOMM_INTERNATIONALIZE: Locale[] = [
  Locale.EnglishUnitedStates,
  Locale.EnglishUnitedKingdom,
  Locale.EnglishCanada,
  Locale.GermanGermany,
  Locale.EnglishAustralia,
  Locale.GermanAustria,
];

export type EcommTogglesPageExcludedLocales = Exclude<
  Locale,
  [Locale.SpanishMexico, Locale.FrenchCanada, Locale.GermanAustria]
>;

type SegmentTLDsForAnalytics = Record<SupportedTLD, string>;

type SegmentConfigForAnalytics = {
  dev: SegmentTLDsForAnalytics;
  e2e: SegmentTLDsForAnalytics;
  qa: SegmentTLDsForAnalytics;
  prod: SegmentTLDsForAnalytics;
};

export const SEGMENT_KEYS_FOR_ANALYTICS: SegmentConfigForAnalytics = {
  dev: Object.values(SupportedTLD).reduce(
    (acc, curr) => ({ ...acc, [curr]: 'hYfSyPTz4G0age6HYnrLfvkedbSfYPeP' }),
    {} as SegmentTLDsForAnalytics,
  ), // This source is disabled in Segment due to usage reasons
  e2e: Object.values(SupportedTLD).reduce(
    (acc, curr) => ({ ...acc, [curr]: 'd8xk69o4mzymFMeBKjh1S5Wj9o4FFKEl' }),
    {} as SegmentTLDsForAnalytics,
  ), // This source is disabled in Segment due to usage reasons
  qa: {
    [SupportedTLD.Com]: 'lPpEbPe0hAbW27ByP4zdvMbpEnCur6WS',
    [SupportedTLD.Au]: 'ocAkPMnheOUnJ5TdfaGsFwdLte90JBPi',
    [SupportedTLD.CoUk]: 'Y2uaYZduaYzinQdsOuTH5je3hHBbokDU',
    [SupportedTLD.Ca]: 'HJCkNH03THczWNAfLksNaIvFVLQCns0D',
    [SupportedTLD.De]: 'KmKDYFAEmfg0WDwcrRfI1jCKZyZJezhC',
    [SupportedTLD.At]: 'KmKDYFAEmfg0WDwcrRfI1jCKZyZJezhC', // Same as DE
  },
  prod: {
    [SupportedTLD.Com]: 'zcOKJNJNisvk9S1wm2NtQ1yDUqh5cBbQ',
    [SupportedTLD.Au]: 'MnxCMOUWPBGnHszgAaMNCVkpunsGZBXs',
    [SupportedTLD.CoUk]: 'akzFOymCVkXACmowBDQkNAIdbH18LiVW',
    [SupportedTLD.Ca]: 'Hvj2C7ZCG8qwVICQyE3wmVr9NeIRMxO2',
    [SupportedTLD.De]: 'PZWGPIomviHHv4meBgYCXaKAm6YVjznr',
    [SupportedTLD.At]: 'PZWGPIomviHHv4meBgYCXaKAm6YVjznr', // Same as DE
  },
};

export const SUPPORTED_LOCALES_FOR_PAGE_BUILDER_MARKDOWN_PAGE: Record<
  SupportedTLD,
  string
> = {
  '.com': 'en-us',
  '.co.uk': 'en-gb',
  '.ca': 'en-ca',
  '.de': 'de',
  '.com.au': 'en-au',
  '.at': 'de',
};

export const DRIFT_CANADA_UNDELIVERABLE_INTERACTION_ID = 63588;
export const DRIFT_DEFAULT_INTERACTION_ID: Record<Locale, number> = {
  [Locale.EnglishUnitedStates]: 76433,
  [Locale.EnglishCanada]: 74619,
  [Locale.EnglishUnitedKingdom]: 48447,
  [Locale.GermanGermany]: 112492,
  [Locale.EnglishAustralia]: 254970,
  // Reusing enUS because we don't need to integrate with drift
  [Locale.EnglishBeta]: 76433,
  [Locale.SpanishMexico]: 76433,
  [Locale.FrenchCanada]: 76433,
  [Locale.GermanAustria]: 76433, // Update with Austria Specific if necessary
};

export const TLDS_FOR_ENV_HOSTNAME_COMPONENTS = [
  'ca',
  'co.uk',
  'com',
  'com.au',
  'de',
  'at',
] as const;

export const CCTLDS_FOR_PARSING_HOSTNAME_IN_PELOTON_ENV = [
  'com',
  'co.uk',
  'ca',
  'de',
  'com.au',
  'at',
];

// from https://regexr.com/4l3r8
export const REGEX_FOR_TESTING_HOSTNAME_IN_PELOTON_ENV = /(([\w-]+)--)?(members|pos|preorder|studio|uikit|upnext|www|next-www|account|admin|universal-logout|cra-www)(--lit)?(--([\w-]+))?\.(secret|test|uat|xtest|prod-test)\.onepeloton\.(com|co\.uk|ca|de|com\.au|at)/;

export const COUNTRY_TO_PRICE_MAP_FOR_WEBTV_SUBSCRIPTIONS: Record<Country, number> = {
  [Country.UnitedStates]: 12.99,
  [Country.UnitedKingdom]: 12.99,
  [Country.Australia]: 16.99,
  [Country.Germany]: 12.99,
  [Country.Austria]: 12.99, // TODO - confirm
  [Country.Canada]: 16.99,
};

export const GRAPHQL_LOCALE_CODE_TO_REST_LOCALE_CODE_MAP_FOR_MEMBERS_PG_SCHEDULE_HELPERS = {
  EN_US: Locale.EnglishUnitedStates,
  DE_DE: Locale.GermanGermany,
  DE_AT: Locale.GermanAustria,
  EN_AU: Locale.EnglishAustralia,
  EN_GB: Locale.EnglishUnitedKingdom,
  EN_CA: Locale.EnglishCanada,
  UZ_UZ: Locale.EnglishBeta,
  ES_MX: Locale.SpanishMexico,
};

export const STRIPE_ACCOUNT_KEYS_FOR_ECOMM = {
  [Locale.EnglishCanada]: 'acct_1DO4RuGkRoERVaCg',
  [Locale.EnglishUnitedKingdom]: 'acct_1Cl0gGJS9uyv6koH',
  [Locale.EnglishUnitedStates]: 'acct_2APPhRDxNsvGqVOxNrfu',
  [Locale.GermanGermany]: 'acct_1ErWzRGPHjjrYkln',
  [Locale.EnglishAustralia]: 'acct_1IEJs1Cgi1cXBKIG',
  [Locale.GermanAustria]: 'acct_1NpyG1IImbyOXGKo',
};

export const SUPPORTED_LOCALES_FOR_PROSPECTS_REVALIDATION = [
  Locale.EnglishUnitedStates,
  Locale.EnglishAustralia,
  Locale.EnglishUnitedKingdom,
  Locale.EnglishCanada,
  Locale.GermanGermany,
];

export type AddressTypeUsedInAccountPgMyMembership = {
  addressee?: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export const US_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP: AddressTypeUsedInAccountPgMyMembership = {
  addressee: 'Peloton Interactive Inc.',
  city: 'New York',
  country: 'USA',
  postalCode: '10001',
  state: 'NY',
  line1: '125 West 25th Street',
  line2: '11th Floor',
};

export const UK_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP: AddressTypeUsedInAccountPgMyMembership = {
  addressee: 'Peloton Interactive Inc.',
  city: 'London',
  country: 'UK',
  postalCode: 'WC2H 9EA',
  state: '',
  line1: 'Orion House',
  line2: '5 Upper St Martin’s Lane',
};

export const DE_ADDRESS_USED_IN_ACCOUNT_PG_MY_MEMBERSHIP: AddressTypeUsedInAccountPgMyMembership = {
  addressee: 'Peloton Interactive Deutschland GmbH',
  city: 'Berlin',
  country: 'DE323550711',
  postalCode: '10178',
  state: '',
  line1: 'c/o WeWork Neue Schönhauser Str. 3-5',
  line2: 'Neue Schönhauser Straße 3-5',
};

export const TREAD_PRODUCT_OFFER_LOCALES: Array<Locale> = [
  Locale.EnglishUnitedStates,
  Locale.EnglishCanada,
  Locale.EnglishUnitedKingdom,
  Locale.GermanGermany,
];

const US_SHIPPING_ADDRESSES_FOR_FSL = [
  {
    shipping_street_address_1: '3154 W Diversey Ave',
    shipping_city: 'Chicago',
    shipping_state: 'IL',
    shipping_postal_code: '60647',
  },
  {
    shipping_street_address_1: '24 Avenue E',
    shipping_city: 'Ronkonkoma',
    shipping_state: 'NY',
    shipping_postal_code: '11779',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '2629 Martin Avenue',
    shipping_city: 'Bellmore',
    shipping_state: 'NY',
    shipping_postal_code: '11710',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '3726 Condor Rd',
    shipping_city: 'Levittown ',
    shipping_state: 'NY',
    shipping_postal_code: '11756',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '344 Horton Highway',
    shipping_city: 'Mineola',
    shipping_state: 'NY',
    shipping_postal_code: '11501',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '18 Fourth St',
    shipping_city: 'Valley Stream',
    shipping_state: 'NY',
    shipping_postal_code: '11581',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '45 Diane Drive',
    shipping_city: 'East Hampton',
    shipping_state: 'NY',
    shipping_postal_code: '11937',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '16 Navy Rd',
    shipping_city: 'Montauk',
    shipping_state: 'NY',
    shipping_postal_code: '11954',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '1216 Main Rd',
    shipping_city: 'Jamesport',
    shipping_state: 'NY',
    shipping_postal_code: '11947',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '99 Bayside Dr',
    shipping_city: 'Point Lookout',
    shipping_state: 'NY',
    shipping_postal_code: '11569',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '45 Marion Walk',
    shipping_city: 'Breezy Point',
    shipping_state: 'NY',
    shipping_postal_code: '11697',
    shipping_country: 'US',
  },
  {
    shipping_street_address_1: '36 Fire Island Blvd',
    shipping_city: 'Fire Island Pines',
    shipping_state: 'NY',
    shipping_postal_code: '11782',
    shipping_country: 'US',
  },
];

const US_SHIPPING_ADDRESSES_FOR_XPO = [
  {
    shipping_street_address_1: '122 xpo way',
    shipping_city: 'Burlingame',
    shipping_state: 'KS',
    shipping_postal_code: '66413',
    shipping_country: 'US',
  },
];

const US_SHIPPING_ADDRESSES_FOR_JB_HUNT = [
  {
    shipping_street_address_1: '7268 Preserve Pointe',
    shipping_city: 'Meritt Island',
    shipping_state: 'FL',
    shipping_postal_code: '34442',
    shipping_country: 'US',
  },
];

const US_SHIPPING_ADDRESSES_FOR_ALASKA = [
  {
    shipping_street_address_1: '123 W Northern Lights Blvd',
    shipping_city: 'Anchorage',
    shipping_state: 'AK',
    shipping_postal_code: '99503',
    shipping_country: 'US',
  },
];

// const US_SHIPPING_ADDRESSES_FOR_HAWAII = [
//   {
//     shipping_street_address_1: '77-6586A Alii Dr',
//     shipping_city: 'Kailua-Kona',
//     shipping_state: 'HI',
//     shipping_postal_code: '96740',
//     shipping_country: 'US',
//   },
// ];

const DE_SHIPPING_ADDRESSES_FOR_FSL = [
  {
    shipping_street_address_1: 'something',
    shipping_city: 'Hamburg',
    shipping_postal_code: '24536',
    shipping_country: 'DE',
  },
];

const DE_SHIPPING_ADDRESSES_FOR_RHENUS = [
  {
    shipping_street_address_1: 'Alexanderpl. 5-7',
    shipping_city: 'Berlin',
    shipping_postal_code: '10178',
    shipping_country: 'DE',
  },
];

const CA_SHIPPING_ADDRESSES_FOR_XPO = [
  {
    shipping_street_address_1: '85 St Paul Blvd',
    shipping_city: 'Bumbay',
    shipping_state: 'BC',
    shipping_postal_code: 'V5G 4G3',
    shipping_country: 'CA',
  },
];

const CA_SHIPPING_ADDRESSES_FOR_FSL = [
  {
    shipping_street_address_1: 'something',
    shipping_city: 'Toronto',
    shipping_state: 'ON',
    shipping_postal_code: 'M5J 1E3',
    shipping_country: 'CA',
  },
];

const UK_SHIPPING_ADDRESSES_FOR_XPO = [
  {
    shipping_street_address_1: 'something',
    shipping_city: 'London',
    shipping_postal_code: 'W1D 1NN',
    shipping_country: 'GB',
  },
];

const UK_SHIPPING_ADDRESSES_FOR_FSL = [
  {
    shipping_street_address_1: 'something',
    shipping_city: 'North Acton',
    shipping_postal_code: 'W3 6UU',
    shipping_country: 'GB',
  },
];

const AU_SHIPPING_ADDRESSES_FOR_WINNINGS = [
  {
    shipping_street_address_1: 'Martin Place',
    shipping_city: 'Sydney',
    shipping_postal_code: '2000',
    shipping_state: 'NSW',
    shipping_country: 'AU',
  },
];

export enum ShippingPartner {
  FSL = 'fls',
  XPO = 'xpo',
  JBHUNT = 'jbhunt',
  Rhenus = 'rhenus',
  Alaska = 'alaska',
  Winnings = 'Winnings',
  Hawaii = 'hawaii',
  Custom = 'custom',
}

export const SHIPPING_PARTNER_ADDRESSES = {
  [Locale.EnglishUnitedStates]: {
    [ShippingPartner.XPO]: US_SHIPPING_ADDRESSES_FOR_XPO,
    [ShippingPartner.FSL]: US_SHIPPING_ADDRESSES_FOR_FSL,
    [ShippingPartner.JBHUNT]: US_SHIPPING_ADDRESSES_FOR_JB_HUNT,
    [ShippingPartner.Alaska]: US_SHIPPING_ADDRESSES_FOR_ALASKA,
    // [ShippingPartner.Hawaii]: US_SHIPPING_ADDRESSES_FOR_HAWAII,
  },
  [Locale.EnglishCanada]: {
    [ShippingPartner.XPO]: CA_SHIPPING_ADDRESSES_FOR_XPO,
    [ShippingPartner.FSL]: CA_SHIPPING_ADDRESSES_FOR_FSL,
  },
  [Locale.EnglishUnitedKingdom]: {
    [ShippingPartner.XPO]: UK_SHIPPING_ADDRESSES_FOR_XPO,
    [ShippingPartner.FSL]: UK_SHIPPING_ADDRESSES_FOR_FSL,
  },
  [Locale.GermanGermany]: {
    [ShippingPartner.FSL]: DE_SHIPPING_ADDRESSES_FOR_FSL,
    [ShippingPartner.Rhenus]: DE_SHIPPING_ADDRESSES_FOR_RHENUS,
  },
  [Locale.EnglishAustralia]: {
    [ShippingPartner.Winnings]: AU_SHIPPING_ADDRESSES_FOR_WINNINGS,
  },
};

export type AddressForEcommCheckout = {
  firstName: string;
  lastName: string;
  phone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  country: Country;
};

export type AddressToValidateForEcommCheckout = Omit<
  AddressForEcommCheckout,
  'firstName' | 'lastName' | 'phone'
>;

export const ADDRESS_KEYS_FOR_ECOMM_CHECKOUT_VALIDATION = {
  line1: 'line1',
  line2: 'line2',
  city: 'city',
  state: 'state',
  postalCode: 'postalCode',
  country: 'country',
};

export const CA_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT = RegExp(
  /^(?:[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z])\s?(?:[0-9][ABCEGHJ-NPRSTV-Z][0-9])?$/,
);
export const UK_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT = RegExp(
  /^((?:(?:GIR)|(?:[A-PR-UWYZ])(?:(?:[0-9](?:[A-HJKSTUW]|[0-9])?)|(?:[A-HK-Y][0-9](?:[0-9]|[ABEHMNPRV-Y])?)))) ?([0-9][ABD-HJLNP-UW-Z]{2})$/,
);
export const US_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT = RegExp(/(^\d{5}$)|(^\d{9}$)/);
export const DE_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT = RegExp(
  /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,
);
export const AT_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT = RegExp(/(^\d{4}$)/);
export const AU_POSTAL_CODE_REGEX_FOR_ECOMM_CHECKOUT = RegExp(/(^\d{4}$)/);

export const LOCALES_REQUIRING_EXPLICIT_CONSENT_FOR_MARKETING_IN_ECOMM_FEATURE_TOGGLES = [
  Locale.EnglishAustralia,
  Locale.EnglishCanada,
  Locale.EnglishUnitedKingdom,
  Locale.GermanGermany,
];

export const DEFAULT_MARKETING_OPT_IN_STATUS_BY_LOCALE = {
  [Locale.EnglishAustralia]: false,
  [Locale.EnglishCanada]: false,
  [Locale.EnglishUnitedKingdom]: true,
  [Locale.GermanGermany]: false,
};

export const COMMERCIAL_BIKE_LINKS_FOR_ECOMM_FOOTER = {
  [Locale.EnglishUnitedKingdom]: 'https://www.precor.com/en-gb/peloton',
  [Locale.GermanGermany]: 'https://www.precor.com/de/peloton',
  [Locale.EnglishAustralia]: 'https://commercial.onepeloton.com/',
};

export const LOCALE_ALT_TEXT_MAP: Record<Locale, string> = {
  [Locale.EnglishCanada]: 'Canada Flag',
  [Locale.EnglishUnitedKingdom]: 'United Kingdom Flag',
  [Locale.EnglishUnitedStates]: 'United States Flag',
  [Locale.EnglishAustralia]: 'Australia Flag',
  [Locale.GermanGermany]: 'Germany Flag',
  [Locale.GermanAustria]: 'Germany Flag', // TODO: change to Austria flag
  [Locale.EnglishBeta]: 'United States Flag',
  [Locale.SpanishMexico]: 'Mexico Flag',
  [Locale.FrenchCanada]: 'Canada Flag',
};

export const LOCALE_CURRENCY_SYMBOL_MAP: Record<Locale, string> = {
  [Locale.EnglishCanada]: '$',
  [Locale.EnglishUnitedKingdom]: '£',
  [Locale.EnglishUnitedStates]: '$',
  [Locale.EnglishAustralia]: '$',
  [Locale.GermanGermany]: '€',
  [Locale.GermanAustria]: '€',
  [Locale.EnglishBeta]: '$',
  [Locale.SpanishMexico]: '$',
  [Locale.FrenchCanada]: '$',
};

export const LOCALES_WITH_AUTO_GRID_COLUMN_LAYOUT_FOR_COMMERCETOOLS_CHECKOUT_ADDRESS_FORM = [
  Locale.EnglishCanada,
  Locale.GermanGermany,
  Locale.EnglishUnitedKingdom,
];

export const ELIGIBLE_LOCALES_FOR_GIFTING = [
  Locale.EnglishUnitedStates,
  Locale.EnglishCanada,
];

export const ELIGIBLE_LOCALES_FOR_GIFTING_ENHANCEMENTS = [Locale.EnglishUnitedStates];

export const LOCALES_FOR_PROGRAM_AVAILABILITY_PAGE = [
  Locale.EnglishUnitedStates,
  Locale.EnglishUnitedKingdom,
  Locale.EnglishCanada,
  Locale.EnglishAustralia,
  Locale.GermanGermany,
];

export const LOCALES_BENEFITING_FROM_HYPHENATION_FOR_PG_SHOP_ACCESSORIES_DISPLAY = [
  Locale.GermanGermany,
];

export type LocalesAvailableForEcommPgToggles = Exclude<
  Locale,
  [Locale.SpanishMexico, Locale.FrenchCanada, Locale.GermanAustria]
>;

export const HARD_CODED_GUIDE_PRICING_FOR_ECOMM_PRICING_HELPERS = {
  [Locale.EnglishAustralia]: {
    price: 44500,
    packages: [],
  },
  [Locale.EnglishCanada]: {
    price: 39500,
    packages: [],
  },
  [Locale.EnglishUnitedKingdom]: {
    price: 27500,
    packages: [],
  },
  [Locale.EnglishUnitedStates]: {
    price: 29500,
    packages: [],
  },
  [Locale.GermanGermany]: {
    price: 29500,
    packages: [],
  },
};

export const FAAS_LOCALES = [
  Locale.EnglishUnitedStates,
  Locale.EnglishCanada,
  Locale.GermanGermany,
  Locale.EnglishUnitedKingdom,
] as const;

export type RentalLocale = typeof FAAS_LOCALES[number];

export type RentalPrices = {
  monthly: number;
  setup: number;
  buyout?: string;
};

export const US_BIKE_RENTAL_PRICE = {
  monthly: 89,
  setup: 150,
  buyout: '895',
};

export const US_BIKE_PLUS_RENTAL_PRICE = {
  monthly: 119,
  setup: 150,
  buyout: '1,595',
};

export const CA_BIKE_RENTAL_PRICE = {
  monthly: 129,
  setup: 250,
  buyout: '1,595',
};

export const CA_BIKE_PLUS_RENTAL_PRICE = {
  monthly: 169,
  setup: 250,
  buyout: '3,045',
};

export const DE_BIKE_RENTAL_PRICE = {
  monthly: 99,
  setup: 200,
  buyout: '1.245',
};

export const DE_BIKE_PLUS_RENTAL_PRICE = {
  monthly: 129,
  setup: 200,
  buyout: '2.295',
};

// Expected to be 0, UK Bike FaaS not available at this time.
export const UK_BIKE_RENTAL_PRICE = {
  monthly: 0,
  setup: 0,
  buyout: '0',
};

export const UK_BIKE_PLUS_RENTAL_PRICE = {
  monthly: 99,
  setup: 200,
  buyout: '0',
};

export const GUIDE_MEMBERSHIP_PRICING = {
  [Locale.EnglishUnitedStates]: 2400,
  [Locale.EnglishCanada]: 3000,
  [Locale.EnglishUnitedKingdom]: 2400,
  [Locale.EnglishAustralia]: 3500,
};

export const CONTRACT_LOCALES_FOR_ENGAGE_LEGAL_DOCUMENT = [
  Locale.EnglishUnitedStates,
  Locale.EnglishCanada,
  Locale.EnglishUnitedKingdom,
  Locale.EnglishAustralia,
  Locale.GermanGermany,
];

export type SupportContact = {
  email: string;
  tel: string;
};

export const AU_SUPPORT_CONTACT: SupportContact = {
  email: 'support@onepeloton.com.au',
  tel: '+ 61 1800 952 535',
};

export const UK_SUPPORT_CONTACT: SupportContact = {
  email: 'support@onepeloton.co.uk',
  tel: '+ 44 808 169 6469',
};

export const US_SUPPORT_CONTACT: SupportContact = {
  email: 'support@onepeloton.com',
  tel: '1-866-679-9129',
};

export enum AddToCartErrorCode {
  TooManyWarranties = 'tooManyWarranties',
  TooManyLeases = 'tooManyLeases',
  CartIsRegularEquipmentOnly = 'cartIsRegularEquipmentOnly',
  CartIsLeasedEquipmentOnly = 'cartIsLeasedEquipmentOnly',
  Default = 'default',
}

// TODO: Map this in contentful somehow and add ability to open the cart from a link within
export const ADD_TO_CART_ERRORS = {
  [AddToCartErrorCode.TooManyLeases]: {
    [Locale.GermanGermany]:
      'Du kannst nicht mehrere Artikel in der gleichen Bestellung mieten. Entferne das Leihgerät aus deinem Warenkorb, um diesen Artikel hinzuzufügen.',
    [Locale.Default]:
      'You can’t rent multiple items in the same order. Remove the rental from your cart to add this item.',
  },
  [AddToCartErrorCode.CartIsRegularEquipmentOnly]: {
    [Locale.GermanGermany]:
      'Du kannst Geräte in der gleichen Bestellung nicht mieten und kaufen. Entferne das Gerät aus deinem Einkaufswagen, um das Mietgerät hinzuzufügen.',
    [Locale.Default]:
      'You can’t buy and rent hardware in the same order. Remove the hardware from your cart to add this rental.',
  },
  [AddToCartErrorCode.CartIsLeasedEquipmentOnly]: {
    [Locale.GermanGermany]:
      'Du kannst Geräte in der gleichen Bestellung nicht mieten und kaufen. Entferne das Leihgerät aus deinem Warenkorb, um diesen Artikel hinzuzufügen.',
    [Locale.Default]:
      'You can’t rent and buy hardware in the same order. Remove the rental from your cart to add this item.',
  },
};

export const ADD_TO_CART_DEFAULT_ERROR = {
  [Locale.GermanGermany]: 'Etwas ist schiefgelaufen.',
  [Locale.Default]: 'Something went wrong',
};

export const GUIDE_PACKAGE_LOCALE_MAP = {
  [Locale.EnglishUnitedStates]: 'guide-us',
  [Locale.EnglishUnitedKingdom]: 'guide-uk',
  [Locale.EnglishCanada]: 'guide-ca',
  [Locale.FrenchCanada]: 'guide-ca',
  [Locale.EnglishAustralia]: 'guide-au',
};

export const AVAILABLE_LOCALES_FOR_STUDIO_METADATA_LINKS = [
  Locale.EnglishAustralia,
  Locale.EnglishCanada,
  Locale.EnglishUnitedKingdom,
  Locale.EnglishUnitedStates,
  Locale.GermanGermany,
];

export const LOCALE_DISPLAY_NAME_MAP_FOR_STUDIO_CHECKOUT_CITY_STATE_POSTAL_INPUTS: Record<
  string,
  Locale
> = {
  ['Deutschland']: Locale.GermanGermany,
  ['United Kingdom']: Locale.EnglishUnitedKingdom,
  ['United States']: Locale.EnglishUnitedStates,
  ['Canada']: Locale.EnglishCanada,
  ['Australia']: Locale.EnglishAustralia,
};

export const SUPPORTED_BILLING_LOCALES_FOR_STUDIO_CHECKOUT_COUNTRY_INPUT = [
  Locale.EnglishUnitedKingdom,
  Locale.EnglishUnitedStates,
  Locale.GermanGermany,
  Locale.EnglishCanada,
  Locale.EnglishAustralia,
];

export const TEAM_UP_LOCALE_MAP = {
  [Locale.EnglishUnitedStates]: 'en-us',
  [Locale.EnglishUnitedKingdom]: 'en-gb',
  [Locale.GermanGermany]: 'de',
};

export type PelotonPlatform = 'sky_tv' | 'lg' | 'samsung' | 'comcast_x1' | 'web';

export const COUNTRIES_REQUIRING_MARKETING_POLICY_DISCLOSURE_AT_ACCOUNT_CREATION = [
  Country.Australia,
  Country.Canada,
  Country.Germany,
  Country.UnitedKingdom,
];

export const COUNTRIES_WHERE_THE_USER_CANNOT_SWITCH_BILLING = [
  Country.Canada,
  Country.Germany,
];

export const COUNTRIES_ELIGIBLE_FOR_APP_CHECKOUT = [
  Country.UnitedStates,
  Country.UnitedKingdom,
  Country.Canada,
  Country.Germany,
  Country.Australia,
];

export const COUNTRY_TO_ADDRESS_INFO_MAP_FOR_CREATE_FIRST_PARTY_DIGITAL_SUBSCRIPTION = {
  [Country.UnitedStates]: {
    shipping_postal_code: '60647',
  },
  [Country.UnitedKingdom]: {
    shipping_postal_code: 'SW18 4LZ',
  },
  [Country.Australia]: {
    shipping_postal_code: '3678',
  },
  [Country.Germany]: {
    shipping_postal_code: '50389',
  },
  [Country.Canada]: {
    shipping_postal_code: 'M3M 1G7',
    state: 'ON',
  },
};

export enum COUNTRY_TO_COMMERCETOOLS_PREFIX {
  Us = 'us-',
  Au = 'au-',
  Ca = 'ca-',
  De = 'de-',
  Uk = 'uk-',
  Gb = 'gb-',
}
export const MAP_COUNTRY_TO_COMMERCETOOLS_PREFIX = {
  [Country.UnitedStates]: COUNTRY_TO_COMMERCETOOLS_PREFIX.Us,
  [Country.Australia]: COUNTRY_TO_COMMERCETOOLS_PREFIX.Au,
  [Country.Canada]: COUNTRY_TO_COMMERCETOOLS_PREFIX.Ca,
  [Country.Germany]: COUNTRY_TO_COMMERCETOOLS_PREFIX.De,
  [Country.UnitedKingdom]: COUNTRY_TO_COMMERCETOOLS_PREFIX.Uk,
};

export const ADDRESS_FORMATS_FOR_SHOWROOMS_PAGE_DETAIL_ADDRESS_VIEW: Record<
  Country,
  string
> = {
  [Country.UnitedStates]: '{displayCity},{nbsp}{displayState}{nbsp}{displayPostalCode}',
  [Country.Canada]: '{displayCity},{nbsp}{displayState}{nbsp}{displayPostalCode}',
  [Country.UnitedKingdom]: '{displayCity}{nbsp}{displayPostalCode}',
  [Country.Germany]: '{displayPostalCode}{nbsp}{displayCity}',
  [Country.Austria]: '{displayPostalCode}{nbsp}{displayCity}', // TODO: update format
  [Country.Australia]: '{displayPostalCode}{nbsp}{displayCity}', // TODO: update format
};

export type SegmentKey =
  | CanadaSegmentKey
  | UKSegmentKey
  | USSegmentKey
  | GermanySegmentKey
  | AustraliaSegmentKey;

export enum USSegmentKey {
  Dev = 'Zo5hOgXEuFffq7hJAb2A9I7YU6hE9wZG',
  Staging = 'B5VGn9ACcaxBrHvr460jpYSDw4kIp5oY',
  Prod = 'uVsMX4gpbvPnCfGtPffjBXqFeQktc5bp',
}

export enum UKSegmentKey {
  Dev = 'w0fkDme0abdapGosCI1o948SZuz9FA1H',
  Staging = 'ioER2uhtXCI9nImaxSHTsJ2soDVjLp7E',
  Prod = 'RNztfSX4vF95gXLroOOVenDuF2uSYEk1',
}

export enum CanadaSegmentKey {
  Dev = 'd67V6s0QLszqmqAvZD09VyrQC4wBGkkI',
  Staging = '4uy13dweh8nxFlmxeB9YaeNskH7TEW2a',
  Prod = 'MqFPpOulFb9qibD0Qj5rQVKccPNMukm6',
}

export enum GermanySegmentKey {
  Dev = 'Drz1PrRsVv1wEL515IDVbQq5HCkUwqMd',
  Staging = 'IJdFXhxtRqZE5XWx1yvaRDuwFPfF7OkQ',
  Prod = 'kDc1tm2P9YautyYiubgJz49Gl32s1mDh',
}

export enum AustraliaSegmentKey {
  Dev = 'ZP9xRFODAh0XdcepHBVm7cfmfIyk7QTG',
  Staging = 'ZP9xRFODAh0XdcepHBVm7cfmfIyk7QTG',
  Prod = 'lmHotPrF0G0lchAXt1iZyERjoebQXinN',
}

export const COUNTRIES_EXCLUDING_TAX_FOR_ONEWELLNESS = [
  Country.UnitedKingdom,
  Country.Australia,
];

export const LOCALES_FOR_ADMIN_LEGAL_PAGES = [
  'en-US',
  'en-GB',
  'en-CA',
  'en-AU',
  'de-DE',
];

export const APOLLO_LOCALE_MAP = {
  'de-DE': '.de',
  'en-US': '.com',
  'en-GB': '.co.uk',
  'en-CA': '.ca',
  'en-AU': '.com.au',
  'de-AT': '.at',
};

// mapping of source TLDs to support locales
export const SUPPORT_LOCALES_FOR_WWW_SCRIPTS = {
  com: 'en-us',
  'co.uk': 'en-gb',
  ca: 'en-ca',
  de: 'de',
  'com.au': 'en-au',
  '.at': 'de-at',
};
// Skips Next-www static site generation
export const SKIP_NEXT_BUILD_LOCALES: Locale[] = [];

export type LocaleLanguagePrefix = 'en' | 'de';

export enum CommerceToolsChannelAvailability {
  AuWeb = 'au-web',
  CaWeb = 'ca-web',
  DeWeb = 'de-web',
  GbWeb = 'gb-web',
  UkWeb = 'uk-web',
  UsWeb = 'us-web',
}

export const MAP_WEB_TO_COMMERCE_TOOLS_CHANNELS = {
  [Store.Australia]: CommerceToolsChannelAvailability.AuWeb,
  [Store.Canada]: CommerceToolsChannelAvailability.CaWeb,
  [Store.Germany]: CommerceToolsChannelAvailability.DeWeb,
  [Store.UnitedKingdom]: CommerceToolsChannelAvailability.UkWeb,
  [Store.UnitedStates]: CommerceToolsChannelAvailability.UsWeb,
};

export const MAP_WEB_TO_TLD_FOR_COMMERCETOOLS_CHANNELS = {
  [Store.Australia]: '.com.au',
  [Store.Canada]: '.ca',
  [Store.Germany]: '.de',
  [Store.UnitedKingdom]: '.co.uk',
  [Store.UnitedStates]: '.com',
};
