import fetch from 'node-fetch';
import { WWW_BUILDER_URL } from '../utils/constants';

const fetchWWWBuilderData = async <T extends any = any>(functionPath: string) => {
  const url = `${WWW_BUILDER_URL}${functionPath}`;

  const res = await fetch(url);

  if (!res.ok) {
    console.error(res);
    throw new Error('Error response from builder');
  }

  return (await res.json()) as T;
};

export default fetchWWWBuilderData;
