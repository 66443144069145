import type { KeyboardEvent as ReactKeyboardEvent } from 'react';

/**
 * This module provides convenience helpers for determining which key was
 * pressed inside of a Keyboard Event handler.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 */

type Event = KeyboardEvent | ReactKeyboardEvent;

const WEBOS_ESCAPE = 461;

// Some older versions of browsers return different names for the same key.
// That's why some of these are arrays
const BACKSPACE = 'Backspace';
const ENTER = ['Enter', 13];
const SPACE = [' ', 'Spacebar'];
const TAB = 'Tab';
const DOWN_ARROW = ['ArrowDown', 'Down', 40];
const LEFT_ARROW = ['ArrowLeft', 'Left', 37];
const RIGHT_ARROW = ['ArrowRight', 'Right', 39];
const UP_ARROW = ['ArrowUp', 'Up', 38];
const ESCAPE = ['Esc', 'Escape', 'XF86Back', 27, WEBOS_ESCAPE];
const MEDIA_FAST_FORWARD = ['MediaFastForward', 417];
const MEDIA_PAUSE = ['MediaPause', 19];
const MEDIA_PLAY = ['MediaPlay', 415];
const MEDIA_PLAY_PAUSE = ['MediaPlayPause', 179];
const MEDIA_REWIND = ['MediaRewind', 412];

const checkKey = (key: string | number | undefined, keyNames: (string | number)[]) => {
  if (!key) {
    return false;
  }

  if (keyNames.length > 0) {
    return keyNames.includes(key);
  }
  return false;
};

const checkEvent = (event: Event, ...keyNames: (string | number)[]) => {
  const { key } = event;
  let code;
  if (event instanceof KeyboardEvent) {
    code = event.code;
  }
  return (
    checkKey(key, keyNames) ||
    checkKey(code, keyNames) ||
    checkKey(event?.keyCode, keyNames)
  );
};

// Whitespace keys
export const isBackspaceKey = (event: Event) => checkEvent(event, BACKSPACE);
export const isEnterKey = (event: Event) => checkEvent(event, ...ENTER);
export const isSpaceKey = (event: Event) => checkEvent(event, ...SPACE);
export const isTabKey = (event: Event) => checkEvent(event, TAB);

// Arrow keys
export const isDownArrowKey = (event: Event) => checkEvent(event, ...DOWN_ARROW);
export const isLeftArrowKey = (event: Event) => checkEvent(event, ...LEFT_ARROW);
export const isRightArrowKey = (event: Event) => checkEvent(event, ...RIGHT_ARROW);
export const isUpArrowKey = (event: Event) => checkEvent(event, ...UP_ARROW);
export const isArrowKey = (event: Event) =>
  checkEvent(event, ...DOWN_ARROW, ...LEFT_ARROW, ...RIGHT_ARROW, ...UP_ARROW);

// UI keys
export const isEscapeKey = (event: Event) => checkEvent(event, ...ESCAPE);

// Media keys
export const isMediaFastForward = (event: Event) =>
  checkEvent(event, ...MEDIA_FAST_FORWARD);
export const isMediaRewind = (event: Event) => checkEvent(event, ...MEDIA_REWIND);
export const isMediaPlayPause = (event: Event) => checkEvent(event, ...MEDIA_PLAY_PAUSE);
export const isMediaPlay = (event: Event) => checkEvent(event, ...MEDIA_PLAY);
export const isMediaPause = (event: Event) => checkEvent(event, ...MEDIA_PAUSE);
