export enum PlanType {
  Digital = 'digital',
  Device = 'device',
}

export enum Interval {
  Monthly = 'monthly',
  None = 'none',
}

export type SubscriptionPlan = {
  chargesOnFulfill: boolean;
  description: string;
  doesRollover: boolean;
  id: string;
  images: string[];
  interval: Interval;
  isTaxCalculated: boolean;
  name: string;
  offsetDays: number;
  planType: PlanType;
  price: number;
  requireAccessCode: boolean;
  rolloverSku: string;
  sku: string;
  slug: string;
  tax: number;
};

export type NormalizedSubscriptions = {
  entities: {
    plans: Record<string, SubscriptionPlan>;
  };
};
