import React from 'react';
import { hardenDashes, unorphanAll } from '@peloton/text';
import { FormattedMessage } from '@ecomm/internationalize';
import type { ID } from '@ecomm/models';

type Props = React.HTMLAttributes<HTMLAnchorElement> & {
  id: ID;
};

// TODO: make a more generic FormattedPhone component (for things like showrooms)
const Phone: React.FC<React.PropsWithChildren<Props>> = ({ id, ...props }) => (
  <FormattedMessage id={id}>
    {(phoneNumber: string) => (
      <a href={`tel:${phoneNumber}`} {...props}>
        {unorphanAll(hardenDashes(phoneNumber))}
      </a>
    )}
  </FormattedMessage>
);

export default Phone;
