import type { Exception } from '@ecomm/exceptions/Exception';

export enum Status {
  Failed = 'failed',
  Init = 'initial',
  Loaded = 'loaded',
  Loading = 'loading',
}

type LoadingState = { status: Status.Loading };
type FailedState = { status: Status.Failed; exception: Exception };
type LoadedState = { status: Status.Loaded };
type InitialState = { status: Status.Init };

export type UIState = LoadingState | FailedState | LoadedState | InitialState;

export type TransactionState = LoadingState | FailedState | InitialState;

export const toUIState = (status: Status, exception?: Exception): UIState => {
  switch (status) {
    case Status.Failed:
      if (!exception) {
        throw Error('Must provide an exception for a failure');
      }
      return { status, exception };

    case Status.Init:
      return { status };

    case Status.Loaded:
      return { status };

    case Status.Loading:
      return { status };

    default:
      throw Error('Unrecognized status') as never;
  }
};

export const hasException = (uiState: UIState): uiState is FailedState =>
  uiState.status === Status.Failed;

export const getException = (
  uiState: UIState | TransactionState | undefined,
): Exception | undefined =>
  uiState?.status === Status.Failed ? uiState.exception : undefined;

export const isInitial = (uiState: UIState): uiState is InitialState =>
  uiState.status === Status.Init;

export const isLoading = (uiState: UIState): uiState is LoadingState =>
  uiState.status === Status.Loading;

export const isLoaded = (uiState: UIState): uiState is LoadedState =>
  uiState.status === Status.Loaded;
