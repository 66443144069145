import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@ecomm/internationalize-ui';
import type { ShowroomProps } from '@ecomm/showrooms/model';
import { b5, reg } from '@ecomm/typography';

type Props = {
  className?: string;
  showrooms: ShowroomProps[];
  useHamburgerMenuStyles?: boolean;
};

const ShowroomLinks: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  showrooms,
  useHamburgerMenuStyles = false,
}) => {
  const { trackEvent } = useTracking();

  return (
    <LocationGrid className={className}>
      {showrooms.map(showroom => {
        const {
          displayName,
          displayCity,
          displayStreetAddress1,
          displayState,
          displayPostalCode,
          slug,
        } = showroom;
        const linkTo = `/showrooms/${slug}`;
        return (
          <StyledTrackedNavLink
            key={displayName}
            to={linkTo}
            href={linkTo}
            onClick={() => {
              trackEvent({
                properties: {
                  category: 'location',
                  linkTo,
                  linkName: displayName,
                  unitName: 'Showroom',
                },
              });
            }}
          >
            <>
              <BoldStyledParagraph useHamburgerMenuStyles={useHamburgerMenuStyles}>
                {displayName}
              </BoldStyledParagraph>
              <StyledParagraph useHamburgerMenuStyles={useHamburgerMenuStyles}>
                {displayStreetAddress1}
              </StyledParagraph>
              <StyledParagraph useHamburgerMenuStyles={useHamburgerMenuStyles}>
                {displayCity} {displayState} {displayPostalCode}
              </StyledParagraph>
            </>
          </StyledTrackedNavLink>
        );
      })}
    </LocationGrid>
  );
};

const LocationGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledTrackedNavLink = styled(Link)`
  display: block;
  width: 50%;

  span {
    text-transform: none;
  }

  &:after {
    display: none;
  }
`;

const BoldStyledParagraph = styled.p<{ useHamburgerMenuStyles: boolean }>`
  ${b5}
  ${reg}
  ${({ useHamburgerMenuStyles }) =>
    useHamburgerMenuStyles ? `color: ${grey[30]};` : ''};

  &:hover {
    color: ${({ useHamburgerMenuStyles }) =>
      useHamburgerMenuStyles ? `${grey[50]}` : `${grey[70]}`};
  }
`;

const StyledParagraph = styled.p<{ useHamburgerMenuStyles: boolean }>`
  ${b5}

  color: ${({ useHamburgerMenuStyles }) =>
    useHamburgerMenuStyles ? `${grey[50]}` : `${grey[70]}`};
`;

export default ShowroomLinks;
