import { combineReducers } from 'redux';
import type { AddedGiftCardData as giftCardsState } from '../redux/giftCards';
import { giftCardsReducer as giftCards } from '../redux/giftCards';
import type { ReducerState as BypassState } from './bypass';
import bypass from './bypass';
import type { ReducerState as DigitalState } from './digital';
import digital from './digital';
import type { MultiStepState } from './multiStep';
import multiStep from './multiStep';
import type { ReducerState as OverridePurchaseAnnualReducerState } from './overridePurchaseAnnual';
import overridePurchaseAnnual from './overridePurchaseAnnual';
import registerUser from './registerUser';
import type { ReducerState as RegisterUserState } from './registerUser';
import type { ReducerState as UserPasswordReducerState } from './saveUserPassword';
import userPassword from './saveUserPassword';
import type { ReducerState as UIState } from './ui';
import ui from './ui';

export type State = DigitalState &
  UIState &
  MultiStepState &
  BypassState &
  OverridePurchaseAnnualReducerState &
  UserPasswordReducerState &
  RegisterUserState &
  giftCardsState;

export default combineReducers({
  digital,
  ui,
  multiStep,
  bypass,
  overridePurchaseAnnual,
  userPassword,
  registerUser,
  giftCards,
});

export type ReducerState = {
  quickCheckout: State;
};
