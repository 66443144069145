// NOTE:
// this implementation of withApollo comes from Josh Navi's closed PR attempt: https://github.com/pelotoncycle/jupiter/pull/29184/files
import type { NormalizedCacheObject, ApolloLink } from '@apollo/client';
import {
  HttpLink,
  from,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import nextWithApollo from 'next-with-apollo';
import React from 'react';
import {
  USE_APOLLO_V3,
  HIDE_CONSOLE_LOGS,
  USE_APOLLO_V3_ECOPY,
} from '@peloton/app-config';
import { useOauth } from '@peloton/auth/OauthProvider';
import { createGraphqlHost } from '../pelotonApi';

const isServer = typeof window === 'undefined';

type Opts = {
  locale?: string;
  initialState?: NormalizedCacheObject;
  authLink?: ApolloLink;
  name?: string;
  connectToDevTools?: boolean;
};

// NOTE: IntrospectionFragmentMatcher was removed in Apollo V3
// Its functional replacement is possibleTypes in InMemoryCache
const possibleTypes = {
  CartItemNext: ['MembershipItem', 'ProductItem', 'CfuBundleItm', 'AccessoryBundleItem'],
};

export const toApolloClient = (opts: Opts) => () => {
  const cache = new InMemoryCache({
    typePolicies: {
      Catalog: {
        merge: true,
      },
    },
    possibleTypes,
  });
  if (opts.initialState) {
    cache.restore(opts.initialState);
  }

  const client = new ApolloClient({
    connectToDevTools: opts.connectToDevTools,
    name: opts.name,
    ssrMode: isServer, // Disables forceFetch on the server (so queries are only run once)
    link: from([
      onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
          console.log(`Operation leading to network error: ${operation.operationName}`);
          console.log(`Query/Mutation: ${operation.query.loc?.source.body}`);
          console.log(`Variables: ${JSON.stringify(operation.variables)}`);
        }
      }),
      ...(opts.authLink ? [opts.authLink] : []),
      new HttpLink({
        uri: createGraphqlHost(opts.locale ? opts.locale : 'en-US'),
        credentials: 'include',
      }),
    ]),
    cache,
  });
  if (typeof window !== 'undefined' && USE_APOLLO_V3_ECOPY) {
    (window as any).__printCopy = () => client.extract();
  }
  return client;
};

// NOTE: this provider is used for slow migrations to Apollo Client V3
// @ts-expect-error
export const ApolloProviderV3 = ({ children, useApolloV3, locale }) => {
  const { isAuthenticated, getAccessTokenSilently } = useOauth();

  if (useApolloV3 !== true) {
    return <>{children}</>;
  }
  // APOLLO V3 is off for Prod so this console should only appear on stage
  if (USE_APOLLO_V3 && !HIDE_CONSOLE_LOGS) {
    console.log('Apollo V3 Provider is active');
  }

  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();

      if (token) {
        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        };
      }
    }

    return {
      headers,
    };
  }) as ApolloLink;

  const client = toApolloClient({ locale, authLink })();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const ApolloProviderComponent = ({ Page, props }: any) => (
  <ApolloProvider client={props.apollo}>
    <Page {...props} />
  </ApolloProvider>
);

const withApollo = (opts: Opts) =>
  // as any bcause next-with-apollo only works for AC2
  nextWithApollo(toApolloClient(opts) as any, {
    render: ApolloProviderComponent,

    onError: (error: any) => {
      throw error;
    },
  });

export default withApollo;
