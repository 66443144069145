import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

const useResizeRefWatch = (
  ref: MutableRefObject<HTMLDivElement | null>,
  setter: (ref: HTMLDivElement | null) => void,
) => {
  const resizeTimer = useRef<number | undefined>();
  useEffect(() => {
    // Offset action execution by 250ms so its not firing throughout the resize
    const handleResize = () => {
      clearTimeout(resizeTimer.current);
      resizeTimer.current = window.setTimeout(() => {
        setter(ref.current);
      }, 250);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimer.current);
    };
  }, []);
};

export default useResizeRefWatch;
