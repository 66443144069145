import type { RouterState } from 'connected-react-router';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import type { PropsOf } from '@peloton/react';

// Handles connected router issue, where browser 'back' may not work as expected
// due to app updating before location has updated in the redux store.
// Implements solution suggested by react router maintainers: see react-router issue #5072.

type OwnProps = PropsOf<Switch>;

const mapStateToProps = (state: { router: RouterState }, ownProps: OwnProps) => ({
  location: ownProps.location || state.router.location,
});

const ConnectedSwitch = connect(mapStateToProps)(Switch);

export default ConnectedSwitch;
