import { schema } from 'normalizr';
import { toBundleType } from '@ecomm/shop/api/schemas/bundle';
import productOption from '@ecomm/shop/api/schemas/option';
import type { ApiBundleOption } from '../types';
import couponSchema from './coupon';

const processBundleOption = ({
  coupon,
  description,
  grossInCents: price,
  id,
  imageUrls: images,
  displayName: name,
  productOptions,
  quantity,
  slug,
}: ApiBundleOption) => ({
  bundleType: toBundleType(productOptions),
  coupon,
  description,
  id,
  images,
  name,
  price,
  productOptions,
  quantity,
  slug,
});

const bundleOption = new schema.Entity(
  'bundleOptions',
  { productOptions: [productOption], coupon: couponSchema },
  { processStrategy: processBundleOption },
);

export default bundleOption;
