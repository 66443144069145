import {
  CONTENTFUL_WWW_PROD_TOKEN,
  CONTENTFUL_WWW_PREVIEW_TOKEN,
} from '@peloton/app-config';
import { isProdEnv, isProdTestEnv } from '@peloton/env/models';
const getContentfulAccessToken = (appName: string = 'www'): string => {
  const PREVIEW =
    CONTENTFUL_WWW_PREVIEW_TOKEN ?? process.env.CONTENTFUL_WWW_PREVIEW_TOKEN;
  const PROD = CONTENTFUL_WWW_PROD_TOKEN ?? process.env.CONTENTFUL_WWW_PROD_TOKEN;

  if (PREVIEW && !isProdEnv(appName) && !isProdTestEnv(appName)) {
    return PREVIEW;
  }

  if (!PROD) {
    throw new Error('CONTENTFUL_WWW_PROD_TOKEN missing');
  }

  return PROD;
};

export const toContentfulAccessToken = getContentfulAccessToken;

export const CONTENTFUL_TOKEN = getContentfulAccessToken();
