import { toV2ConfigSchema } from '@peloton/auth/oauthConfigSchema';
import { OauthEnvironment } from '@peloton/env/oauthEnv';

const oauthConfigMap = {
  [OauthEnvironment.Dev]: {
    domain: 'auth-dev.onepeloton.com',
    audience: 'https://api-dev.onepeloton.com/',
    api: 'api-api-core.dev.k8s.pelotime.com',
    client_id: 'gLNMvB9T9TDT75KS8jx5YkFmMm9PZWmB',
  },
  [OauthEnvironment.Stage]: {
    domain: 'auth-stage.onepeloton.com',
    audience: 'https://api-stage.onepeloton.com/',
    api: 'qa1.onepeloton.com',
    client_id: 'eRy8es06WOrcQGhcLxy8HlVxwrqUa117',
  },
  [OauthEnvironment.Test]: {
    domain: 'auth-test.onepeloton.com',
    audience: 'https://api-test.onepeloton.com/',
    api: 'qa2.onepeloton.com',
    client_id: '<client-id>',
  },
  [OauthEnvironment.Prod]: {
    domain: 'auth.onepeloton.com',
    audience: 'https://api.onepeloton.com/',
    api: 'api.onepeloton.com',
    client_id: 'q6lqsS8VoP0OzCNJ5PmbglDGkOD7NxxV',
  },
};

export const toOauthConfig = (env: OauthEnvironment) => {
  const oauthConfigEnv = oauthConfigMap[env] ?? oauthConfigMap[OauthEnvironment.Dev];
  const oauthConfig = toV2ConfigSchema(oauthConfigEnv);
  return oauthConfig;
};
