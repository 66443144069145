import { takeEvery, put, all, getContext, select } from 'redux-saga/effects';
import type { User } from '@peloton/auth/models';
import { isInternalEcommGroupMember } from '@peloton/auth/models';
import type { A } from '@peloton/auth/redux';
import { UserReducerActionType } from '@peloton/auth/redux';
import type { EnvironmentFlags } from '@peloton/env/models';
import { getEnvFlags } from '@peloton/env/redux';
import { toLocale } from '@peloton/internationalize';
import type { DataFiles } from '@ecomm/feature-toggle/optimizely';
import { OPTIMIZELY_FEATURE_CONTEXT, toFeatures } from '@ecomm/feature-toggle/optimizely';
import { switchToggle } from './redux';

export const updateOptimizelyTogglesSaga = function* (
  action: A<UserReducerActionType.REQUEST_SUCCESS, User>,
) {
  const user: User = action.payload;
  const isEcommTester = Boolean(user) && isInternalEcommGroupMember(user);

  // if the user isn't an ecomm admin, we have no reason to re-process
  // the feature toggles. Better to avoid the redux dispatchers
  if (!isEcommTester) return;

  const optimizelyData: DataFiles = yield getContext(OPTIMIZELY_FEATURE_CONTEXT);
  const envFlags: EnvironmentFlags = yield select(getEnvFlags);

  const optimizelyFeatures = toFeatures(
    {
      locale: toLocale(),
      isEcommTester,
      email: user.kind !== 'GUEST' ? user.email : undefined,
    },
    optimizelyData,
    envFlags.isLocal
      ? 'dev'
      : envFlags.isProd || envFlags.isStaging
      ? 'production'
      : 'uat',
  );
  const switchToggleCalls = Object.entries(
    optimizelyFeatures,
  ).map(([feature, { active }]) => put(switchToggle(feature, active)));
  yield all(switchToggleCalls);
};

export const sagas = function* () {
  yield takeEvery(UserReducerActionType.REQUEST_SUCCESS, updateOptimizelyTogglesSaga);
};
