import React from 'react';
import type { ExperimentAndVariation } from '@peloton/split-testing';
import type { PromoBanner } from '@ecomm/copy';
import { useOptimizelyPromoBannerData } from '@ecomm/copy';
import { usePromoBannerCollectionQueryQuery } from '@ecomm/copy/queries/contentCollections/promoBannerCollectionQuery.generated';
import { useAllOtdEstimates } from '@ecomm/otd';
import DriftInteractionBanner from './DriftInteractionBanner';
import DualCtaBanner from './DualCtaBanner';
import type { BannerPropsDriftCta, BannerPropsCta, OtdEstimates } from './models';
import SingleCtaBanner from './SingleCtaBanner';
import UnclickableBanner from './UnclickableBanner';

type Props = {
  cmsId: PromoBanner;
  dataTestIdPrefix: string;
  experimentsAndVariations: ExperimentAndVariation[];
};

const CmsPromoBanner: React.FC<React.PropsWithChildren<Props>> = ({
  cmsId,
  dataTestIdPrefix,
  experimentsAndVariations = [],
}) => {
  const allPromoBanners = usePromoBannerCollectionQueryQuery({ variables: {} });
  const bannerData = useOptimizelyPromoBannerData(
    experimentsAndVariations,
    cmsId,
    allPromoBanners,
  );

  const estimates = useAllOtdEstimates();

  const bannerProps: BannerPropsDriftCta | BannerPropsCta = {
    ...bannerData,
    dataTestIdPrefix,
    estimates: transformEstimatesForConsumers(estimates),
  };

  if (bannerProps.kind === 'CtaWithDriftInteraction') {
    return <DriftInteractionBanner {...bannerProps} />;
  }

  switch (bannerProps.links?.length) {
    case 2:
      return <DualCtaBanner {...bannerProps} />;
    case 1:
      return <SingleCtaBanner {...bannerProps} />;
    default:
      return <UnclickableBanner {...bannerProps} />;
  }
};

function transformEstimatesForConsumers(estimates: OtdEstimates) {
  return {
    bikeEstimate: estimates.bike,
    bikePlusEstimate: estimates.bikePlus,
    treadEstimate: estimates.tread,
    treadPlusEstimate: estimates.treadPlus,
  };
}

export default CmsPromoBanner;
