import * as oneWellnessBenefits from './1wBenefits';
import * as account from './account';
import * as digital from './digital';
import * as members from './members';
import * as misc from './misc';
import * as social from './social';
import * as stores from './stores';
import * as studio from './studio';
import * as support from './support';
import * as www from './www';
import * as wwwPrivateBike from './wwwPrivate';
import * as wwwPrivateTread from './wwwPrivateTread';

const Links = {
  ...account,
  ...digital,
  ...misc,
  ...oneWellnessBenefits,
  ...social,
  ...studio,
  ...stores,
  ...support,
  ...www,
  ...wwwPrivateBike,
  ...wwwPrivateTread,
  ...members,
};

export { Links };
