import { omit } from 'ramda';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  PromoFieldUpdated = 'ecomm/checkout/promo/FIELD_UPDATED',
  PromoFieldBlurred = 'ecomm/checkout/promo/FIELD_BLURRED',
  ApplyPromo = 'ecomm/checkout/promo/APPLY_REQUEST',
  ApplyPromoSuccess = 'ecomm/checkout/promo/APPLY_SUCCESS',
  ApplyPromoFailure = 'ecomm/checkout/promo/APPLY_FAILURE',
}

export type PromoState = {
  value: string;
  error?: string;
  ui: UIState;
};

export const defaultState = {
  value: '',
  ui: toUIState(Status.Init),
};

const reducer = (state: PromoState = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.PromoFieldBlurred:
    case ActionTypes.PromoFieldUpdated: {
      const { value } = action.payload;
      return {
        ...state,
        ui: toUIState(Status.Init),
        value,
      };
    }

    case ActionTypes.ApplyPromo: {
      return {
        ...state,
        ui: toUIState(Status.Loading),
      };
    }

    case ActionTypes.ApplyPromoSuccess: {
      return {
        ...omit(['error'], state),
        ui: toUIState(Status.Loaded),
      };
    }

    case ActionTypes.ApplyPromoFailure: {
      return {
        ...state,
        value: '',
        ui: toUIState(Status.Init),
        error: action.payload.errorMsg,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  promo: PromoState;
};

export const blurPromoField = (value: string) =>
  <const>{
    type: ActionTypes.PromoFieldBlurred,
    payload: { value },
  };

export const updatePromoField = (value: string) =>
  <const>{
    type: ActionTypes.PromoFieldUpdated,
    payload: { value },
  };

export const applyPromo = (subscriptionSku: string) =>
  <const>{
    type: ActionTypes.ApplyPromo,
    payload: { subscriptionSku },
  };

export const applyPromoSuccess = () =>
  <const>{
    type: ActionTypes.ApplyPromoSuccess,
  };

export const applyPromoFailure = (errorMsg: string) =>
  <const>{
    type: ActionTypes.ApplyPromoFailure,
    payload: { errorMsg },
  };

export type BlurPromoFieldAction = ReturnType<typeof blurPromoField>;

export type UpdatePromoFieldAction = ReturnType<typeof updatePromoField>;

export type ApplyPromoAction = ReturnType<typeof applyPromo>;

export type ApplyPromoSuccessAction = ReturnType<typeof applyPromoSuccess>;

export type ApplyPromoFailureAction = ReturnType<typeof applyPromoFailure>;

type Action =
  | BlurPromoFieldAction
  | UpdatePromoFieldAction
  | ApplyPromoAction
  | ApplyPromoSuccessAction
  | ApplyPromoFailureAction;
