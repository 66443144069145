import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import type { EnvironmentFlags } from '@peloton/env';
import { ApiEnvs } from '@peloton/external-links';
import { Locale } from '@peloton/internationalize';
import { getKeys } from '@ecomm/vendor-keys';
import type { OneWellnessClient } from './OneWellnessClient';

export const CLIENT_CONTEXT = 'oneWellnessGraphQLClient';

export const toOneWellnessClient = (
  apiEnv: ApiEnvs,
  flags: EnvironmentFlags,
): OneWellnessClient => {
  const isProd = apiEnv === ApiEnvs.Prod;

  // Make sure uat-prod and api.test uses Prod env and that UAT uses Staging env.
  const oneWellnessEnvFlags: EnvironmentFlags = {
    ...flags,
    isProd,
  };

  const oneWellnessKeys = getKeys({ locale: Locale.Default, ...oneWellnessEnvFlags })
    .oneWellness;

  const envKeys = isProd ? oneWellnessKeys.prod : oneWellnessKeys.staging;

  const headers = {
    Authorization: `Bearer ${envKeys.token}`,
  };

  envKeys.id && (headers['CF-Access-Client-Id'] = envKeys.id);
  envKeys.secret && (headers['CF-Access-Client-Secret'] = envKeys.secret);

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers,
    });

    if (forward) {
      return forward(operation);
    } else {
      return null;
    }
  });

  const uri = isProd
    ? 'https://gateway.benefits.onepeloton.com/api/graphql'
    : 'https://cerberus-1w.stage.k8s.onepeloton.com/api/graphql';

  const host = new HttpLink({
    uri,
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: authMiddleware.concat(host),
    name: 'jupiter',
    version: '1.0.0',
  });
};
