const mapToView = (content: any) => {
  const baseContent = content['rentalCfuPrices'];

  if (!baseContent) {
    return {
      BIKE: {
        setup: 0,
        monthly: 0,
        buyout: 0,
      },
      BIKE_PLUS: {
        setup: 0,
        monthly: 0,
        buyout: 0,
      },
    };
  }

  return {
    BIKE: {
      setup: parseInt(baseContent['bikeRentalSetupPrice'].value),
      monthly: parseInt(baseContent['bikeRentalMonthlyPrice'].value),
      buyout: parseInt(baseContent['bikeRentalBuyoutPrice'].value),
    },
    BIKE_PLUS: {
      setup: parseInt(baseContent['bikePlusRentalSetupPrice'].value),
      monthly: parseInt(baseContent['bikePlusRentalMonthlyPrice'].value),
      buyout: parseInt(baseContent['bikePlusRentalBuyoutPrice'].value),
    },
  };
};

export default mapToView;
