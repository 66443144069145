import { useEffect, useState } from 'react';
import { geoDataLacksCountryAndState, isLocatedInProtectedState } from './utils';

type UseLocationReturn = {
  loading: boolean;
  isInProtectedState: boolean | null;
  geoData?: { country: string; state: string };
};

export function useLocation(): UseLocationReturn {
  const [loading, setLoading] = useState<boolean>(true);
  const [isInProtectedState, setIsInProtectedState] = useState<boolean | null>(null);
  const [geoData, setGeoData] = useState<
    { country: string; state: string } | undefined
  >();

  useEffect(() => {
    const getOneTrustLocation = async () => {
      const { __promiseHooks } = window as any;
      try {
        const oneTrustGeoData = await __promiseHooks.oneTrustLoaderPromise;
        setLoading(false);
        if (geoDataLacksCountryAndState(oneTrustGeoData)) {
          throw new Error('OneTrust location data lacks country and state');
        }
        setGeoData(oneTrustGeoData);
        if (oneTrustGeoData) {
          setIsInProtectedState(isLocatedInProtectedState(oneTrustGeoData));
        }
      } catch (e) {
        setLoading(false);
        console.error('Error getting OneTrust location', e);
        setIsInProtectedState(null);
      }
    };
    getOneTrustLocation();
  }, []);

  return { loading, isInProtectedState, geoData };
}
