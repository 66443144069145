const mapToView = (content: any) => ({
  appleButtonText: content['sso']['checkout.SSO.buttons.apple'],
  googleButtonText: content['sso']['checkout.SSO.buttons.google'],
  emailButtonText: content['sso']['checkout.SSO.buttons.email'],
  continuetoCheckoutLegend: content['sso']['checkout.SSO.legend'],
  orText: content['sso']['checkout.SSO.orText'],
  accountExistsModalHeading: content['sso']['socialLinking.accountExists'],
  socialLinkingErrors10502: content['sso']['socialLinking.errors.errorCode.10502'],
  socialLinkingErrors10503: content['sso']['socialLinking.errors.errorCode.10503'],
  socialLinkingErrors20001: content['sso']['socialLinking.errors.errorCode.20001'],
  socialLinkingErrors20101: content['sso']['socialLinking.errors.errorCode.20101'],
  socialLinkingErrors20102: content['sso']['socialLinking.errors.errorCode.20102'],
  socialLinkingErrors20103: content['sso']['socialLinking.errors.errorCode.20103'],
  socialLinkingErrors20104: content['sso']['socialLinking.errors.errorCode.20104'],
  socialLinkingErrors20105: content['sso']['socialLinking.errors.errorCode.20105'],
  socialLinkingErrors20106: content['sso']['socialLinking.errors.errorCode.20106'],
  socialLinkingErrors20107: content['sso']['socialLinking.errors.errorCode.20107'],
  socialLinkingErrors20109: content['sso']['socialLinking.errors.errorCode.20109'],
  socialLinkingErrors20999: content['sso']['socialLinking.errors.errorCode.20999'],
  socialLinkingErrors20110: content['sso']['socialLinking.errors.errorCode.20110'],
  socialLinkingErrors20111: content['sso']['socialLinking.errors.errorCode.20111'],
  socialLinkingErrors20601: content['sso']['socialLinking.errors.errorCode.20601'],
  socialLinkingErrors20602: content['sso']['socialLinking.errors.errorCode.20602'],
  socialLinkingErrors20701: content['sso']['socialLinking.errors.errorCode.20701'],
  socialLinkingErrors20702: content['sso']['socialLinking.errors.errorCode.20702'],
});

export default mapToView;
