import { decamelizeKeys } from 'humps';
import {
  adjust,
  startsWith,
  split,
  endsWith,
  compose,
  allPass,
  keys,
  partition,
  pick,
  fromPairs,
  map,
  toPairs,
  without,
  join,
} from 'ramda';
import { titleize } from '@peloton/text';

const decamelizeWithSpaces = (obj: Object) => decamelizeKeys(obj, { separator: ' ' });

const renameKeys = (fn: (key: string) => string) =>
  compose(fromPairs, map<any[], any[]>(adjust(fn, 0)), toPairs);

export const toSegmentReadableKeys = compose<Object, Object, Object>(
  renameKeys(titleize),
  decamelizeWithSpaces,
);

/**
 * Creates an object of key/value pairs that are human readable for analytics consumption.
 * It will respect a special delimiter to ignore transformation and return keys as-is.
 *
 * @param {object} properties - The analytics properties to be transformed.
 * @param {string} [exactStart='['] - Delimiter that marks the start of a exact string (e.g. '[').
 * @param {string} [exactEnd=']'] - Delimiter that marks the end of a exact string (e.g. ']').
 */
export const toAnalyticsKeysWithOverrides = (
  properties: { [k: string]: string },
  exactStart: string = '[',
  exactEnd: string = ']',
) => {
  const [exactKeys, standardKeys] = compose<Object, string[], string[][]>(
    partition(isExactAnalyticsKey(exactStart, exactEnd)),
    keys,
  )(properties);

  return {
    ...toSegmentReadableKeys(pick(standardKeys, properties)),
    ...toExactKeys(exactStart, exactEnd)(pick(exactKeys, properties)),
  };
};

const isExactAnalyticsKey = (start: string, end: string) =>
  allPass([startsWith(start), endsWith(end)]);
const toAnalyticsString = (start: string, end: string) =>
  compose(join(''), without([start, end]), split(''));
const toExactKeys = (start: string, end: string) =>
  compose<
    { [k: string]: string },
    Array<[string, string]>,
    Array<[string, string]>,
    { [k: string]: string }
  >(
    fromPairs,
    map<string[], [string, string]>(([key, value]) => {
      const analyticsString: string = toAnalyticsString(start, end)(key);
      return [analyticsString, value];
    }),
    toPairs,
  );
