import { BreakpointWidth } from '@peloton/styles';

type AnalyticsDeviceCategory = 'Desktop' | 'Tablet' | 'Handset';

export const toDeviceCategoryFromWindow = (
  width = window.innerWidth,
): AnalyticsDeviceCategory => {
  if (width >= BreakpointWidth.desktop) {
    return 'Desktop';
  } else if (width >= BreakpointWidth.tablet) {
    return 'Tablet';
  } else {
    return 'Handset';
  }
};
