import type { PeloLink } from '@peloton/external-links';
import { toWWWLink, toBusinessLink } from '@peloton/external-links';
import type { Audience } from '@onewellness/routes';

export const benefitsTerms = toWWWLink('/benefits/terms-of-service');

export const BenefitsWelcome = (audience: Audience): PeloLink =>
  toWWWLink(`/benefits/${audience}/get-set-up`);

export const benefitAddedParam = 'benefit_added=success';

const toOptionsPageUrl = (audience: Audience) => `/benefits/${audience}/options`;
export const OptionsPage = (audience: Audience): PeloLink =>
  toWWWLink(toOptionsPageUrl(audience));
export const BenefitsAccountAdded = (audience: Audience): PeloLink =>
  toWWWLink(`${toOptionsPageUrl(audience)}?success=true`);

export const corporateBenefitsProgramTerms = toWWWLink(
  '/corporate-wellness/program-terms',
);
export const corporateBenefitsProgramTermsUk = toWWWLink(
  '/corporate-wellness/program-terms-uk',
);

export const corporateWellness = toBusinessLink('/solutions/corporate-wellness');
