import type { IBrowser as ResponsiveState } from 'redux-responsive';
import { createResponsiveStateReducer } from 'redux-responsive';
import { isTouch } from './isTouch';

export default createResponsiveStateReducer(
  {
    // Redux-responsive only defines breakpoints as 'max-width', the inverse of us.
    // This is hidden from us when we select from the store, but the config is different here.
    mobile: 379,
    smallScreen: 579,
    tablet: 639,
    tabletLarge: 767,
    tabletXLarge: 799,
    desktop: 1023,
    desktopLarge: 1279,
    desktopXLarge: 1919,
  },
  {
    extraFields: () => ({
      isTouch,
      // Note: This value will NOT update unless a state change occurs.
      windowHeight: typeof window === 'undefined' ? 0 : window.innerHeight,
    }),
  },
);

export const getIsBreakpointGreaterThan = (
  state: ResponsiveSelectorState,
  breakpoint: Breakpoint,
) => state.browser.greaterThan[breakpoint];

export const getIsBreakpointLessThan = (
  state: ResponsiveSelectorState,
  breakpoint: Breakpoint,
) => state.browser.lessThan[breakpoint];

export const getIsBreakpointAt = (
  state: ResponsiveSelectorState,
  breakpoint: Breakpoint,
) => state.browser.is[breakpoint];

export const getIsTouch = (state: ResponsiveSelectorState) => state.browser.isTouch;

export const getMediaType = (state: ResponsiveSelectorState) => state.browser.mediaType;

export const getBreakpoints = (state: ResponsiveSelectorState) =>
  state.browser.breakpoints;

export type Breakpoint =
  | 'mobile'
  | 'smallScreen'
  | 'tablet'
  | 'tabletLarge'
  | 'tabletXLarge'
  | 'desktop'
  | 'desktopLarge'
  | 'desktopXLarge'
  | 'infinity';

export type ResponsiveSelectorState = {
  browser: ResponsiveState<Breakpoint> & ExtraFields;
};
type ExtraFields = { isTouch: boolean; windowHeight: number };
