import React, { createContext, useContext } from 'react';
import { Locale, toLocaleFromPath, toLocaleFromTLD } from './models/locale';

/**
 * The toLocaleFromTLD function, which has been widely utilized across the system,
 * will now serve as the fall-back locale provider.
 * Should an explicit provider not be specified,
 * the context will default to using the toLocaleFromTLD function's output,
 * leveraging its inherent default value.
 */
const DEFAULT_LOCALE = Locale.EnglishUnitedStates;

export const LocaleContext: React.Context<{ locale: Locale }> = createContext<{
  locale: Locale;
}>({
  locale: DEFAULT_LOCALE,
});

export interface LocaleProviderProps {
  envLocale?: Locale;
}

export const LocaleProvider: React.FC<React.PropsWithChildren<LocaleProviderProps>> = ({
  children,
  envLocale,
}) => (
  <LocaleContext.Provider value={{ locale: envLocale || DEFAULT_LOCALE }}>
    {children}
  </LocaleContext.Provider>
);

export const useLocale = (): Locale => useContext(LocaleContext).locale;

export type I18nSupportType = 'tld' | 'sub-path';

const toEnvLocale = (envSource: I18nSupportType) => {
  switch (envSource) {
    case 'tld':
      return toLocaleFromTLD();
    case 'sub-path':
      return toLocaleFromPath();
  }
};

export const withLocale = <T extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
  LocaleProviderComponent: React.ComponentType<
    React.PropsWithChildren<LocaleProviderProps>
  >,
  envSource: I18nSupportType,
): React.ComponentType<React.PropsWithChildren<T>> => {
  const envLocale = toEnvLocale(envSource);
  // eslint-disable-next-line react/display-name
  return (props: T) => (
    <LocaleProviderComponent envLocale={envLocale}>
      <Component {...props} />
    </LocaleProviderComponent>
  );
};
