import { compose, propOr } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, put, getContext, select } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError, ErrorType } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { PaymentMethod } from '@ecomm/models';
import type { PaymentsOverrideConfiguration } from '@ecomm/quick-checkout/models';
import { selectStripeAccountKey, selectVendorKeys } from '@ecomm/vendor-keys';
import { createSetupIntent, createSetupIntentWithCaptcha } from '../api';
import PaymentErrorMap from '../stripe/PaymentErrorMap';
import type { PaymentGateway } from '../stripe/PaymentGateway';

export type SetupIntentOptions = {
  paymentGateway: PaymentGateway;
  paymentsOverride?: PaymentsOverrideConfiguration;
  postalCode: string;
  recaptchaToken?: string;
};

export const createSetupIntentSaga = function* ({
  paymentGateway: { handleCardSetup, confirmCardSetup },
  paymentsOverride = {},
  postalCode,
  recaptchaToken,
}: SetupIntentOptions): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const stripeAccount = yield select(toStripeAccount);
  try {
    const { clientKey } = recaptchaToken
      ? yield call(createSetupIntentWithCaptcha, client, stripeAccount, recaptchaToken)
      : yield call(createSetupIntent, client, stripeAccount);

    const paymentMethod = paymentsOverride.paymentMethod || PaymentMethod.Unknown;
    const paymentRequestOptions = [PaymentMethod.ApplePay, PaymentMethod.GooglePay];

    let handleCardSetupOpts = {};
    let setupIntentResult;

    if (
      paymentRequestOptions.includes(paymentMethod) &&
      paymentsOverride.paymentMethodId
    ) {
      setupIntentResult = yield call(confirmCardSetup, clientKey, {
        payment_method: paymentsOverride.paymentMethodId,
      });
    } else {
      handleCardSetupOpts = {
        payment_method_data: {
          billing_details: { address: { postal_code: postalCode } },
        },
      };
      setupIntentResult = yield call(handleCardSetup, clientKey, handleCardSetupOpts);
    }

    const { setupIntent, error } = setupIntentResult;

    if (error) {
      throw error;
    } else {
      return yield setupIntent as any;
    }
  } catch (err) {
    const errorCode: string = propOr('default', err.code, PaymentErrorMap);
    const error = new DomainError(`Error setting up Stripe intent: ${errorCode}`, {
      ...err,
      name: ErrorType.SetupIntent,
    });
    yield put(reportError({ error }));
    throw err;
  }
};

export const toStripeAccount = compose(selectStripeAccountKey, selectVendorKeys);
