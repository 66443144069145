import type { Reducer } from 'redux';
import type { OrderData } from '@ecomm/checkout';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { Money, PaymentMethod } from '@ecomm/models';
import type { Order } from '@ecomm/order/models';
import type { PaymentGateway, PaymentToken } from '@ecomm/payment';
import { getInitialExeption } from '../api/exceptions';
import type { OrderType } from '../models';

export type State = {
  order?: Order;
  isLoading: boolean;
  exception?: Exception;
};

const defaultState: State = {
  isLoading: false,
  exception: getInitialExeption(),
};

export type ReviewOrderPayload = {
  kind: OrderType;
  token?: PaymentToken;
  captchaToken?: string;
  paymentGateway?: PaymentGateway;
  orderData: OrderData;
  method: PaymentMethod;
  paymentMethod?: stripe.paymentMethod.PaymentMethod;
  cartTotal: Money;
  isQuickPayCheckoutEnabled?: boolean;
  onSuccess?(): void;
  onError?(error: any): void;
};

export type ReviewWalletOrderPayload = {
  kind: OrderType;
  paymentGateway?: PaymentGateway;
  captchaToken?: string;
  orderData: OrderData;
  method: PaymentMethod;
  paymentMethod: stripe.paymentMethod.PaymentMethod;
  cartTotal: Money;
  onSuccess(): void;
  onError(error: any): void;
};

// Actions
export type ReviewOrderAction = {
  type: Actions.ReviewOrder;
  payload: ReviewOrderPayload;
};
export type ReviewWalletOrderAction = {
  type: Actions.ReviewWalletOrder;
  payload: ReviewWalletOrderPayload;
};
export type ReviewOrderSuccessAction = {
  type: Actions.ReviewOrderSuccess;
  payload: { kind: OrderType };
};
export type ReviewOrderFailureAction = {
  type: Actions.ReviewOrderFailure;
  payload: Exception;
};
export type ReviewOrderClearErrorAction = {
  type: Actions.ReviewOrderClearError;
};

export enum Actions {
  ReviewWalletOrder = 'ecomm/checkout/REVIEW_WALLET_ORDER',
  ReviewOrder = 'ecomm/checkout/REVIEW_ORDER',
  ReviewOrderSuccess = 'ecomm/checkout/REVIEW_ORDER_SUCCESS',
  ReviewOrderFailure = 'ecomm/checkout/REVIEW_ORDER_FAILURE',
  ReviewOrderClearError = 'ecomm/checkout/REVIEW_ORDER_CLEAR_ERROR',
}

export type Action =
  | ReviewOrderAction
  | ReviewOrderSuccessAction
  | ReviewOrderFailureAction
  | ReviewOrderClearErrorAction;

// Reducer
const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.ReviewOrder:
      return {
        isLoading: true,
      };
    case Actions.ReviewOrderSuccess:
      return {
        isLoading: false,
      };
    case Actions.ReviewOrderClearError:
      return {
        ...state,
        exception: undefined,
      };
    case Actions.ReviewOrderFailure:
      return {
        isLoading: false,
        exception: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  reviewOrder: State;
};

// Action Creators
export const reviewOrder = (data: ReviewOrderPayload): ReviewOrderAction => ({
  type: Actions.ReviewOrder,
  payload: data,
});

export const reviewWalletOrder = (
  data: ReviewWalletOrderPayload,
): ReviewWalletOrderAction => ({
  type: Actions.ReviewWalletOrder,
  payload: data,
});

export const reviewOrderSuccess = (orderType: OrderType): ReviewOrderSuccessAction => ({
  type: Actions.ReviewOrderSuccess,
  payload: { kind: orderType },
});

export const reviewOrderFailure = (error: Error): ReviewOrderFailureAction => ({
  type: Actions.ReviewOrderFailure,
  payload: toException(`checkout.errors.order.${error.message}`),
});

export const reviewOrderClearError = (): ReviewOrderClearErrorAction => ({
  type: Actions.ReviewOrderClearError,
});

// Selectors
export const loadingSelector = (state: State): boolean => state.isLoading;

export const exceptionSelector = (state: State): Exception | undefined => state.exception;
