export enum ActionType {
  UpdateKey = 'ecomm/vendorKeys/UPDATED_API_KEY',
}

export enum Vendor {
  StripeAccount = 'stripeAccount',
  StripeApi = 'stripeApi',
  Drift = 'drift',
  Recaptcha = 'recaptcha',
}

export const defaultState: State = {
  [Vendor.StripeAccount]: '',
  [Vendor.StripeApi]: '',
  [Vendor.Drift]: '',
  [Vendor.Recaptcha]: '',
};

const reducer = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateKey: {
      const vendor = action.payload.vendor;
      const key = action.payload.key;

      if (!!key) {
        return {
          ...state,
          [vendor]: key,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default reducer;

export const updateVendorKey = (vendor: Vendor, key: string | null): UpdateKeyAction => ({
  type: ActionType.UpdateKey,
  payload: { vendor, key },
});

const toSelectKey = (vendor: Vendor) => (state: State): string => state[vendor];

export const selectStripeApiKey = toSelectKey(Vendor.StripeApi);
export const selectStripeAccountKey = toSelectKey(Vendor.StripeAccount);
export const selectDriftKey = toSelectKey(Vendor.Drift);
export const selectRecaptchaKey = toSelectKey(Vendor.Recaptcha);

export type State = Record<Vendor, string>;

export type UpdateKeyAction = {
  type: ActionType.UpdateKey;
  payload: {
    vendor: Vendor;
    key: string | null;
  };
};

type Action = UpdateKeyAction;
