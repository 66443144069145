import React from 'react';
import styled from 'styled-components';
import { toFormattedText } from '@peloton/copy';
import { Phone } from '@ecomm/contact-us';
import { useKeyValueData } from '@ecomm/copy';
import { inlineLinkStyles } from '@ecomm/links';
import { Content, Title, Subtitle } from './sharedErrorComponents';

const ErrorComponent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const title = useKeyValueData('errors.default');
  const subtitle = useKeyValueData('errors.defaultSubtitle');
  return (
    <Content>
      <Title>{title}</Title>
      <Subtitle>
        {toFormattedText(subtitle, {
          phone: <PhoneLink id="supportPhoneNumber" />,
        })}
      </Subtitle>
    </Content>
  );
};
export default ErrorComponent;

const PhoneLink = styled(Phone)`
  ${inlineLinkStyles}
`;
