import { useTracking } from 'react-tracking';
import * as segment from '@peloton/analytics/analytics';
import type { EventType } from '@peloton/analytics/types';

export const driftExists = () => Boolean((window as any).drift);

export const getDrift = () => (window as any).drift;

export const startInteraction = (interactionId: number) =>
  (window as any).drift.api.startInteraction({ interactionId });

export type UserData = {
  email: string;
  name: string;
  phone: string;
};

export const identifyWithSegmentId = (userData?: UserData) => {
  const drift = getDrift();
  if (drift) {
    segment.anonymousId(anonymousId => {
      drift.identify(anonymousId, userData);
    });
  }
};

export const trackDrift = <T extends object>(event: EventType<T>) => {
  if (typeof event !== 'string') {
    const { event: eventName, properties } = event;
    const drift = getDrift();
    if (drift) {
      const trackEventName =
        properties.page && properties.name
          ? `${eventName} | ${properties.name} | ${properties?.page}`
          : eventName;
      drift.track(trackEventName, properties);
    }
  }
};

export const trackWithDriftAndSegment = <T extends object>(event: EventType<T>) => {
  segment.track(event);
  trackDrift(event);
};

export const useTrackWithDriftAndSegment = () => {
  const { trackEvent } = useTracking();
  const track = (event: EventType<any>) => {
    trackEvent(event);
    trackDrift(event);
  };

  return {
    track,
  };
};
