export enum ActionType {
  ValidStudentInfo = 'ecomm/checkout/VALID_STUDENT_INFO',
  InvalidStudentInfo = 'ecomm/checkout/INVALID_STUDENT_INFO',
  UpdateStudentEmail = 'ecomm/checkout/UPDATE_STUDENT_EMAIL',
  UpdateStudentZipcode = 'ecomm/checkout/UPDATE_STUDENT_ZIPCODE',
}

export type State = {
  isValid: boolean;
};

export const defaultState = {
  isValid: false,
};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.ValidStudentInfo:
      return {
        ...state,
        isValid: true,
      };

    case ActionType.InvalidStudentInfo:
      return {
        ...state,
        isValid: false,
      };

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  studentStatusValidationState: State;
};

export const validStudentInfo = (isValid: boolean): ValidStudentInfoAction => ({
  type: ActionType.ValidStudentInfo,
  payload: { isValid },
});

export const inValidStudentInfo = (isValid: boolean): InvalidStudentInfoAction => ({
  type: ActionType.InvalidStudentInfo,
  payload: { isValid },
});

export type ValidStudentInfoAction = {
  type: ActionType.ValidStudentInfo;
  payload: { isValid: boolean };
};

export type InvalidStudentInfoAction = {
  type: ActionType.InvalidStudentInfo;
  payload: { isValid: boolean };
};

type Action = ValidStudentInfoAction | InvalidStudentInfoAction;
