import { Eyebrow, Display, Support, white } from '@pelotoncycle/design-system';
import React, { useEffect, useState } from 'react';
import gfm from 'remark-gfm';
import { ThemeProvider } from 'styled-components';
import { track } from '@peloton/analytics';
import { Markdown } from '@peloton/copy';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import type { PromoHeroData } from '@ecomm/copy';
import { IdMeButton } from '@ecomm/pg-id-me';
import type { TrackingProps } from '../trackingUtils';
import { getCtaTrackingData, getTrackingName } from '../trackingUtils';
import LinkRenderer from './LinkRenderer';
import {
  ContentWrapperWithGradient,
  CtaWrapper,
  HeroCta,
  SupportTextWrapper,
} from './styles';
import SubheadWithTransitions from './SubheadWithTransitions';

type Props = Omit<PromoHeroData, 'video' | 'image'> &
  TrackingProps & {
    className?: string;
    ctaThemes?: object[];
    activeSlide?: number;
    customContent?: React.ReactNode;
  };

const HeroContent: React.FC<React.PropsWithChildren<Props>> = ({
  className = '',
  ctas,
  ctaThemes = [],
  eyebrow,
  header,
  index: slideIndex = undefined,
  subhead,
  support,
  activeSlide = 0,
  customContent,
}) => {
  const [trackingName, setTrackingName] = useState<string>('');
  const [path, setPath] = useState<string>('');
  const showHeader = !slideIndex || activeSlide === slideIndex;

  useEffect(() => {
    const relativePath = getCurrentRelativePathname()!;
    setPath(relativePath);
    setTrackingName(getTrackingName(relativePath, slideIndex));
  }, [slideIndex]);

  // match for any bold characters in subhead markup (this indicates a price)
  const increaseMaxWidth = /\*\*.+\*\*/.test(subhead);

  return (
    <ContentWrapperWithGradient className={className}>
      {customContent}
      {eyebrow && (
        <Eyebrow
          as="p"
          data-test-id={`${trackingName}-eyebrow`}
          textColor={white}
          size="large"
          marginBottom="16px"
        >
          {eyebrow}
        </Eyebrow>
      )}
      {header && showHeader && (
        <Markdown
          content={header}
          markdown={{
            renderers: {
              // eslint-disable-next-line react/display-name
              paragraph: (() => ({ children }: { children: React.ReactNode }) => (
                <Display data-test-id={`${trackingName}-header`} hasDropShadow>
                  {children}
                </Display>
              ))(),
            },
            plugins: [gfm],
          }}
        />
      )}
      {subhead && (
        <SubheadWithTransitions
          subhead={subhead}
          trackingName={trackingName}
          increaseMaxWidth={increaseMaxWidth}
        />
      )}
      {ctas && (
        <CtaWrapper>
          {ctas.map(({ text, url }, ctaIndex) =>
            text == 'ID.me' ? (
              <IdMeButton />
            ) : (
              text &&
              url && (
                <ThemeProvider key={text} theme={ctaThemes[ctaIndex] || {}}>
                  <HeroCta
                    data-test-id={`${trackingName}-cta-${ctaIndex + 1}`}
                    to={url}
                    onClick={(e: React.SyntheticEvent) => {
                      const anchorIdx = url.lastIndexOf('#');
                      if (anchorIdx !== -1) {
                        e.preventDefault();
                        const el = document.querySelector(url.substring(anchorIdx));

                        if (el) {
                          el.scrollIntoView({ behavior: 'smooth' });
                        }
                      }

                      track(getCtaTrackingData(path, url, ctaIndex, slideIndex));
                    }}
                  >
                    {text}
                  </HeroCta>
                </ThemeProvider>
              )
            ),
          )}
        </CtaWrapper>
      )}
      {support && (
        <SupportTextWrapper>
          <Support
            is="h2"
            data-test-id={`${trackingName}-support`}
            textColor={white}
            size="large"
          >
            <Markdown
              content={support}
              markdown={{
                renderers: {
                  link: LinkRenderer,
                },
              }}
            />
          </Support>
        </SupportTextWrapper>
      )}
    </ContentWrapperWithGradient>
  );
};

export default HeroContent;
