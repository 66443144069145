import { Container, brand, spacing, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import asyncComponent from '@peloton/code-splitting/asyncComponent';
import { ErrorBoundary, useErrorReporter } from '@peloton/error-reporting';
import { theUserIsInTheUnitedStates } from '@peloton/internationalize';
import { toCountryFromLocale } from '@peloton/internationalize/models/country';
import type { Locale } from '@peloton/internationalize/models/locale';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { useOneTrust } from '@peloton/onetrust/useOneTrust';
import { media } from '@peloton/styles/breakpoints';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { useHideSignup } from '@acme-ui/nav/hooks/useHideSignup';
import useFooter from '@content/client/www/footer/useFooter';
import useProductState from '@content/client/www/footer/useProductState';
import useFooterToggles from '@content/client/www/footer/useToggles';
import { dividerGray } from '@ecomm/colors';
import AppStoreLinks from './AppStoreLinks';
import ContactSection from './ContactSection';
import FinancingDisclaimer from './FinancingDisclaimer';
import LeadGen from './LeadGen';
import LinkColumn from './LinkColumn';
import type { CtaWithUrlSetData } from './models';

const FooterBar = asyncComponent(
  () => import(/* webpackChunkName: "FooterBarView" */ './FooterBar'),
);

type Props = {
  locale: Locale;
  path?: string;
};

export const filterByToggle = (items: any[], map: any) => {
  return items.filter(item => !map.hasOwnProperty(item.key) || map[item.key]);
};

const isShowDoNotSellShareLink = (
  locale: Locale,
  locationLoading: boolean,
  userInProtectedState: boolean | null,
) => !locationLoading && userInProtectedState && theUserIsInTheUnitedStates(locale);

const FooterView: React.FC<React.PropsWithChildren<Props>> = ({ locale, path }) => {
  const { footerInViewProps } = React.useContext(GlobalReferenceContext);
  const activeFooterRef = footerInViewProps?.ref;

  const { content, isLoading } = useFooter(locale);
  const toggles = useFooterToggles();
  const productStateOverrides = useProductState();
  const { shouldHideSignup } = useHideSignup();
  const {
    accessibilityPage,
    areShowroomsActive,
    ccpaNotice,
    hasBlog,
    homeTrial,
    hotelFinder,
    instructors,
    ourPledgeEnabled,
    bikeRentals,
    showFinancingDisclosure,
    showMembershipPage,
    showLondonStudioLink,
    showRecallLink,
    supportCommercialBike,
    showFinancingComplaints,
    securityPage,
    showFinancingPartner,
  } = {
    ...toggles,
    ...productStateOverrides,
  };

  const { geolocationLoading, isDoNotSellEnabled } = useOneTrust();
  const showDoNotSellShareLink = isShowDoNotSellShareLink(
    locale,
    geolocationLoading,
    isDoNotSellEnabled,
  );

  const country = toCountryFromLocale(locale);

  const keyToggleMap = {
    'footer.financingDisclosureRefresh': showFinancingDisclosure,
    'footer.homeTrial': homeTrial,
    'footer.membership': showMembershipPage,
    'footer.onepelotonclub': bikeRentals,
    'footer.instructors': instructors,
    'footer.blog': hasBlog,
    'footer.pledge': ourPledgeEnabled,
    'footer.linkColumns.visitUs': areShowroomsActive,
    'footer.londonStudio': showLondonStudioLink,
    'footer.commercialBike': supportCommercialBike,
    'footer.hotelFinder': hotelFinder,
    'footer.financingDisclosureLink': showFinancingComplaints,
    'footer.financingComplaintsLink': showFinancingComplaints,
    'footer.productRecalls': showRecallLink,
    'footer.legal.ipPolicy': ccpaNotice,
    'footer.legal.californiaPrivacyNotice': ccpaNotice,
    'footer.legal.doNotSellShare': showDoNotSellShareLink,
    'footer.legal.accessibility': accessibilityPage,
    'footer.security': securityPage,
    'footer.financing': showFinancingPartner,
  };

  if (!content && isLoading) {
    return null;
  }

  const {
    linkColumns,
    contactLinks,
    leadGen,
    socialLinks,
    appStoreLinks,
    legalLinks,
    copyright,
    disclaimer,
    localePicker,
  } = content;

  const legalNavigationLabel = content.legalNavigationLabel?.value || '';
  const secondaryNavigationLabel = content.secondaryNavigationLabel?.value || '';

  const activeLegalLinkCtas =
    legalLinks && filterByToggle(legalLinks.ctas || [], keyToggleMap);

  return (
    <Background id="footer">
      <Container
        verticalMargin={{
          mobile: `${spacing[24]}`,
          desktop: `${spacing[48]}`,
        }}
        horizontalPadding={{
          mobile: `${spacing[32]} ${spacing[24]}`,
          tablet: `${spacing[48]} ${spacing[32]}`,
        }}
        margin={'auto'}
        ref={activeFooterRef}
      >
        <Content data-test-id="footerSection">
          {linkColumns && !shouldHideSignup && (
            <LinkColumnsContainer
              aria-label={secondaryNavigationLabel}
              data-test-id="footerLinkColumnsContainer"
            >
              {/* filterByToggle() is called with linkColumns because the "Visit Us" column is based on the "areShowroomsActive" toggle */}
              {filterByToggle(linkColumns, keyToggleMap).map(
                ({ ctas, ...props }: CtaWithUrlSetData) => (
                  <LinkColumn
                    {...props}
                    ctas={filterByToggle(ctas, keyToggleMap)}
                    country={country}
                    key={props.key}
                  />
                ),
              )}
            </LinkColumnsContainer>
          )}
          <ContactContainer>
            {leadGen && (
              <LeadGen
                {...leadGen}
                country={country}
                path={
                  typeof window === 'undefined' ? path : getCurrentRelativePathname()!
                }
                id={leadGen.key}
              />
            )}
            <ContactSection contactLinks={contactLinks} socialLinks={socialLinks} />
          </ContactContainer>
        </Content>
        <MobileDivider />
        {appStoreLinks && <AppStoreLinks {...appStoreLinks} country={locale} />}
        {disclaimer && showFinancingDisclosure && (
          <FinancingDisclaimer disclaimer={disclaimer} />
        )}
      </Container>
      <FooterBar
        ctas={activeLegalLinkCtas}
        copyright={copyright}
        locale={locale}
        localePicker={localePicker}
        legalLinks={legalLinks}
        legalNavigationLabel={legalNavigationLabel}
      />
    </Background>
  );
};

const Footer: React.FC<React.PropsWithChildren<Props>> = props => {
  const { errorReporter } = useErrorReporter();

  return (
    <ErrorBoundary
      renderError={() => <></>}
      reportError={errorReporter.reportError}
      errorContext={{ tags: { component: 'footer' } }}
    >
      <FooterView {...props} />
    </ErrorBoundary>
  );
};

export default Footer;

const Background = styled.footer`
  background-color: ${white};
  color: ${brand.darkest};

  @media print {
    display: none;
  }
`;

const MobileDivider = styled.hr`
  border: none;
  background-color: ${dividerGray};
  height: 1px;
  margin-top: 40px;
  margin-bottom: 30px;
  ${media.desktop`
    display: none;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  ${media.desktop`
    flex-direction: column;
  `}

  ${media.desktopLarge`
    flex-direction: row;
  `}
`;

const LinkColumnsContainer = styled.nav`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${media.desktop`
    flex: 1;
    justify-content: space-between;
    width: initial;
  `}

  ${media.desktopLarge`
    flex-wrap: initial;
    margin-right: 60px;
  `}

  ${media.desktopXLarge`
    flex: initial;
  `}
`;

const ContactContainer = styled.section`
  margin-top: 40px;
  width: 100%;

  ${media.tablet`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    margin-top: 60px;
  `}

  ${media.desktopLarge`
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0;
    max-width: 386px;
  `}

  ${media.desktopXLarge`
    min-width: 235px;
  `}
`;
