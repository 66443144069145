import { createClient } from 'contentful';
import { CONTENTFUL_TOKEN } from './accessToken';

export const SPACE_ID = '6jnflt57iyzx';
const previewHost = 'preview.contentful.com';
const prodHost = 'cdn.contentful.com';

type Props = { isProd: boolean };

const toContentfulClient = ({ isProd }: Props) =>
  createClient({
    accessToken: CONTENTFUL_TOKEN,
    host: isProd ? prodHost : previewHost,
    space: SPACE_ID,
  });

export default toContentfulClient;
