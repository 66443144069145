import toEncodedKey from './toEncodedKey';

type ContentfulEntryMap = { [k: string]: ContentfulEntryMap | string };

/**
 * Given an `obj`, return the same object with the string leaf values replaces
 * with the dot path value to that leaf.
 *
 * @param {object} [obj] - The object to swap values for dot path value.
 * @param {string} parentPath - The dot path value of the parent to a node.
 */

const processContentfulData = <T extends object>(obj: T): T => {
  if (!obj) return {} as T;
  const type = Object.keys(obj)[0];

  if (type) {
    const data = obj[type];
    return { [type]: toDotPathValue(data) } as T;
  } else {
    return obj;
  }
};

export default processContentfulData;

const IgnoreList = [null, undefined, 'PromoBanner', 'ValuePropSet', 'StudioValueProp'];

const toDotPathValue = <T extends ContentfulEntryMap | string>(
  obj: T,
  parentPath: string = '',
): T => {
  const parentType = obj['__typename'];
  const parentKey = parentType === 'Asset' ? obj['title'] : obj['key'];

  if (IgnoreList.includes(parentType)) {
    return obj;
  }

  const result = Object.keys(obj).reduce((acc, field) => {
    const node = obj[field];

    if (node === null || node === undefined) {
      acc[field] = node;
      return acc;
    }

    const path = toEncodedKey({ parentPath, parentType, parentKey, field });

    switch (node.constructor) {
      case Object:
        acc[field] = toDotPathValue(node, path);
        break;
      case Array:
        acc[field] = node.map((item: ContentfulEntryMap) => toDotPathValue(item, path));
        break;
      case String:
        switch (field) {
          case '__typename':
          case 'key':
            acc[field] = node;
            break;
          default:
            acc[field] = `${node}${path}`;
            break;
        }
        break;
      default:
        acc[field] = node;
        break;
    }
    return acc;
  }, {});

  // Making the cast explicit on the next line so that a potential type error in the
  // function above is preserved.
  return result as T;
};
