import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useHistoryMethod = () => {
  const { push, replace } = useHistory();
  return { push, replace };
};

export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => Object.fromEntries(new URLSearchParams(search).entries()), [
    search,
  ]);
};

export const usePathname = () => {
  const { pathname } = useLocation();
  return pathname;
};
