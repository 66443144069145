import { css } from 'styled-components';
import { media } from '@peloton/styles/breakpoints';
import { headingFamily, secondaryHeadingFamily } from './families';
import { bold } from './weights';

export const heading = css`
  ${bold}
  font-family: ${headingFamily};
  letter-spacing: 0.1em;
  line-height: 1.375em;
  text-transform: uppercase;
`;

export const secondaryHeading = css`
  font-family: ${secondaryHeadingFamily};
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.375em;
`;

export const hb1 = css`
  ${heading}
  font-size: 28px;
  line-height: 1.25em;

  ${media.tablet`
    font-size: 40px;
    line-height: 1.125em;
  `}

  ${media.desktop`
    font-size: 48px;
  `}
`;

export const hb2 = css`
  ${heading}
  font-size: 22px;

  ${media.tablet`
    font-size: 28px;
    line-height: 1.25em;
  `}

  ${media.desktop`
    font-size: 32px;
  `}
`;

export const hb3 = css`
  ${heading}
  font-size: 18px;
  ${media.tablet`
    font-size: 24px;
  `}
`;

export const hb4 = css`
  ${heading}
  font-size: 18px;

  ${media.tablet`
    font-size: 20px;
  `}
`;

/**
 * @deprecated prefer `import { Eyebrow } from '@pelotoncycle/design-system'`
 * @see https://ds.test.onepeloton.com/?path=/story/typography-primitives-eyebrow--sizes
 */
export const hb5 = css`
  ${secondaryHeading}
  ${bold}
  font-size: 16px;
`;

/**
 * @deprecated prefer `import { Eyebrow } from '@pelotoncycle/design-system'`
 * @see https://ds.test.onepeloton.com/?path=/story/typography-primitives-eyebrow--sizes
 */
export const hb6 = css`
  ${secondaryHeading}
  ${bold}
  font-size: 14px;
`;

/**
 * @deprecated prefer `import { Eyebrow } from '@pelotoncycle/design-system'`
 * @see https://ds.test.onepeloton.com/?path=/story/typography-primitives-eyebrow--sizes
 */
export const hb7 = css`
  ${secondaryHeading}
  ${bold}
  font-size: 13px;
`;

/**
 * @deprecated prefer `import { Eyebrow } from '@pelotoncycle/design-system'`
 * @see https://ds.test.onepeloton.com/?path=/story/typography-primitives-eyebrow--sizes
 */
export const hb8 = css`
  ${secondaryHeading}
  ${bold}
  font-size: 12px;
`;

export const hr1 = css`
  ${secondaryHeading}
  font-size: 36px;

  ${media.tablet`
    font-size: 50px;
    line-height: 1.1875em;
  `}

  ${media.desktop`
    font-size: 60px;
  `}
`;

export const hr2 = css`
  ${secondaryHeading}
  font-size: 28px;

  ${media.tablet`
    font-size: 40px;
    line-height: 1.25em;
  `}
  ${media.desktop`
    font-size: 44px;
  `}
`;

export const hr3 = css`
  ${secondaryHeading}
  font-size: 24px;

  ${media.tablet`
    font-size: 32px;
  `}
  ${media.desktop`
    font-size: 36px;
  `}
`;

export const hr4 = css`
  ${secondaryHeading}
  font-size: 20px;

  ${media.tablet`
    font-size: 28px;
  `}
`;

export const hr5 = css`
  ${secondaryHeading}
  font-size: 24px;

  ${media.tablet`
    font-size: 28px;
  `}
`;

export const hr6 = css`
  ${secondaryHeading}
  font-size: 16px;
`;

export const hr7 = css`
  ${secondaryHeading}
  font-size: 18px;

  ${media.tablet`
    font-size: 20px;
  `}
`;
