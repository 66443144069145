import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { DomainError, ErrorType } from '@peloton/domain-error';

export type ClientError = HttpError | NetworkError | UnknownError;

export type RequestInfo = {
  url: string;
  method: string; // 'get' | 'post' | 'put' | 'delete',
  params?: any;
};

export type AxiosErrorWithMeta = AxiosError & {
  config: AxiosRequestConfig & {
    meta?: {
      skipErrorHandlers?: boolean;
    };
  };
};

export const fromAxiosError = <T = any>(error: Error): ClientError => {
  if (isAxiosError(error) && error.response) {
    return new HttpError<T>(
      error.message,
      error.response.status,
      {
        url: error.config.url || '/',
        method: error.config.method || 'get',
        params: error.config.params,
      },
      error.response.data,
      error.config.meta,
      error.response,
    );
  } else if (isAxiosNetworkError(error)) {
    return new NetworkError(error.message, error.config);
  } else {
    return new UnknownError(error.message);
  }
};

export const isHttpError = (error: ClientError): error is HttpError =>
  error.name === ErrorType.Http;

export const isUnknownError = (error: ClientError): error is UnknownError =>
  error.name === ErrorType.Unknown;

export const isNetworkError = (error: ClientError): error is NetworkError =>
  error.name === ErrorType.Network;

export const throwError = <T>(toErrorCode: (error: ClientError) => T) => (
  error: ClientError,
) => {
  throw new DomainError(`${toErrorCode(error)}`, error);
};

const isAxiosError = (error: Error): error is AxiosErrorWithMeta => 'response' in error;

const isAxiosNetworkError = (
  error: Error,
): error is { config: AxiosErrorWithMeta['config'] } & Error =>
  error.message === 'Network Error';

export enum ApiErrors {
  API_ERROR = 'API_ERROR',
  BAD_REQUEST = 'BAD_REQUEST',
  CONFLICT = 'CONFLICT',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NETWORK_FAILURE = 'NETWORK_FAILURE',
  FORBIDDEN = 'FORBIDDEN',
}

export const getApiErrorType = (error: any): ApiErrors => {
  switch (error.response && error.response.status) {
    case 400:
      return ApiErrors.BAD_REQUEST;
    case 401:
      return ApiErrors.UNAUTHORIZED;
    case 403:
      return ApiErrors.FORBIDDEN;
    case 409:
      return ApiErrors.CONFLICT;
    case undefined:
      return ApiErrors.NETWORK_FAILURE;
    default:
      return ApiErrors.API_ERROR;
  }
};

export class HttpError<T = any> extends Error {
  public name = ErrorType.Http;

  public constructor(
    message: string,
    public status: number,
    public request: RequestInfo,
    public responseBody: T,
    public meta: Record<string, any> = {},
    public response: AxiosResponse<T>,
  ) {
    super(message);
  }
}

export class UnknownError extends Error {
  public name = ErrorType.Unknown;
}

export class NetworkError extends Error {
  public name = ErrorType.Network;

  public constructor(message: string, public config: AxiosErrorWithMeta['config']) {
    super(message);
  }
}
