import { normalize } from 'normalizr';
import type { NormalizedSubscriptions } from '../models';
import type { ApiSubscriptionPlan } from './schemas/plan';
import subscriptionSchema from './schemas/plan';

const toSubscriptionPlans = (
  data: ApiSubscriptionPlan[] | ApiSubscriptionPlan,
): NormalizedSubscriptions =>
  normalize(Array.isArray(data) ? data : [data], [subscriptionSchema]);

export default toSubscriptionPlans;
