import ignore from 'ignore';
import React from 'react';
import StackTracey from 'stacktracey';
import CODEOWNERS from './../../../.github/CODEOWNERS';
import type { ErrorProperties } from './ErrorReporter';

/* CodeOwnerErrorBoundar doesn't actually deal with errors. It parse the the error satck when an error in the
   child component gets thrown then try to match the top stack with code owners from the CODEOWNERS file.
   If it finds the code owner, it will include the code owner info in the wrapper and throw the error so the
   ErrorBoundary will include the code owners in the tags when posting errors. Otherwise, it will just re-throw
   the original error.
   Therefore, this component should always be placed under the ErrorBoundary component.
 */
type Props = {
  errorContext?: ErrorProperties;
  children: React.ReactNode;
};
export type CodeOwnerEntry = {
  path: string;
  usernames: string[];
  match: (pathString: string) => boolean;
};

export type RenderProps = {
  error: Error | undefined;
  codeOwnerEntries: CodeOwnerEntry[] | undefined;
};

type State = RenderProps;

const ownerMatcher = (pathString: string) => {
  const matcher = ignore().add(pathString);
  return matcher.ignores.bind(matcher);
};

export class CodeOwnerErrorWrapper extends Error {
  firstTraceFilePath: string | undefined;
  codeOwner: string | undefined;
  constructor(messge: string, firstTraceFilePath: string, codeOwner: string) {
    super(messge);
    this.firstTraceFilePath = firstTraceFilePath;
    this.codeOwner = codeOwner;
  }
}
export default class CodeOwnerErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: undefined,
    codeOwnerEntries: undefined,
  };

  constructor(props: Props) {
    super(props);
    const codeOwnerEntries: CodeOwnerEntry[] = [];
    CODEOWNERS.split(/\r?\n/).forEach((line: string) => {
      if (!line.startsWith('#') && line.trim() != '') {
        const [pathString, ...usernames] = line.split(/\s+/);
        codeOwnerEntries.push({
          path: pathString,
          usernames,
          match: ownerMatcher(pathString),
        });
      }
    });
    this.state = { error: undefined, codeOwnerEntries };
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { codeOwnerEntries } = this.state;
    const stack = new StackTracey(error.stack).withSources();
    const topStack = stack.items[0];
    if (topStack && topStack.sourceFile && codeOwnerEntries != undefined) {
      const firstTraceFilePath = this.parseSourcePath(topStack.sourceFile?.path);
      for (const entry of codeOwnerEntries) {
        if (firstTraceFilePath && entry.match(firstTraceFilePath)) {
          throw new CodeOwnerErrorWrapper(
            error.message,
            firstTraceFilePath,
            entry.usernames[0],
          );
        }
      }
      throw error;
    }
  }

  parseSourcePath(sourcePath: string): string {
    let parsedPath = sourcePath;
    const cwd = process.cwd();
    if (parsedPath.lastIndexOf('///') > -1) {
      parsedPath = parsedPath.slice(parsedPath.lastIndexOf('///') + 3);
    } else if (parsedPath.lastIndexOf('../') > -1) {
      parsedPath = parsedPath.slice(parsedPath.lastIndexOf('../') + 3);
    } else if (parsedPath.lastIndexOf(cwd) > -1) {
      parsedPath = parsedPath.slice(parsedPath.lastIndexOf(cwd) + cwd.length + 1);
    }
    return parsedPath;
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    return error ? <></> : children;
  }
}
