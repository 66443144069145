/** @TODO Remove from SonarQube exclusions if you make meaningful changes, and bite the bullet testing this */
import type { Country } from '@peloton/internationalize';
import {
  shortcodeToProvince,
  theUserIsInCanada,
  toCountry,
} from '@peloton/internationalize';
import type { ContactInfo } from '@ecomm/models';

export enum AddressType {
  Billing = 'billing',
  Shipping = 'shipping',
}

export type Address = {
  firstName: string;
  lastName: string;
  phone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  country: Country;
};

export const toAddress = (address?: Partial<Address>): Address => ({
  firstName: '',
  lastName: '',
  phone: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postalCode: '',
  country: toCountry(),
  ...address,
});

export const toContactInfoFromAddress = (x: Address): ContactInfo => ({
  name: {
    first: x.firstName,
    last: x.lastName,
  },
  phone: x.phone,
  address: {
    line1: x.line1,
    line2: x.line2,
    city: x.city,
    state: theUserIsInCanada(toCountry())
      ? shortcodeToProvince[x.state] || x.state
      : x.state,
    postalCode: x.postalCode,
    country: x.country,
  },
});

export type AddressToValidate = Omit<Address, 'firstName' | 'lastName' | 'phone'>;
