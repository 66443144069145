import {
  PB_CF_SPACE_ID,
  PB_CF_PREVIEW_ACCESS_TOKEN,
  PB_CF_DELIVERY_ACCESS_TOKEN,
} from '@peloton/app-config';
import { createClientHelpers, createClients } from './client';

const clientParams = {
  space: PB_CF_SPACE_ID ?? (process.env.PB_CF_SPACE_ID as string),
  accessToken:
    PB_CF_DELIVERY_ACCESS_TOKEN ?? (process.env.PB_CF_DELIVERY_ACCESS_TOKEN as string),
  previewAccessToken:
    PB_CF_PREVIEW_ACCESS_TOKEN ?? process.env.PB_CF_PREVIEW_ACCESS_TOKEN,
  environment: process.env.PB_CF_ENV || 'master',
};

const { client, previewClient } = createClients(clientParams);

const { getClient } = createClientHelpers(client, previewClient);

export { getClient, client, previewClient };

export default client;
