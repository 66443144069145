const mapToView = (content: any) => ({
  eyebrowText: content['financingStaticCopy']['financingStaticCopy.eyebrow'],
  headlineText: content['financingStaticCopy']['financingStaticCopy.headline'],
  bodyText: content['financingStaticCopy']['financingStaticCopy.body'],
  supportText: content['financingStaticCopy']['financingStaticCopy.support'],
  bannerText: content['financingStaticCopy']['financingStaticCopy.bannerText'],
  bannerCta: content['financingStaticCopy']['financingStaticCopy.bannerCta'],
});

export default mapToView;
