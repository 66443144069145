import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import type { BreakpointTransformsProps } from '@ecomm/image';
import { BreakpointTransforms } from '@ecomm/image';

type BackgroundDivProps = {
  /** A boolean specifying whether to repeat the image in a tile pattern. */
  bgRepeat?: boolean;
};

export type Props = React.HTMLAttributes<HTMLDivElement> &
  Pick<BreakpointTransformsProps, Exclude<keyof BreakpointTransformsProps, 'render'>> &
  BackgroundDivProps & {
    /** The image's alt text */
    alt?: string;
    lazy?: boolean;
  };

/**
 * A div with an image sized to cover its background, clipping
 * either the sides or the top and bottom.
 *
 * Accepts `options` and `breakpointOptions` to set quality
 * and resoution transforms.
 */
class ResponsiveBackgroundImage extends React.Component<Props, {}> {
  public render() {
    return <BreakpointTransforms {...this.props} render={BackgroundImage} />;
  }
}

const BackgroundImage = ({ alt, children, src, lazy = false, ...props }: Props) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
    skip: !lazy,
    initialInView: !lazy,
  });

  return (
    <BackgroundDiv
      ref={ref}
      {...props}
      style={
        inView
          ? {
              backgroundImage: `url(${src})`,
              ...props.style,
            }
          : props.style
      }
    >
      {alt && <span role="img" aria-label={alt} />}
      {children}
    </BackgroundDiv>
  );
};

const BackgroundDiv = styled.div`
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  ${({ bgRepeat }: BackgroundDivProps) =>
    bgRepeat &&
    `
      background-size: auto auto;
      background-position: top left;
      background-repeat: repeat;
    `}
`;

export default ResponsiveBackgroundImage;
