import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, pipeError, throwError } from '@peloton/api';
import type { ErrorCode } from '../models';
import { toErrorCode } from '../models';

// Payemnt Intents
const PAYMENT_INTENT_ENDPOINT = '/ecomm/stripe/payment_intents';

type PaymentInformation = {
  paymentMethod: string;
  amount: number;
  currency: string;
  captchaToken?: string;
  captchaVersion?: string;
  country?: string;
};

export const createPaymentIntent = (
  api: Client,
  paymentInformation: PaymentInformation,
) =>
  api
    .post(PAYMENT_INTENT_ENDPOINT, paymentInformation)
    .then(pipeData(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));

const CONFIRM_PAYMENT_INTENT_ENDPOINT = '/ecomm/stripe/payment_intents/confirm';

type ConfirmIntentArgs = {
  paymentIntentId: string;
  stripeAccount: string;
};

export const confirmPaymentIntent = (
  api: Client,
  { paymentIntentId, stripeAccount }: ConfirmIntentArgs,
) =>
  api
    .post(CONFIRM_PAYMENT_INTENT_ENDPOINT, { paymentIntentId, stripeAccount })
    .then(pipeData(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));

// Setup Intentks
const SETUP_INTENT_ENDPOINT = '/ecomm/stripe/setup_intents';

export const createSetupIntent = (api: Client, stripeAccount: string) =>
  api
    .post(SETUP_INTENT_ENDPOINT, { stripeAccount })
    .then(pipeData(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));

export const createSetupIntentWithCaptcha = (
  api: Client,
  stripeAccount: string,
  captchaToken: string,
) =>
  api
    .post(SETUP_INTENT_ENDPOINT, {
      stripeAccount,
      captchaToken,
      captcha_version: 'enterprise',
    })
    .then(pipeData(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
