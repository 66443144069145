import axios from 'axios';
import { decamelizeKeys } from 'humps';
import { getAPIAuthToken } from '@peloton/auth/config';
import type { ExtLink, ExtLinkEnv } from '@peloton/external-links/models';
import { toHref as toHrefDefault, toApiLink } from '@peloton/external-links/models';
import {
  toDefaultRequestTransforms,
  toDefaultResponseTransforms,
} from './client-transforms';
import type { Client, Platform } from './utils';

export const toClient = ({
  env,
  platform = 'web',
  toHref = toHrefDefault,
  headers = {},
  timeout,
  authorizationHeaderEnabled = false,
  withCredentials = true,
}: ClientOpts): Client => {
  const client = axios.create({
    baseURL: toHref(toApiLink('/'), env),
    responseType: 'json',
    withCredentials,
    timeout,
    headers: {
      'X-Requested-With': 'XmlHttpRequest',
      'Peloton-Platform': platform,
      ...headers,
    },
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
  });

  if (authorizationHeaderEnabled) {
    client.interceptors.request.use(request => {
      const token = getAPIAuthToken();
      if (token) request.headers['X-Secrets-Authorization'] = token;
      return request;
    });
  }

  return client;
};

type ClientOpts = {
  headers?: Object;
  env: ExtLinkEnv;
  platform?: Platform;
  timeout?: number;
  toHref?(link: ExtLink, env: ExtLinkEnv): string;
  authorizationHeaderEnabled?: boolean;
  withCredentials?: boolean;
};

type CartCheckoutClientOpts = {
  baseURL: string;
  timeout?: number;
};

export const createCartCheckoutClient = ({
  baseURL,
  timeout = 60000,
}: CartCheckoutClientOpts): Client => {
  const client = axios.create({
    baseURL,
    responseType: 'json',
    timeout,
    transformRequest: toDefaultRequestTransforms(decamelizeKeys),
    transformResponse: toDefaultResponseTransforms(),
  });

  return client;
};
