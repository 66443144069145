// for more context about this file, see docs/consolidating-toggle-logic-20-09-2023.md
import { REFERRAL_CODE_STORAGE_KEY } from '@ecomm/cart-next/hooks/usePartnerPromo';
import type { StorageItem } from '@ecomm/cart-next/shared/getStorageItem';
import { getStorageItem } from '@ecomm/cart-next/shared/getStorageItem';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

export type MigrationStatus = {
  CTCartEnabled: boolean; //killswitch
  buyFlowATCEnabled: boolean; // CT ATC (R1 & R2 combined)
  buyFlowCheckoutEnabled: boolean; // CT Checkout (R3 + R4 combined)
  CTGiftCardEnabled: boolean;
  bfcmReferralPulseActive: boolean;
};

export const KILL_SWITCH_TOGGLE = 'commercetoolscartenabled';
export const GIFT_CARD_TOGGLE = 'commercetoolsGiftCardEnabled';
export const CT_CHECKOUT_ON_OFF_TOGGLE = 'ctCheckoutEnabled';
export const BFCM_REFERRAL_PULSE_TOGGLE = 'bfcm_2024_referral_pulse';

export const useMigrationStatus = (): MigrationStatus => {
  const isToggleActive = useIsToggleActive();
  const CTCartEnabled = isToggleActive(KILL_SWITCH_TOGGLE);
  const CTGiftCardEnabled = isToggleActive(GIFT_CARD_TOGGLE);
  const CTCheckoutEnabled = isToggleActive(CT_CHECKOUT_ON_OFF_TOGGLE);
  const bfcmReferralPulseEnabled = isToggleActive(BFCM_REFERRAL_PULSE_TOGGLE);
  const referralCodeInStorage = getStorageItem(REFERRAL_CODE_STORAGE_KEY) as StorageItem;
  const referralCheckPasses =
    Boolean(!referralCodeInStorage) ||
    Boolean(referralCodeInStorage && CTCheckoutEnabled);

  return {
    CTCartEnabled,
    buyFlowATCEnabled: CTCartEnabled && referralCheckPasses,
    buyFlowCheckoutEnabled: CTCartEnabled && CTCheckoutEnabled,
    CTGiftCardEnabled,
    bfcmReferralPulseActive: bfcmReferralPulseEnabled,
  };
};
