import { capitalize } from '@peloton/text';

export enum Platform {
  Amazon = 'amazon',
  Apple = 'apple',
  Bango = 'bango',
  Google = 'google',
  Peloton = 'peloton',
  Roku = 'roku',
}

export const isThirdParty = (sub: SubLike): boolean =>
  Boolean(sub.platform) && sub.platform !== Platform.Peloton;

export const isPelotonPlatform = (sub: SubLike): boolean =>
  Boolean(sub.platform) && sub.platform === Platform.Peloton;

export const thirdPartyProviderName = (sub: SubLike): string | undefined => {
  return sub
    ? isThirdParty(sub) && sub.platform
      ? capitalize(sub.platform)
      : undefined
    : undefined;
};

type SubLike = {
  platform?: Platform;
};
