import { prop } from 'ramda';
import { toUIState, Status } from '@ecomm/models';
import { getUserSubscriptions } from '@ecomm/user-subscriptions';
import type { Subscription } from '@ecomm/user-subscriptions/models';
import {
  isActive,
  isPreDelivery,
  isPelotonPlatform,
  isTrial,
} from '@ecomm/user-subscriptions/models';
import type { ReducerState as SubscriptionsState } from '@ecomm/user-subscriptions/redux';
import type { TOSFlags } from './applyBenefit';
import type { ReducerState as BenefitsReducerState } from './benefits';
import type { ReducerState as UserProfileReducerState } from './userProfile';

export const isFirstLookup = (state: BenefitsReducerState) => {
  return state.benefits.lookup.lookupCount <= 1;
};

export const isPelotonTrial = (sub: Subscription): boolean =>
  isPelotonPlatform(sub) && isTrial(sub);

export const getAudience = (state: BenefitsReducerState): string | undefined =>
  state.benefits.applyBenefit.audience;

export const getApplyBenefitUIState = (state: BenefitsReducerState) =>
  state.benefits.applyBenefit;

export const getBookmarkUIState = (state: UserProfileReducerState, classId: string) =>
  state.userProfile.classBookmarks[classId] ?? toUIState(Status.Init);

export const get1wSubs = (state: SubscriptionsState): Subscription[] =>
  (Object.values(getUserSubscriptions(state)) as Subscription[]).filter(sub => {
    return isActive(sub) && !isPreDelivery(sub) && !isPelotonTrial(sub);
  });

export const isClassBookmarked = (state: UserProfileReducerState, classId: string) =>
  state.userProfile.classBookmarks[classId]?.isBookmarked ?? false;

export const getLookupScrollPosition = (state: BenefitsReducerState) => {
  return state.benefits.lookup.scrollY;
};

export const getLookupUIState = (state: BenefitsReducerState) => state.benefits.lookup;

export const getLookupEmail = (state: BenefitsReducerState) =>
  state.benefits.lookup.email;

export const getPartner = (state: BenefitsReducerState) => state.benefits.partner.entity;

export const getPartnerUIState = (state: BenefitsReducerState) => state.benefits.partner;

export const getPartnerHashId = (state: BenefitsReducerState) =>
  state.benefits.partner.hashId;

export const getPlansUIState = (state: BenefitsReducerState) => state.benefits.plans;

export const getBenefitsUIState = (state: BenefitsReducerState) => state.benefits.ui;

export const getApplyBenefitTOSFlags = (state: BenefitsReducerState) =>
  state.benefits.applyBenefit.tos;

export const getApplyBenefitTOSFlag = (
  state: BenefitsReducerState,
  name: keyof TOSFlags,
) => prop(name, state.benefits.applyBenefit.tos);

export const getTrackOrderUIState = (state: BenefitsReducerState) =>
  state.benefits.trackOrder;

export const getUsernameUIState = (state: UserProfileReducerState) =>
  state.userProfile.username;
