export enum ActionType {
  ReportError = 'ecomm/errorReporting/REPORT_ERROR',
}

export type ReportErrorActionPayload = {
  error: Error;
  options?: {};
};

export type ReportErrorAction = {
  type: ActionType.ReportError;
  payload: ReportErrorActionPayload;
};

export const reportError = (payload: ReportErrorActionPayload): ReportErrorAction => ({
  type: ActionType.ReportError,
  payload,
});
