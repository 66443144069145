import type { ClientError } from '@peloton/api';
import { isHttpError } from '@peloton/api';

export enum ErrorCode {
  InvalidPayment = 'reusedBadPaymentMethod',
  PaymentDeclined = 'transactionDeclined',
  UnableToCreateKlarnaSession = 'unableToCreateKlarnaSession',
  Default = 'default',
}

const apiErrorMap = {
  6301: ErrorCode.UnableToCreateKlarnaSession,
  4100: ErrorCode.InvalidPayment,
  4490: ErrorCode.PaymentDeclined,
};

export const toErrorCode = (error: ClientError): ErrorCode => {
  const apiErrorCode = isHttpError(error) ? error.responseBody.errorCode : undefined;

  return apiErrorMap[apiErrorCode] || ErrorCode.Default;
};
