import type { SocialLink } from '@peloton/external-links';
import { toSocialLink } from '@peloton/external-links';
import type { Locale } from '@peloton/internationalize';
import { toCountry, Country } from '@peloton/internationalize';

export const facebook = toSocialLink('https://www.facebook.com/onepeloton');
export const facebookGermany = toSocialLink(
  'https://www.facebook.com/pelotondeutschland',
);
export const instagram = toSocialLink('https://www.instagram.com/onepeloton');
export const twitter = toSocialLink('https://twitter.com/onepeloton');
export const youtube = toSocialLink('https://www.youtube.com/c/onepeloton');
export const pelothon2020LinkUS = toSocialLink('https://www.pelothon2020.com');
export const pelothon2020LinkCA = toSocialLink('https://www.pelothon2020.ca');
export const pelothon2020LinkUK = toSocialLink('https://www.pelothon2020.co.uk');
export const pelothon2020LinkDE = toSocialLink('https://www.pelothon2020.de');

export const pelotonPledgeLinkUS = toSocialLink('https://pledge.onepeloton.com');
export const pelotonPledgeLinkCA = toSocialLink('https://pledge.onepeloton.ca');
export const pelotonPledgeLinkUK = toSocialLink('https://pledge.onepeloton.co.uk');
export const pelotonPledgeLinkDE = toSocialLink('https://pledge.onepeloton.de');
export const pelotonPledgeLinkAU = toSocialLink('https://pledge.onepeloton.au');

export const getFacebookLink = () =>
  toCountry() === Country.Germany ? facebookGermany : facebook;

export const getPelotonPledgeLink = (locale?: Locale): SocialLink => {
  switch (locale) {
    case 'en-GB':
      return pelotonPledgeLinkUK;
    case 'en-CA':
      return pelotonPledgeLinkCA;
    case 'en-AU':
      return pelotonPledgeLinkAU;
    case 'de-DE':
      return pelotonPledgeLinkDE;
    case 'en-US':
    default:
      return pelotonPledgeLinkUS;
  }
};

export const getPelothon2020Link = (locale?: Locale): SocialLink => {
  switch (locale) {
    case 'en-GB':
      return pelothon2020LinkUK;
    case 'en-CA':
      return pelothon2020LinkCA;
    case 'de-DE':
      return pelothon2020LinkDE;
    case 'en-US':
    default:
      return pelothon2020LinkUS;
  }
};
