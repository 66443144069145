import { css } from 'styled-components';
import { media } from '@peloton/styles';
import { bodyFamily } from './families';
import { light } from './weights';

export const body = css`
  ${light}
  font-family: ${bodyFamily};
  letter-spacing: 0.025em;
  line-height: 1.5em;
`;

export const b1 = css`
  ${body}
  font-size: 18px;

  ${media.tablet`
    font-size: 24px;
  `}
`;

export const b2 = css`
  ${body}
  font-size: 16px;

  ${media.tablet`
    font-size: 18px;
  `}
`;

export const b3 = css`
  ${body}
  font-size: 14px;

  ${media.tablet`
    font-size: 16px;
    letter-spacing: 0.4px;
  `}
`;

export const b4 = css`
  ${body}
  font-size: 14px;
`;

export const b5 = css`
  ${body}
  font-size: 13px;
`;

export const b6 = css`
  ${body}
  font-size: 12px;
`;

export const b6Grow = css`
  ${body}
  font-size: 12px;

  ${media.tablet`
    font-size: 16px;
  `}
`;

export const b7 = css`
  ${body}
  font-size: 12px;
`;
