import type { ClientError } from '@peloton/api';
import { isHttpError } from '@peloton/api';

export enum ErrorCode {
  EmailAlreadyInUse = 'EmailAlreadyInUse',
  UsernameAlreadyInUse = 'UsernameAlreadyInUse',
  PasswordInvalid = 'PasswordInvalid',
  FirstTimeRegistration = 'FirstTimeRegistration',
  RepeatRegistration = 'RepeatRegistration',
  Default = 'default',
}

export const errorMap = {
  10: ErrorCode.UsernameAlreadyInUse,
  30: ErrorCode.EmailAlreadyInUse,
  21: ErrorCode.PasswordInvalid,
  3170: ErrorCode.FirstTimeRegistration,
  3180: ErrorCode.RepeatRegistration,
};

export const toErrorCode = (error: ClientError): ErrorCode => {
  // These errors share the error code 150 and contain actual error on the subcode
  const errorCode =
    isHttpError(error) && error.responseBody.subcode
      ? error.responseBody.subcode
      : isHttpError(error)
      ? error.responseBody.errorCode
      : undefined;

  return errorMap[errorCode] || ErrorCode.Default;
};

export const LOGIN_FAILED = 'failedOnLogin';
