import { keys, values } from 'ramda';
import * as yup from 'yup';
import type { MixedSchema, StringSchema } from 'yup';
import {
  Country,
  Locale,
  Provinces,
  States,
  getPostalCodeRegexForLocale,
  toCountryFromLocale,
} from '@peloton/internationalize';
import { EMAIL_REGEX, UMICH_EMAIL_REGEX } from '@ecomm/models';
import type { Address } from './Address';
import type { CreditCard } from './Payment';
import type { Student } from './Student';
import type { User } from './User';

export enum Errors {
  Missing = 'missing',
  Invalid = 'invalid',
  InvalidPhone = 'invalidPhone',
  InvalidPromoCode = 'invalidPromoCode',
}

export type Validator = MixedSchema | StringSchema;

export type Validations<T> = Record<keyof T, Validator>;

yup.addMethod(yup.string, 'phone', function (country: Country, message: string) {
  return this.test('phone', message, async (phone: string) => {
    const { isValidPhoneNumber } = await import(
      /*webpackChunkName: "phoneNumber"*/ '@peloton/internationalize/models/phoneNumber'
    );

    try {
      return isValidPhoneNumber({ phone, country });
    } catch (err) {
      return false;
    }
  });
});

yup.addMethod(yup.boolean, 'alwaysTrue', function (message: string) {
  return this.test('alwaysTrue', message, (value: boolean) => {
    return value === true;
  });
});

export const toAddressValidation = (locale: Locale): Validations<Address> => ({
  firstName: yup.string().trim().required(Errors.Missing),
  lastName: yup.string().trim().required(Errors.Missing),
  phone: toPhoneValidation(locale),
  line1: yup.string().trim().required(Errors.Missing),
  line2: yup.string(),
  city: yup.string().trim().required(Errors.Missing),
  state: toStateValidation(locale),
  postalCode: toPostalCodeValidation(locale),
  country: yup
    .mixed()
    .required(Errors.Missing)
    .oneOf([...keys(Country), ...values(Country)], Errors.Invalid),
});

export const toCreditCardValidation = (): Validations<Pick<CreditCard, 'name'>> => ({
  name: yup.string().trim().required(Errors.Missing),
});

export const toUserValidation = (): Validations<User> => ({
  allowMarketing: yup.boolean().notRequired(),
  email: yup
    .string()
    .trim()
    .required(Errors.Missing)
    .matches(EMAIL_REGEX, Errors.Invalid),
  hasAcceptedPolicy: (yup.boolean() as any)
    .required(Errors.Missing)
    .alwaysTrue(Errors.Invalid),
  hasAcceptedLeaseAgreement: yup.boolean().notRequired(),
});

const toPhoneValidation = (locale: Locale): StringSchema =>
  (yup.string() as any)
    .required(Errors.Missing)
    .phone(toCountryFromLocale(locale), Errors.InvalidPhone);

const toPostalCodeValidation = (locale: Locale): StringSchema =>
  yup
    .string()
    .required(Errors.Missing)
    .matches(getPostalCodeRegexForLocale(locale), Errors.Invalid);

const rPostalUmich = RegExp(/^48[0-9]{3}$|^49[0-8]\d{2}$|^499[0-6]\d|^4997[01]$/);
const toPostalCodeForStudentValidation = (): StringSchema =>
  yup.string().required(Errors.Missing).matches(rPostalUmich, Errors.Invalid);

export const toStudentAddressValidation = (): Validations<Student> => ({
  studentEmail: yup
    .string()
    .trim()
    .required(Errors.Missing)
    .matches(UMICH_EMAIL_REGEX, Errors.Invalid),
  studentPostalCode: toPostalCodeForStudentValidation(),
});

const toStateValidation = (locale: Locale): MixedSchema => {
  switch (locale) {
    case Locale.EnglishCanada:
      return yup.mixed().required(Errors.Missing).oneOf(Provinces, Errors.Invalid);

    case Locale.EnglishUnitedStates:
      return yup.mixed().required(Errors.Missing).oneOf(States, Errors.Invalid);

    default:
      return yup.mixed().notRequired();
  }
};
