import type { CreditCard as Card, ID, Money } from '@ecomm/models';

export enum PaymentKind {
  Deposit = 'deposit',
  Financing = 'financing',
  CreditCard = 'CreditCard',
}

export type Payment = CreditCardPayment | DepositPayment | FinancingPayment;

type CreditCardPayment = BasePayment & {
  kind: PaymentKind.CreditCard;
  card: Card;
};

type DepositPayment = BasePayment & {
  kind: PaymentKind.Deposit;
};

type FinancingPayment = BasePayment & {
  kind: PaymentKind.Financing;
};

type BasePayment = {
  id: ID;
  amount: Money;
  billingPartner: string;
  label: string;
};
