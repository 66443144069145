import { contains, propEq } from 'ramda';
import type { ID, Money, Slug } from '@ecomm/models';

export type Product = {
  categories: ID[];
  description: string;
  dimensions: ID[];
  id: ID;
  imageUrls: string[];
  name: string;
  options: ID[];
  price: Money;
  productLine: ProductLine;
  slug: Slug;
};

type ProductLike = Pick<Product, 'slug'>;

export enum ProductLine {
  Bike = 'consumer_bike',
  BikePlus = 'bike-plus',
  Other = 'other',
  DeviceSubscription = 'consumer_subscription',
  DigitalSubscription = 'digital_subscription',
  EquipmentLease = 'equipment_lease',
  Tread = 'tread',
  TreadPlus = 'tread-plus',
  Warranty = 'warranty',
  StoneOcean = 'stoneocean', // TODO: completely replace with BikePlus when possible
  RainforestCafe = 'guide',
  RefurbBike = 'refurbished-bike',
  RefurbBikePlus = 'refurbished-bike-plus',
  Row = 'row',
  // Accessory-specific product lines
  TreadWeight = 'tread_weight',
  TreadAccessory = 'tread_accessory',
  ParcelOnly = 'parcel_only',
  SparePart = 'spare_part',
  SubscriptionPrepaidCredit = 'subscription_prepaid_credit',
}

export const isProductLine = (productLine: string): productLine is ProductLine =>
  Object.values(ProductLine).includes(productLine as ProductLine);

export const TREAD_WELCOME_BOX_SLUG = 'peloton-tread-welcome-box';
export const SUBSCRIPTION_SLUG = 'subscription';
export const CYCLING_SHOE_SLUG = 'cycling-shoes';
export const PELOTON_WEIGHTS_SLUG = 'dumbbells';
export const TREAD_SHOE_SLUG_WOMEN = 'circuit-runner-women';
export const TREAD_SHOE_SLUG_MEN = 'circuit-runner-men';
export const ROW_MAT = 'row-mat';

export type ProductWithCount = Partial<Product> & { count: number };

export const isConfigurable = (product: Product): boolean =>
  !isSubscription(product) && !isWarranty(product) && product.dimensions.length > 0;

export const toIsProduct = (productLine: ProductLine) => (product: Product): boolean =>
  product.productLine === productLine;

export const isBike = toIsProduct(ProductLine.Bike);

export const isBikePlus = toIsProduct(ProductLine.BikePlus);

export const isDeviceSubscription = toIsProduct(ProductLine.DeviceSubscription);

export const isDigitalSubscription = toIsProduct(ProductLine.DigitalSubscription);

export const isTread = toIsProduct(ProductLine.Tread);

export const isTreadPlus = toIsProduct(ProductLine.TreadPlus);

export const isWarranty = toIsProduct(ProductLine.Warranty);

export const isSubscription = (product: Product): boolean =>
  isDeviceSubscription(product) || isDigitalSubscription(product);

export const isSubscriptionProductLine = (productLine: ProductLine) =>
  contains(productLine, [
    ProductLine.DeviceSubscription,
    ProductLine.DigitalSubscription,
  ]);

export const isDevice = (product: Product): boolean =>
  isBike(product) || isBikePlus(product) || isTread(product) || isTreadPlus(product);

export const isTreadWelcomeBox = (productLike: ProductLike) =>
  propEq('slug', TREAD_WELCOME_BOX_SLUG)(productLike);

export const toDeviceName = (productLine: ProductLine) =>
  productLine === ProductLine.TreadPlus ? 'tread' : 'bike';
