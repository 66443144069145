import { always, isNil, map, pipe, pluck, sum, when } from 'ramda';
import type { Money } from '@ecomm/models';

export type Discount = {
  code: string;
  amount: Money;
  description: string;
  isRemovable: boolean;
  displayName: string;
};

export const toTotalDiscount = pipe<Discount[], (number | undefined)[], number[], number>(
  pluck('amount'),
  map(when(isNil, always(0))),
  sum,
);
