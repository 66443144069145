import { brand, grey } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { withI18n } from '@peloton/internationalize-ui/components/withI18n';
import type { Transition } from '@peloton/styles';
import { hoverTransition } from '@peloton/styles';
import { superRed, white, transparentBlack } from '@ecomm/colors';

export const inlineLinkStyles = css`
  cursor: pointer;

  color: inherit;
  font-size: inherit;
  text-decoration: underline;
  text-decoration-color: currentColor;

  transition: opacity 250ms ease;

  &:hover {
    text-decoration: underline;
  }
`;

export const underlineLinkStyle = css`
  cursor: pointer;
  width: fit-content;

  color: inherit;
  font-weight: normal;
  padding-bottom: 1px;

  transition: opacity 250ms ease;

  border-bottom: 1px solid ${brand.darkest};

  &:hover {
    opacity: 0.6;
    border-bottom: 1px solid ${transparentBlack};
  }
`;

export const InlineLink = withI18n(styled.a`
  ${inlineLinkStyles}
`);

export const link = (from: string, to: string, textDecoration?: string) => (
  ...transitions: Transition[]
) => css`
  cursor: pointer;
  text-decoration: ${textDecoration || 'none'};
  ${hoverTransition(
    {
      property: 'color, fill',
      from,
      to,
    },
    ...transitions,
  )};
`;

export const l1 = link(superRed, brand.darkest);

export const l2 = link(brand.darkest, superRed);

export const l3 = link(grey[70], brand.darkest);

export const l3Dark = link(grey[70], brand.darkest);

export const l3Blue = link(grey[70], brand.dark);

export const l3Black = link(
  brand.darkest,
  transparentize(0.3, brand.darkest),
  'underline',
);

export const l4 = link(white, transparentize(0.4, white));

export const L1 = styled.a`
  ${l1()}
`;
export const L2 = styled.a`
  ${l2()}
`;
export const L3 = styled.a`
  ${l3()}
`;
export const L3Dark = styled.a`
  ${l3Dark()}
`;
export const L3Black = styled.a`
  ${l3Black()}
`;
export const L4 = styled.a`
  ${l4()}
`;
