import { grey, Flex, spacing, brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { defaultTransition, hover, underlineActive } from '@peloton/styles';
import { Link } from '@ecomm/internationalize-ui';
import HeaderText from './HeaderText';
import { fadeInAnimation } from './NavAnimations';

type Props = {
  text?: string;
  link: string;
  testId: string;
  isActive: boolean;
  onClick?: React.MouseEventHandler;
};

const HighlightedLinkItem: React.FC<React.PropsWithChildren<Props>> = ({
  text,
  testId,
  link,
  isActive,
  onClick,
}) => {
  return (
    <StyledListItem data-test-id={testId}>
      <StyledLink to={link} href={link} onClick={onClick}>
        <StyledMenuItem>
          <Flex>
            <Text className={isActive ? 'active' : undefined}>{text}</Text>
          </Flex>
        </StyledMenuItem>
      </StyledLink>
    </StyledListItem>
  );
};

export default HighlightedLinkItem;

const StyledListItem = styled.li`
  display: flex;
  ${fadeInAnimation};
`;

const StyledLink = styled(Link)`
  padding-right: ${spacing[12]};
  &::after {
    content: none;
  }
`;

const Text = styled(HeaderText)`
  -webkit-font-smoothing: auto;
`;

const StyledMenuItem = styled.div`
  color: ${brand.accent};

  ${defaultTransition()}

  ${hover`
    color: ${grey[50]}
  `}

  span {
    font-size: 1.125rem;
    ${underlineActive}

    &::after {
      color: currentColor;
    }
  }
`;
