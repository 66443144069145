import React from 'react';
import type { OneWellnessClient } from './OneWellnessClient';

const Context = React.createContext<OneWellnessClient | undefined>(undefined);

const ClientProvider: React.FC<
  React.PropsWithChildren<{
    client: OneWellnessClient;
  }>
> = ({ children, client }) => (
  <Context.Provider value={client}>{children}</Context.Provider>
);

export const useOneWellnessClient = () => React.useContext(Context) as OneWellnessClient;

export default ClientProvider;
