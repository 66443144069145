import { ProductLine } from '../../models';

export const toProductLine = (productLine: string): ProductLine => {
  switch (productLine) {
    case 'consumer_bike':
      return ProductLine.Bike;

    case 'consumer_subscription':
      return ProductLine.DeviceSubscription;

    case 'digital_subscription':
      return ProductLine.DigitalSubscription;

    case 'stoneocean':
      return ProductLine.StoneOcean; // TODO: completely replace with BikePlus when possible

    case 'tread':
      return ProductLine.Tread;

    case 'tread-plus':
      return ProductLine.TreadPlus;

    case 'bike-plus':
      return ProductLine.BikePlus;

    case 'warranty':
      return ProductLine.Warranty;

    case 'guide':
      return ProductLine.RainforestCafe; // TODO: completely replace with Guide when possilbe

    default:
      return ProductLine.Other;
  }
};
