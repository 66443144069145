import React from 'react';
import { oneLine } from '@peloton/text';
import type { IconProps } from './types';

export enum Orientation {
  Left = 90,
  Down = 0,
  Right = -90,
  Up = 180,
}

export type ChevronProps = IconProps & {
  title?: string;
  orientation?: Orientation;
  role?: string;
  rounded?: boolean;
};

const getTransform = (
  orientation: Orientation = Orientation.Down,
): { transform: string } => ({ transform: `rotate3d(0,0,1,${orientation}deg)` });

const Chevron: React.FC<React.PropsWithChildren<ChevronProps>> = React.memo(
  ({ title = 'chevron', orientation, style, role = 'img', rounded = false, ...props }) =>
    rounded ? (
      <svg
        viewBox="0 0 32 32"
        style={{ ...style, ...getTransform(orientation) }}
        role={role}
        {...props}
      >
        <title>{title}</title>
        <g fill="none" fillRule="evenodd">
          <path d="M0 32V0h32v32z" />
          <path
            d="M16 21l10.213-10.213M6 10.787L16 21"
            strokeLinecap="round"
            stroke="#FFF"
            strokeWidth="2"
          />
        </g>
      </svg>
    ) : (
      <svg
        viewBox="0 0 12 7"
        style={{ ...style, ...getTransform(orientation) }}
        role={role}
        {...props}
      >
        <title>{title}</title>
        <path
          d={oneLine(`
          M5.981 4.92l-4.7-4.7A.75.75 0 0 0 .22 1.28l5.163 5.163a.748.748 0 0 0
          .598.217.748.748 0 0 0 .6-.217l5.162-5.163a.75.75 0 0 0-1.06-1.06L5.98 4.92z
        `)}
        />
      </svg>
    ),
);

Chevron.displayName = 'Chevron';

export const ChevronLight: React.FC<React.PropsWithChildren<ChevronProps>> = React.memo(
  ({
    title = 'chevron',
    color = 'white',
    orientation,
    style,
    role = 'img',
    rounded = false,
    ...props
  }) =>
    rounded ? (
      <svg
        viewBox="0 0 32 32"
        style={{ ...style, ...getTransform(orientation) }}
        role={role}
        {...props}
      >
        <title>{title}</title>
        <g fill="none" fillRule="evenodd">
          <path d="M0 32V0h32v32z" />
          <path
            d="M16 21l10.213-10.213M6 10.787L16 21"
            strokeLinecap="round"
            style={{ stroke: color }}
            strokeWidth="2"
          />
        </g>
      </svg>
    ) : (
      <svg
        viewBox="0 0 26 19"
        style={{ ...style, ...getTransform(orientation) }}
        role={role}
        {...props}
      >
        <title>{title}</title>
        <path d="M13 12.49l-8-8M21 4.49l-8.35 8.35" style={{ stroke: color }} />
      </svg>
    ),
);

ChevronLight.displayName = 'ChevronLight';

export default Chevron;
