import { schema } from 'normalizr';
import type { ApiTrait } from '../types';

const processTrait = ({ id, slug, value: name }: ApiTrait) => ({
  id,
  name,
  slug,
});

const trait = new schema.Entity('traits', {}, { processStrategy: processTrait });

export default trait;
