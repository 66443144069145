import { schema } from 'normalizr';
import type { ApiCoupon } from '@ecomm/cart/api/types';

const processCoupons = ({ code, description, id, value }: ApiCoupon) => ({
  code,
  description,
  id,
  value,
});

const coupon = new schema.Entity('coupons', {}, { processStrategy: processCoupons });

export default coupon;
