// States that have privacy policy rules not allowing geolocation fetching without explicit permission
export enum ProtectedStates {
  CALIFORNIA = 'CA',
  COLORADO = 'CO',
  CONNECTICUT = 'CT',
  NEVADA = 'NV',
  UTAH = 'UT',
  VIRGINIA = 'VA',
  WASHINGTON = 'WA',
  MONTANA = 'MT',
  TEXAS = 'TX',
  OREGON = 'OR',
}

export type OneTrustGeoData = {
  country: string;
  state: string;
};
