import { connectRouter } from 'connected-react-router';
import type { ReducersMapObject } from 'redux';
import { combineReducers } from 'redux';
import { reducer as responsiveStateReducer } from '@peloton/responsive';
import history from './history';

const RootReducer = (reducers: ReducersMapObject) =>
  combineReducers({
    // This needs to be the same history reference as the one passed in the app index
    router: connectRouter(history),
    browser: responsiveStateReducer,
    ...reducers,
  });

export default RootReducer;
