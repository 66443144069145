import { HttpLink } from 'apollo-link-http';
import fetch from 'node-fetch';
import { TLD, GRAPHQL_HOST, GRAPHQL_PROD_HOST } from '@peloton/app-config';
import { isUatProdBackendEnv } from '@peloton/env/models';
import { APOLLO_LOCALE_MAP } from '@peloton/internationalize';
import { DEFAULT_LOCALE } from '@peloton/internationalize/models/path';
import { LOCALE_TOGGLE } from '@peloton/internationalize/models/toggle';
const fetchRetry = require('@vercel/fetch-retry');

export const getGraphqlHost = () => {
  if (isUatProdBackendEnv()) {
    return GRAPHQL_PROD_HOST;
  }

  return GRAPHQL_HOST;
};

export const createGraphqlHost = (locale: string) => {
  const singleTld = LOCALE_TOGGLE;
  let tld;
  if (singleTld) {
    tld = APOLLO_LOCALE_MAP[DEFAULT_LOCALE]; // return .com graphql host for i18n urls
  } else if (locale) {
    tld = APOLLO_LOCALE_MAP[locale]; // return locale specific graphql host for TLD-based urls
  } else {
    tld = TLD; // return env TLD
  }
  const graphqlHost = getGraphqlHost();
  return `https://${graphqlHost}${tld}/graphql`;
};

export default new HttpLink({
  uri: `https://${GRAPHQL_HOST}${TLD}/graphql`,
  fetch: fetchRetry(fetch),
  credentials: 'include',
});

export const createApi = (locale?: string) => {
  const tld = locale ? APOLLO_LOCALE_MAP[locale] : TLD;
  const graphqlHost = getGraphqlHost();
  return new HttpLink({
    uri: `https://${graphqlHost}${tld}/graphql`,
    fetch: fetchRetry(fetch),
    credentials: 'include',
  });
};
