import type { SignedInUser } from './models';
import type { Session } from './models/session';
import type { ApiMeUser, ApiSession } from './types';

export const toUser = (apiUser: ApiMeUser): SignedInUser => ({
  kind: 'SIGNED_IN',
  id: apiUser.id,
  email: apiUser.email,
  username: apiUser.username,
  avatarUrl: apiUser.imageUrl,
  memberGroups: apiUser.memberGroups || [],
  hashedEmail: apiUser.obfuscatedEmail,
  createdAt: apiUser.createdAt,
  firstName: apiUser.firstName,
  lastName: apiUser.lastName,
  phoneNumber: apiUser.phoneNumber,
  gender: apiUser.gender,
  weight: apiUser.weight !== 0 ? apiUser.weight : undefined,
  height: apiUser.height !== 0 ? apiUser.height : undefined,
  birthday: apiUser.birthday !== 0 ? apiUser.birthday : undefined,
  isProvisional: apiUser.isProvisional,
  accountCreationMethod: apiUser.accountCreationMethod,
  socialConnections: apiUser.socialConnections,
});

export const toSession = (apiSession: ApiSession): Session => ({
  userId: apiSession.userId,
  sessionId: apiSession.sessionId,
  pubsubSession: apiSession.pubsubSession,
});

export const USER_MAPPER_CONTEXT = 'userMapper';
