import type {
  AccordionHeaderStateProps as TAccordionHeaderStateProps,
  AccordionProps as TAccordionProps,
} from './Accordion';
import Accordion, { AccordionChevron, AutoAccordian } from './Accordion';

export default Accordion;

export { AutoAccordian, AccordionChevron };

export type AccordionHeaderStateProps = TAccordionHeaderStateProps;
export type AccordionProps = TAccordionProps;
