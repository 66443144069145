import { createInstance } from '@optimizely/optimizely-sdk';
import { isBrowser } from '@peloton/browser/identify';
import type { Toggles } from '../models/Toggles';
import { ToggleStatus } from '../models/ToggleStatus';
import { FEATURES } from './features';
import type { OptimizelyToggleId } from './features';
import isDateTriggerFeatureActive from './isDateTriggerFeatureActive';
import type { Attributes } from './models';
import { getId } from './models';

export type DataFiles = {
  uat: object;
  dev: object;
  production: object;
};

interface FeatureToggle {
  original: ToggleStatus;
  active: boolean;
}

const noopEventDispatcher = {
  dispatchEvent: () => {
    return;
  },
};

// per https://docs.developers.optimizely.com/full-stack/v2.1/docs/configure-the-logger#section-default-logging-in-each-sdk
const OPTIMIZELY_WARNING_LOG_LEVEL = 3;
const toClient = (datafile: object) =>
  createInstance({
    datafile,
    logLevel: OPTIMIZELY_WARNING_LOG_LEVEL,
    eventDispatcher: noopEventDispatcher,
  });

const resolveDataFileByEnv = (datafiles: DataFiles, datafileEnv: keyof DataFiles) =>
  datafiles[datafileEnv];

export const toFeatures = (
  attributes: Attributes,
  optimizelyDataFiles: DataFiles,
  datafileEnv: keyof DataFiles,
  nowOverride?: Date,
): Toggles<typeof FEATURES[number]> => {
  const client = toClient(resolveDataFileByEnv(optimizelyDataFiles, datafileEnv));

  const userId = isBrowser ? getId() : '';

  if (client === null) {
    return toDefaults(FEATURES);
  }

  return client.getEnabledFeatures(userId, attributes).reduce(
    (acc, feature) => ({
      ...acc,
      [feature]: {
        active: isDateTriggerFeatureActive(
          client.getAllFeatureVariables(feature, userId, attributes),
          nowOverride,
        ),
        original: ToggleStatus.Optimizely,
      },
    }),
    toDefaults(FEATURES),
  ) as Toggles<typeof FEATURES[number]>;
};

const toDefaults = (
  features: typeof FEATURES,
): Record<OptimizelyToggleId, FeatureToggle> =>
  features.reduce(
    (acc, feature: OptimizelyToggleId) => ({
      ...acc,
      [feature]: { active: false, original: ToggleStatus.Optimizely },
    }),
    {} as Record<OptimizelyToggleId, FeatureToggle>,
  );
