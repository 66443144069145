const mapToView = (data: any) => {
  const content = data['switchPlanPage'];
  const planComparisonTable = content['switchPlanPage.planComparisonTable'] || {};
  return {
    switchPlanTableCategories: extractValues(
      planComparisonTable['switchPlanPage.planComparisonTable.categories'] || {},
    ),
    switchPlanTableProducts: extractValues(
      planComparisonTable['switchPlanPage.planComparisonTable.products'] || {},
    ),
    monthlyBillingCadence: content['membershipMonthlyBillingCadence']['value'],
    yearlyBillingCadence: content['membershipYearlyBillingCadence']['value'],
  };
};

export default mapToView;

const extractValues = (data: object) =>
  Object.values(data).map((item: any) => item.value);
