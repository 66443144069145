import type { AxiosInstance } from 'axios';
import React from 'react';
import { ClientProvider as PClientProvider } from '@peloton/api';
import useAuthorizationHeader from './useAuthorizationHeader';

const ClientProvider: React.FC<React.PropsWithChildren<{ client: AxiosInstance }>> = ({
  children,
  client,
}) => {
  const clientHeadersReady = useAuthorizationHeader(client);

  return clientHeadersReady ? (
    <PClientProvider client={client}>{children}</PClientProvider>
  ) : null;
};

export default ClientProvider;
