import type { PeloLinkType } from '@peloton/external-links';

export const KEY = 'appName';

type State = PeloLinkType;

const defaultState = '' as any;

const reducer = (state: State = defaultState) => state;

export type ReducerState = {
  [KEY]: State;
};

export default reducer;
