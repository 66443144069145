import { prop, propOr } from 'ramda';
import { createSelector } from 'reselect';
import type { PeloLinkType } from '@peloton/external-links';
import type { Language } from '@peloton/internationalize';
import { Locale, toLanguageFromLocale } from '@peloton/internationalize';
import { KEY as appNameKey } from './appName';
import type { ReducerState } from './rootReducer';

const getEnv = (state: ReducerState) => state.env;

export const getFlags = (state: ReducerState) => getEnv(state).flags;

export const getIsProdEnv = createSelector([getFlags], prop('isProd'));

export const getIsStagingEnv = createSelector([getFlags], prop('isStaging'));

export const getIsLocalEnv = createSelector([getFlags], prop('isLocal'));

export const getIsTestEnv = createSelector([getFlags], prop('isTest'));

export const getLocale = (state: ReducerState): Locale =>
  propOr(Locale.Default, 'locale', getEnv(state));

export const getLanguage = (state: ReducerState): Language => {
  const locale = getLocale(state);
  return toLanguageFromLocale(locale);
};

export const getAppName = (state: ReducerState): PeloLinkType =>
  prop(appNameKey, getEnv(state));
