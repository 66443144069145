import { bypassSetEmailCapture, reset as resetBypassSetEmailCapture } from './bypass';
import {
  ActionTypes as DigitalActionTypes,
  errorCheckout,
  paymentInfoEntered,
  resolveError,
} from './digital';
import type {
  NextFromEmailCaptureRequestedAction as TNextFromEmailCaptureRequestedAction,
  PreviousFromCheckoutRequestedAction as TPreviousFromCheckoutRequestedAction,
} from './multiStep';
import {
  ActionTypes as MultiStepActionTypes,
  allowNextFromEmailCapture,
  AllowPreviousFromCheckoutStep,
  denyNextFromEmailCapture,
  DenyPreviousFromCheckoutStep,
  requestNextFromEmailCapture,
  requestPreviousFromCheckoutStep,
} from './multiStep';
import { overridePurchaseAnnualSubscriptionSet } from './overridePurchaseAnnual';
import {
  ActionTypes as RegisterUserActionTypes,
  registerUserSuccess,
  registerUserStart,
  registerUserFail,
} from './registerUser';
import type { ReducerState as TReducerState } from './rootReducer';
import reducer from './rootReducer';
import { saveUserPassword } from './saveUserPassword';
import type { RootState as TRootState } from './selectors';
import {
  getBypass,
  getDigitalCheckoutData,
  getDigitalErrors,
  getOverridePurchaseAnnual,
  getPostalCode,
  getQuickCheckoutData,
  getQuickCheckoutState,
  getUserPassword,
} from './selectors';
import type {
  CheckoutRequestAction as TCheckoutRequestAction,
  ExistingPaymentCheckoutRequestAction as TExistingPaymentCheckoutRequestAction,
} from './ui';
import {
  ActionTypes,
  checkoutReset,
  checkoutSuccess,
  failCheckout,
  requestCheckout,
  requestCheckoutWithCaptcha,
  checkoutLoading,
} from './ui';

export default reducer;

export {
  ActionTypes,
  allowNextFromEmailCapture,
  AllowPreviousFromCheckoutStep,
  bypassSetEmailCapture,
  checkoutLoading,
  checkoutReset,
  checkoutSuccess,
  denyNextFromEmailCapture,
  DenyPreviousFromCheckoutStep,
  DigitalActionTypes,
  errorCheckout,
  failCheckout,
  getBypass,
  getDigitalCheckoutData,
  getDigitalErrors,
  getOverridePurchaseAnnual,
  getUserPassword,
  getPostalCode,
  getQuickCheckoutData,
  getQuickCheckoutState,
  MultiStepActionTypes,
  overridePurchaseAnnualSubscriptionSet,
  saveUserPassword,
  paymentInfoEntered,
  RegisterUserActionTypes,
  registerUserStart,
  registerUserFail,
  registerUserSuccess,
  requestCheckout,
  requestCheckoutWithCaptcha,
  requestNextFromEmailCapture,
  requestPreviousFromCheckoutStep,
  resetBypassSetEmailCapture,
  resolveError,
};

export type CheckoutRequestAction = TCheckoutRequestAction;
export type ExistingPaymentCheckoutRequestAction = TExistingPaymentCheckoutRequestAction;
export type NextFromEmailCaptureRequestedAction = TNextFromEmailCaptureRequestedAction;
export type PreviousFromCheckoutRequestedAction = TPreviousFromCheckoutRequestedAction;
export type ReducerState = TReducerState;
export type RootState = TRootState;
