import {
  toBookingLink,
  toBoutiqueLink,
  toCommercialLink,
  toPeloLink,
} from '@peloton/external-links';

export const blog = toPeloLink('blog', '/');
export const booking = toBookingLink();
export const boutique = toBoutiqueLink('/');
export const commercial = toCommercialLink('/');
export const findYourRide = toPeloLink('findyourride', '/');
export const global = toPeloLink('global', '/');
export const house = toPeloLink('house', '/');
export const hospitality = toPeloLink('hospitality', '/');
export const milestoneLink = toPeloLink('milestone', '/');
