import React from 'react';
import type { ErrorReporter } from './ErrorReporter';
import consoleErrorReporter from './reporters/console';

type Context = {
  errorReporter: ErrorReporter;
};

export default React.createContext<Context>({
  errorReporter: consoleErrorReporter(),
});
