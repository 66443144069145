import { useEffect, useState } from 'react';
import type { Language } from '@peloton/internationalize/models/language';
import { useLocation } from './useLocation';

type OptionsParam = {
  deleteSelectorContent?: boolean;
  makeSelectorVisible?: boolean;
  makeElementsVisible?: string[];
  deleteElements?: string[];
} | null;

declare global {
  interface Window {
    OneTrust?: {
      ToggleInfoDisplay: () => void;
      Close: () => void;
      IsAlertBoxClosed: () => boolean;
      GetDomainData: () => { ConsentModel: { Name: string } };
      getGeolocationData: () => { country: string; state: string };
      changeLanguage: (language: Language) => void;
      InsertHtml: (
        element: string,
        selector: string,
        callback: (() => void) | null,
        options: OptionsParam,
        groupId: string,
      ) => void;
    };
    OptanonWrapper: () => void;
  }
}

type OneTrustHook = {
  isReady: boolean;
  toggleInfoDisplay: () => void;
  getConsentModelType: () => string | undefined;
  getGeolocationData: () => { country: string; state: string } | undefined;
  getIsAlertBoxClosed: () => boolean | undefined;
  geolocationData: { country: string; state: string } | undefined;
  geolocationLoading: boolean;
  isDoNotSellEnabled: boolean;
  doNotSellLink: string;
  insertHTML: (
    element: string,
    selector: string,
    callback: (() => void) | null,
    options: OptionsParam,
    groupId: string,
  ) => void;
};

export const useOneTrust = (): OneTrustHook => {
  const [isReady, setReady] = useState<boolean>(false);
  const { loading: geoLoading, isInProtectedState, geoData } = useLocation();

  useEffect(() => {
    if (window.hasOwnProperty('OneTrust')) {
      setReady(true);
    }
  }, []);

  useEffect(() => {
    // runs on page reload
    // OneTrust SDK looks for a function with this name on the window and calls it
    window.OptanonWrapper = () => {
      if (window.hasOwnProperty('OneTrust')) {
        setReady(true);
      }
    };
  }, []);

  const insertHTML = (
    element: string,
    selector: string,
    callback: (() => void) | null,
    options: OptionsParam,
    groupId: string,
  ) => {
    try {
      window?.OneTrust?.InsertHtml(element, selector, callback, options, groupId);
    } catch (e) {
      console.error('Error inserting HTML in OneTrust', e);
    }
  };

  const toggleInfoDisplay = () => {
    try {
      window?.OneTrust?.ToggleInfoDisplay();
    } catch (e) {
      console.error('Error toggling info display in OneTrust', e);
    }
  };

  const getConsentModelType = () => {
    try {
      return window?.OneTrust?.GetDomainData()?.ConsentModel.Name;
    } catch (e) {
      console.error('Error getting consent model type from OneTrust', e);
      return undefined;
    }
  };

  const getGeolocationData = () => {
    try {
      return window?.OneTrust?.getGeolocationData();
    } catch (e) {
      console.error('Error getting geolocation data from OneTrust', e);
      return { country: '', state: '' };
    }
  };

  const getIsAlertBoxClosed = () => {
    try {
      return window?.OneTrust?.IsAlertBoxClosed();
    } catch (e) {
      console.error('Error getting alert box closed status from OneTrust', e);
      return undefined;
    }
  };

  return {
    isReady,
    toggleInfoDisplay,
    getConsentModelType,
    getGeolocationData,
    geolocationData: geoData,
    geolocationLoading: geoLoading,
    getIsAlertBoxClosed,
    isDoNotSellEnabled: isInProtectedState === true,
    doNotSellLink: 'https://preferences.onepeloton.com',
    insertHTML,
  };
};
