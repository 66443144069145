import { black, brand, Eyebrow, grey, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useContext } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { theUserIsInAustralia } from '@peloton/internationalize/consolidatedAppConfigs/utils';
import { useLocale } from '@peloton/next/hooks/useLocale';
import useNav from '@content/client/www/nav/useNav';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { zIndex } from '@ecomm/header/constants';
import { HeaderNavContext } from '@ecomm/header/HeaderNavProvider';
import { Link } from '@ecomm/internationalize-ui';
import { useGetShowroomStatus } from '@ecomm/showrooms/hooks';
import { hb6, b4 } from '@ecomm/typography';
import MyShowrooms from './MyShowrooms';
import StudioLink from './StudioLink';
import VirtualShowroomLink from './VirtualShowroomLink';

const GeoPickerMenu: React.FC<
  React.PropsWithChildren<{ isWithinHamburgerMenu?: boolean }>
> = ({ isWithinHamburgerMenu = false }) => {
  const locale = useLocale();
  const { hideMenu } = useContext(HeaderNavContext);
  const isToggleActive = useIsToggleActive();
  const isUseMyLocationActive = !theUserIsInAustralia(locale);
  const showStudioSection = isToggleActive('studioInNav');
  const areVirtualShowroomsActive = theUserIsInAustralia(locale);
  const showroomStatus = useGetShowroomStatus();
  const showBookingLink = showroomStatus === 'OPEN' && !theUserIsInAustralia(locale);
  const { trackEvent } = useTracking();
  const { content, isLoading } = useNav(locale);

  if (isLoading) {
    return null;
  }

  const geoMenuContent = content?.geoMenu?.['navigation.geoMenu'];

  const findAStore = geoMenuContent?.['nav.geo.findStore']?.text;
  const viewAllStores = geoMenuContent?.['nav.geo.viewAll'];
  const testRide = geoMenuContent?.['nav.geo.testRide'];
  const whereToTry = geoMenuContent?.['nav.geo.whereToTry'];

  const useMyLocationText = geoMenuContent?.['nav.geo.location'];
  const noShowroomsText = geoMenuContent?.['nav.geo.noShowroomsError']?.text;
  const geolocationErrorText = geoMenuContent?.['nav.geo.unknownError']?.text;

  return (
    <StyledMenu
      usePopupMenuStyle={!isWithinHamburgerMenu}
      data-test-id={'locationCardOpen'}
    >
      {isWithinHamburgerMenu ? (
        <Eyebrow size="medium">{findAStore}</Eyebrow>
      ) : (
        <FindAStore>{findAStore}</FindAStore>
      )}

      <ul>
        {isUseMyLocationActive && (
          <li>
            <MyShowrooms
              useMyLocationText={useMyLocationText.text}
              noShowroomsText={noShowroomsText}
              geolocationErrorText={geolocationErrorText}
              useHamburgerMenuStyles={isWithinHamburgerMenu}
            />
          </li>
        )}
        <li>
          <MenuLink
            usePopupMenuStyle={!isWithinHamburgerMenu}
            to={viewAllStores.url}
            href={viewAllStores.url}
            onClick={() =>
              trackEvent({
                properties: {
                  category: 'location',
                  unitName: 'nav.geo.viewAll',
                  linkTo: viewAllStores.url,
                  linkName: viewAllStores.text,
                },
              })
            }
          >
            {viewAllStores.text}
          </MenuLink>
        </li>
        {areVirtualShowroomsActive ? <VirtualShowroomLink hideMenu={hideMenu} /> : null}
        {showBookingLink ? (
          <li>
            <MenuLink
              usePopupMenuStyle={!isWithinHamburgerMenu}
              to={testRide.url}
              href={testRide.url}
              onClick={() => {
                trackEvent({
                  properties: {
                    category: 'location',
                    unitName: 'nav.geo.testRide',
                    linkTo: testRide.url,
                    linkName: testRide.text,
                  },
                });
              }}
            >
              {testRide.text}
            </MenuLink>
          </li>
        ) : null}
        {whereToTry && (
          <li>
            <MenuLink
              usePopupMenuStyle={!isWithinHamburgerMenu}
              to={whereToTry.text}
              href={whereToTry.url}
              onClick={() => {
                trackEvent({
                  properties: {
                    category: 'location',
                    unitName: 'nav.geo.whereToTry',
                    linkTo: whereToTry.url,
                    linkName: whereToTry.text,
                  },
                });
              }}
            >
              {whereToTry.text}
            </MenuLink>
          </li>
        )}
      </ul>
      {showStudioSection ? <StudioLink /> : null}
    </StyledMenu>
  );
};

export default GeoPickerMenu;

const StyledMenu = styled.div<{ usePopupMenuStyle: boolean }>`
  ${({ usePopupMenuStyle }) =>
    usePopupMenuStyle
      ? `
          position: absolute;
          top: calc(100% - 4px); /* height of the Utilities menu + offset for caret*/
          background-color: ${white};
          border-radius: 3px;
          box-shadow: 0 30px 70px 0 ${rgba(black, 0.11)};
          z-index: ${zIndex.HEADER};`
      : ''};

  width: 395px;
  padding: 40px 0 0;
  right: 2rem;
  color: ${({ usePopupMenuStyle }) =>
    usePopupMenuStyle ? `${brand.darkest}` : `${white}`};

  ul {
    padding: ${({ usePopupMenuStyle }) =>
      usePopupMenuStyle ? '24px 40px 32px' : '16px 0'};
    li {
      padding-bottom: 16px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    a:hover {
      color: ${({ usePopupMenuStyle }) =>
        usePopupMenuStyle ? `${grey[70]}` : `${grey[50]}`};
    }
    button {
      width: auto;
      padding: 0;
      svg {
        width: 12px;
        height: auto;
        display: inline;
        vertical-align: middle;
      }
      &:hover {
        color: ${({ usePopupMenuStyle }) =>
          usePopupMenuStyle ? `${grey[70]}` : `${grey[50]}`};
      }
    }
  }

  h3.light {
    color: #777a81;
  }
`;

const FindAStore = styled.h2`
  ${hb6}
  margin-left: 40px;
`;

const MenuLink = styled(Link)<{ usePopupMenuStyle: boolean }>`
  ${({ usePopupMenuStyle }) =>
    usePopupMenuStyle
      ? ''
      : `
      color: ${grey[30]};
      &:hover {
        color: ${grey[50]};
      }
  `}

  span {
    ${b4}
    text-transform: none;
  }

  &:after {
    display: none;
  }
`;
