import { brand } from '@pelotoncycle/design-system';
import React from 'react';
import type { CSSProperties } from 'react';
import type { LogoProps } from './types';

type PelotonLogoProps = LogoProps & { title?: string };

/**
 * The full Peloton logo, complete with the "Peloton" name
 */
const Peloton: React.FC<React.PropsWithChildren<PelotonLogoProps>> = ({
  title = 'Peloton Logo',
  ...otherProps
}) => (
  <svg role="img" width="156" height="46" viewBox="0 0 156 46" {...otherProps}>
    <title>{title}</title>
    <path d="M27.04 11.065l3.31-5.745A3.515 3.515 0 0 0 29.067.522a3.504 3.504 0 0 0-4.79 1.287l-3.31 5.743c-7.055-2.227-14.952.655-18.804 7.337A16.143 16.143 0 0 0 .908 28.305l6.113-10.606a10.456 10.456 0 0 1 6.387-4.91 10.436 10.436 0 0 1 7.982 1.053c5.023 2.905 6.75 9.362 3.85 14.392-2.9 5.032-9.346 6.762-14.368 3.857l3.506-6.083a3.503 3.503 0 0 0 4.79-1.285 3.516 3.516 0 0 0-1.284-4.797 3.504 3.504 0 0 0-4.79 1.285L1.91 40.614a3.515 3.515 0 0 0 1.283 4.797 3.502 3.502 0 0 0 4.79-1.286l3.311-5.743c7.054 2.227 14.952-.655 18.804-7.338 3.852-6.682 2.395-14.974-3.059-19.98M47.227 22.83c1.567 0 2.565-.705 2.565-2.411 0-1.728-1.044-2.386-2.565-2.386h-2.158v4.796h2.158zm-5.336-7.596h5.426c3.156 0 5.745 1.569 5.745 5.185 0 3.616-2.611 5.21-5.745 5.21H45.07V30.7h-3.18V15.234zm15.84 0h10.217v2.799h-7.039v3.546h6.2v2.729h-6.2v3.595h7.358V30.7H57.73V15.234m15.886 0h3.179v12.669h6.994V30.7H73.616V15.234m26.437 7.732c0-2.887-1.907-5.094-4.722-5.094-2.817 0-4.723 2.207-4.723 5.094 0 2.89 1.906 5.096 4.723 5.096 2.815 0 4.722-2.207 4.722-5.096zm-12.715 0c0-4.57 3.542-7.937 7.993-7.937 4.45 0 7.99 3.367 7.99 7.937 0 4.572-3.54 7.94-7.99 7.94s-7.993-3.368-7.993-7.94zm23.814-4.933h-4.404v-2.8h11.988v2.8h-4.404V30.7h-3.18V18.033m23.704 4.933c0-2.887-1.907-5.094-4.722-5.094-2.817 0-4.724 2.207-4.724 5.094 0 2.89 1.907 5.096 4.724 5.096 2.815 0 4.722-2.207 4.722-5.096zm-12.716 0c0-4.57 3.543-7.937 7.994-7.937 4.45 0 7.992 3.367 7.992 7.937 0 4.572-3.542 7.94-7.992 7.94-4.451 0-7.994-3.368-7.994-7.94zm25.696.546c-.771-.727-1.84-2.115-1.84-2.115s.229 1.706.229 2.82v6.484h-2.975V15.097h.317l7.788 7.325c.75.704 1.816 2.113 1.816 2.113s-.203-1.75-.203-2.818v-6.483h2.974v15.602h-.316l-7.79-7.324" />
  </svg>
);

/**
 * The small Peloton logo, a.k.a. the "Peloton P"
 */
const PelotonMark: React.FC<React.PropsWithChildren<PelotonLogoProps>> = ({
  title = 'Peloton Logo',
  ...otherProps
}) => (
  <svg role="img" width="25" height="36" viewBox="0 0 25 36" {...otherProps}>
    <title>{title}</title>
    <path d="M20.95 8.533l2.566-4.451a2.724 2.724 0 0 0-.994-3.717 2.715 2.715 0 0 0-3.712.996l-2.565 4.45C10.78 4.085 4.66 6.318 1.676 11.496A12.508 12.508 0 0 0 .704 21.89l4.736-8.218a8.101 8.101 0 0 1 4.948-3.804 8.086 8.086 0 0 1 6.186.816c3.891 2.251 5.23 7.253 2.983 11.151-2.248 3.899-7.242 5.239-11.133 2.988l2.716-4.713c1.3.752 2.96.305 3.711-.996a2.724 2.724 0 0 0-.994-3.717 2.715 2.715 0 0 0-3.712.996L1.48 31.428a2.723 2.723 0 0 0 .995 3.717c1.3.752 2.961.305 3.71-.996l2.567-4.45c5.465 1.725 11.584-.508 14.569-5.686 2.984-5.177 1.855-11.602-2.37-15.48" />
  </svg>
);

/**
 * The text-only Peloton logo
 */
const PelotonText: React.FC<
  React.PropsWithChildren<PelotonLogoProps & { fill?: CSSProperties['fill'] }>
> = ({ title = 'Peloton Logo', fill = brand.darkest, ...otherProps }) => (
  <svg
    height="24"
    viewBox="0 0 117 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <title>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.42905 7.86178C7.02302 7.86178 8.03815 7.15218 8.03815 5.43234C8.03815 3.69099 6.9764 3.02783 5.42905 3.02783H3.23438V7.86178H5.42905V7.86178ZM0 0.207031H5.51921C8.72921 0.207031 11.3623 1.78815 11.3623 5.4324C11.3623 9.07722 8.70619 10.6825 5.51921 10.6825H3.23225V15.7947H0V0.207031V0.207031Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1094 0.207031H26.5009V3.02789H19.3416V6.60193H25.6471V9.352H19.3416V12.9748H26.8253V15.7947H16.1094V0.207031"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2656 0.207031H35.4986V12.9748H42.6122V15.7947H32.2656V0.207031"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.1536 7.99951C59.1536 5.0894 57.2138 2.86548 54.3506 2.86548C51.4858 2.86548 49.5469 5.0894 49.5469 7.99951C49.5469 10.911 51.4858 13.1351 54.3506 13.1351C57.2138 13.1351 59.1536 10.911 59.1536 7.99951V7.99951ZM46.2188 7.99942C46.2188 3.39345 49.8213 0 54.348 0C58.874 0 62.4756 3.39345 62.4756 7.99942C62.4756 12.6069 58.874 16 54.348 16C49.8213 16 46.2188 12.6069 46.2188 7.99942V7.99942Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.4487 3.02789H65.9688V0.207031H78.1626V3.02789H73.6826V15.7947H70.4487V3.02789"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.561 7.99951C94.561 5.0894 92.6212 2.86548 89.7579 2.86548C86.8932 2.86548 84.9531 5.0894 84.9531 7.99951C84.9531 10.911 86.8932 13.1351 89.7579 13.1351C92.6212 13.1351 94.561 10.911 94.561 7.99951V7.99951ZM81.625 7.99942C81.625 3.39345 85.2281 0 89.7552 0C94.2818 0 97.8839 3.39345 97.8839 7.99942C97.8839 12.6069 94.2818 16 89.7552 16C85.2281 16 81.625 12.6069 81.625 7.99942V7.99942Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.758 8.5496C106.974 7.81622 105.888 6.41778 105.888 6.41778C105.888 6.41778 106.119 8.13686 106.119 9.25976V15.7941H103.094V0.0683594H103.416L111.338 7.45086C112.1 8.16046 113.185 9.5804 113.185 9.5804C113.185 9.5804 112.978 7.81622 112.978 6.73975V0.20651H116.002V15.9308H115.681L107.758 8.5496"
      fill={fill}
    />
  </svg>
);

export { Peloton, PelotonMark, PelotonText };
