import type { Locale } from '@peloton/internationalize/models/locale';
import { parseAggregateData } from '../utils/parseAggregateData';
import fetchBuilderData from './fetchBuilderData';
import fetchWWWBuilderData from './fetchWWWBuilderData';

export const fetchBuilderContentAggregate = async (entryId: string, locale: Locale) => {
  const peloContentBuilderPath = `/peloContentEntryHandler/${locale}/${entryId}`;
  const globalComponentData = await fetchBuilderData(peloContentBuilderPath);
  return parseAggregateData(globalComponentData, entryId);
};

export const fetchWWWBuilderContentAggregate = async (
  entryId: string,
  locale: Locale,
) => {
  const peloContentBuilderPath = `/peloContentEntryHandler/${locale}/${entryId}`;
  const globalComponentData = await fetchWWWBuilderData(peloContentBuilderPath);
  return parseAggregateData(globalComponentData, entryId);
};
