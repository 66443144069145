import type { SagaIterator } from 'redux-saga';
import { takeEvery, select, put, getContext, call } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { Errors } from '@ecomm/checkout';
import type { applyPromo } from '@ecomm/checkout/redux';
import {
  PromoActionTypes,
  getPromoField,
  applyPromoSuccess,
  applyPromoFailure,
} from '@ecomm/checkout/redux';
import { validatePromoCode as validatePromoCodeQuery } from '../api';

export const validatePromoCodeSaga = function* (
  action: ReturnType<typeof applyPromo>,
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  const promoCode = yield select(getPromoField);

  try {
    yield call(validatePromoCodeQuery, client, {
      code: promoCode,
      sku: action.payload.subscriptionSku,
    });
    yield put(applyPromoSuccess());
  } catch (e) {
    yield put(applyPromoFailure(Errors.InvalidPromoCode));
  }
};

const watcherSaga = function* () {
  yield takeEvery(PromoActionTypes.ApplyPromo, validatePromoCodeSaga);
};

export default watcherSaga;
