import { pipe, useWith as ramdaUseWith, pick, not } from 'ramda';
import equal from 'react-fast-compare';
import { shouldUpdate } from 'recompose';

/** Will only render if previous props and next props are equal (**deep** equality) */
export default function <T>(s: ReadonlyArray<keyof T>) {
  const propPicker = pick(s as ReadonlyArray<string>);
  const equalFn: (
    a: typeof propPicker,
    b: typeof propPicker,
  ) => boolean = ramdaUseWith(equal as any, [propPicker, propPicker]);

  return shouldUpdate(pipe(equalFn, not));
}
