import { addIndex, map, fromPairs, sort, cond } from 'ramda';

export const bikes = [
  'bike-ruby',
  'bike-international',
  'bike-de',
  'bike-au',
  'ba02-0011', // Bike+ product option passed for CA
  'bike-plus-au',
  'bike-plus-na',
  'bike-plus-eu',
  'bike-refurbished-consumer',
  'bike-refurbished-pl-11-r-a',
  'bikeplus-refurbished-consumer-na',
  // commercetools
  'ba01-0010',
  'bike-plus-eu-au',
  'bike-plus-us',
  'bike-plus-ca',
  'ba01-0008',
  'ba01-0009',
];

export const treads = [
  'tread',
  'peloton-tread-with-box',
  'tread-na',
  'ta02-0003',
  'ta02-0001', // Tread product option passed for CA
  'tread-eu',
  'tread-plus-na',
  'tread-au',
  // commercetools
  'tread-ca',
  'tread-us',
];

const guides = [
  'guide-na',
  'guide-uk',
  'guide-au',
  // commercetools
  'th01-0001',
  'th01-0002',
  'th01-0004',
];

export const rows = ['row-us', 'rw01-0001'];

const equipment = [...bikes, ...treads, ...guides, ...rows];

const weights = [
  'peloton-weights',
  'tread-weight',
  'tread-free-weights',
  'dumbbells', // Dumbbells Variable pricing (Parent Product)
  'dumbbells-pkg', // Dumbbells
  'dumbbells-rogue-na',
];

const mats = [
  'bike-mat-v2',
  'reversible-workout-mat',
  'row-mat',
  'se-workout-mat',
  'tread-exercise-mat',
];

const accessories = [
  'cycling-shoes',
  'resistance-band',
  'resistance-bands', // new resistance bands in the DE tread package
  ...weights,
  'urbanears-sumpan-earbuds',
  'heart-rate-monitor-2',
  ...mats,
  'jblx-peloton-earbuds',
  'jblx-peloton-earbuds-2',
  'peloton-glass-water-bottle',
  'podium-sport-bottle',
  'hrm-accelerometer',
  'pl-hr-c-01',
  'heart-rate-band',
];

const replacements = ['touchscreen-with-cover', 'pedal_set'];

const warrantiesCommercetools = [
  'limited-bike-warr-au',
  'limited-bike-warr-ca',
  'limited-bike-warr-de',
  'limited-bike-warr-na',
  'limited-bike-warr-uk',
  'limited-bike-plus-warr-au',
  'limited-bike-plus-warr-ca',
  'limited-bike-plus-warr-de',
  'limited-bike-plus-warr-uk',
  'limited-bike-plus-warr-us',
  'limited-warr-tread-au',
  'limited-warr-tread-ca',
  'limited-warr-tread-de',
  'limited-warr-tread-uk',
  'limited-warr-tread-us',
  'limited-guide-warr-au',
  'limited-guide-warr-ca',
  'limited-guide-warr-uk',
  'limited-guide-warr-us',
  'limited-warr-row-us',
];

const intangibles = [
  'delivery',
  'delivery-no-assembly',
  'warranty',
  'peloton-bike-service-plan',
  'peloton-tread-service-plan',
  '1-year-standard-peloton',
  'bike-warr-au',
  'bike-warr-ca',
  'bike-warr-de',
  'bike-warr-na',
  'bike-warr-uk',
  'bike-plus-warr-au',
  'bike-plus-warr-de',
  'bike-plus-warr-us',
  'bike-plus-warr-uk',
  'bike-plus-warr-ca',
  'tread-plus-warr-us',
  // legacy tread warranties
  'tread-warr-us',
  'tread-warr-ca',
  'tread-warr-uk',
  // *new* tread warranties
  'warr-tread-au',
  'warr-tread-us',
  'warr-tread-uk',
  'warr-tread-ca',
  'warr-tread-de', // hasn't been created yet as of 20201026
  // guide warranties
  'guide-warr-au',
  'guide-warr-ca',
  'guide-warr-uk',
  'guide-warr-us',
  // row warranties
  'ext-warr-row-us',
];

const productSlugOrder = [
  ...equipment,
  ...accessories,
  ...intangibles,
  ...warrantiesCommercetools,
  // for touchscreen replacement bundle
  ...replacements,
];

const indexedMap = addIndex<string, [string, number]>(map);
const productSlugsWithIndex = fromPairs(
  indexedMap((p, index) => [p, index], productSlugOrder),
);

const getRank = cond([
  [
    slug => productSlugsWithIndex[slug] !== undefined,
    slug => productSlugsWithIndex[slug],
  ],
  // default to placing unknown slugs in the middle
  [() => true, () => Math.floor(productSlugOrder.length / 2)],
]);

export function sortProducts<T extends { slug: string }>(products: T[]): T[] {
  return sort<T>((a, b) => {
    const rankA = getRank(a.slug);
    const rankB = getRank(b.slug);
    return rankA === rankB ? (a.slug > b.slug ? 1 : -1) : rankA > rankB ? 1 : -1;
  })(products);
}
