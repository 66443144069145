import { Buffer } from 'buffer';
import { isEmpty } from 'ramda';
import { toAnalyticsKeysWithOverrides } from './formatters';

const toEncodedHeader = (obj: Object) =>
  Buffer.from(JSON.stringify(obj)).toString('base64');

export const toClientDetailsHeader = <T extends { [K: string]: any }>(
  clientDetails?: ClientDetails<T> | string,
) => {
  // Support legacy code until callisto migrates
  if (!clientDetails) {
    return {};
  }
  return isEmpty(clientDetails)
    ? {}
    : {
        headers: { 'Peloton-Client-Details': toEncodedClientDetails(clientDetails) },
      };
};

export const toEncodedClientDetails = <T extends { [K: string]: any }>(
  clientDetails?: ClientDetails<T> | string,
) => {
  if (!clientDetails) {
    return '';
  }
  const details = isLegacySource(clientDetails)
    ? { Source: clientDetails }
    : fromAnyPropsToStringProps(clientDetails);
  return toEncodedHeader(toAnalyticsKeysWithOverrides(details));
};

/* The following two functions are used in Members where we don't want
to stringify the client details to maintain lists in analytics headers.*/
export const toClientDetailsHeaderWithoutStringify = <T extends { [K: string]: any }>(
  clientDetails?: ClientDetails<T> | string,
) => {
  // Support legacy code until callisto migrates
  if (!clientDetails) {
    return {};
  }
  return isEmpty(clientDetails)
    ? {}
    : {
        headers: {
          'Peloton-Client-Details': toEncodedClientDetailsWithoutStringify(clientDetails),
        },
      };
};

export const toEncodedClientDetailsWithoutStringify = <T extends { [K: string]: any }>(
  clientDetails?: ClientDetails<T> | string,
) => {
  if (!clientDetails) {
    return '';
  }
  const details = isLegacySource(clientDetails)
    ? { Source: clientDetails }
    : clientDetails;
  return toEncodedHeader(toAnalyticsKeysWithOverrides(details));
};

export const toClientDetails = <T extends { [K: string]: string }>(
  screenProps: T,
  source: string,
): ClientDetails<T> => ({
  ...(screenProps as any),
  Source: source,
});

const isLegacySource = (details: ClientDetails<any> | string): details is string =>
  typeof details === 'string';

export type ClientDetails<T extends { [K: string]: any }> = T & { Source?: string };

export const fromAnyPropsToStringProps = <T extends Record<string, any>>(
  props: T,
): Record<keyof T, string> => {
  Object.keys(props).forEach(key => {
    if (typeof props[key] !== 'string') {
      // TODO: is this a TS bug?
      (props as Record<string, any>)[key] = String(props[key]);
    }
  });
  return props;
};
