import type { Credentials } from '@peloton/auth';
import type { Country } from '@peloton/internationalize';
import { toCountry } from '@peloton/internationalize';

type ConfirmPassword = {
  confirmPassword: string;
};

export type Registration = Credentials & {
  allowMarketing: boolean;
  hasAcceptedPolicy: boolean;
};

export type RegistrationForm = ConfirmPassword & Registration;

export type RegistrationWithCountry = Registration & { country: Country };

export const toRegistrationWithCountry = (
  data: Registration,
): RegistrationWithCountry => ({
  ...data,
  country: toCountry().toUpperCase() as Country,
});
