import type { Reducer } from 'redux';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { PaymentMethod, Token } from '@ecomm/models';
import { getInitialExeption } from '../api/exceptions';
import type { OrderReceipt, OrderType } from '../models';

export type State = {
  receipt?: OrderReceipt;
  isLoading: boolean;
  exception?: Exception;
};

const defaultState: State = {
  isLoading: false,
  exception: getInitialExeption(),
};

export type OrderSubmitPayload = {
  kind: OrderType;
  method: PaymentMethod;
  token: Token;
  captchaToken?: string;
  captchaVersion?: string;
  onSuccess?(): void;
  onError?(error: any): void;
};

// Actions
export type OrderSubmitAction = {
  type: Actions.OrderSubmit;
  payload: OrderSubmitPayload;
};
export type OrderSubmitFailureAction = {
  type: Actions.OrderSubmitFailure;
  payload: Exception;
};

export enum Actions {
  OrderSubmit = 'ecomm/checkout/ORDER_SUBMIT',
  OrderSubmitFailure = 'ecomm/checkout/ORDER_SUBMIT_FAILURE',
}

export type Action = OrderSubmitAction | OrderSubmitFailureAction;

// Reducer
const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.OrderSubmit:
      return {
        isLoading: true,
      };
    case Actions.OrderSubmitFailure:
      return {
        isLoading: false,
        exception: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  submitOrder: State;
};

// Action Creators
export type SubmitOrder = (data: OrderSubmitPayload) => OrderSubmitAction;

export const submitOrder: SubmitOrder = data => ({
  type: Actions.OrderSubmit,
  payload: data,
});

export const submitOrderFailure = (error: Error): OrderSubmitFailureAction => ({
  type: Actions.OrderSubmitFailure,
  payload: toException(`checkout.errors.order.${error.message}`),
});

// Selectors
export const loadingSelector = (state: State): boolean => state.isLoading;

export const exceptionSelector = (state: State): Exception | undefined => state.exception;
