import { gql } from '@apollo/client';
import type { ApolloClient, ApolloQueryResult } from 'apollo-client';

export const toData = <T>(response: ApolloQueryResult<T>) => response.data;

export const getPartnerByHashId = (client: ApolloClient<any>, hashId: string) =>
  client
    .query({
      query: GET_PARTNER_BY_HASH_ID,
      variables: { hashId },
    })
    .then(toData);

const GET_PARTNER_BY_HASH_ID = gql`
  query PartnerByHashId($hashId: String!) {
    partnerByHashId(hashId: $hashId) {
      id
      name
    }
  }
`;

export const getPartnerBySlug = (client: ApolloClient<any>, slug: string) =>
  client
    .query({
      query: GET_PARTNER_BY_SLUG,
      variables: { slug },
    })
    .then(toData);

const GET_PARTNER_BY_SLUG = gql`
  query PartnerBySlug($slug: String!) {
    partnerBySlug(slug: $slug) {
      federatedConnection
    }
  }
`;
