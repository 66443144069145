import type { ApolloQueryResult } from '@apollo/client';
import type { ClientError } from '@peloton/api';
import { isHttpError } from '@peloton/api';

export const toData = <T>(response: ApolloQueryResult<T>) => response.data;

export enum ErrorCode {
  BenefitHubError = 'one-wellness-error',
  ClaimedBenefitHubToken = 'claimed-access-token',
  EnrollmentNotCovered = 'enrollment-not-covered',
  ExpiredBenefitHubToken = 'access-token-expired',
  InvalidBenefitHubToken = 'invalid-access-token',
  ActiveSharedSubscription = 'active-shared-subscription',
  ActiveMultipleSubscriptions = 'active-multiple-subscriptions',
  ActiveConcessionarySubscription = 'active-concessionary-subscriptions',
  InvalidEmail = 'invalid-email',
}

const apiErrorMap = {
  1300: ErrorCode.BenefitHubError,
  1301: ErrorCode.InvalidBenefitHubToken,
  1302: ErrorCode.ExpiredBenefitHubToken,
  1303: ErrorCode.ClaimedBenefitHubToken,
  1304: ErrorCode.EnrollmentNotCovered,
  1310: ErrorCode.ActiveSharedSubscription,
  1311: ErrorCode.ActiveMultipleSubscriptions,
  1312: ErrorCode.ActiveConcessionarySubscription,
  1317: ErrorCode.InvalidEmail,
};

export const toErrorCode = (error: ClientError): ErrorCode => {
  const apiErrorCode = isHttpError(error) ? error.responseBody?.errorCode : undefined;

  return apiErrorMap[apiErrorCode] || ErrorCode.BenefitHubError;
};

export const toErrorCodeFromString = (
  string: string | null | undefined,
): ErrorCode | undefined => {
  const errorsPrefix = 'errors.';

  if (string?.length) {
    let errorCode = string;

    if (string.startsWith(errorsPrefix)) {
      errorCode = string.slice(errorsPrefix.length);
    }
    //https://stackoverflow.com/questions/62821682/how-can-i-cast-a-string-to-an-enum-in-typescript
    if (Object.values(ErrorCode).some((error: string) => error === errorCode)) {
      return <ErrorCode>errorCode;
    } else return ErrorCode.BenefitHubError;
  }

  return undefined;
};
