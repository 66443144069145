import React from 'react';
import type { ExtLinkEnv } from '../models';

const Context = React.createContext<ExtLinkEnv | undefined>(undefined);

export const ExtLinkEnvProvider: React.FC<
  React.PropsWithChildren<{ extLinkEnv: ExtLinkEnv }>
> = ({ children, extLinkEnv }) => (
  <Context.Provider value={extLinkEnv}>{children}</Context.Provider>
);

export const useExtLinkEnv = () => {
  const extLinkEnv = React.useContext(Context);
  if (!extLinkEnv) {
    throw Error('ExtLinkEnv initialize without value');
  }

  return extLinkEnv;
};
