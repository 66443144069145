import { lensProp, set } from 'ramda';
import type { Credentials } from '@peloton/auth';
import { toException } from '@ecomm/exceptions';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';

export enum ActionTypes {
  RegisterUserStart = 'ecomm/quick-checkout/REGISTER_USER_START',
  RegisterUserSuccess = 'ecomm/quick-checkout/REGISTER_USER_SUCCESS',
  RegisterUserFailed = 'ecomm/quick-checkout/REGISTER_USER_FAILED',
}

type State = {
  status: UIState;
};

export const defaultState = {
  status: toUIState(Status.Init) as UIState,
};

const reducer = (state = defaultState, action: Action) => {
  const updateLens = lensProp('status');

  switch (action.type) {
    case ActionTypes.RegisterUserStart: {
      return set(updateLens, toUIState(Status.Loading), state);
    }
    case ActionTypes.RegisterUserSuccess: {
      return set(updateLens, toUIState(Status.Loaded), state);
    }
    case ActionTypes.RegisterUserFailed: {
      const { error } = action.payload;
      const exception = toException(error);
      return set(updateLens, toUIState(Status.Failed, exception), state);
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  registerUser: State;
};

export type RegistrationPayload = Credentials & {
  allowMarketing: boolean;
  hasAcceptedPolicy: boolean;
  onRegistrationSuccess: (userId?: string) => void | Promise<void>;
  isFreeCheckoutOauthEnabled?: boolean;
};

export const registerUserStart = (data: RegistrationPayload) =>
  <const>{
    type: ActionTypes.RegisterUserStart,
    payload: data,
  };

export const registerUserFail = (error: string) =>
  <const>{
    type: ActionTypes.RegisterUserFailed,
    payload: { error },
  };

export const registerUserSuccess = () =>
  <const>{
    type: ActionTypes.RegisterUserSuccess,
  };

export type saveUserPasswordStartAction = ReturnType<typeof registerUserStart>;
export type SaveUserPasswordFailedAction = ReturnType<typeof registerUserFail>;
export type SaveUserPasswordSuccessAction = ReturnType<typeof registerUserSuccess>;

type Action =
  | saveUserPasswordStartAction
  | SaveUserPasswordFailedAction
  | SaveUserPasswordSuccessAction;
