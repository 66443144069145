import type { Attribution } from '@ecomm/checkout/models';
import { Partner } from '@ecomm/checkout/models';

export enum ActionTypes {
  AttributionSet = 'ecomm/checkout/ATTRIBUTION_SET',
}

export const defaultState = {
  type: Partner.Peloton,
};

const reducer = (state: Attribution = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.AttributionSet: {
      const { code, type } = action.payload;
      return {
        ...state,
        code,
        type,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  attribution: Attribution;
};

export const attributionSet = (type: Partner, code?: string) =>
  <const>{
    type: ActionTypes.AttributionSet,
    payload: { code, type },
  };

export type AttributionSetAction = ReturnType<typeof attributionSet>;

type Action = AttributionSetAction;
