import type { Currency } from './currency';

const toFormats = (currency: Currency) => ({
  number: {
    currency: {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    currencyWithCents: {
      style: 'currency',
      currency,
    },
    currencyWithCentsIfProvided: {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
  time: {
    hourAndMinute: {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
      hour12: true,
    },
    militaryTime: {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
      hour12: false,
    },
  },
  date: {
    monthDay: {
      month: 'numeric',
      day: 'numeric',
    },
    weekdayMonthDay: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    },
    weekdayShortMonthShortDayNumeric: {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    },
  },
});

export default toFormats;
