import { schema } from 'normalizr';
import { BundleType, ProductLine } from '../../models';
import type { ApiBundle, ApiProduct, ApiProductOption } from '../types';
import category from './category';
import product from './product';

const processBundle = ({
  categorySet: categories,
  description,
  discountInCents: discount,
  id,
  imageUrls: images,
  displayName: name,
  priceInCents: price,
  products,
  slug,
}: ApiBundle) => ({
  bundleType: toBundleType(products),
  categories,
  description,
  discount,
  id,
  images,
  name,
  products,
  price,
  slug,
});

const bundle = new schema.Entity(
  'bundles',
  {
    products: [product],
    categories: [category],
  },
  { processStrategy: processBundle },
);

export default bundle;

export const toBundleType = (products: ApiProduct[] | ApiProductOption[]): BundleType => {
  for (const { productLine } of products) {
    if (productLine === ProductLine.Bike) {
      return BundleType.Bike;
    } else if (productLine === ProductLine.BikePlus) {
      return BundleType.BikePlus;
    } else if (productLine === ProductLine.Tread) {
      return BundleType.Tread;
    } else if (productLine === ProductLine.TreadPlus) {
      return BundleType.TreadPlus;
    } else if (productLine === ProductLine.RainforestCafe) {
      return BundleType.RainforestCafe;
    }
  }

  return BundleType.Accessories;
};
