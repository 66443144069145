import { contains, pipe, pickAll, trim } from 'ramda';
import type { SocialProvider } from '../types';
import type { EmailAddress, Username } from './auth';
import type { CreateAccountCredentials } from './credentials';
import MemberGroup from './MemberGroup';

export type User = Guest | SignedInUser | SignedUpUser;

export type BaseUser = {
  avatarUrl: string;
  createdAt: number;
  email: EmailAddress;
  firstName: string;
  gender?: string;
  hashedEmail: string;
  height?: number;
  id: string;
  isProvisional: boolean;
  lastName: string;
  memberGroups: MemberGroup[];
  phoneNumber?: string;
  username: Username;
  weight?: number;
  birthday?: number;
  accountCreationMethod?: string;
  socialConnections: SocialProvider[];
};

export type ForgotPasswordUser = {
  email: EmailAddress;
};

export type Guest = { kind: 'GUEST' };

export type SignedInUser = { kind: 'SIGNED_IN' } & BaseUser;

export type SignedUpUser = { kind: 'SIGNED_UP' } & {
  id: string;
} & CreateAccountCredentials;

export const isSignedIn = (user: User = GUEST): user is SignedInUser =>
  user.kind === 'SIGNED_IN';

export const isGuest = (user: User): user is Guest => user.kind === 'GUEST';

export const isBetaGroupMember = (user: User = GUEST) =>
  isInternalBetaGroupMember(user) ||
  isExternalBetaGroupMember(user) ||
  isExternalWebBetaGroupMember(user);

export const isInternalBetaGroupMember = (user: User = GUEST) =>
  isSignedIn(user) && contains(MemberGroup.InternalBetaTester, user.memberGroups);

export const isExternalBetaGroupMember = (user: User = GUEST) =>
  isSignedIn(user) && contains(MemberGroup.ExternalBetaTester, user.memberGroups);

export const isExternalWebBetaGroupMember = (user: User = GUEST) =>
  isSignedIn(user) && contains(MemberGroup.ExternalWebBetaTester, user.memberGroups);

export const isDigitalInternalGroupMember = (user: User = GUEST) =>
  isSignedIn(user) && contains(MemberGroup.DigitalInternal, user.memberGroups);

export const isInternalEcommGroupMember = (user: User = GUEST) =>
  isSignedIn(user) && contains(MemberGroup.InternalEcommTester, user.memberGroups);

export const GUEST: Guest = { kind: 'GUEST' };

export const toFullName = pipe<User, Record<'firstName' | 'lastName', string>, string>(
  pickAll(['firstName', 'lastName']),
  ({ firstName, lastName }) =>
    trim(`${firstName ? firstName : ''} ${lastName ? lastName : ''}`),
);

export type UserTrackingProperties = {
  isLoggedIn: boolean;
  hasDeviceSubscription: boolean;
  hasDigitalSubscription: boolean;
  email?: string;
  hashedEmail?: string;
  userId?: string;
};
