import { APIErrorCode } from '@onewellness/api';

type Error = {
  errorCode: number;
};

type Response = {
  requestOk: boolean;
  requestErrors?: Array<Error>;
};

const EligiblePersonActivateErrors = {
  1306: APIErrorCode.InvalidEmail,
  1317: APIErrorCode.InvalidEmail,
  1303: APIErrorCode.ClaimedBenefitHubToken,
};

type EligiblePersonActivateResponse = { eligiblePersonActivate?: Response };

export const toErrorCode = (response: EligiblePersonActivateResponse): APIErrorCode => {
  const code = response.eligiblePersonActivate?.requestErrors?.[0].errorCode;
  if (code && code in EligiblePersonActivateErrors) {
    return EligiblePersonActivateErrors[code];
  }
  return APIErrorCode.BenefitHubError;
};
