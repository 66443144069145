import IntlMessageFormat from 'intl-messageformat';
import React from 'react';
import {
  toFormats,
  toLocale,
  toCountry,
  toCurrency,
} from '@peloton/internationalize/models';

// TODO: get locale from context
const DEFAULT_CONTRY = toCountry();
const DEFAULT_CURRENCY = toCurrency(DEFAULT_CONTRY);
const DEFAULT_FORMATS = toFormats(DEFAULT_CURRENCY);

type Config = {
  formats: ReturnType<typeof toFormats>;
  locale: ReturnType<typeof toLocale>;
};

const DEFAULT_CONFIG: Config = {
  formats: DEFAULT_FORMATS,
  locale: toLocale(),
};

/**
 * String formatter that uses IntlMessageFormat to format strings with values
 * NOTE: Consider using useFormattedText hook instead of this function for next apps
 */
const toFormattedText = (
  content = '',
  values: Object = {},
  config: Partial<Config> = {},
) => {
  // Copied from react-intl --> allows react element values in formatted strings
  // https://github.com/formatjs/react-intl/blob/master/src/components/message.tsx#L91

  const { formats, locale } = { ...DEFAULT_CONFIG, ...config };

  let tokenDelimiter: string = '';
  const tokenizedValues: Record<string, string> = {};
  const elements: Record<string, string | React.ReactChild> = {};

  const hasValues = values && Object.keys(values).length > 0;
  if (hasValues) {
    // Creates a token with a random UID that should not be guessable or
    // conflict with other parts of the `message` string.
    const uid = Math.floor(Math.random() * 0x10000000000).toString(16);

    const generateToken = (() => {
      let counter = 0;
      return () => `ELEMENT-${uid}-${(counter += 1)}`;
    })();

    // Splitting with a delimiter to support IE8. When using a regex
    // with a capture group IE8 does not include the capture group in
    // the resulting array.
    tokenDelimiter = `@__${uid}__@`;

    // Iterates over the `props` to keep track of any React Element
    // values so they can be represented by the `token` as a placeholder
    // when the `message` is formatted. This allows the formatted
    // message to then be broken-up into parts with references to the
    // React Elements inserted back in.
    Object.keys(values).forEach(name => {
      const value = values[name];

      if (React.isValidElement(value)) {
        const token = generateToken();
        tokenizedValues[name] = tokenDelimiter + token + tokenDelimiter;
        elements[token] = value;
      } else {
        tokenizedValues[name] = value;
      }
    });
  }

  try {
    const formattedMessage = new IntlMessageFormat(
      content ? content.replace(/\\/g, '') : '',
      locale,
      formats,
    ).format(tokenizedValues);

    if (Object.keys(elements).length === 0) {
      return formattedMessage;
    }

    let nodes: Array<string | React.ReactChild>;

    const hasElements = elements && Object.keys(elements).length > 0;
    if (hasElements) {
      // Split the message into parts so the React Element values captured
      // above can be inserted back into the rendered message. This
      // approach allows messages to render with React Elements while
      // keeping React's virtual diffing working properly.
      nodes = formattedMessage
        .split(tokenDelimiter)
        .filter(part => !!part)
        .map(part => elements[part] || part);
    } else {
      nodes = [formattedMessage];
    }

    // Needs to use `createElement()` instead of JSX, otherwise React will
    // warn about a missing `key` prop with rich-text message formatting.
    return React.createElement<{ children?: any }>(React.Fragment, null, ...nodes);
  } catch (e) {
    console.error(e, 'in', content);

    return content ? content.replace(/\\/g, '') : '';
  }
};

export default toFormattedText;
