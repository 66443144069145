import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ALLOW_QUERY_TOGGLES } from '@peloton/app-config';
import type { Toggles } from '../models/Toggles';

const ToggleContext = createContext<{
  getIsToggleActive: (t: string) => boolean;
  getAreTogglesActive: (ts: string[]) => boolean;
  setToggleValue: (t: string, v: boolean) => void;
  toggles: Toggles<string>;
}>({
  toggles: {},
  getIsToggleActive: _ => false,
  getAreTogglesActive: _ => false,
  setToggleValue: (_1, _2) => undefined,
});

export type Props = {
  features: Toggles<string>;
};

export const ToggleProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  features,
}) => {
  const [toggles, setToggles] = useState(features || {});

  const getIsToggleActive = useCallback(
    (t: string) => (t ? toggles[t]?.active ?? false : false),
    [toggles],
  );

  const getAreTogglesActive = useCallback(
    (ts: string[]) => ts.map(getIsToggleActive).every(Boolean),
    [getIsToggleActive],
  );

  const setToggleValue = useCallback(
    (t: string, v: boolean) =>
      setToggles(ts => ({ ...ts, [t]: { ...ts[t], active: v } })),
    [],
  );

  useEffect(() => {
    // Force re-render for query toggles on staging environments
    if (ALLOW_QUERY_TOGGLES) {
      setToggles(features);
    }
  }, [features]);

  return (
    <ToggleContext.Provider
      value={{
        toggles,
        getIsToggleActive,
        getAreTogglesActive,
        setToggleValue,
      }}
    >
      {children}
    </ToggleContext.Provider>
  );
};

export const useGenericIsToggleActive = <T extends string>() => {
  const getter = useContext(ToggleContext).getIsToggleActive;
  return getter;
};

export const useGenericSetToggleValue = <T extends string>() => {
  const setter = useContext(ToggleContext).setToggleValue;
  return setter;
};

export const useGenericAreTogglesActive = <T extends string>() => {
  const getter = useContext(ToggleContext).getAreTogglesActive;
  return getter;
};

export const useToggles = () => useContext(ToggleContext).toggles;
