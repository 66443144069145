import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import { takeEvery } from 'redux-saga/effects';
import { page } from './analytics';
import type { Page } from './types';

export type PageMapper = (action: LocationChangeAction) => Page<object>;

const createPageSaga = (mapper: PageMapper) => (action: LocationChangeAction) =>
  page(mapper(action));

export const createPageAnalyticsSaga = (mapper: PageMapper) =>
  function* () {
    const pageSaga = createPageSaga(mapper);
    yield takeEvery(LOCATION_CHANGE, pageSaga);
  };
