import { I18N_LOCALE_TOGGLE } from '@peloton/app-config';
import { toHostnameEnv } from '@peloton/env-hostnames/toHostnameEnv';
import type { EnvironmentFlags } from '@peloton/env/models';
import {
  toMatchingTld,
  toExternalTld,
  SupportedTLD as TLD,
  Locale,
} from '@peloton/internationalize/models/locale';
import { oneLineTrim } from '@peloton/text/oneLine';

type FieldValueOf<T extends {}> = T extends {} ? T[keyof T] : never;

const curHostname =
  typeof window !== 'undefined' ? window?.location?.hostname : undefined;
const hostnameEnv = curHostname ? toHostnameEnv(curHostname) : undefined;
const uatSubdomain = hostnameEnv?.type === 'uat' ? hostnameEnv?.subdomain : 'test';
// If hostnameEnv has field urlApp, then we are using prod-test url structure instead of prod-<app>
const hasUrlApp = hostnameEnv?.hasOwnProperty('urlApp');
const curWWWAppName =
  (hostnameEnv?.type === 'uat' || hostnameEnv?.type === 'prod') && hostnameEnv.urlApp
    ? hostnameEnv.urlApp
    : 'www';
const prodTestID = hostnameEnv?.type === 'prod' ? hostnameEnv.prodTestID : undefined;

const stageN = () => {
  let match;
  if (curHostname) {
    match = /--api-core-(.*)--stage/.exec(curHostname);
  }
  if (match && match[1]) {
    return match[1];
  }
  //default to stage 2
  return '2';
};

export const LinkEnvs = {
  Local: 'local-www',
  Prod: 'www',
  ProdTest: hasUrlApp
    ? `${prodTestID}.${curWWWAppName}.prod-test`
    : `${prodTestID}--prod-www.test`,
  Qa1: `www--lit.${uatSubdomain}` as string,
  Qa2: 'qa2-www',
  UatQa: `${curWWWAppName}.${uatSubdomain}` as string,
  UatProd: 'uat-prod-www',
  StageN: `www--api-core-${stageN()}--stage.${uatSubdomain}` as string,
} as const;

export const DigitalLinkEnvs = {
  Local: 'local-members',
  UatProd: `members.test`,
  UatQa: `members.test`,
  UatLitProd: `members--lit.test`,
  UatLitQa: `members--lit-api-qa.test`,
  StgProd: 'stg-members',
  StgQa: `members.test`,
  StageN: `members--api-core-${stageN()}--stage.test`,
  ProdTest: 'members',
  Prod: 'members',
} as const;

type DigitalUatEnv =
  | typeof DigitalLinkEnvs.UatProd
  | typeof DigitalLinkEnvs.UatQa
  | typeof DigitalLinkEnvs.UatLitProd
  | typeof DigitalLinkEnvs.UatLitQa;

export const withDigitalUatPrefix = (linkEnv: DigitalUatEnv): DigitalUatEnv => {
  try {
    const matches = /^([a-z\d\-_]*)--members/.exec(window.location.hostname);
    return Array.isArray(matches) && matches.length
      ? ([matches[1], linkEnv].join('--') as DigitalUatEnv) // this is a lie
      : linkEnv;
  } catch (_) {
    return linkEnv;
  }
};

export enum ApiEnvs {
  Qa1 = 'qa1',
  Qa2 = 'qa2',
  Prod = 'api',
  ProdTest = 'api',
  Local = 'local-api',
  Test = 'localhost', // CircleCi needs to run the server on localhost for contract tests
}

export const isK8s = (apiEnv: ApiEnvs) => apiEnv.endsWith('k8s');

export const getApiCluster = (env: ApiEnvs) => {
  if (env === ApiEnvs.Prod) {
    return 'prod';
  } else if (env === ApiEnvs.Qa1) {
    return 'stage';
  } else if (env === ApiEnvs.Qa2) {
    return 'test';
  } else if (env === ApiEnvs.Local) {
    return 'stage';
  } else {
    const [, cluster] = env.split('.').reverse();
    return cluster;
  }
};

// k8s uses pelotime in dev/test, and onepeloton in stage/prod
export const isPelotimeK8s = (apiEnv: ApiEnvs) =>
  isK8s(apiEnv) && ['dev', 'test'].includes(getApiCluster(apiEnv));

export const isCustomApiEnv = (apiEnv: ApiEnvs | string) =>
  !Object.values(ApiEnvs).some(v => v === apiEnv);

export enum PreorderLinkEnvs {
  Uat = 'uat-preorder',
  Stg = 'stg-preorder',
  Prod = 'preorder',
}

export const StudioLinkEnvs = {
  Local: 'local-studio',
  Prod: 'studio',
  Staging: `studio--lit.${uatSubdomain}` as string,
  UatQa: `studio.${uatSubdomain}` as string,
} as const;

export enum HomecomingLinkEnvs {
  Prod = 'homecoming',
  Dev = 'homecoming-dev',
}

export const AccountLinkEnvs = {
  Local: 'local-account',
  Prod: 'account',
  ProdTest: 'account',
  UatQa: `account.${uatSubdomain}` as string,
  UatProd: `account--api.${uatSubdomain}` as string,
  StageN: `account--api-core-${stageN()}--stage.${uatSubdomain}` as string,
} as const;

export const AuthLinkEnvs = {
  Local: 'local-auth',
  Prod: 'auth',
  ProdTest: 'auth',
  UatQa: `auth.test` as string,
  UatProd: `auth.test` as string,
  StageN: `auth.test` as string,
} as const;

export enum BookingLinkEnvs {
  Local = 'book-staging',
  Prod = 'booking',
  Staging = 'book-staging',
  UatQa = 'book-staging',
  UatProd = 'book-staging',
}

export type ExtLinkEnv = {
  www: FieldValueOf<typeof LinkEnvs>;
  account: FieldValueOf<typeof AccountLinkEnvs>;
  auth: FieldValueOf<typeof AuthLinkEnvs>;
  ecomm: FieldValueOf<typeof LinkEnvs>;
  digital: FieldValueOf<typeof DigitalLinkEnvs>;
  api: ApiEnvs;
  preorder: PreorderLinkEnvs;
  house: 'house';
  hostname: string;
  subpathLocale?: Locale;
  support: 'support';
  boutique: 'boutique';
  findyourride: 'findyourride';
  studio: FieldValueOf<typeof StudioLinkEnvs>;
  blog: 'blog';
  commercial: 'commercial';
  global: 'global';
  internal: 'internal';
  booking: BookingLinkEnvs;
  hospitality: 'hospitality';
  milestone: 'investor';
  homecoming: HomecomingLinkEnvs;
  press: 'press';
  business: 'business';
};

export type PeloLinkType = keyof ExtLinkEnv;

type HostnameOverrides = Partial<
  Record<TLD, (link: ExtLink, env: ExtLinkEnv, hostname: string) => string>
>;

export type PeloLink = {
  type: PeloLinkType;
  path: string;
  hostnameOverrides?: HostnameOverrides;
};

type PeloLinkWithOverrides = PeloLink & { hostnameOverrides: HostnameOverrides };

export type SocialLink = {
  type: 'social';
  href: string;
};

export type ExtLink = PeloLink | SocialLink;

export const isExtLink = (
  link: Record<string, any>,
  extLinkEnv: ExtLinkEnv,
): link is ExtLink =>
  'type' in link &&
  ((link.type in extLinkEnv && 'path' in link) ||
    (link.type === 'social' && 'href' in link));

export type ExtLinkToHref = (link: ExtLink) => string;

export const toExtLinkEnv = (opts: Partial<ExtLinkEnv> = {}): ExtLinkEnv => ({
  api: ApiEnvs.Prod,
  blog: 'blog',
  boutique: 'boutique',
  commercial: 'commercial',
  digital: DigitalLinkEnvs.Prod,
  ecomm: LinkEnvs.Prod,
  findyourride: 'findyourride',
  global: 'global',
  hostname:
    typeof window !== 'undefined' && window && window.location && window.location.hostname
      ? window.location.hostname
      : 'onepeloton.com',
  house: 'house',
  homecoming: HomecomingLinkEnvs.Prod,
  internal: 'internal',
  preorder: PreorderLinkEnvs.Prod,
  support: 'support',
  studio: StudioLinkEnvs.Prod,
  www: LinkEnvs.Prod,
  booking: BookingLinkEnvs.Prod,
  hospitality: 'hospitality',
  milestone: 'investor',
  account: AccountLinkEnvs.Prod,
  auth: AuthLinkEnvs.Prod,
  press: 'press',
  business: 'business',
  ...opts,
});

export const toBookingLinkEnv = ({
  isProd,
  isStaging,
  isLocal,
  isUatProd,
}: EnvironmentFlags): BookingLinkEnvs =>
  (isProd && BookingLinkEnvs.Prod) ||
  (isStaging && BookingLinkEnvs.Staging) ||
  (isLocal && BookingLinkEnvs.Local) ||
  (isUatProd && BookingLinkEnvs.UatProd) ||
  BookingLinkEnvs.UatQa;

export const toPeloLink = (
  type: PeloLinkType,
  path: string,
  hostnameOverrides: HostnameOverrides = {},
): PeloLink => ({
  type,
  path,
  hostnameOverrides,
});

export const toSocialLink = (href: string): SocialLink => ({
  type: 'social',
  href,
});

export const toApiLink = (path: string): PeloLink =>
  toPeloLink('api', path, {
    [TLD.CoUk]: toPreservedNonPelotimeK8sTld,
    [TLD.Ca]: toPreservedNonPelotimeK8sTld,
    [TLD.De]: toPreservedNonPelotimeK8sTld,
    [TLD.Au]: toPreservedNonPelotimeK8sTld,
    [TLD.At]: toPreservedNonPelotimeK8sTld,
  });

// Convenience methods

export const toWWWLink = (path: string) =>
  toPeloLink('www', path, {
    [TLD.CoUk]: toPreservedTld,
    [TLD.Ca]: toPreservedTld,
    [TLD.De]: toPreservedTld,
    [TLD.Au]: toPreservedTld,
    [TLD.At]: toPreservedTld,
  });

export const toPressLink = (path: string) =>
  toPeloLink('press', path, {
    [TLD.Com]: toLocaleTld,
    [TLD.CoUk]: toPreservedTld,
    [TLD.Ca]: toPreservedTld,
    [TLD.De]: toPreservedTld,
    [TLD.Au]: toPreservedTld,
    [TLD.At]: toPreservedTld,
  });

export const toEcommLink = (path: string) =>
  toPeloLink('ecomm', path, {
    [TLD.CoUk]: toPreservedTld,
    [TLD.Ca]: toPreservedTld,
    [TLD.De]: toPreservedTld,
    [TLD.Au]: toPreservedTld,
    [TLD.At]: toPreservedTld,
  });

export const toDigitalLink = (path: string) =>
  toPeloLink('digital', path, {
    [TLD.CoUk]: toPreservedTld,
    [TLD.Ca]: toPreservedTld,
    [TLD.De]: toPreservedTld,
    [TLD.Au]: toPreservedTld,
    [TLD.At]: toPreservedTld,
  });

export const toStudioLink = (path: string) =>
  toPeloLink('studio', path, {
    [TLD.CoUk]: toPreservedTld,
    [TLD.Ca]: toPreservedTld,
    [TLD.De]: toPreservedTld,
    [TLD.Au]: toPreservedTld,
    [TLD.At]: toPreservedTld,
  });

export const toCommercialLink = (path: string) =>
  toPeloLink('commercial', path, {
    [TLD.CoUk]: toPreservedTld,
    [TLD.Ca]: toPreservedTld,
    [TLD.De]: toPreservedTld,
    [TLD.Au]: toPreservedTld,
    [TLD.At]: toPreservedTld,
  });

export const toAccountLink = (path: string) =>
  toPeloLink('account', path, {
    [TLD.CoUk]: toPreservedTld,
    [TLD.Ca]: toPreservedTld,
    [TLD.De]: toPreservedTld,
    [TLD.Au]: toPreservedTld,
    [TLD.At]: toPreservedTld,
  });

export const toAuthLink = (path: string) => toPeloLink('auth', path);

export const toHomecomingLink = (path: string) => toPeloLink('homecoming', path, {});

export const toSupportLink = (path: string) =>
  toPeloLink('support', path, {
    [TLD.Com]: toLocalizedSupportLink('en-us'),
    [TLD.CoUk]: toLocalizedSupportLink('en-gb'),
    [TLD.Ca]: toLocalizedSupportLink('en-ca'),
    [TLD.De]: toLocalizedSupportLink('de'),
    [TLD.Au]: toLocalizedSupportLink('en-au'),
    [TLD.At]: toLocalizedSupportLink('de-at'),
  });

const toLocalizedSupportLink = (locale: string) => (
  link: ExtLink,
  env: ExtLinkEnv,
  hostname: string,
) =>
  (link as PeloLink).path === '/'
    ? toPreservedTld(link as PeloLink, env, hostname)
    : `support.onepeloton.com/hc/${locale}`;

export const toBookingLink = (location: string = '') => {
  const queryString = `/?location=${location.replace(/ /g, '-').toLowerCase()}`;
  return toPeloLink('booking', location ? queryString : '/', {
    [TLD.Ca]: toPreservedTld,
    [TLD.CoUk]: toPreservedTld,
    [TLD.De]: toPreservedTld,
    [TLD.Au]: toPreservedTld,
    [TLD.At]: toPreservedTld,
  });
};

export const toBoutiqueLink = (path: string) =>
  toPeloLink('boutique', path, {
    [TLD.Ca]: () => 'apparel.onepeloton.ca',
    [TLD.CoUk]: () => 'apparel.onepeloton.co.uk',
    [TLD.De]: () => 'apparel.onepeloton.de',
    [TLD.Au]: () => 'apparel.onepeloton.com.au',
    [TLD.At]: () => 'apparel.onepeloton.at',
  });

export const toBusinessLink = (path: string) =>
  toPeloLink('business', path, {
    [TLD.Ca]: () => 'business.onepeloton.ca',
    [TLD.CoUk]: () => 'business.onepeloton.co.uk',
    [TLD.De]: () => 'business.onepeloton.de',
    [TLD.Au]: () => 'business.onepeloton.com.au',
    [TLD.At]: () => 'business.onepeloton.at',
  });

export const toHref = (link: ExtLink, env: ExtLinkEnv, location?: Location): string => {
  if (link.type === 'social') {
    return toSocialHref(link); // Social links don't have environments.
  }

  return oneLineTrim(`
    ${getProtocol(link, env)}
    ${getHost(link, env)}
    ${getPort(link, env, location)}
    ${getSubpathLocale(env)}
    ${link.path}
  `);
};

const getSubpathLocale = (env: ExtLinkEnv) => {
  if (
    I18N_LOCALE_TOGGLE &&
    env.subpathLocale &&
    env.subpathLocale !== Locale.EnglishUnitedStates
  ) {
    return `/${env.subpathLocale}`;
  }
  return '';
};

export const getUrlForTracking = (link: ExtLink, extLinkEnv: ExtLinkEnv): string =>
  toHref(link, extLinkEnv);

const getProtocol = (link: ExtLink, env: ExtLinkEnv) =>
  isLocalApi(link, env) || isLocalStudio(link, env) || isTestApi(link, env)
    ? 'http://'
    : 'https://';

const getEnv = (link: ExtLink, env: ExtLinkEnv) => env[link.type];

const getPort = (link: ExtLink, env: ExtLinkEnv, location?: Location) => {
  if (
    isLocalWww(link, env) ||
    isLocalStudio(link, env) ||
    isLocalMembers(link, env) ||
    isLocalAccount(link, env)
  ) {
    return currentPortFormatted(location);
  } else if (isTestApi(link, env)) {
    return `:${TEST_PORT}`;
  }

  return '';
};

const toLocaleTld = (link: ExtLink, env: ExtLinkEnv, hostname: string) =>
  `${getEnv(link, env)}.${toDomain(link, env)}${toExternalTld(hostname)}`;

export const toTldFromLocale = (link: ExtLink, env: ExtLinkEnv, locale: string) => {
  // the kv path is on link don't be fooled
  // @ts-expect-error
  return `${getProtocol(link, env)}${toLocaleTld(link, env, locale)}${link?.path}`;
};

const toPreservedTld = (link: ExtLink, env: ExtLinkEnv, hostname: string) => {
  return `${getEnv(link, env)}.${toDomain(link, env)}${toMatchingTld(hostname)}`;
};

const toPreservedNonPelotimeK8sTld = (link: ExtLink, env: ExtLinkEnv, hostname: string) =>
  isPelotimeK8s(env.api)
    ? `${getEnv(link as PeloLink, env)}.${toDomain(link, env)}.com`
    : toPreservedTld(link, env, hostname);

export const toDomain = (link: ExtLink, env: ExtLinkEnv) => {
  if (link.type === 'api' && env.api) {
    /* kubernetes deployments _always_ run on pelotime domains */
    return isPelotimeK8s(env.api) ? 'pelotime' : 'onepeloton';
  }

  return 'onepeloton';
};

const hasHostnameOverrides = (link: any): link is PeloLinkWithOverrides =>
  Boolean((link as PeloLink).hostnameOverrides);

export const getHost = (link: PeloLink, env: ExtLinkEnv) => {
  if (hasHostnameOverrides(link)) {
    const handler = link.hostnameOverrides[toMatchingTld(env.hostname)];
    if (handler) {
      return handler(link, env, env.hostname);
    }
  }

  return isTestApi(link, env)
    ? 'localhost'
    : `${getEnv(link, env)}.${toDomain(link, env)}.com`;
};

export const currentPortFormatted = (location: Location = window.location) =>
  // TODO: add wwwPort field to ExtLinkEnv to pass port to toHref
  location.port !== '' ? `:${location.port}` : '';

const isLocalApi = (link: ExtLink, env: ExtLinkEnv) =>
  isApi(link) && env[(link as PeloLink).type] === ApiEnvs.Local;

const isLocalWww = (link: ExtLink, env: ExtLinkEnv) =>
  (link.type === 'www' || link.type === 'ecomm') && env.www === LinkEnvs.Local;

const isLocalStudio = (link: ExtLink, env: ExtLinkEnv) =>
  link.type === 'studio' && env.studio === StudioLinkEnvs.Local;

const isLocalMembers = (link: ExtLink, env: ExtLinkEnv) =>
  link.type === 'digital' && env.digital === DigitalLinkEnvs.Local;

const isLocalAccount = (link: ExtLink, env: ExtLinkEnv) =>
  link.type === 'account' && env.account === AccountLinkEnvs.Local;

const isTestApi = (link: ExtLink, env: ExtLinkEnv) =>
  isApi(link) && env[(link as PeloLink).type] === ApiEnvs.Test;

const isApi = (link: ExtLink) => link.type === 'api';

export const TEST_PORT = 8989;

export const toSocialHref = (link: SocialLink): string => link.href;

export const safelyDecode = (str: string | string[] | undefined): string => {
  try {
    const computedStr = Array.isArray(str) ? str.join(',') : str;
    return atob(computedStr ?? '');
  } catch {
    // invalid path
    return '';
  }
};
