export enum ErrorType {
  Http = 'Peloton.Api.HttpError',
  PaymentGateway = 'Peloton.Stripe.CreateTokenError',
  SCA = 'Peloton.Stripe.SCAError',
  SetupIntent = 'Peloton.Stripe.SetupIntentError',
  Unknown = 'Peloton.Api.Unknown',
  Network = 'Peloton.Api.Network',
}

// TODO: this needs tests

class DomainError extends Error {
  // This is required to use the __proto__ variable which allows me to
  // do `instanceof DomainError` - https://github.com/Microsoft/TypeScript/issues/13965
  __proto__: Error;

  public originalError: any;
  public errorType: ErrorType;

  get tags() {
    if (this.isHttpError) {
      return this.getHttpErrorTags();
    } else if (this.isPaymentGatewayError) {
      return this.getPaymentGatewayErrorTags();
    }
    return undefined;
  }

  constructor(errorCode: string, originalError: any) {
    super(errorCode);
    const trueProto = new.target.prototype;
    this.originalError = originalError;
    this.errorType = this.originalError.name;
    this.name = 'DomainError';
    this.__proto__ = trueProto;
  }

  private getHttpErrorTags() {
    const {
      responseBody: { errorCode, message, subcode },
    } = this.originalError;

    return {
      pelo_errorCode: `${errorCode}`,
      pelo_message: `${message}`,
      pelo_subcode: `${subcode}`,
    };
  }

  private getPaymentGatewayErrorTags() {
    const { code, message } = this.originalError;

    return {
      stripe_code: `${code}`,
      stripe_message: `${message}`,
    };
  }

  private get isHttpError() {
    return this.errorType === ErrorType.Http;
  }

  private get isPaymentGatewayError() {
    return this.errorType === ErrorType.PaymentGateway;
  }
}

export default DomainError;
