import { PreorderLinkEnvs } from '@peloton/external-links/models';
import type { EnvironmentFlags } from './models';

const toPreorderLinkEnv = ({
  isProd,
  isStaging,
  isLocal,
  isUatProd,
}: EnvironmentFlags): PreorderLinkEnvs =>
  (isProd && PreorderLinkEnvs.Prod) ||
  (isStaging && PreorderLinkEnvs.Stg) ||
  (isLocal && PreorderLinkEnvs.Uat) ||
  (isUatProd && PreorderLinkEnvs.Prod) ||
  PreorderLinkEnvs.Uat;

export default toPreorderLinkEnv;
