import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';
import { DomainError } from '@peloton/domain-error';
import { reportError } from '@peloton/error-reporting';
import { CLIENT_CONTEXT } from '@onewellness/api/client';
import { getPartnerByHashIdAPI } from '../api';
import type { PartnerLookupRequestAction } from '../redux';
import {
  PartnerLookupActionTypes,
  partnerLookupSucceeded,
  partnerLookupFailed,
} from '../redux';

export const lookupPartnerByHashId = function* ({
  payload: { hashId },
}: PartnerLookupRequestAction): SagaIterator {
  if (hashId) {
    try {
      const client = yield getContext(CLIENT_CONTEXT);
      const partnerResponse = yield call(getPartnerByHashIdAPI, client, hashId);
      yield put(partnerLookupSucceeded(partnerResponse.partnerByHashId));
    } catch (error) {
      const report = new DomainError(error.message, {
        ...error,
        name: 'PartnerLookup',
      });
      const errorId = `errors.${error.message}`;
      yield all([put(partnerLookupFailed(errorId)), put(reportError({ error: report }))]);
    }
  }
};

const watcherSaga = function* () {
  yield takeEvery(PartnerLookupActionTypes.PartnerLookupRequested, lookupPartnerByHashId);
};

export default watcherSaga;
