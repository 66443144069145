import { propEq } from 'ramda';

export enum SubscriptionKind {
  Digital = 'Digital',
  Device = 'Device',
}

export const isDeviceSub = (sub: SubLike): boolean =>
  isKind(SubscriptionKind.Device, sub);

export const isDigitalSub = (sub: SubLike): boolean =>
  isKind(SubscriptionKind.Digital, sub);

const isKind = propEq('kind');

type SubLike = {
  kind: SubscriptionKind;
};
