export type PosStore = {
  kind: 'pos';
  slug: string;
};

// TODO Remove hardcoded retail store for POS
const toPosStore = (): PosStore => ({
  kind: 'pos',
  slug: 'chelsea',
});

export { toPosStore };
