import type { ReactNode } from 'react';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { ALLOW_PROMO_PANEL, ALLOW_TOGGLE_PANEL } from '@peloton/app-config';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import PanelManagerProvider from './PanelManagerProvider';

type StateThemeSetter = React.Dispatch<React.SetStateAction<string | undefined>>;
type StateToggle = React.Dispatch<React.SetStateAction<boolean>>;

type State = {
  freeze: boolean;
  globalTheme?:
    | 'lilac'
    | 'lime'
    | 'rose'
    | 'blue'
    | 'patina'
    | 'pelotonRed'
    | 'roseGold'
    | 'michiganBlue';
  reduceMotion: boolean;
  setGlobalTheme: StateThemeSetter;
  toggleFreeze: StateToggle;
  toggleReduceMotion: StateToggle;
};

export const GlobalUiStateContext = createContext<State>({
  freeze: false,
  globalTheme: undefined,
  reduceMotion: false,
  setGlobalTheme: () => {},
  toggleFreeze: () => {},
  toggleReduceMotion: () => {},
});

type Props = {
  children: ReactNode;
};

const GlobalUiStateProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const [globalTheme, setGlobalTheme] = useState(undefined);
  const [freeze, toggleFreeze] = useState(false);
  const [reduceMotion, toggleReduceMotion] = useState(false);

  const handleReduceMotionChange = (event: MediaQueryListEvent) =>
    toggleReduceMotion(event.matches);

  useEffect(() => {
    const reduceMotionQuery = window.matchMedia('(prefers-reduced-motion)');

    // Only use setter on mount if it differs from the default value.
    // Using matchMedia in useEffect to support portability to Next
    if (reduceMotionQuery.matches) toggleReduceMotion(true);

    // Listener if user changes preferences while app is open
    reduceMotionQuery.addListener(handleReduceMotionChange);

    // Call it on mount for the true initial value

    return () => {
      reduceMotionQuery.removeListener(handleReduceMotionChange);
    };
  }, []);

  const value = useMemo(() => {
    return {
      freeze,
      globalTheme,
      setGlobalTheme,
      toggleFreeze,
      reduceMotion,
      toggleReduceMotion,
    };
  }, [freeze, globalTheme, reduceMotion]);

  const allowPanelManager =
    useIsToggleActive()('enableTestingPanelsInProd') ||
    ALLOW_TOGGLE_PANEL ||
    ALLOW_PROMO_PANEL;

  return (
    <GlobalUiStateContext.Provider value={value}>
      {allowPanelManager ? (
        <PanelManagerProvider>{children}</PanelManagerProvider>
      ) : (
        children
      )}
    </GlobalUiStateContext.Provider>
  );
};

export default GlobalUiStateProvider;

export const useGlobalUiStateContext = () => React.useContext(GlobalUiStateContext);
