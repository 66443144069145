import axios from 'axios';
import {
  toDefaultRequestTransforms,
  toDefaultResponseTransforms,
} from '@peloton/api/client-transforms';
import { SupportedTLD } from '@peloton/internationalize/models/locale';

export enum AuthEnv {
  Test = 'https://auth-orca.test.k8s.onepeloton',
  Stage = 'https://auth-orca.stage.k8s.onepeloton',
  Prod = 'https://auth-orca.onepeloton',
}

const authClient = (
  baseUrl: AuthEnv = AuthEnv.Prod,
  tld: SupportedTLD = SupportedTLD.Com,
  headers: Record<string, string> = {},
) => {
  return axios.create({
    baseURL: `${baseUrl}${tld}`,
    responseType: 'json',
    withCredentials: true,
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
    headers,
  });
};

export default authClient;
