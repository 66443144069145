import React from 'react';

const REACT_MODAL_OVERLAY = 'ReactModal__Overlay';

/**
 * https://github.com/Pomax/react-onclickoutside#ie-does-not-support-classlist-for-svg-elements
 * Some browsers (IE11) don't support classList for SVG elements.
 */
type MaybeClassList = DOMTokenList | null | undefined;

export const useOnClickOutside = (
  ref: React.RefObject<Element | Element[] | null>,
  cb: Function,
  allowScrolling: boolean = true,
  enabled: boolean = true,
) => {
  const handleClickOutside = React.useCallback(
    ({ target }: Event) => {
      if (!(target instanceof Node) || ref.current === null || !enabled) {
        return;
      }

      const refs = toElements(ref.current);
      const anyInside = refs.find(element => element.contains(target));
      const classList = (target as Element).classList as MaybeClassList;
      const isModalOverlay = classList?.contains(REACT_MODAL_OVERLAY) ?? false;

      if (!anyInside && !isModalOverlay) {
        cb();
        if (allowScrolling) {
          document.removeEventListener('wheel', handleClickOutside);
        }
      }
    },
    [ref.current, cb, enabled],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    if (allowScrolling) {
      document.addEventListener('wheel', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      if (allowScrolling) {
        document.removeEventListener('wheel', handleClickOutside);
      }
    };
  }, [enabled]);
};

const toElements = (elementOrElements: Element | Element[]): Element[] => {
  if (elementOrElements instanceof Element) {
    return [elementOrElements];
  } else {
    return elementOrElements;
  }
};
