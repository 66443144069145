export const ORDER_TRACKED = 'peloton-order-analytics';

export type OrderTrackingDetails = {
  orderId?: string;
  storage?: any;
  key?: any;
};

const getTrackedOrders = ({
  storage = window.localStorage,
  key = ORDER_TRACKED,
}: OrderTrackingDetails) =>
  (storage && storage.getItem(key) && JSON.parse(storage.getItem(key)).orders) || [];

export const trackOrder = ({
  orderId,
  storage = window.localStorage,
  key = ORDER_TRACKED,
}: OrderTrackingDetails) => {
  if (!hasTracked({ orderId, storage, key })) {
    const orders = getTrackedOrders({ storage, key });
    orders.push(orderId);
    storage.setItem(key, JSON.stringify({ orders }));
  }
};

export const hasTracked = ({
  orderId,
  storage = window.localStorage,
  key = ORDER_TRACKED,
}: OrderTrackingDetails) => getTrackedOrders({ storage, key }).indexOf(orderId) !== -1;
