import { prop, pipe, pickAll, pathOr } from 'ramda';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { State } from '@peloton/env';
import { getIsProdEnv, getIsStagingEnv } from '@peloton/env';
import { Status } from '@ecomm/models';
import type { Toggles } from './models';
import type { ReducerState, Toggle } from './redux';

export const getIsDark = createSelector(
  getIsProdEnv,
  getIsStagingEnv,
  (...flags: boolean[]) => !flags.find(x => x),
);

const mapStateToProps = (state: State) => ({
  canShowWhenDark: getIsDark(state),
});

export const withCanShowWhenDark = connect(mapStateToProps);

export const getToggles = pathOr({}, ['toggles', 'toggles']);

const getToggleStatus = pathOr(Status.Init, ['toggles', 'status']);

export const getAreTogglesLoaded = createSelector(
  getToggleStatus,
  (status: Status) => status === Status.Loaded,
);

export const getDatesForToggle = <T extends string>(toggleId: T) =>
  createSelector<ReducerState, Record<T, Toggle>, Pick<Toggle, 'start' | 'end'>>(
    [getToggles],
    pipe<Record<T, Toggle>, Toggle, Pick<Toggle, 'start' | 'end'>>(
      prop(toggleId),
      pickAll(['start', 'end']),
    ),
  );

const getActiveKeys = (toggles: Toggles<string>): string[] =>
  Object.entries(toggles)
    .filter(([key, toggle]) => toggle.active)
    .map(([key]) => key);

export const getActiveToggles = createSelector(getToggles, toggles =>
  getActiveKeys(toggles),
);

export const _getIsToggleActive = <T extends string>(toggleId: T) =>
  createSelector<ReducerState, Toggles<T>, boolean>(
    getToggles,
    pathOr(false, [toggleId, 'active']),
  );
