import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { TransactionState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { Audience } from '@onewellness/routes';

export enum ActionTypes {
  RegistrationFailed = 'onewellness/register/REGISTRATION_REQUEST_FAILED',
  RegistrationRequested = 'onewellness/register/REGISTRATION_REQUESTED',
  RegistrationSucceeded = 'onewellness/register/REGISTRATION_REQUEST_SUCCEEDED',
}

export type State = TransactionState;

export const defaultState = toUIState(Status.Init) as TransactionState;

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.RegistrationRequested:
      return toUIState(Status.Loading);

    case ActionTypes.RegistrationFailed: {
      const exception = action.payload.exception;
      return toUIState(Status.Failed, exception);
    }
    case ActionTypes.RegistrationSucceeded:
      return toUIState(Status.Loaded);

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  register: State;
};

export const registerUser = (audience: Audience, token: string): RequestAction => ({
  type: ActionTypes.RegistrationRequested,
  payload: { audience, token },
});

export const succeedRegistration = (): SuccessAction => ({
  type: ActionTypes.RegistrationSucceeded,
});

export const failRegistration = (
  errorId: string = 'errors.one-wellness-error',
): FailAction => ({
  type: ActionTypes.RegistrationFailed,
  payload: { exception: toException(errorId) },
});

export type RequestAction = {
  type: ActionTypes.RegistrationRequested;
  payload: { audience: Audience; token: string };
};

export type SuccessAction = {
  type: ActionTypes.RegistrationSucceeded;
};

export type FailAction = {
  type: ActionTypes.RegistrationFailed;
  payload: { exception: Exception };
};

export type Actions = RequestAction | SuccessAction | FailAction;
