const mapToView = (content: any) => ({
  addToCart: content['cart']['cart.addToCart'],
  apply: content['cart']['cart.discounts.apply'],
  ariaLabel: content['cart']['cart.panel.ariaLabel'],
  bikeLeaseMonthlyCharge: content['cart']['cart.OPC.bikeMonthlyCharge'],
  bikePlusLeaseMonthlyCharge: content['cart']['cart.OPC.bikePlusMonthlyCharge'],
  bikeLeaseUMichMonthlyCharge: content['cart']['cart.UMich.OPC.bikeMonthlyCharge'],
  bikePlusLeaseUMichMonthlyCharge:
    content['cart']['cart.UMich.OPC.bikePlusMonthlyCharge'],
  checkout: content['cart']['cart.checkout'],
  checkoutTitle: content['cart']['cartSummary.title'],
  emptyCart: content['cart']['cart.emptyCart'],
  enterPromoCode: content['cart']['pgCheckout.promoCodeForm.placeholder'],
  enterCode: content['cart']['cart.discounts.enterCode'],
  couponErrorCartDoesNotQualifyForPromotion:
    content['cart']['couponErrors']['cartDoesNotQualifyForPromotion'],
  couponErrorInvalidCode: content['cart']['couponErrors']['invalidCode'],
  couponErrorPromotionAllowedOncePerTransaction:
    content['cart']['couponErrors']['promotionAllowedOncePerTransaction'],
  couponErrorPromotionExpired: content['cart']['couponErrors']['promotionExpired'],
  couponErrorPromotionNotApplicable:
    content['cart']['couponErrors']['promotionNotApplicable'],
  couponErrorPromotionRedemptionLimitReached:
    content['cart']['couponErrors']['promotionRedemptionLimitReached'],
  couponErrorRegionInvalid: content['cart']['couponErrors']['regionInvalid'],
  estimatedShippingLabel: content['cart']['cart.shipping.estimateLabel'],
  estimatedShippingLabelDeliveryOnly:
    content['cart']['cart.shipping.estimateLabel.deliveryOnly'],
  estimatedTaxLabel: content['cart']['cart.tax.estimateLabel'],
  estimatedTaxMarkdown: content['cart']['cart.tax.estimateMarkdown'],
  estimatedTotalLabel: content['cart']['cart.total.estimateLabel'],
  financingPricing: content['cart']['financing.pricing.block'],
  financingPricingVariation: content['cart']['financing.pricing.block.variation'],
  financingPricingAffirm: content['cart']['financing.pricing.affirm'],
  financingPricingTruemed: content['cart']['financing.pricing.truemed'],
  financingPricingTruemedText: content['cart']['financing.pricing.truemed.text'],
  leaseEstimatedTotal: content['cart']['cart.OPC.estimatedTotal'],
  leasePricing: content['cart']['cart.OPC.pricing'],
  leaseUMichPricing: content['cart']['cart.UMich.OPC.pricing'],
  membershipDescription: content['cart']['cart.membership.description'],
  membershipInfo: content['cart']['cart.membership.info'],
  membershipName: content['cart']['cart.membership.name'],
  freeAAMPromo: content['cart']['cart.membership.promo.description'],
  noAmount: content['cart']['cart.noAmount'],
  quantity: content['cart']['cart.quantity'],
  remove: content['cart']['cart.remove'],
  shopNow: content['cart']['cart.shopNow'],
  subtotalLabel: content['cart']['cart.subtotal.label'],
  title: content['cart']['cart.panel.title'],
  shippingMethodDetailsBikeAndTread:
    content['cart']['cart.shipping.methodDetails.actual.bikeAndTreadAuthRemoved'],
  shippingMethodDetailsTread:
    content['cart']['cart.shipping.methodDetails.actual.treadAuthRemoved'],
  shippingMethodDetailsBike: content['cart']['cart.shipping.methodDetails.actual.bike'],
  shippingMethodDetailsGuide: content['cart']['cart.shipping.methodDetails.actual.guide'],
  shippingMethodDetailsAccessories:
    content['cart']['cart.shipping.methodDetails.actual.accessories'],
  shippingIncluded: content['cart']['cart.shipping.included'],
  shippingLabel: content['cart']['cart.shipping.label'],
  taxLabel: content['cart']['cart.tax.label'],
  totalLabel: content['cart']['cart.total.label'],
  tradeInTitle: content['cart']['cart.tradeIn.title'],
  tradeInDescription: content['cart']['cart.tradeIn.description'],
  upsellTitle: content['cart']['cart.upsell.title'],
  vatLabel: content['cart']['cart.tax.vatLabel'],
  vatIncludedLabel: content['cart']['cart.tax.includesVat'],
  guideMembershipName: content['cart']['cart.guide.name'],
  guideMembershipDescription: content['cart']['cart.guide.description'],
  guideMembershipInfo: content['cart']['cart.guide.info'],
  otdHeader: content['cart']['cart.otd.header'],
  otdBody: content['cart']['cart.otd.body'],
  screenReaderNewItemAlert: content['cart']['cart.a11y.newItemAlert'],
  giftingLabel: content['cart']['cart.giftCheckbox.label'],
  giftingDescription: content['cart']['cart.giftCheckbox.description'],
  estimatedTax: content['cart']['cart.tax.estimateLabel'],
  taxEstimation: content['cart']['cart.taxEstimation'],
  taxEstimationDetail: content['cart']['cart.taxEstimationDetail'],
  membershipInfoTitle: content['cart']['cart.membership.infoTitle'],
  membershipInfoMsg: content['cart']['cart.membership.infoMsg'],
  otdToolTipMsg: content['cart']['cart.otd.toolTipMsg'],
  unavailableProductTxt: content['cart']['cart.cartSummary.unavailableProductTxt'],
  zipMonthlyPayment: content['cart']['cart.zip.monthlyPayment'],
  trialHeading: content['cart']['cart.cartSummary.trialHeading'],
  trialInfoLine1: content['cart']['cart.cartSummary.trialInfoLine1'],
  trialInfoLine2: content['cart']['cart.cartSummary.trialInfoLine2'],
  trialInfoLine3: content['cart']['cart.cartSummary.trialInfoLine3'],
  trialSuggestion: content['cart']['cart.cartSummary.trialSuggestion'],
  plusIconInfo: content['cart']['cart.plus.icon.toolTip.description'],
  shippingToolTipTitle: content['cart']['cart.shipping.tooltip.title'],
  shippingToolTipDescription: content['cart']['cart.shipping.tooltip.description'],
  haulawayLabel: content['cart']['cartFlyout.haulaway.label'],
  haulawayDescription: content['cart']['cartFlyout.haulaway.description'],
  haulawayCheckboxLabel: content['cart']['cart.haulawayCheckbox.label'],
  haulawayCheckboxDescription: content['cart']['cart.haulawayCheckbox.description'],
});

export default mapToView;
