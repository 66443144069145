import React from 'react';
import type { Props as BTProps } from './BreakpointTransforms';
import BreakpointTransforms from './BreakpointTransforms';
import type { LazyImageProps } from './LazyImage';
import LazyImage from './LazyImage';

export type Props = React.ImgHTMLAttributes<HTMLImageElement> &
  Pick<BTProps, Exclude<keyof BTProps, 'render'>> &
  LazyImageProps;

const EnvironmentImage: React.FC<React.PropsWithChildren<Props>> = ({
  loading,
  layout,
  ...props
}) => (
  <BreakpointTransforms
    {...props}
    render={(transformProps: React.ImgHTMLAttributes<HTMLImageElement>) =>
      loading !== 'lazy' ? (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img {...transformProps} />
      ) : (
        <LazyImage
          {...transformProps}
          loading="lazy"
          layout={layout}
          width={props.width}
          height={props.height}
        />
      )
    }
  />
);

export default EnvironmentImage;
