import { transparentize } from 'polished';

/**
 * NOTE: We're moving over to using a design system!
 * https://www.github.com/pelotoncycle/design-system
 *
 * Colors are being the first thing converted.
 * We'll be going through and updating each individually, and deprecating them here as we go.
 *
 * If you have any questions/concerns, please reach out on Slack.
 * CHANNEL: #acme-design-systems
 *
 * STORYBOOK: https://main--ds-onepeloton-com.netlify.app/?path=/story/design-tokens-colors
 */

/** @deprecated prefer import { black } from '@pelotoncycle/design-system'; */
export const black = '#000000';

/** @deprecated prefer import { white } from '@pelotoncycle/design-system'; */
export const white = '#ffffff';

/**
 * @deprecated prefer import { brand, grey } from '@pelotoncycle/design-system';
 * @example brand.lightest
 * @example grey[20]
 */
export const snowWhite = '#f9fafb';

/**
 * @deprecated prefer import { brand, grey } from '@pelotoncycle/design-system';
 * @example brand.light
 * @example grey[30]
 */
export const offWhite = '#f5f7f9';

/**
 * @deprecated prefer import { grey } from '@pelotoncycle/design-system';
 * @example grey[40]
 */
export const borderGray = '#e4e6e7';
export const paleBlue = '#e4e6e7';

/**
 * @deprecated prefer import { grey } from '@pelotoncycle/design-system';
 * @example grey[50]
 */
export const iconGray = '#a8acb1';

/**
 * @deprecated prefer import { grey } from '@pelotoncycle/design-system';
 * @example grey[60]
 */
export const mediumGrayOnDark = '#888b93';

/**
 * @deprecated prefer import { grey } from '@pelotoncycle/design-system';
 * @example grey[70]
 */
export const mediumGray = '#65666a';

/**
 * @deprecated prefer import { brand, grey } from '@pelotoncycle/design-system';
 * @example brand.dark
 * @example grey[80]
 */
export const blueBlack = '#222529';

/**
 * @deprecated prefer import { brand, grey } from '@pelotoncycle/design-system';
 * @example brand.darkest
 * @example grey[90]
 */
export const offBlack = '#181a1d';

/**
 * @deprecated prefer import { slate } from '@pelotoncycle/design-system';
 * @example slate[50]
 */
export const slate = '#697180';

/**
 * @deprecated prefer import { brand, red } from '@pelotoncycle/design-system';
 * @example brand.primary
 * @example red[50]
 */
export const superRed = '#df1c2f';

/**
 * @deprecated prefer import { red } from '@pelotoncycle/design-system';
 * @example red[80]
 */
export const superRedDark = '#d00c2a';

/**
 * @deprecated prefer import { alert, yellow } from '@pelotoncycle/design-system';
 * @example alert.warning
 * @example yellow[50]
 */
export const yellow = '#ffe28c';

/**
 * @deprecated prefer import { alert, green } from '@pelotoncycle/design-system';
 * @example alert.success
 * @example green[80]
 */
export const green = '#08820B';

// not in DS
export const highlightLightBlue = '#B8DDF5';
export const highlightBlue = '#5E9ED6';
export const darkBlue = '#406599';
export const slateBlack = '#23252a';

// Semi-transparent
export const disabledGray = transparentize(0.4, mediumGray);
export const disabledRed = transparentize(0.2, superRed);
export const dividerGray = transparentize(0.7, mediumGray);
export const transparentBlack = transparentize(0.2, offBlack);
export const dropShadowGray = transparentize(0.9, offBlack);
