import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, pipeError, throwError } from '@peloton/api';
import { toStoreSlug } from '@peloton/stores';
import type { ErrorCode } from '@ecomm/checkout/models';
import { toErrorCode } from '@ecomm/checkout/models';
import type {
  DigitalCheckout,
  QuickCheckout,
  ValidatePromoCode,
  ReferrerPayload,
} from './models';

const toCheckoutUrl = () => `/ecomm/store/${toStoreSlug()}/one_step_checkout`;

export const digitalCheckout = (api: Client, data: DigitalCheckout) =>
  api
    .post(toCheckoutUrl(), data)
    .then(pipeData(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));

export const quickCheckout = (api: Client, data: QuickCheckout) =>
  api
    .post(toCheckoutUrl(), data)
    .then(pipeData(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));

export const validatePromoCode = (api: Client, data: ValidatePromoCode) =>
  api
    .post('/ecomm/access_code/validate', data)
    .then(pipeData(identity))
    .catch(pipeError(throwError(identity)));

export const referrerData = (api: Client, data: ReferrerPayload) =>
  api
    .get(`/ecomm/access_code/referrer/${data.code}`)
    .then(pipeData(identity))
    .catch(pipeError(throwError(identity)));
