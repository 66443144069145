import { connect } from 'react-redux';
import type { State as EnvState } from '@peloton/env';
import { getIsLocalEnv } from '@peloton/env';
import type {
  Props as BTProps,
  BreakpointOptions as BTBreakpointOptions,
} from '@peloton/images';
import { BreakpointTransforms } from '@peloton/images';
import type { Omit } from '@peloton/types';

export type Props = Omit<BTProps, 'isLocal'>;
export type BreakpointOptions = BTBreakpointOptions;

const mapStateToProps = (state: EnvState) => ({
  isLocal: getIsLocalEnv(state),
});

export default connect(mapStateToProps, {})(BreakpointTransforms);
