import { propEq } from 'ramda';

export enum BillingType {
  Trial = 'trial',
  AppleMonthly = 'apple_monthly',
  AmazonMonthly = 'amazon_monthly',
  BangoMonthly = 'bango_monthly',
  GoogleMonthly = 'google_monthly',
  AppleOneWeek = 'apple_one_week',
  PelotonInternal = 'peloton_internal',
  PreDelivery = 'pre_delivery',
}

export const isPreDelivery = (sub: SubLike): boolean =>
  isBillingType(BillingType.PreDelivery, sub);
export const isTrial = (sub: SubLike): boolean => isBillingType(BillingType.Trial, sub);
export const isPelotonInternal = (sub: SubLike): boolean =>
  isBillingType(BillingType.PelotonInternal, sub);

const isBillingType = propEq('billingType');

type SubLike = {
  billingType: BillingType;
};
