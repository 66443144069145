import type { RoutingState } from '@peloton/redux';
import { getLocation } from '@peloton/redux';
import { getUser as getCheckoutUser } from '@ecomm/checkout/redux';
import type { ReducerState as CheckoutState } from '@ecomm/checkout/redux/rootReducer';
import type { MarketingOptionsState } from '@ecomm/checkout/redux/selectors';
import { getShowOptOut } from '@ecomm/checkout/redux/selectors';
import { RoutePaths as RegisterRoutes } from '@ecomm/pg-register/config';
import type { ReducerState as RegisterState } from '@ecomm/registration/redux/rootReducer';
import { getRegistrationFormData } from '@ecomm/registration/redux/selectors';

export type OptInOutFields =
  | {
      email: string;
      fieldChecked: 'Yes' | 'No';
    }
  | {};

type State = CheckoutState & RegisterState & RoutingState & MarketingOptionsState;

const formatFields = (
  email: string,
  allowMarketing: boolean,
  hasVariation: boolean,
): OptInOutFields => {
  if (hasVariation) {
    return {
      email,
      fieldChecked: allowMarketing ? 'No' : 'Yes',
    };
  }

  return {
    email,
    fieldChecked: allowMarketing ? 'Yes' : 'No',
  };
};

export const getOptInOutFieldsFromLocation = (state: State): OptInOutFields => {
  const { pathname } = getLocation(state);
  const hasVariation = getShowOptOut(state);

  if (RegisterRoutes.RegisterPage === pathname) {
    const { email, allowMarketing } = getRegistrationFormData(state);
    return formatFields(email, allowMarketing, hasVariation);
  } else {
    const { email, allowMarketing } = getCheckoutUser(state);
    return formatFields(email, allowMarketing, hasVariation);
  }
};
