import React from 'react';
import type { ToggleButtonTheme } from '@peloton/buttons';
import { resolveFillColor, StyledSvg } from './TogglePause';
import type { IconProps } from './types';

type PlayProps = IconProps &
  ToggleButtonTheme & {
    label: string;
  };

const TogglePlay: React.FC<React.PropsWithChildren<PlayProps>> = ({
  theme = 'dark',
  label,
  ...iconProps
}) => (
  <StyledSvg
    aria-label={label}
    fill={resolveFillColor(theme)}
    role="img"
    theme={theme}
    viewBox="0 0 34 34"
    xmlns="http://www.w3.org/2000/svg"
    {...iconProps}
  >
    <g fillRule="nonzero">
      <path d="M17 .25C7.75.25.25 7.75.25 17S7.75 33.75 17 33.75 33.75 26.25 33.75 17 26.25.25 17 .25zm0 1.5c8.422 0 15.25 6.828 15.25 15.25S25.422 32.25 17 32.25 1.75 25.422 1.75 17 8.578 1.75 17 1.75z" />
      <path d="M15.436 11.411l6.812 5.476a1 1 0 010 1.559l-6.812 5.476a1 1 0 01-1.627-.78V12.192a1 1 0 011.627-.78z" />
    </g>
  </StyledSvg>
);

export default TogglePlay;
