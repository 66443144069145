import type { SagaMiddleware as Middleware, SagaIterator } from 'redux-saga';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

function* rootSaga(sagas: (() => SagaIterator)[]) {
  yield all(sagas.map(saga => saga()));
}

export const toSagaMiddleware = createSagaMiddleware;

export const runSagas = <T extends object>(
  middleware: Middleware<T>,
  sagas: (() => SagaIterator)[],
) => {
  middleware.run(rootSaga, sagas);
};
