import { curry, lensPath, set } from 'ramda';
import { Partners } from '@ecomm/financing/models/Partners';
import { PaymentMethod } from '@ecomm/models';
import type { CreditCard } from '../models';

export enum ActionTypes {
  PaymentFieldBlurred = 'ecomm/checkout/payment/FIELD_BLURRED',
  PaymentFieldErrored = 'ecomm/checkout/payment/FIELD_ERRORED',
  PaymentFieldUpdated = 'ecomm/checkout/payment/FIELD_UPDATED',
  SelectPaymentMethod = 'ecomm/checkout/payment/METHOD_SELECTED',
  Reset = 'ecomm/checkout/payment/RESET',
}

type CreditCardErrors = Record<
  PaymentMethod.CreditCard,
  Partial<Record<keyof CreditCard, string>>
>;

type State = { [PaymentMethod.CreditCard]: Partial<CreditCard> } & {
  errors: CreditCardErrors;
} & { selectedMethod: PaymentMethod } & { financingPartner: Partners };

export const defaultState = {
  [PaymentMethod.CreditCard]: {},
  errors: {
    [PaymentMethod.CreditCard]: {},
  },
  selectedMethod: PaymentMethod.Unknown,
  financingPartner: Partners.None,
};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.PaymentFieldErrored: {
      const { paymentMethod, name, errorMsg } = action.payload;
      const updateLens = lensPath(['errors', paymentMethod, name]);
      return set(updateLens, errorMsg, state);
    }

    case ActionTypes.PaymentFieldUpdated: {
      const { paymentMethod, name, value } = action.payload;
      const updateLens = lensPath([paymentMethod, name]);
      return set(updateLens, value, state);
    }

    case ActionTypes.SelectPaymentMethod:
      return {
        ...state,
        selectedMethod: action.payload.paymentMethod,
        financingPartner: action.payload.financingPartner,
      };

    case ActionTypes.Reset:
      return defaultState;

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  payment: State;
};

type Name = keyof CreditCard;

export const blurPaymentField = curry(
  (paymentMethod: PaymentMethod, name: Name, value: string): BlurPaymentFieldAction => ({
    type: ActionTypes.PaymentFieldBlurred,
    payload: { paymentMethod, name, value },
  }),
);

export const updatePaymentError = curry(
  (
    paymentMethod: PaymentMethod,
    name: Name,
    errorMsg: string,
  ): UpdatePaymentErrorAction => ({
    type: ActionTypes.PaymentFieldErrored,
    payload: { paymentMethod, name, errorMsg },
  }),
);

export const updatePaymentField = curry(
  (
    paymentMethod: PaymentMethod,
    name: Name,
    value: string,
  ): UpdatePaymentFieldAction => ({
    type: ActionTypes.PaymentFieldUpdated,
    payload: { paymentMethod, name, value },
  }),
);

export const selectPaymentMethod = (
  paymentMethod: PaymentMethod,
  financingPartner: Partners,
): SelectPaymentMethodAction => ({
  type: ActionTypes.SelectPaymentMethod,
  payload: { paymentMethod, financingPartner },
});

export const reset = (): ResetPaymentAction => ({
  type: ActionTypes.Reset,
});

export type BlurPaymentFieldAction = {
  type: ActionTypes.PaymentFieldBlurred;
  payload: {
    paymentMethod: PaymentMethod;
    name: Name;
    value: string;
  };
};

export type UpdatePaymentErrorAction = {
  type: ActionTypes.PaymentFieldErrored;
  payload: {
    paymentMethod: PaymentMethod;
    name: Name;
    errorMsg: string;
  };
};

export type UpdatePaymentFieldAction = {
  type: ActionTypes.PaymentFieldUpdated;
  payload: {
    paymentMethod: PaymentMethod;
    name: Name;
    value: string;
  };
};

export type SelectPaymentMethodAction = {
  type: ActionTypes.SelectPaymentMethod;
  payload: {
    paymentMethod: PaymentMethod;
    financingPartner: Partners;
  };
};

export type ResetPaymentAction = {
  type: ActionTypes.Reset;
};

type Action =
  | UpdatePaymentErrorAction
  | UpdatePaymentFieldAction
  | SelectPaymentMethodAction
  | ResetPaymentAction;
