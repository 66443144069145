import type { State } from './redux';
import vendorKeysReducer, {
  Vendor,
  selectDriftKey,
  selectRecaptchaKey,
  selectStripeAccountKey,
  selectStripeApiKey,
  updateVendorKey,
} from './redux';

export { default as getKeys, isPlatformStripeAccount } from './getKeys';

export type VendorKeysState = {
  vendorKeys: State;
};

export default vendorKeysReducer;
export {
  Vendor,
  selectRecaptchaKey,
  selectStripeAccountKey,
  selectStripeApiKey,
  updateVendorKey,
};

export const selectVendorKeys = (state: VendorKeysState): State => state.vendorKeys;

export const getDriftKey = (state: VendorKeysState): string =>
  selectDriftKey(selectVendorKeys(state));

export const getRecaptchaKey = (state: VendorKeysState): string =>
  selectRecaptchaKey(selectVendorKeys(state));
