import type { Auth0ClientOptions, AuthorizationParams } from '@auth0/auth0-spa-js';
import type { OauthProviderProps } from './OauthProvider';

type OauthEnvConfig = {
  domain: string;
  audience: string;
  api: string;
  client_id: string;
};

export const toV2ConfigSchema = (
  oauthEnvConfig: OauthEnvConfig,
): Auth0ClientOptions & { api: string } => {
  const { audience, client_id, ...oauthConfigs } = oauthEnvConfig;
  return {
    ...oauthConfigs,
    clientId: client_id,
    authorizationParams: {
      audience,
    },
  };
};

export const addAuthParamsToV2ConfigSchema = (
  auth0Config: OauthProviderProps,
  authParams: AuthorizationParams,
) => {
  const { authorizationParams, ...restConfig } = auth0Config;

  return {
    ...restConfig,
    authorizationParams: {
      ...authorizationParams,
      ...authParams,
    },
  };
};
