const mapToView = (content: any) => ({
  youMayAlsoLike: content['apparel-pdp']['apparel-pdp.upsell.youMayAlsoLike'],
  sizeGuide: content['apparel-pdp']['apparel-pdp.sizeGuide'],
  viewAll: content['apparel-pdp']['apparel-pdp.viewAll'],
  collapse: content['apparel-pdp']['apparel-pdp.collapse'],
  small: content['apparel-pdp']['apparel-pdp.small'],
  medium: content['apparel-pdp']['apparel-pdp.medium'],
  large: content['apparel-pdp']['apparel-pdp.large'],
  extraLarge: content['apparel-pdp']['apparel-pdp.extraLarge'],
  qtyPlaceholder: content['apparel-pdp']['apparel-pdp.qtyPlaceholder'],
  sizePlaceholder: content['apparel-pdp']['apparel-pdp.sizePlaceholder'],
  finalSale: content['apparel-pdp']['apparel-pdp.finalSale'],
  productExchangeAvailability:
    content['apparel-pdp']['apparel-pdp.productExchangeAvailability'],
  requiredField: content['apparel-pdp']['apparel-pdp.requiredField'],
  sizeButton: content['apparel-pdp']['apparel-pdp.sizeButton'],
  reviews: content['apparel-pdp']['apparel-pdp.review.reviews'],
  ratingShot: content['apparel-pdp']['apparel-pdp.review.ratingShot'],
  rating5Stars: content['apparel-pdp']['apparel-pdp.review.rating5Stars'],
  rating4Stars: content['apparel-pdp']['apparel-pdp.review.rating4Stars'],
  rating3Stars: content['apparel-pdp']['apparel-pdp.review.rating3Stars'],
  rating2Stars: content['apparel-pdp']['apparel-pdp.review.rating2Stars'],
  rating1Star: content['apparel-pdp']['apparel-pdp.review.rating1Star'],
  overallFit: content['apparel-pdp']['apparel-pdp.review.overallFit'],
  runsBig: content['apparel-pdp']['apparel-pdp.review.runsBig'],
  trueToSize: content['apparel-pdp']['apparel-pdp.review.trueToSize'],
  runsSmall: content['apparel-pdp']['apparel-pdp.review.runsSmall'],
  sortBy: content['apparel-pdp']['apparel-pdp.review.sortBy'],
  newest: content['apparel-pdp']['apparel-pdp.review.newest'],
  oldest: content['apparel-pdp']['apparel-pdp.review.oldest'],
  all: content['apparel-pdp']['apparel-pdp.review.all'],
  averageRating: content['apparel-pdp']['apparel-pdp.review.averageRating'],
  locale: content['apparel-pdp']['apparel-pdp.review.locale'],
  australia: content['apparel-pdp']['apparel-pdp.review.australia'],
  canada: content['apparel-pdp']['apparel-pdp.review.canada'],
  germany: content['apparel-pdp']['apparel-pdp.review.germany'],
  uk: content['apparel-pdp']['apparel-pdp.review.uk'],
  us: content['apparel-pdp']['apparel-pdp.review.us'],
  hrsAgo: content['apparel-pdp']['apparel-pdp.review.hrsAgo'],
  overallFitDetail: content['apparel-pdp']['apparel-pdp.review.overallFitDetail'],
  sizePurchased: content['apparel-pdp']['apparel-pdp.review.sizePurchased'],
  height: content['apparel-pdp']['apparel-pdp.review.height'],
  recommendationCheck: content['apparel-pdp']['apparel-pdp.review.recommendationCheck'],
  showingOf: content['apparel-pdp']['apparel-pdp.review.showingOf'],
  moreReviews: content['apparel-pdp']['apparel-pdp.review.moreReviews'],
  readMore: content['apparel-pdp']['apparel-pdp.review.readMore'],
  seeLess: content['apparel-pdp']['apparel-pdp.review.seeLess'],
  allPhotos: content['apparel-pdp']['apparel-pdp.review.allPhotos'],
  noOfPhotos: content['apparel-pdp']['apparel-pdp.review.noOfPhotos'],
  prevReview: content['apparel-pdp']['apparel-pdp.review.prevReview'],
  nextReview: content['apparel-pdp']['apparel-pdp.review.nextReview'],
  daysAgo: content['apparel-pdp']['apparel-pdp.review.daysAgo'],
  reviewsWithPhotos: content['apparel-pdp']['apparel-pdp.review.reviewsWithPhotos'],
  showMore: content['apparel-pdp']['apparel-pdp.showMore'],
});

export default mapToView;
