import { propOr } from 'ramda';
import React, { useEffect } from 'react';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';

const ScrollToTop: React.FC<React.PropsWithChildren<RouteComponentProps<{}>>> = ({
  children,
  location: { pathname, state },
}) => {
  useEffect(() => {
    isScrollToTopDisabled(state) || window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

export default withRouter(ScrollToTop);

const isScrollToTopDisabled = propOr(false, 'noScroll');
