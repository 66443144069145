import { brand, grey, white, red } from '@pelotoncycle/design-system';
import type { FlattenSimpleInterpolation, FlattenInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { a11yOutline as sharedA11yOutline } from '@peloton/accessibility/styles';
import { hoverTransition } from '@peloton/styles/animation';
import { media } from '@peloton/styles/breakpoints';

const baseButtonStyles = css`
  text-align: center;
  border-radius: 4px;
  font-weight: 600;

  &:active {
    outline: none;
  }
`;

const TabletStandardSize = css`
  ${baseButtonStyles}
  border-width: 3px;
  height: 50px;
  line-height: 44px;
  min-width: 200px;
  padding-left: 30px;
  padding-right: 30px;
`;

const MobileStandardSize = css`
  ${baseButtonStyles}
  border-width: 3px;
  height: 50px;
  line-height: 44px;
  min-width: 200px;
  padding-left: 30px;
  padding-right: 30px;
`;

const SmallSize = css`
  ${baseButtonStyles}
  border-width: 3px;
  height: 40px;
  line-height: 34px;
  min-width: 180px;
  padding-left: 20px;
  padding-right: 20px;
`;

const XSmallSize = css`
  ${baseButtonStyles}
  border-width: 2px;
  height: 30px;
  line-height: 26px;
  min-width: 100px;
  padding-left: 10px;
  padding-right: 10px;
`;

const StandardSize = css`
  ${baseButtonStyles}
  ${MobileStandardSize}
  ${media.tablet`
    ${TabletStandardSize}
  `}
`;

const ButtonSizeMap: SizeCss = {
  XSMALL: XSmallSize,
  SMALL: SmallSize,
  STANDARD: TabletStandardSize,
};

const ButtonSize = (opts = {}) => {
  const size = { ...StandardButtonSize, ...opts };
  return css`
    ${size.mobile === 'STANDARD' ? MobileStandardSize : ButtonSizeMap[size.mobile]}
    ${media.tablet`${ButtonSizeMap[size.tablet]}`}
    ${media.desktop`${ButtonSizeMap[size.desktop]}`}
  `;
};

type ThemedButtonProps = ButtonProps & {
  theme: BUTTON_THEME;
};

const toFullButtonStyles = ({
  size,
  disabled,
  theme,
}: ThemedButtonProps): FlattenInterpolation<ThemedButtonProps> =>
  css`
    ${baseButtonStyles}
    ${ButtonSize(size)}
    ${disabled
      ? `
        opacity: 0.3;
        cursor: default;
      `
      : `
        cursor: pointer;
      `}
    ${ButtonThemes[theme]}
  `;

/*
 *  EXAMPLE:
 *  <Button1 size={{mobile: 'XSMALL'}}>Submit</Button1>
 *  Will leave all breakpoints standard (default), but will shrink to xsmall on mobile
 */
const Button = styled.button`
  ${({ size, disabled, theme }: ThemedButtonProps) =>
    toFullButtonStyles({ size, disabled, theme })}
`;

export const a11yOutline = css`
  ${sharedA11yOutline}
`;

const ButtonThemes = {
  Button1: css`
    background-color: ${brand.primary};
    color: ${white};
    fill: ${white};
    ${a11yOutline}
    ${({ disabled }: ButtonProps) =>
      disabled
        ? `background-color: ${grey[40]}; color:${grey[70]}; opacity: 1;`
        : hoverTransition({
            property: 'background-color',
            to: red[80],
          })}
  `,
  Button2: css`
    background-color: transparent;
    border-style: solid;
    border-color: ${brand.darkest};
    color: ${brand.darkest};
    fill: ${brand.darkest};
    stroke: ${brand.darkest};
    ${a11yOutline}
    ${({ disabled }: ButtonProps) =>
      disabled
        ? ``
        : hoverTransition(
            {
              property: 'background-color',
              to: brand.darkest,
            },
            {
              property: 'color, fill, stroke',
              to: white,
            },
          )}
  `,
  Button3: css`
    background-color: transparent;
    border-style: solid;
    border-color: ${brand.primary};
    color: ${brand.primary};
    fill: ${brand.primary};
    stroke: ${brand.primary};
    ${a11yOutline}
    ${({ disabled }: ButtonProps) =>
      disabled
        ? ``
        : hoverTransition(
            {
              property: 'background-color',
              to: brand.primary,
            },
            {
              property: 'color, fill, stroke',
              to: white,
            },
          )}
  `,
  Button4: css`
    color: ${brand.darkest};
    fill: ${brand.darkest};
    stroke: ${brand.darkest};
    background-color: rgba(255, 255, 255, 0.8);
    ${a11yOutline}
    ${({ disabled }: ButtonProps) =>
      disabled
        ? ``
        : hoverTransition({
            property: 'background-color',
            from: 'rgba(255, 255, 255, 0.8)',
            to: white,
          })}
  `,
  Button5: css`
    background-color: transparent;
    border-style: solid;
    border-color: ${white};
    color: ${white};
    fill: ${white};
    stroke: ${white};
    ${a11yOutline}
    ${({ disabled }: ButtonProps) =>
      disabled
        ? ``
        : hoverTransition(
            {
              property: 'background-color',
              to: white,
            },
            {
              property: 'color, fill, stroke',
              to: brand.darkest,
            },
          )}
  `,
};

const Button1 = styled(Button)``;
Button1.defaultProps = { theme: 'Button1' };

const Button2 = styled(Button)``;
Button2.defaultProps = { theme: 'Button2' };

const Button3 = styled(Button)``;
Button3.defaultProps = { theme: 'Button3' };

const Button4 = styled(Button)``;
Button4.defaultProps = { theme: 'Button4' };

const Button5 = styled(Button)``;
Button5.defaultProps = { theme: 'Button5' };

export const StandardButtonSize: ResponsiveSizeCss = {
  mobile: 'STANDARD',
  tablet: 'STANDARD',
  desktop: 'STANDARD',
};
export const SmallButtonSize: ResponsiveSizeCss = {
  mobile: 'SMALL',
  tablet: 'SMALL',
  desktop: 'SMALL',
};
export const XSmallButtonSize: ResponsiveSizeCss = {
  mobile: 'XSMALL',
  tablet: 'XSMALL',
  desktop: 'XSMALL',
};

// TODO: investigate exporting style(s), or adding a button link component, for links styled to look like buttons.
export {
  baseButtonStyles,
  toFullButtonStyles,
  Button,
  Button1,
  Button2,
  Button3,
  Button4,
  Button5,
  ButtonSizeMap,
  ButtonThemes,
  MobileStandardSize,
  SmallSize,
  StandardSize,
  TabletStandardSize,
  XSmallSize,
};

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: ResponsiveSizeCss;
};
export type BUTTON_SIZE = 'XSMALL' | 'SMALL' | 'STANDARD';
type BUTTON_BREAKPOINTS = 'mobile' | 'tablet' | 'desktop';
export type BUTTON_THEME = 'Button1' | 'Button2' | 'Button3' | 'Button4' | 'Button5';
type SizeCss = Record<BUTTON_SIZE, FlattenSimpleInterpolation>;
export type ResponsiveSizeCss = Record<BUTTON_BREAKPOINTS, BUTTON_SIZE>;
