import { toAccountLink } from '@peloton/external-links/models';
import {
  ANONYMOUS_ID_LOCAL_STORAGE_KEY,
  ANONYMOUS_ID_QUERY_PARAM,
  TIK_TIK_TRACKING_ID,
} from '../analytics/constants';

export const getTrackedAccountLink = (path: string) => {
  const queryParams = getTrackedAccountQueryParams();

  // Append the query params to the link
  if (queryParams.toString()) {
    path += `?${queryParams.toString()}`;
  }
  return toAccountLink(path);
};

const removeQuotes = (str: string) => str.replace(/^"(.*)"$/, '$1');

export const getTrackedAccountQueryParams = () => {
  const queryParams = new URLSearchParams();

  // Append the ttclid to the link if it exists
  const ttclid = sessionStorage.getItem(TIK_TIK_TRACKING_ID);
  if (ttclid) {
    queryParams.append(TIK_TIK_TRACKING_ID, removeQuotes(ttclid));
  }

  // append the anonymousId to the link if it exists
  const anonymousId = window.localStorage.getItem(ANONYMOUS_ID_LOCAL_STORAGE_KEY);
  if (anonymousId) {
    queryParams.append(ANONYMOUS_ID_QUERY_PARAM, removeQuotes(anonymousId));
  }
  return queryParams;
};
