/* eslint-disable no-case-declarations */
import { curry, lensProp, path, set } from 'ramda';
import type { LoadAuthUserSuccessAction } from '@peloton/auth';
import { UserReducerActionType } from '@peloton/auth';
import { toException } from '@ecomm/exceptions/Exception';
import type { ID, TransactionState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { PaymentGateway } from '@ecomm/payment';
import type {
  CheckoutType,
  PaymentsOverrideConfiguration,
  SuccessRedirectOverride,
} from '../models';
import type { AddedGiftCardData } from './giftCards';

export enum ActionTypes {
  CheckoutFailed = 'ecomm/quick-checkout/CHECKOUT_FAILED',
  CheckoutRequested = 'ecomm/quick-checkout/CHECKOUT_REQUESTED',
  CheckoutSuccess = 'ecomm/quick-checkout/CHECKOUT_SUCCEEDED',
  CheckoutReset = 'ecomm/quick-checkout/CHECKOUT_RESET',
  CheckoutLoading = 'ecomm/quick-checkout/CHECKOUT_LOADING',
  ExistingPaymentCheckoutRequested = 'ecomm/quick-checkout/EXISTING_PAYMENT_CHECKOUT_REQUESTED',
}

export type State = {
  status: TransactionState;
};

export const defaultState = {
  status: toUIState(Status.Init) as TransactionState,
};

const reducer = (state: State = defaultState, action: Actions) => {
  const updateLens = lensProp('status');

  switch (action.type) {
    case ActionTypes.CheckoutRequested:
    case ActionTypes.ExistingPaymentCheckoutRequested:
    case ActionTypes.CheckoutLoading:
      return set(updateLens, toUIState(Status.Loading), state);

    case ActionTypes.CheckoutFailed:
      const { error } = action.payload;
      const exception = toException(error);
      return set(updateLens, toUIState(Status.Failed, exception), state);

    case ActionTypes.CheckoutReset:
      return set(updateLens, toUIState(Status.Init), state);

    case ActionTypes.CheckoutSuccess:
      return set(updateLens, toUIState(Status.Init), state);

    case UserReducerActionType.REQUEST_SUCCESS:
      const id = path(['status', 'exception', 'id'], state);
      return id === 'checkout.errors.needsLogin'
        ? set(updateLens, toUIState(Status.Init), state)
        : state;

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  ui: State;
};

//Find a way to make promotion optional
export const requestCheckout = curry(
  (
    paymentGateway: PaymentGateway,
    checkoutType: CheckoutType,
    product: ID,
    promotion: string,
    paymentsOverride: PaymentsOverrideConfiguration,
    giftCardPayments?: AddedGiftCardData[],
  ): CheckoutRequestAction => ({
    type: ActionTypes.CheckoutRequested,
    payload: {
      checkoutType,
      paymentGateway,
      promotion,
      product,
      paymentsOverride,
      giftCardPayments,
    },
  }),
);

export const requestCheckoutWithRedirectOverride = curry(
  (
    successRedirectOverride: SuccessRedirectOverride,
    paymentGateway: PaymentGateway,
    checkoutType: CheckoutType,
    product: ID,
    promotion: string,
    paymentsOverride: PaymentsOverrideConfiguration,
    giftCardPayments?: AddedGiftCardData[],
  ): CheckoutRequestAction => ({
    type: ActionTypes.CheckoutRequested,
    payload: {
      checkoutType,
      paymentGateway,
      promotion,
      product,
      paymentsOverride,
      successRedirectOverride,
      giftCardPayments,
    },
  }),
);

export const requestCheckoutWithCaptcha = curry(
  (
    paymentGateway: PaymentGateway,
    checkoutType: CheckoutType,
    product: ID,
    promotion: string,
    paymentsOverride: PaymentsOverrideConfiguration,
    recaptchaToken?: string,
    giftCardPayments?: AddedGiftCardData[],
  ): CheckoutRequestAction => ({
    type: ActionTypes.CheckoutRequested,
    payload: {
      checkoutType,
      paymentGateway,
      promotion,
      product,
      paymentsOverride,
      recaptchaToken,
      giftCardPayments,
    },
  }),
);

export const requestCheckoutWithCaptchaWithRedirectOverride = curry(
  (
    successRedirectOverride: SuccessRedirectOverride,
    paymentGateway: PaymentGateway,
    checkoutType: CheckoutType,
    product: ID,
    promotion: string,
    paymentsOverride: PaymentsOverrideConfiguration,
    recaptchaToken?: string,
    giftCardPayments?: AddedGiftCardData[],
  ): CheckoutRequestAction => ({
    type: ActionTypes.CheckoutRequested,
    payload: {
      checkoutType,
      paymentGateway,
      promotion,
      product,
      paymentsOverride,
      recaptchaToken,
      successRedirectOverride,
      giftCardPayments,
    },
  }),
);

export const failCheckout = (error: string): CheckoutFailAction => ({
  type: ActionTypes.CheckoutFailed,
  payload: { error },
});

export const checkoutSuccess = (): CheckoutSuccessAction => ({
  type: ActionTypes.CheckoutSuccess,
});

export const checkoutReset = (): CheckoutResetAction => ({
  type: ActionTypes.CheckoutReset,
});

export const checkoutLoading = (): CheckoutLoadingAction => ({
  type: ActionTypes.CheckoutLoading,
});

// TODO: Remove payment gateway from payload. Generally, our gateways are api
// methods and are not passed via redux actions (largely due to the fact that
// they are not serializable). In the future, we should rely less on singletons.
export type CheckoutRequestAction = {
  type: ActionTypes.CheckoutRequested;
  payload: {
    checkoutType: CheckoutType;
    product: ID;
    promotion: string | undefined;
    paymentGateway: PaymentGateway;
    paymentsOverride: PaymentsOverrideConfiguration;
    recaptchaToken?: string;
    successRedirectOverride?: SuccessRedirectOverride;
    giftCardPayments?: AddedGiftCardData[];
  };
};

export type CheckoutFailAction = {
  type: ActionTypes.CheckoutFailed;
  payload: {
    error: string;
  };
};

export type CheckoutSuccessAction = {
  type: ActionTypes.CheckoutSuccess;
};

export type CheckoutResetAction = {
  type: ActionTypes.CheckoutReset;
};

export type CheckoutLoadingAction = {
  type: ActionTypes.CheckoutLoading;
};

export type ExistingPaymentCheckoutRequestAction = Omit<
  CheckoutRequestAction,
  'type' | 'recaptchaToken'
> & {
  type: ActionTypes.ExistingPaymentCheckoutRequested;
  payload: { postalCode: string };
};

type Actions =
  | CheckoutRequestAction
  | CheckoutFailAction
  | CheckoutSuccessAction
  | CheckoutResetAction
  | CheckoutLoadingAction
  | ExistingPaymentCheckoutRequestAction
  | LoadAuthUserSuccessAction;
