import { Locale } from './locale';

const localeCurrencySymbolMap: Record<Locale, string> = {
  [Locale.EnglishCanada]: '$',
  [Locale.EnglishUnitedKingdom]: '£',
  [Locale.EnglishUnitedStates]: '$',
  [Locale.EnglishAustralia]: '$',
  [Locale.GermanGermany]: '€',
  [Locale.EnglishBeta]: '$',
  [Locale.SpanishMexico]: '$',
  [Locale.FrenchCanada]: '$',
  [Locale.GermanAustria]: '€',
};

export const toCurrencySymbol = (locale: Locale) => localeCurrencySymbolMap[locale];
