import { curry, lensProp, set } from 'ramda';
import type { Exception } from '@ecomm/exceptions/Exception';
import { toException } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { PaymentGateway } from '@ecomm/payment';
import type { Partner } from '@onewellness/models';
import type { Audience } from '@onewellness/routes';

export type TOSFlags = {
  hasAcceptedPolicy: boolean;
  hasAcceptedPaymentMethodTerms: boolean;
  hasAcceptedContinuationOfTerms: boolean;
};

export enum ActionTypes {
  ApplyBenefit = 'onewellness/apply-benefit/APPLY_BENEFIT_REQUESTED',
  ApplyBenefitSucceeded = 'onewellness/apply-benefit/APPLY_BENEFIT_SUCCEEDED',
  ApplyBenefitFailed = 'onewellness/apply-benefit/APPLY_BENEFIT_FAILED',
  ApplyBenefitLoading = 'onewellness/apply-benefit/APPLY_BENEFIT_LOADING',
  UserFlagUpdated = 'onewellness/apply-benefit/user/FLAG_UPDATED',
}

export type State = UIState & {
  audience?: Audience;
  redirectToBenefitsOptions: boolean;
  tos: TOSFlags;
};

export const defaultState = {
  ...toUIState(Status.Init),
  redirectToBenefitsOptions: true,
  tos: {
    hasAcceptedPolicy: false,
    hasAcceptedPaymentMethodTerms: false,
    hasAcceptedContinuationOfTerms: false,
  },
};

const reducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.UserFlagUpdated: {
      const { name, value } = action.payload;
      const updateLens = lensProp(name);

      return { ...state, tos: set(updateLens, value, state.tos) };
    }
    case ActionTypes.ApplyBenefit:
      return {
        ...state,
        ...toUIState(Status.Loading),
        audience: action.payload.audience,
        token: action.payload.token,
        partner: action.payload.partner,
        paymentGateway: action.payload.paymentGateway,
        redirectToBenefitsOptions: action.payload.redirectToBenefitsOptions,
        purchaseDigitalSub: action.payload.purchaseDigitalSub,
      };
    case ActionTypes.ApplyBenefitSucceeded:
      return {
        ...state,
        ...toUIState(Status.Loaded),
      };
    case ActionTypes.ApplyBenefitFailed: {
      const { exception: exceptionPayload, errorCode } = action.payload;
      const { status, ...rest } = toUIState(Status.Failed, exceptionPayload);
      const error = { errorCode, status, ...rest };

      return {
        ...state,
        ...error,
      };
    }
    case ActionTypes.ApplyBenefitLoading: {
      return {
        ...state,
        ...toUIState(Status.Loading),
      };
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  applyBenefit: State;
};

export const updateUserFlag = curry(
  (name: keyof TOSFlags, value: boolean): UpdateUserFlagAction => ({
    type: ActionTypes.UserFlagUpdated,
    payload: { name, value },
  }),
);

export const requestApplyBenefit = (
  audience: Audience,
  token: string,
  redirectToBenefitsOptions: boolean,
  partner?: Partner,
  paymentGateway?: PaymentGateway,
  purchaseDigitalSub?: boolean,
): ApplyBenefitAction => ({
  type: ActionTypes.ApplyBenefit,
  payload: {
    audience,
    token,
    redirectToBenefitsOptions,
    partner,
    paymentGateway,
    purchaseDigitalSub,
  },
});

export const applyBenefitSucceeded = (): ApplyBenefitSuccessAction => ({
  type: ActionTypes.ApplyBenefitSucceeded,
});
export const applyBenefitLoading = (): ApplyBenefitLoadingAction => ({
  type: ActionTypes.ApplyBenefitLoading,
});

export const applyBenefitFailed = (
  errorId: string = 'onewellness.errors.applyBenefit',
  errorCode: number = 400,
): ApplyBenefitFailedAction => ({
  type: ActionTypes.ApplyBenefitFailed,
  payload: { exception: toException(errorId), errorCode },
});

export type UpdateUserFlagAction = {
  type: ActionTypes.UserFlagUpdated;
  payload: { name: keyof TOSFlags; value: boolean };
};

export type ApplyBenefitAction = {
  type: ActionTypes.ApplyBenefit;
  payload: {
    audience: Audience;
    token: string;
    redirectToBenefitsOptions: boolean;
    partner?: Partner;
    paymentGateway?: PaymentGateway;
    purchaseDigitalSub?: boolean;
  };
};
type ApplyBenefitSuccessAction = { type: ActionTypes.ApplyBenefitSucceeded };
type ApplyBenefitLoadingAction = { type: ActionTypes.ApplyBenefitLoading };
type ApplyBenefitFailedAction = {
  type: ActionTypes.ApplyBenefitFailed;
  payload: { exception: Exception; errorCode: number };
};

export type Actions =
  | UpdateUserFlagAction
  | ApplyBenefitAction
  | ApplyBenefitSuccessAction
  | ApplyBenefitFailedAction
  | ApplyBenefitLoadingAction;
