import type { ActionCreator, AnyAction, ReducersMapObject, StoreCreator } from 'redux';
import { createStore as createStoreRedux } from 'redux';
import type { SagaIterator } from 'redux-saga';
import { runSagas, toSagaMiddleware } from './sagas';
import toDefaultMiddleware from './toMiddleware';
import toRootReducer from './toRootReducer';

type State = Record<string, any>;

const toStore = <T extends object>({
  appInit,
  reducers = {},
  sagas = [],
  context,
  initialState = {},
  middleware: toMiddleware = toDefaultMiddleware,
  rootReducer = toRootReducer,
  createStore = createStoreRedux,
  createSagaMiddleware = toSagaMiddleware,
  onError,
}: StoreOpts<T>) => {
  const reducer = rootReducer(reducers);
  const sagaMiddleware = createSagaMiddleware<T>({ context, onError });
  const store = createStore(reducer, initialState, toMiddleware([sagaMiddleware]));

  runSagas(sagaMiddleware, sagas);

  if (appInit) {
    store.dispatch(appInit());
  }

  return store;
};

type StoreOpts<T extends object> = {
  appInit?: ActionCreator<AnyAction>;
  reducers?: ReducersMapObject;
  sagas?: (() => SagaIterator)[];
  middleware?: typeof toDefaultMiddleware;
  rootReducer?: typeof toRootReducer;
  createStore?: StoreCreator;
  createSagaMiddleware?: typeof toSagaMiddleware;
  context?: T;
  initialState?: State;
  onError?(error: Error): void;
};

export default toStore;
