import { AuthEnv } from './authClient';

// this cookie is applied for single-sign-off
// but is being reused here to determine logged in state without syncing with Auth0 first
const loggedInCookieName = (authEnv: AuthEnv) => {
  switch (authEnv) {
    case AuthEnv.Stage:
      return 'stage_peloton_visited_sites';

    case AuthEnv.Test:
      return 'test_peloton_visited_sites';

    case AuthEnv.Prod:
    default:
      return 'peloton_visited_sites';
  }
};

// if this cookie exists:
// then the member has most likely logged in (perhaps on a different subdomain)
// and it should be safe to call loginWithRedirect to sync the app authentication state with Auth0

// if this cookie does not exist:
// then the member is most likely not logged in elsewhere
// but it is possible that the cookie has been cleared or prevented from being set so this is not 100% guaranteed
export const getContainsLoginCookie = (authEnv: AuthEnv) => {
  return !!document?.cookie
    .split(' ')
    .find(c => c.split('=')[0] === loggedInCookieName(authEnv));
};
