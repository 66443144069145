import { pipe } from 'ramda';
export const PROFILE = 'profile';
export const MEMBERS = 'members';

export const PROFILE_BASE_PATH = `/${PROFILE}`;
export const MEMBER_BASE_PATH = `/${MEMBERS}`;
export const MEMBERS_OVERVIEW_SUBPATH = '/:userId/overview';
export const OVERVIEW_SUBPATH = '/overview';

export type Joinable = {
  basePath: string;
  subPath: string;
};

const maybeStripLeadingSlash = (str: string): string =>
  str.charAt(0) === '/' ? str.slice(1) : str;

export const toString = ({ basePath, subPath }: Joinable): string =>
  [basePath, maybeStripLeadingSlash(subPath)].join('/');

export const setUserId = (userId: string) => ({
  basePath,
  subPath,
}: Joinable): Joinable => ({
  basePath,
  subPath: subPath.replace(/:userId/, userId),
});

export const toProfileUrl = (
  mePath: string,
  memberPath: string,
  id: string,
  isMe: boolean,
): string =>
  isMe || !id
    ? toString({ basePath: PROFILE_BASE_PATH, subPath: mePath })
    : pipe(setUserId(id), toString)({ basePath: MEMBER_BASE_PATH, subPath: memberPath });

export const toOverviewUrl = (username: string, isMe: boolean = false) =>
  toProfileUrl(OVERVIEW_SUBPATH, MEMBERS_OVERVIEW_SUBPATH, username, isMe);
