import React from 'react';
import { useSelector } from 'react-redux';
import { track } from '@peloton/analytics';
import { getAppName } from '@peloton/env';
import { getUserTrackingProperties } from '@ecomm/auth';
import type { Exception } from '@ecomm/exceptions/Exception';
import type { UIState } from '@ecomm/models';
import type { Partner } from '@onewellness/models';
import type { Audience } from '@onewellness/routes';
import { isComplete } from '@onewellness/utils/models/UIState';
import { OnboardingStep } from '@onewellness/utils/onboardingStep';

const CHECK_ELIGIBILITY_STEP_NAME = 'Check Eligibility';

export type SharedTrackingEligibilityProps = {
  audience: Audience;
  exception?: Exception;
  partner?: Partner;
};

export type UserTrackingEligibilityProps = SharedTrackingEligibilityProps & {
  userTrackingProperties: ReturnType<typeof getUserTrackingProperties>;
};

type TrackStepProps = UserTrackingEligibilityProps & {
  step: number;
  stepName: string;
};

type TrackCompletedProps = TrackStepProps & {
  email?: string;
  success?: boolean;
};

type TrackEventProps = UserTrackingEligibilityProps & {
  event: string;
};

type TrackingViewProps = SharedTrackingEligibilityProps & {
  onboardingStep: OnboardingStep;
  partnerUIState: UIState;
};

export const useTrackEligibilityStepViewed = ({
  audience,
  exception,
  onboardingStep,
  partner,
  partnerUIState,
}: TrackingViewProps) => {
  const [hasTracked, setHasTracked] = React.useState(false);
  const userTrackingProperties = useSelector(getUserTrackingProperties);
  const appName = useSelector(getAppName);

  let step = 1;
  let stepName = CHECK_ELIGIBILITY_STEP_NAME;

  switch (onboardingStep) {
    case OnboardingStep.checkYourInbox:
      step = 2;
      stepName = 'Verify Eligibility';
      break;
    case OnboardingStep.complete:
      step = 3;
      switch (appName) {
        case 'www':
          stepName = 'Create Account';
          break;
        default:
          stepName = 'Apply Benefit';
          break;
      }
      break;
    default:
      break;
  }

  React.useEffect(() => {
    if (!hasTracked && isComplete(partnerUIState)) {
      setHasTracked(true);
      trackEligibilityStepViewed({
        audience,
        exception,
        partner,
        step,
        stepName,
        userTrackingProperties,
      });
    }
  }, [
    audience,
    exception,
    hasTracked,
    partner,
    partnerUIState,
    step,
    stepName,
    userTrackingProperties,
  ]);
};

export const trackLookupSuccess = ({
  audience,
  email,
  partner,
  userTrackingProperties,
}: UserTrackingEligibilityProps & { email: string }) =>
  trackEligibilityStepCompleted({
    audience,
    email,
    partner,
    step: 1,
    stepName: CHECK_ELIGIBILITY_STEP_NAME,
    success: true,
    userTrackingProperties,
  });

export const trackLookupFailed = ({ ...props }: UserTrackingEligibilityProps) =>
  trackEligibilityStepCompleted({
    step: 1,
    stepName: CHECK_ELIGIBILITY_STEP_NAME,
    success: false,
    ...props,
  });

export const trackCreateAccountSuccess = ({
  audience,
  email,
  partner,
  userTrackingProperties,
}: UserTrackingEligibilityProps & { email: string }) => {
  trackEligibilityStepCompleted({
    audience,
    email,
    partner,
    step: 3,
    stepName: 'Create Account',
    success: true,
    userTrackingProperties,
  });
};

export const trackEligibilityStepCompleted = ({ ...props }: TrackCompletedProps) =>
  trackEvent({
    event: 'Eligibility Step Completed',
    ...props,
  });

export const trackSsoPage = (exception: Exception | undefined = undefined) =>
  track({
    event: 'Eligibility SSO Page Viewed',
    properties: {
      errorState: exception,
      page: window.location.pathname,
      promotion: 'Corporate Wellness',
    },
  });

type TrackingPropsWithStepname = UserTrackingEligibilityProps & {
  stepName: string;
};

export const ClaimBenefitStepName = 'Claim Benefit';

export enum ApplyBenefitSuccessTypes {
  DirectPaymentScreen = 'Direct Payment Screen',
  ThirdPartyPaymentScreen = 'Third-Party Payment Screen',
}

export const trackApplyBenefitSuccess = ({ ...props }: TrackingPropsWithStepname) =>
  trackEligibilityStepCompleted({
    step: 3,
    success: true,
    ...props,
  });

export const trackApplyBenefitFailure = ({ ...props }: UserTrackingEligibilityProps) =>
  trackEligibilityStepCompleted({
    step: 3,
    stepName: ClaimBenefitStepName,
    success: false,
    ...props,
  });

const trackEligibilityStepViewed = ({ ...props }: TrackStepProps) =>
  trackEvent({
    event: 'Eligibility Step Viewed',
    ...props,
  });

export const trackEvent = ({
  audience,
  exception,
  event,
  userTrackingProperties,
  partner,
  ...additionalProps
}: TrackEventProps & any) => {
  track({
    event: event,
    properties: {
      audience,
      errorState: exception,
      ...userTrackingProperties,
      partnerName: partner?.name ?? '',
      page: window.location.pathname,
      promotion: 'Corporate Wellness',
      ...additionalProps,
    },
  });
};
