import environmentFlags from '../environmentFlags';
import type { EnvironmentFlags } from '../models';

export type State = EnvironmentFlags;

const toDefaultState = () => environmentFlags('')();

const reducer = (state: State = toDefaultState()) => state;

export type ReducerState = {
  flags: State;
};

export default reducer;
