import type ContentAggregate from '../aggregates/ContentAggregate';
import mapToView from './mapToView';

// https://app.contentful.com/spaces/6jnflt57iyzx/environments/master/entries/17b9uMjPbseVxMvQ5eGB3V
export const PRIVACY_POLICY_ENTRY_ID = '17b9uMjPbseVxMvQ5eGB3V';

export default {
  entryId: PRIVACY_POLICY_ENTRY_ID,
  contentType: 'privacyPolicy',
  customExtractor: mapToView,
} as ContentAggregate;
