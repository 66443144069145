import { useCallback, useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { isBrowser } from '@peloton/browser/identify';

export const LOCAL_STORAGE_CUSTOM_EVENT_TYPE = 'localStorage';

export type Payload<T> = [
  value: T | null,
  setValue: (value: T) => void,
  removeValue: () => void,
];

const useLocalStorage = <T>(key: string, initialValue: T | null): Payload<T> => {
  const getValue = useCallback((): T | null => {
    if (!isBrowser) {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T | null>(getValue);

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value: T): void => {
      try {
        if (!isBrowser) {
          return;
        }
        const oldValue = window.localStorage.getItem(key);
        window.localStorage.setItem(key, JSON.stringify(value));

        if (oldValue !== JSON.stringify(value)) {
          window.dispatchEvent(
            new StorageEvent(LOCAL_STORAGE_CUSTOM_EVENT_TYPE, {
              key,
            }),
          );
        }

        setStoredValue(value);
      } catch (error) {
        console.log(error);
      }
    },
    [key],
  );

  const handleStorageEvent = useCallback(
    (event: StorageEvent): void => {
      if (event.key !== key) {
        return;
      }
      setStoredValue(getValue());
    },
    [key, getValue],
  );

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    window.addEventListener(LOCAL_STORAGE_CUSTOM_EVENT_TYPE, handleStorageEvent);

    return () =>
      window.removeEventListener(LOCAL_STORAGE_CUSTOM_EVENT_TYPE, handleStorageEvent);
  }, [handleStorageEvent, key]);

  const removeValue = useCallback((): void => {
    try {
      if (!isBrowser) {
        return;
      }

      window.localStorage.removeItem(key);

      setStoredValue(null);
    } catch (error) {
      console.log(error);
    }
  }, [key]);
  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
