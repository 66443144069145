import { schema } from 'normalizr';
import productOptionSchema from '@ecomm/shop/api/schemas/option';
import type { ApiOrderItem } from '../types';
import couponSchema from './coupon';

const processOrderItems = ({
  bundleOptionId,
  coupon,
  grossInCents: price,
  id,
  productOption,
  quantity,
}: ApiOrderItem) => ({
  coupon,
  id,
  price,
  productOption,
  quantity,
});

const orderItem = new schema.Entity(
  'orderItems',
  { productOption: productOptionSchema, coupon: couponSchema },
  { processStrategy: processOrderItems },
);

export default orderItem;
