import { pathOr, pickAll, keys, map, mapAccum, values, zipObj } from 'ramda';

export type Image = {
  url: string;
  description: string;
};

export type ImageBreakpoints = {
  mobile?: Image;
  tablet?: Image;
  desktop?: Image;
  desktopLarge?: Image;
};

export type BpImageObjType = {
  mobile: string;
  tablet: string;
  desktop: string;
  desktopLarge: string;
};

export enum TrackKind {
  Captions = 'captions',
  Descriptions = 'descriptions',
  Subtitles = 'subtitles',
}

export type Track = {
  src: string;
  kind: TrackKind;
};

export type Media = {
  title?: string;
  url: string;
  description?: string;
};

export type Video = {
  key?: string;
  poster: Media;
  video: Media;
  tracks?: Track[];
};

export type BpVideo = {
  key?: string;
  mobile: Video;
  tablet: Video;
  desktop: Video;
  desktopLarge: Video;
};

export type VideoUrls = {
  poster: string;
  video: string;
};

export type BpVideoObjType = {
  desktop: VideoUrls;
  desktopLarge: VideoUrls;
  mobile: VideoUrls;
  tablet: VideoUrls;
};

export type ImageData = {
  url: string;
  description: string;
};

export const IMAGE_BREAKPOINT_NAMES = ['mobile', 'tablet', 'desktop', 'desktopLarge'];

const getMediaUrls = pathOr(undefined, ['url']);
const mapUrlsToBreakpoints = (obj: object) =>
  zipObj(keys(obj), map(getMediaUrls, values(obj)));

// TODO: image breakpoints should be { url: '', description: '' }
export const toImgObj = (image: ImageBreakpoints): BpImageObjType => {
  const { mobile, tablet, desktop, desktopLarge }: BpImageObjType = pickAll(
    IMAGE_BREAKPOINT_NAMES,
    mapUrlsToBreakpoints(image),
  );

  return {
    mobile: mobile || '',
    tablet: tablet || '',
    desktop: desktop || '',
    desktopLarge: desktopLarge || '',
  };
};

export const toVidObj = (videoData: Video) => {
  const { poster, video }: { poster: Media; video: Media } = pickAll(
    ['poster', 'video'],
    mapUrlsToBreakpoints(videoData),
  );

  return {
    poster: poster || '',
    video: video || '',
  };
};

export const toBpVidObj = (bpVideo: BpVideo): BpVideoObjType => {
  const { mobile, tablet, desktop, desktopLarge }: BpVideoObjType = pickAll(
    IMAGE_BREAKPOINT_NAMES,
    zipObj(keys(bpVideo), map(toVidObj, values(bpVideo))),
  );

  return {
    mobile,
    tablet,
    desktop,
    desktopLarge,
  };
};

export const toImgWithFallbacks = (
  bpImage: Partial<ImageBreakpoints>,
): BpImageObjType => {
  const safeImage = {
    mobile: '',
    tablet: '',
    desktop: '',
    desktopLarge: '',
  };
  const objKeys = Object.keys(bpImage);
  objKeys.forEach(
    (key, i) => (safeImage[key] = bpImage[key]?.url || safeImage[objKeys[i - 1]]),
  );
  return safeImage;
};

const imageReducer = (a: ImageData, b: ImageData): [any, any] => {
  if (!b || !b.url) {
    return [a, a];
  }
  return [b, b];
};

export const imageGuard = (imgArray: ImageData[]) =>
  mapAccum(imageReducer, {}, imgArray)[1];
