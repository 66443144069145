export const mapCohort = (cohort: any) => {
  return {
    ...cohort,
    products: cohort.products.map((product: any) => {
      if (product.factoidIcon) {
        const {
          fields: {
            title,
            file: { url },
          },
        } = product.factoidIcon;

        return {
          ...product,
          factoidIcon: {
            url,
            title,
          },
        };
      }
      return product;
    }),
  };
};

const mapToView = (content: any) => {
  const {
    'productRecommendations.addedToCart': { value: addedToCart },
    'productRecommendations.inYourCart': { value: inYourCart },
    'productRecommendations.quickAdd': { value: quickAdd },
    'productRecommendations.deliveryAndSetup': { value: deliveryAndSetup },
    'productRecommendations.loadingHeadline': { value: loadingHeadline },
    'productRecommendations.delivery': { value: delivery },
    'productRecommendations.freeReturns': { value: freeReturns },
    'productRecommendations.addToCart': { value: addToCart },
    'productRecommendations.factoidHeading': { value: factoidHeading },
    'productRecommendations.productDetails': { value: productDetails },
    'productRecommendations.selectAttribute': { value: selectAttribute },
    'productRecommendations.isRequired': { value: isRequired },
    'productRecommendations.dropdownOptionSelected': { value: dropdownOptionSelected },

    'errors.default': { value: defaultError },
    ...cohorts
  } = content['product-recommendations'];

  return {
    copy: {
      addedToCart,
      inYourCart,
      quickAdd,
      deliveryAndSetup,
      loadingHeadline,
      delivery,
      freeReturns,
      addToCart,
      factoidHeading,
      productDetails,
      selectAttribute,
      isRequired,
      dropdownOptionSelected,
      defaultError,
    },
    cohorts,
  };
};

export default mapToView;
