import { schema } from 'normalizr';
import type { SubscriptionPlan } from '../../models';
import { Interval, PlanType } from '../../models';

export type ApiSubscriptionPlan = {
  description: string;
  displayName: string;
  doesRollover: boolean;
  id: string;
  imageUrls: string[];
  interval: string;
  isTaxCalculated: boolean;
  recurringPriceInCents: number;
  requireAccessCode: boolean;
  shouldChargeOnFulfill: boolean;
  sku: string;
  slug: string;
  subscriptionType: string;
  taxInCents: number;
  rolloverSku: string;
  initialOffsetDays: number;
};

const processPlan = ({
  description,
  displayName: name,
  doesRollover,
  id,
  imageUrls: images,
  isTaxCalculated,
  initialOffsetDays: offsetDays,
  interval,
  recurringPriceInCents: price,
  requireAccessCode,
  shouldChargeOnFulfill: chargesOnFulfill,
  sku,
  slug,
  subscriptionType,
  taxInCents: tax,
  rolloverSku,
}: ApiSubscriptionPlan): SubscriptionPlan => ({
  chargesOnFulfill,
  description,
  doesRollover,
  id,
  images,
  interval: toInterval(interval),
  isTaxCalculated,
  name,
  offsetDays,
  planType: toPlanType(subscriptionType),
  requireAccessCode,
  price,
  sku,
  slug,
  tax,
  rolloverSku,
});

const planSchema = new schema.Entity('plans', {}, { processStrategy: processPlan });

export default planSchema;

const toPlanType = (subscriptionType: string) =>
  subscriptionType === 'digital' ? PlanType.Digital : PlanType.Device;

const toInterval = (interval: string) => Interval.Monthly;
