import { path } from 'ramda';
import type { ClientError } from '@peloton/api';
import { isHttpError, getApiErrorType, ApiErrors } from '@peloton/api';

// Change Password Errors
export class InvalidPasswordChangeError extends Error {
  public static errorKey = 'AuthInvalidPasswordChangeError';
  public name = InvalidPasswordChangeError.errorKey;
}

export const isInvalidPasswordChangeError = (error: Error) =>
  error.name === InvalidPasswordChangeError.errorKey;

export const handlePasswordChangeError = (err: ClientError) => {
  if (isHttpError(err) && err.status === 401) {
    throw new InvalidPasswordChangeError(err.message);
  } else {
    throw err;
  }
};

// Create Account Errors
export enum AccountSubErrors {
  EmailAlreadyInUse = 'EmailAlreadyInUse',
  UsernameAlreadyInUse = 'UsernameAlreadyInUse',
  UsernameInvalid = 'UsernameInvalid',
}

const getCreateAccountApiError = (err: ClientError) => {
  switch (path(['response', 'data', 'subcode'], err)) {
    case 30: {
      return AccountSubErrors.EmailAlreadyInUse;
    }
    case 10: {
      return AccountSubErrors.UsernameAlreadyInUse;
    }
    case 11: {
      return AccountSubErrors.UsernameInvalid;
    }
    default: {
      throw 'Error Create Account API: Unhandled API error for STATUS 400';
    }
  }
};

// Forbidden access to account (deactivation) errors
export enum AccountForbiddenErrors {
  AccountNotActive = 'AccountNotActive',
  AccountLocked = 'AccountLocked',
}

const getDeactivatedAccountApiError = (err: ClientError) => {
  switch (path(['response', 'data', 'errorCode'], err)) {
    case 3140: {
      return AccountForbiddenErrors.AccountNotActive;
    }
    case 3150: {
      return AccountForbiddenErrors.AccountLocked;
    }
    default: {
      return ApiErrors.FORBIDDEN;
    }
  }
};

export const handleAccountApiError = (
  err: ClientError,
): AccountSubErrors | ApiErrors | AccountForbiddenErrors => {
  const apiError = getApiErrorType(err);
  // bad request means some validation is wrong.
  if (ApiErrors.BAD_REQUEST === apiError) {
    const parsedError = getCreateAccountApiError(err);
    throw parsedError;
  } else if (ApiErrors.FORBIDDEN === apiError) {
    // forbidden request means account has been deactivated or locked.
    const deactivatedAccountError = getDeactivatedAccountApiError(err);
    throw deactivatedAccountError;
  } else {
    throw apiError;
  }
};
