import { schema } from 'normalizr';
import { pluck, not, any, compose, equals } from 'ramda';
import { Currency } from '@peloton/internationalize';
import type { Address, CreditCard } from '@ecomm/models';
import type { ApiBilling, ApiOrder, ApiShipment } from '../types';
import bundleOption from './bundleOption';
import couponSavingsSchema from './couponSavings';
import orderItem from './orderItem';
import payment from './payment';

const processOrders = ({
  billing,
  bundleOptionSet: bundles,
  confirmationCode,
  containsPreorder,
  couponSavings,
  createdAt,
  currency,
  email,
  id,
  isNewUser,
  itemSet: items,
  shipmentSet: shipments,
  shippingAddressee,
  shippingCity,
  shippingCountry,
  shippingState,
  shippingPostalCode,
  shippingStreetAddress1,
  shippingStreetAddress2,
  shippingPhoneNumber,
  shippingPaidInCents: shipping,
  status,
  subtotalInCents,
  grossSubtotalInCents,
  paymentSet: payments,
  referrerId,
  taxPaidInCents: taxPaid,
  totalWithTaxPaidInCents: totalWithTaxPaid,
}: ApiOrder & { billing: ApiBilling[] }) => ({
  billingAddress: toBillingAddress(billing),
  bundles,
  card: toCard(billing),
  confirmationCode,
  containsPreorder,
  couponSavings,
  createdAt: createdAt * 1000, // Convert date stamp to timestamp
  currency: toCurrency(currency),
  deliveryDate: toDeliveryDate(shipments),
  email,
  id,
  isNewUser, // TODO: send to user
  items: items.filter(item => item.bundleOptionId === null),
  phone: shippingPhoneNumber,
  shippingAddress: {
    addressee: shippingAddressee,
    city: shippingCity,
    country: shippingCountry,
    state: shippingState,
    postalCode: shippingPostalCode,
    line1: shippingStreetAddress1,
    line2: shippingStreetAddress2,
  },
  shipping,
  thirdPartyShipping: toThirdPartyShipping(shipments),
  status,
  subtotal: grossSubtotalInCents || subtotalInCents,
  payments,
  referrerId,
  taxPaid,
  totalWithTaxPaid,
});

const order = new schema.Entity(
  'orders',
  {
    bundles: [bundleOption],
    couponSavings: [couponSavingsSchema],
    items: [orderItem],
    payments: [payment],
  },
  { processStrategy: processOrders },
);

export default order;

const toBillingAddress = ([billing]: ApiBilling[] = []): Address | {} => {
  if (!billing) {
    return {};
  }

  const {
    name: addressee,
    addressLine1: line1,
    addressLine2: line2,
    addressCity: city,
    addressState: state,
    addressZip: postalCode,
    addressCountry: country,
  } = billing;

  return { addressee, line1, line2, city, state, postalCode, country };
};

const toCard = ([billing]: ApiBilling[] = []): CreditCard | {} => {
  if (!billing) {
    return {};
  }

  const { last4, brand, expMonth, expYear } = billing;

  return { brand, last4, expYear, expMonth };
};

const toCurrency = (currency: string): Currency => {
  switch (currency) {
    case 'EUR':
      return Currency.Euro;

    case 'CAD':
      return Currency.CanadianDollar;

    case 'GBP':
      return Currency.PoundSterling;

    case 'USD':
      return Currency.UnitedStatesDollars;

    case 'AUD':
      return Currency.AustralianDollars;

    default:
      return Currency.Default;
  }
};

const toDeliveryDate = (shipments: ApiShipment[]): string | undefined => {
  const shipmentWithDelivery = shipments.find(shipment =>
    Boolean(shipment.scheduledDeliveryDate),
  );
  return shipmentWithDelivery ? shipmentWithDelivery.scheduledDeliveryDate : undefined;
};

const toThirdPartyShipping: (shipments: Array<ApiShipment>) => boolean = compose(
  not,
  any(equals('peloton')),
  pluck('partnerFlag'),
);
