import type {
  ErrorProperties,
  ErrorReporter,
  ErrorReporterFactory,
  ReportError,
} from './ErrorReporter';

export type ErrorHandler = {
  reportError: ReportError;
  sagaOnError: ReportError;
};

export const configureErrorHandler = (
  reporterFactories: ErrorReporterFactory[] = [],
  config: object = {},
): ErrorHandler => {
  const reporters = reporterFactories.map(reporter => reporter(getConfig(config)));

  return {
    reportError: createReportError(reporters),
    sagaOnError: createSagaOnError(reporters),
  };
};

const getConfig = (config: object) => ({
  release: process.env.REACT_APP_VERSION,
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
  includePaths: [
    // should match all onepeloton.com domains regardless of subdomain(s)
    /https?:\/\/([^.]+\.)*?onepeloton\.com/,
  ],
  ignoreUrls: [
    /https?:\/\/local-([^.]+\.)*?onepeloton\.com/,
    /127\.0\.0\.1/,
    /https?:\/\/([^.]+\.)*?example\.com/,
  ],
  ...config,
});

const createSagaOnError = (reporters: ErrorReporter[]) => (error: Error) =>
  reporters.forEach(reporter =>
    reporter.reportError(error, { tags: { type: 'redux-saga' } }),
  );

const createReportError = (reporters: ErrorReporter[]) => (
  error: Error,
  properties: ErrorProperties,
) => reporters.forEach(reporter => reporter.reportError(error, properties));
