import { toCountry, Country } from '@peloton/internationalize';

export type SegmentKey =
  | CanadaSegmentKey
  | UKSegmentKey
  | USSegmentKey
  | GermanySegmentKey
  | AustraliaSegmentKey;

enum USSegmentKey {
  Dev = 'Zo5hOgXEuFffq7hJAb2A9I7YU6hE9wZG',
  Staging = 'B5VGn9ACcaxBrHvr460jpYSDw4kIp5oY',
  Prod = 'uVsMX4gpbvPnCfGtPffjBXqFeQktc5bp',
}

enum UKSegmentKey {
  Dev = 'w0fkDme0abdapGosCI1o948SZuz9FA1H',
  Staging = 'ioER2uhtXCI9nImaxSHTsJ2soDVjLp7E',
  Prod = 'RNztfSX4vF95gXLroOOVenDuF2uSYEk1',
}

enum CanadaSegmentKey {
  Dev = 'd67V6s0QLszqmqAvZD09VyrQC4wBGkkI',
  Staging = '4uy13dweh8nxFlmxeB9YaeNskH7TEW2a',
  Prod = 'MqFPpOulFb9qibD0Qj5rQVKccPNMukm6',
}

enum GermanySegmentKey {
  Dev = 'Drz1PrRsVv1wEL515IDVbQq5HCkUwqMd',
  Staging = 'IJdFXhxtRqZE5XWx1yvaRDuwFPfF7OkQ',
  Prod = 'kDc1tm2P9YautyYiubgJz49Gl32s1mDh',
}

enum AustraliaSegmentKey {
  Dev = 'ZP9xRFODAh0XdcepHBVm7cfmfIyk7QTG',
  Staging = 'ZP9xRFODAh0XdcepHBVm7cfmfIyk7QTG',
  Prod = 'lmHotPrF0G0lchAXt1iZyERjoebQXinN',
}

const toSegmentKeyEnum = (country = toCountry()) => {
  switch (country) {
    case Country.UnitedStates:
      return USSegmentKey;
    case Country.UnitedKingdom:
      return UKSegmentKey;
    case Country.Canada:
      return CanadaSegmentKey;
    case Country.Germany:
      return GermanySegmentKey;
    case Country.Australia:
      return AustraliaSegmentKey;
    default:
      return USSegmentKey;
  }
};

export { toSegmentKeyEnum };
