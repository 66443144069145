import type { ContentfulClientApi, CreateClientParams } from 'contentful';
import { createClient } from 'contentful';

type ContentClient = {
  getClient: (preview: boolean) => ContentfulClientApi;
};

type EnhancedCreateClientParams = CreateClientParams & {
  previewAccessToken?: string;
};

export const createClients = (params: EnhancedCreateClientParams) => {
  const { previewAccessToken, ...restOfParams } = params;

  const client = createClient(restOfParams);

  // If the preview access token is not defined use the regular client whose access token should always be defined in app config.
  const previewClient = previewAccessToken
    ? createClient({
        ...restOfParams,
        accessToken: previewAccessToken,
        host: 'preview.contentful.com',
      })
    : client;

  return {
    client,
    previewClient,
  };
};

export const createClientHelpers = (
  client: ContentfulClientApi,
  previewClient: ContentfulClientApi,
): ContentClient => ({
  getClient: (preview?: boolean) => (preview ? previewClient : client),
});
