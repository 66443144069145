import { StudioLinkEnvs } from '@peloton/external-links/models';
import type { EnvironmentFlags } from './environmentFlags';

const toLinkEnv = ({
  isProd,
  isStaging,
  isLocal,
}: Pick<
  EnvironmentFlags,
  'isProd' | 'isStaging' | 'isLocal'
>): typeof StudioLinkEnvs[keyof typeof StudioLinkEnvs] =>
  (isProd && StudioLinkEnvs.Prod) ||
  (isStaging && StudioLinkEnvs.Staging) ||
  (isLocal && StudioLinkEnvs.Local) ||
  StudioLinkEnvs.UatQa;

export default toLinkEnv;
