const mapToView = (content: any) => {
  return {
    subnavs: content['subnavs'],
    geoMenu: content['geoMenu'],
    userMenu: content['userMenu'],
    ariaLabel: content['ariaLabel'],
    logoAriaLabel: content['logoAriaLabel'],
    panelNavLinks: content['panelNavLinks'],
    hamburgerButtonLabel: content['hamburgerButtonLabel'],
    cartButtonLabel: content['cartButtonLabel'],
    toggleLocator: content['toggleLocator'],
    toggleAccount: content['toggleAccount'],
    toggleCart: content['toggleCart'],
  };
};

export default mapToView;
