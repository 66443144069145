export enum Severity {
  Error = 'error',
  Warning = 'warning',
}

export enum DigitalErrors {
  ExistingSub = 'errors.existingSub',
  FreeTrialUsed = 'errors.freeTrialUsed',
}

export type Errors = DigitalErrors;
