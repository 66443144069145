import type { SubscriptionPlan } from './models';

export enum ActionType {
  Add = 'peloton/subscription-plans/ADD_SUBSCRIPTION_PLANS',
}

export type State = Record<string, SubscriptionPlan>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add:
      return {
        ...state,
        ...action.payload.plans,
      };

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  subscriptionPlans: State;
};

export const addPlans = (plans: Record<string, SubscriptionPlan>) => ({
  type: ActionType.Add,
  payload: { plans },
});

type AddPlansAction = {
  type: ActionType.Add;
  payload: { plans: Record<string, SubscriptionPlan> };
};

type Action = AddPlansAction;
