import { pathOr, pickAll } from 'ramda';
import type { PromotionalText } from '../models.generated';
import { usePromotionalTextQuery } from '../queries/contentTypes/promotionalText.generated';

export type PromotionalTextData = {
  key: string;
  text: string;
  __typename: string;
};

export const transformPromotionalTextData = (response: object) => {
  const { key, text }: PromotionalTextData = pickAll(
    ['key', 'text'],
    pathOr({}, ['data', 'promotionalText'], response),
  );

  return text || `Promotional Text: ${key}`;
};

const usePromotionalTextData = (id: PromotionalText = '' as PromotionalText) => {
  const res = usePromotionalTextQuery({ variables: { id } });

  return transformPromotionalTextData(res);
};

export default usePromotionalTextData;
