const mapToView = (content: any) => ({
  accessories: content['apparel-plp']['apparel-plp.accessories'],
  bestSelling: content['apparel-plp']['apparel-plp.bestSelling'],
  bottoms: content['apparel-plp']['apparel-plp.bottoms'],
  clearAll: content['apparel-plp']['apparel-plp.clearAll'],
  colors: content['apparel-plp']['apparel-plp.colors'],
  discipline: content['apparel-plp']['apparel-plp.discipline'],
  disciplines: content['apparel-plp']['apparel-plp.disciplines'],
  fabricCollection: content['apparel-plp']['apparel-plp.fabricCollection'],
  featured: content['apparel-plp']['apparel-plp.featured'],
  filters: content['apparel-plp']['apparel-plp.filters'],
  filtersSelected: content['apparel-plp']['apparel-plp.filtersSelected'],
  gender: content['apparel-plp']['apparel-plp.gender'],
  highestToLowest: content['apparel-plp']['apparel-plp.highestToLowest'],
  items: content['apparel-plp']['apparel-plp.items'],
  loadMore: content['apparel-plp']['apparel-plp.loadMore'],
  lowestToHighest: content['apparel-plp']['apparel-plp.lowestToHighest'],
  newArrivals: content['apparel-plp']['apparel-plp.newArrivals'],
  newestToOldest: content['apparel-plp']['apparel-plp.newestToOldest'],
  oldestToNewest: content['apparel-plp']['apparel-plp.oldestToNewest'],
  showItems: content['apparel-plp']['apparel-plp.showItems'],
  showingOf: content['apparel-plp']['apparel-plp.showingOf'],
  sortBy: content['apparel-plp']['apparel-plp.sortBy'],
  tops: content['apparel-plp']['apparel-plp.tops'],
});

export default mapToView;
