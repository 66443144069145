export enum PaymentMethod {
  CreditCard = 'Credit Card',
  Financing = 'Financing',
  PaymentRequest = 'Payment Request',
  ApplePay = 'Apple Pay',
  GooglePay = 'Google Pay',
  Unknown = 'unknown',
  KlarnaDirectDebit = 'Klarna Direct Debit',
  KlarnaInvoice = 'Klarna Invoice',
  KlarnaBankTransfer = 'Klarna Bank Transfer',
  GiftCard = 'Gift Card',
}

export type KlarnaPaymentMethods = typeof KlarnaOptions[number];

export const KlarnaOptions = [
  PaymentMethod.KlarnaDirectDebit,
  PaymentMethod.KlarnaBankTransfer,
  PaymentMethod.KlarnaInvoice,
];

export const isPaymentRequest = (paymentMethod?: PaymentMethod): boolean =>
  Boolean(
    paymentMethod &&
      (paymentMethod === PaymentMethod.PaymentRequest ||
        paymentMethod === PaymentMethod.ApplePay ||
        paymentMethod === PaymentMethod.GooglePay),
  );
